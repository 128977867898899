<div class="productsList">
  <div class="scrollingWrapper" #productList>
    <div class="scrollingContainer">
      <div class="scrolled" *ngFor="let jsonProduct of products">
        <norby-product [productJSON]="jsonProduct"></norby-product>
      </div>
    </div>
  </div>
  <div class="gradient leftGradient"></div>
  <div class="gradient rightGradient"></div>
  <norby-scroll-button
    *ngIf="leftVisible | async"
    [direction]="'left'"
    [ref]="productList"
    (scrolledNow)="onScroll($event)"
  >
  </norby-scroll-button>
  <norby-scroll-button
    *ngIf="(rightVisible | async) && products.length > 1"
    [direction]="'right'"
    [ref]="productList"
    (scrolledNow)="onScroll($event)"
  >
  </norby-scroll-button>
</div>
