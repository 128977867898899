import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  public currentWidgetType$ = new BehaviorSubject<string>('');

  reset(): void {
    if (this.currentWidgetType$.value) {
      this.currentWidgetType$.next('');
    }
  }
}
