<button [hidden]="hidden">
  <svg
    class="inner-svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 5.11765C3.75 4.36231 4.36231 3.75 5.11765 3.75H13.5882C14.3436 3.75 14.9559 4.36233 14.9559 5.11765V5.64706C14.9559 6.06127 15.2917 6.39706 15.7059 6.39706C16.1201 6.39706 16.4559 6.06127 16.4559 5.64706V5.11765C16.4559 3.53387 15.172 2.25 13.5882 2.25H5.11765C3.53389 2.25 2.25 3.53389 2.25 5.11765V13.5882C2.25 15.172 3.53387 16.4559 5.11765 16.4559C5.53186 16.4559 5.86765 16.1201 5.86765 15.7059C5.86765 15.2917 5.53186 14.9559 5.11765 14.9559C4.36233 14.9559 3.75 14.3436 3.75 13.5882V5.11765Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2942 7.54407C8.77541 7.54407 7.54419 8.77528 7.54419 10.2941V19C7.54419 20.5187 8.77541 21.75 10.2942 21.75H19.0001C20.5189 21.75 21.7501 20.5187 21.7501 19V10.2941C21.7501 8.77528 20.5189 7.54407 19.0001 7.54407H10.2942ZM9.04419 10.2941C9.04419 9.60371 9.60383 9.04407 10.2942 9.04407H19.0001C19.6904 9.04407 20.2501 9.60371 20.2501 10.2941V19C20.2501 19.6903 19.6904 20.25 19.0001 20.25H10.2942C9.60383 20.25 9.04419 19.6903 9.04419 19V10.2941Z"
      fill="black"
    />
  </svg>
</button>
