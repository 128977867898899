import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-accent-button',
  templateUrl: './accent-button.component.html',
  styleUrls: ['./accent-button.component.css'],
})
export class AccentButtonComponent {
  @Input() title = 'AccentButton';
  @Input() type: 'button' | 'submit' = 'button';
  @Input() isLoading = false;
  @Input() disabled = false;
}
