<article *ngIf="user" [class.closed]="isExpanded" class="user-widget">
  <header (click)="isExpanded = !isExpanded">
    <div class="header-main">
      <div class="header-main-inner">
        <div *ngIf="user.is_online" class="online-indicator"></div>
        <h2 class="header-name">{{ user.name || user.generated_name }}</h2>
      </div>
      <div class="header-last-seen">
        {{ (user.last_seen | date: "dd.MM.YY HH:mm") || "Unknown" }}
      </div>
    </div>
    <div *ngIf="!!user.email" class="header-email">{{ user.email }}</div>
    <div *ngIf="!user.email" class="unknown-email">Unknown email</div>
  </header>
  <ng-container *ngIf="!isExpanded">
    <section class="main">
      <norby-attributes-title
        [route]="'/settings/attributes/user'"
        [title]="'User Details'"
      ></norby-attributes-title>
      <div class="attributes-wrapper">
        <ng-container *ngFor="let attribute of user.attributes">
          <norby-attribute-list-item
            [name]="attribute.name"
            [value]="attribute.value || ''"
          ></norby-attribute-list-item>
        </ng-container>
      </div>
    </section>
  </ng-container>
</article>
<div class="line"></div>
