import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-clock-icon',
  templateUrl: './clock-icon.component.html',
  styleUrls: ['./clock-icon.component.css'],
})
export class ClockIconComponent {
  @Input() color = '#A8A8A8';
}
