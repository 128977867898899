<button class="search-button">
  <svg
    class="inner-svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      *ngIf="!isOpen"
      d="M18.9697 20.0303C19.2626 20.3232 19.7374 20.3232 20.0303 20.0303C20.3232 19.7374 20.3232 19.2625 20.0303 18.9696L18.9697 20.0303ZM16.4053 15.3446C16.1124 15.0517 15.6376 15.0517 15.3447 15.3446C15.0518 15.6375 15.0518 16.1124 15.3447 16.4053L16.4053 15.3446ZM17.0833 11.1667C17.0833 14.4344 14.4344 17.0833 11.1667 17.0833V18.5833C15.2628 18.5833 18.5833 15.2628 18.5833 11.1667H17.0833ZM11.1667 17.0833C7.89898 17.0833 5.25 14.4344 5.25 11.1667H3.75C3.75 15.2628 7.07055 18.5833 11.1667 18.5833V17.0833ZM5.25 11.1667C5.25 7.89898 7.89898 5.25 11.1667 5.25V3.75C7.07055 3.75 3.75 7.07055 3.75 11.1667H5.25ZM11.1667 5.25C14.4344 5.25 17.0833 7.89898 17.0833 11.1667H18.5833C18.5833 7.07055 15.2628 3.75 11.1667 3.75V5.25ZM20.0303 18.9696L16.4053 15.3446L15.3447 16.4053L18.9697 20.0303L20.0303 18.9696Z"
      fill="black"
    />
    <path
      *ngIf="isOpen"
      d="M16 8L8 16M8 8L16 16"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</button>
