import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[norbyAutoResize]',
})
export class AutoResizeDirective implements OnInit {
  constructor(public element: ElementRef) {}
  @Input() maximumHeight = 0;
  @Input() minHeight = 0;
  @HostListener('input', ['$event.target'])
  ngOnInit(): void {
    this.adjustCustom();
  }

  adjustCustom(): void {
    const element = this.element.nativeElement;
    element.style.height = this.minHeight + 'px';
    element.style.height = element.scrollHeight + 'px';
    if (element.scrollHeight <= this.maximumHeight) {
      element.style.overflowY = 'hidden';
      delete element.style.maxHeight;
    } else {
      element.style.overflowY = 'scroll';
      element.style.maxHeight = this.maximumHeight + 'px';
    }
  }
}
