<p class="billing-info-title">Billing Information</p>
<form class="billing-info-form" [formGroup]="billingDetailsForm">
  <div class="flex">
    <norby-autocomplete-select
      class="full-width"
      [label]="'Country'"
      [placeholder]="'Choose …'"
      [optionValues]="countries$"
      [currentValue]="(userBillingAddress$ | async)?.country?.country"
      (setOptionValue)="setCountry($event)"
    ></norby-autocomplete-select>
    <norby-select
      *ngIf="isCountriesWithState"
      class="full-width"
      [label]="'State'"
      [currentValue]="(userBillingAddress$ | async)?.state?.state"
      (setChangeValue)="setState($event)"
      [placeholder]="'Choose …'"
    >
    </norby-select>
    <div class="form-control">
      <label class="billing-info-label">City</label>
      <input
        autocomplete="off"
        class="billing-info-input"
        type="text"
        [value]="(userBillingAddress$ | async)?.city"
        placeholder="Your City …"
        formControlName="city"
      />
      <div
        *ngIf="
          billingDetailsForm.controls.city.invalid &&
          (billingDetailsForm.controls.city.dirty ||
            billingDetailsForm.controls.city.touched)
        "
        class="error"
      >
        <div *ngIf="billingDetailsForm.controls.city.errors?.required">
          Required field
        </div>
      </div>
    </div>
  </div>
  <div class="form-mid-side">
    <div class="form-control">
      <label class="billing-info-label">Address</label>
      <input
        autocomplete="off"
        class="billing-info-input"
        type="text"
        [value]="(userBillingAddress$ | async)?.line1"
        placeholder="Company Address …"
        formControlName="address"
      />
      <div
        *ngIf="
          billingDetailsForm.controls.address.invalid &&
          (billingDetailsForm.controls.address.dirty ||
            billingDetailsForm.controls.address.touched)
        "
        class="error"
      >
        <div *ngIf="billingDetailsForm.controls.address.errors?.required">
          Required field
        </div>
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="form-control">
      <label class="billing-info-label">Postal Code</label>
      <input
        autocomplete="off"
        class="billing-info-input"
        type="text"
        [value]="(userBillingAddress$ | async)?.postal_code"
        placeholder="Code …"
        formControlName="code"
      />
      <div
        *ngIf="
          billingDetailsForm.controls.code.invalid &&
          (billingDetailsForm.controls.code.dirty ||
            billingDetailsForm.controls.code.touched)
        "
        class="error"
      >
        <div *ngIf="billingDetailsForm.controls.code.errors?.required">
          Required field
        </div>
      </div>
    </div>
    <div class="form-control">
      <div class="label-input">
        <label class="billing-info-label">Registration Code</label>
        <p>Optional</p>
      </div>
      <input
        autocomplete="off"
        class="billing-info-input"
        type="text"
        [value]="(companyDetails$ | async)?.reg_number"
        placeholder="Code …"
        formControlName="regNumber"
      />
    </div>
    <div class="form-control">
      <div class="label-input">
        <label class="billing-info-label">VAT Code</label>
        <p>Optional</p>
      </div>
      <input
        autocomplete="off"
        class="billing-info-input"
        type="text"
        [value]="(companyDetails$ | async)?.vat_number"
        placeholder="Code …"
        formControlName="vatNumber"
      />
    </div>
  </div>
</form>
<div class="billing-info-link">
  <a class="info-url" [href]="updateLink$ | async" target="_blank"
    >Manage Tax IDs</a
  >
  <norby-external-link-icon
    [url]="updateLink$ | async"
  ></norby-external-link-icon>
</div>
