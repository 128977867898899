import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-question-circle',
  templateUrl: './question-circle.component.html',
  styleUrls: ['./question-circle.component.css'],
})
export class QuestionCircleComponent {
  @Input() link = '';
}
