import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SelectOption } from './../../../../../components/select/select.component';

@Component({
  selector: 'norby-attribute-add-popup',
  templateUrl: './attribute-add-popup.component.html',
  styleUrls: ['./attribute-add-popup.component.css'],
})
export class AttributeAddPopupComponent implements AfterContentChecked {
  @Output() openAddAttributeModal = new EventEmitter();
  @Output() createAttribute = new EventEmitter();
  @Input() popupTitle = '';
  @Input() addAttributePopupDescription = '';
  @Input() currentUrl = '';
  public dataTypes: SelectOption[] = [
    { key: 'text', value: 'Text' },
    { key: 'number', value: 'Number' },
    { key: 'date', value: 'Date' },
    { key: 'boolean', value: 'Boolean' },
  ];
  public form: UntypedFormGroup;
  public addingAttribute = false;
  public currentValue: string | undefined = 'Text';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      description: [null, [Validators.maxLength(499)]],
      dataType: ['text', [Validators.required]],
    });
  }

  public getControl(controlName: string): UntypedFormControl {
    return this.form.controls[controlName] as UntypedFormControl;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  getOptionValue({ key, value }: SelectOption): void {
    this.currentValue = value;
    this.form.get('dataType')?.setValue(key);
  }

  public addAttribute(form: UntypedFormGroup): void {
    if (this.form.valid) {
      this.addingAttribute = true;
      this.createAttribute.emit(form);
      this.addingAttribute = false;
      this.form.reset();
      this.openAddAttributeModal.emit();
    }
  }
}
