import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'norby-debounce-input',
  templateUrl: './debounce-input.component.html',
  styleUrls: ['./debounce-input.component.css'],
})
export class DebounceInputComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @ViewChild('input', { static: true }) input!: ElementRef;
  @Input() placeholder = '';
  @Output() delayedInput = new EventEmitter<string>();

  ngOnInit(): void {
    this.subscriptions = [
      fromEvent(this.input.nativeElement, 'keydown')
        .pipe(filter(Boolean), debounceTime(400), distinctUntilChanged())
        .subscribe(() => {
          this.delayedInput.emit(this.input.nativeElement.value);
        }),
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
