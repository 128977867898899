import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  show$ = new BehaviorSubject<boolean>(false);

  open(): void {
    this.show$.next(true);
  }

  close(): void {
    this.show$.next(false);
  }
}
