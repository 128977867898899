import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import Chat from '../../../@core/domain/Chat';
import { ChatService } from '../../../@core/services/chat.service';

@Component({
  selector: 'norby-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css'],
})
export class ClientDetailsComponent {
  chat$: Observable<Chat | undefined>;

  constructor(private cs: ChatService) {
    this.chat$ = this.cs.selectedChat$;
  }
}
