import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.css'],
})
export class SearchButtonComponent {
  @Input() isOpen = false;
}
