<section *ngIf="!!selectedChat" id="send-message-form">
  <form [formGroup]="messageForm" (ngSubmit)="sendMessage()" class="send-form">
    <div class="chat-input">
      <textarea
        maxlength="2000"
        norbyAutoResize
        #textarea
        [maximumHeight]="200"
        [minHeight]="minHeight"
        id="send-message-input"
        formControlName="message"
        (input)="selectedChat.emitTyping()"
        (focus)="ts.stop()"
        [placeholder]="placeholder"
        autocomplete="off"
      ></textarea>
      <div class="input-actions">
        <div (click)="imageInput.click()" class="image-input">
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.75 16.5H18.5M5.75 16.5H1.5V1.5H18.5V16.5M5.75 16.5C5.90741 13.6875 10.3778 7.70625 13.7778 8.45625C17.1778 9.20625 18.5 13.875 18.5 16.5"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.3125 7.7085C7.17545 7.7085 7.875 7.00894 7.875 6.146C7.875 5.28305 7.17545 4.5835 6.3125 4.5835C5.44956 4.5835 4.75 5.28305 4.75 6.146C4.75 7.00894 5.44956 7.7085 6.3125 7.7085Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <input
            class="display-none"
            #imageInput
            type="file"
            (click)="imageInput.value = ''"
            (input)="sendImage($event)"
            accept="image/png,image/jpg,image/jpeg,image/heic,image/webp,.png,.jpg,.jpeg,.heic,.webp"
          />
        </div>
        <svg
          *ngIf="
            (selectedChat?.mine && selectedChat?.open && isOnline) ||
            (selectedChat?.mine &&
              selectedChat?.open &&
              !isOnline &&
              email === '')
          "
          (click)="send()"
          id="send"
          class="pointer"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="20" cy="20" r="20" fill="var(--nrb-ob-color)" />
          <path
            d="M25 20H14"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25 20L22.5 23"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25 20L22.5 17"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          *ngIf="
            selectedChat?.mine &&
            selectedChat?.open &&
            !isOnline &&
            email !== ''
          "
          id="send-email"
          (click)="sendEmail()"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill="#FFFFFF"
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
          />
        </svg>
      </div>
    </div>
  </form>
</section>
