<ng-container *ngIf="message">
  <div
    *ngIf="message.author === 'agent' || message.author === 'manager'"
    class="message_container"
  >
    <div class="agent_message_container">
      <div class="avatar" *ngIf="message.showAvatar">
        <div class="bot_icon">
          <norby-agent-avatar
            [avatar]="message.agent?.avatar"
          ></norby-agent-avatar>
        </div>
        <div class="agent_name">
          {{ message.agent?.firstName }} {{ message.agent?.lastName }}
        </div>
      </div>
      <div *ngIf="message.type === 'text'" class="message">
        <div
          class="message_text"
          [style.backgroundColor]="
            (message.confirmed | async) ? 'white' : 'var(--grayF1)'
          "
        >
          {{ convertMessage(message.message) }}
        </div>
        <div class="time">{{ message.full_date | date: "HH:mm" }}</div>
      </div>
      <div *ngIf="message.type === 'image'" class="message">
        <div *ngIf="message.image | async as image">
          <div class="image-stub" *ngIf="!image.path && !image.preview_path">
            <norby-spinner
              [color]="'#d21639'"
              *ngIf="!(message.errorMessage | async)"
            >
            </norby-spinner>
            <div *ngIf="message.errorMessage | async">
              <norby-exclamation-mark
                [size]="'large'"
                [fill]="'red'"
              ></norby-exclamation-mark>
            </div>
          </div>
          <div class="image-wrapper" *ngIf="image.path && image.preview_path">
            <a
              [href]="image.path"
              target="_blank"
              *ngIf="image.preview_path"
              class="image-link"
              [style.background-image]="'url(' + image.preview_path + ')'"
            ></a>
          </div>
        </div>
        <div class="time">{{ message.full_date | date: "HH:mm" }}</div>
      </div>
      <div class="error-message" *ngIf="message.errorMessage | async as error">
        {{ error }}&nbsp;
        <norby-exclamation-mark
          [size]="'small'"
          [fill]="'yellow'"
        ></norby-exclamation-mark>
      </div>
    </div>
  </div>
  <div *ngIf="message.author === 'bot'" class="message_container">
    <div *ngIf="message.type === 'text'" class="agent_message_container">
      <div *ngIf="message.showAvatar" class="avatar">
        <norby-bot-icon></norby-bot-icon>
        <div class="agent_name">Bot</div>
      </div>
      <div class="message">
        <div
          class="message_text"
          [innerHTML]="formatMessage(message.message)"
        ></div>
        <div class="time">{{ message.full_date | date: "HH:mm" }}</div>
      </div>
    </div>
    <div *ngIf="message.type === 'product'" class="agent_message_container">
      <div *ngIf="message.showAvatar" class="avatar">
        <norby-bot-icon></norby-bot-icon>
        <div class="agent_name">Bot</div>
      </div>
      <div class="message">
        <norby-product [productJSON]="message.message"></norby-product>
      </div>
    </div>
    <div *ngIf="message.type === 'form'" class="agent_message_container">
      <div *ngIf="message.showAvatar" class="avatar">
        <norby-bot-icon></norby-bot-icon>
        <div class="agent_name">Bot</div>
      </div>
      <div class="message">
        <norby-chat-form [message]="message.message"></norby-chat-form>
        <div class="time">{{ message.full_date | date: "HH:mm" }}</div>
      </div>
    </div>
    <div
      *ngIf="message.type === 'product_list'"
      class="agent_message_container"
    >
      <div *ngIf="message.showAvatar" class="avatar">
        <norby-bot-icon></norby-bot-icon>
        <div class="agent_name">Bot</div>
      </div>
      <norby-product-list [productsJSON]="message.message"></norby-product-list>
    </div>
  </div>

  <div *ngIf="message.author === 'client'" class="message_container flex-end">
    <div class="client_message_container">
      <div *ngIf="message.type === 'text'" class="client-chat-message">
        <div class="time">{{ message.full_date | date: "HH:mm" }}</div>
        <div class="message_text client_message_text">
          {{ convertMessage(message.message) }}
        </div>
        <norby-client-icon *ngIf="message.showAvatar"></norby-client-icon>
        <div *ngIf="!message.showAvatar">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ></svg>
        </div>
      </div>
      <div *ngIf="message.type === 'image'" class="client-chat-message">
        <div class="time">{{ message.full_date | date: "HH:mm" }}</div>
        <div *ngIf="message.image | async as image">
          <div class="image-stub" *ngIf="!image.path || !image.preview_path">
            <norby-spinner [color]="'#d21639'"></norby-spinner>
          </div>
          <div class="image-wrapper" *ngIf="image.path && image.preview_path">
            <a
              [href]="image.path"
              target="_blank"
              *ngIf="image.preview_path"
              class="image-link"
              [style.background-image]="'url(' + image.preview_path + ')'"
            ></a>
          </div>
        </div>
        <norby-client-icon *ngIf="message.showAvatar"></norby-client-icon>
        <div
          class="error-message"
          *ngIf="message.errorMessage | async as error"
        >
          {{ error }}
          <norby-exclamation-mark
            [fill]="'yellow'"
            [size]="'small'"
          ></norby-exclamation-mark>
        </div>
      </div>
    </div>
  </div>
</ng-container>
