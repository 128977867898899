<svg
  [style]="sizeStyle"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="20" cy="20" r="20" fill="var(--nrb-ob-lightest-color)" />
  <path
    d="M27 28.5V26.5C27 25.4391 26.6312 24.4217 25.9749 23.6716C25.3185 22.9214 24.4283 22.5 23.5 22.5H16.5C15.5717 22.5 14.6815 22.9214 14.0251 23.6716C13.3687 24.4217 13 25.4391 13 26.5V28.5"
    stroke="var(--nrb-ob-color)"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M20.0002 19.0833C22.0942 19.0833 23.7918 17.3857 23.7918 15.2917C23.7918 13.1976 22.0942 11.5 20.0002 11.5C17.9061 11.5 16.2085 13.1976 16.2085 15.2917C16.2085 17.3857 17.9061 19.0833 20.0002 19.0833Z"
    stroke="var(--nrb-ob-color)"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
