import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Autocomplete } from '../../@core/enums/Inputs';

@Component({
  selector: ' norby-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = '';
  @Input() label = '';
  @Input() eyeIcon = false;
  @Input() crossedEye = false;
  @Input() type = 'text';
  @Input() name = 'This field';
  @Input() minLength = 0;
  @Input() maxLength = 0;
  @Input() formError = '';
  @Input() inputWidth = '387px';
  @Input() autocomplete: Autocomplete = Autocomplete.off;
  @Input() hasSubmitted = false;

  togglePassword(): void {
    this.type === 'password' ? (this.type = 'text') : (this.type = 'password');
    this.crossedEye = !this.crossedEye;
  }

  clearApiError(): void {
    this.formError = '';
  }
}
