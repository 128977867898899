import { Component } from '@angular/core';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';

@Component({
  selector: 'norby-new-slug',
  templateUrl: './new-slug.component.html',
  styleUrls: ['./new-slug.component.css'],
})
export class NewSlugComponent {
  constructor(protected ss: SlugsService) {}
}
