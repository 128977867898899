import { Component, Input } from '@angular/core';
import { ButtonDetails } from '../../../../@core/types/Automations/Widgets/ButtonDetails';

@Component({
  selector: 'norby-product-button',
  templateUrl: './product-button.component.html',
  styleUrls: ['./product-button.component.css'],
})
export class ProductButtonComponent {
  @Input() button!: ButtonDetails | undefined;
}
