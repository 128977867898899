<div class="attributes-modal__wrapper" role="dialog">
  <div class="modal-header">
    <h3 class="attributes-modal__title">{{ popupTitle }}</h3>
  </div>
  <div class="attributes-modal__text">{{ addAttributePopupDescription }}</div>
  <form (submit)="addAttribute(form.value)" [formGroup]="form">
    <div>
      <div>
        <p class="required-name">Required</p>
        <norby-input
          [control]="getControl('name')"
          [label]="'Attribute Name'"
          [maxLength]="255"
          [name]="'Attribute name'"
          [placeholder]="'Enter here...'"
        ></norby-input>
      </div>
      <norby-select
        (setChangeValue)="getOptionValue($event)"
        [control]="getControl('dataType')"
        [currentValue]="currentValue"
        [label]="'Type'"
        [optionValues]="dataTypes"
      ></norby-select>
    </div>
    <norby-text-area
      [control]="getControl('description')"
      [label]="'Attribute Description'"
      [maxLength]="500"
      [placeholder]="
        'Why and in which cases are you going to use the attribute?'
      "
      [rows]="3"
      [textareaWidth]="'100%'"
    ></norby-text-area>
    <norby-accent-button
      [disabled]="!form.valid"
      [isLoading]="addingAttribute"
      [title]="'Add'"
      [type]="'submit'"
    ></norby-accent-button>
  </form>
</div>
