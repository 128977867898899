<button class="update-button" (click)="loadNext()">
  <div [class]="loading ? 'rotate-update-icon' : ''">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.83331 5.33333V10.3333M2.83331 10.3333H7.83331M2.83331 10.3333L6.69998 6.69998C7.5956 5.80391 8.70362 5.14932 9.92065 4.79729C11.1377 4.44527 12.4241 4.40727 13.6597 4.68686C14.8954 4.96645 16.0401 5.55451 16.9871 6.39616C17.934 7.23782 18.6523 8.30564 19.075 9.49998M21.1666 18.6667V13.6667M21.1666 13.6667H16.1666M21.1666 13.6667L17.3 17.3C16.4044 18.1961 15.2963 18.8506 14.0793 19.2027C12.8623 19.5547 11.5759 19.5927 10.3402 19.3131C9.10453 19.0335 7.95981 18.4455 7.01287 17.6038C6.06592 16.7621 5.34762 15.6943 4.92498 14.5"
        stroke="#ABABAB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</button>
