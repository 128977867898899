import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Language } from '../../types/Automations/Language';

@Injectable({
  providedIn: 'root',
})
export class UserLanguagesApiService {
  url = `${environment.botEndpoint}/api`;

  constructor(private http: HttpClient) {}

  public getUserLanguages(): Observable<Language[]> {
    return this.http
      .get<{ data: Language[] }>(`${this.url}/user/languages`)
      .pipe(map((x) => x.data));
  }

  getSettingsLanguages(): Observable<Language[]> {
    return this.http
      .get<{ data: Language[] }>(`${this.url}/user/set-up/languages`)
      .pipe(map((x) => x.data));
  }

  public removeLanguage(body: { data: Language[] }): Observable<Language[]> {
    return this.http
      .post<{ data: Language[] }>(`${this.url}/user/set-up/languages`, body)
      .pipe(map((x) => x.data));
  }
}
