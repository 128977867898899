import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-search-reset',
  templateUrl: './search-reset.component.html',
  styleUrls: ['./search-reset.component.css'],
})
export class SearchResetComponent {
  @Input() color = 'black';
}
