<div *ngIf="spinnerService.show$ | async" class="loading_modal">
  <norby-spinner [color]="'#FFFFFF'"></norby-spinner>
</div>
<norby-navbar />
<div class="signup-container">
  <div class="form-box">
    <h3 class="signup-title">Sign up</h3>
    <ul class="signup-steps">
      <li class="step-group">
        <norby-radio-button
          class="check-icon"
          *ngIf="obs.firstStep.passed && !obs.mobile"
          [color]="'#A8A8A8'"
          [cursorPointer]="false"
        ></norby-radio-button>
        <p class="signup-step" [class.active]="obs.firstStep.state">
          Your Info
        </p>
        <norby-arrow class="arrow"></norby-arrow>
      </li>
      <li class="step-group">
        <norby-radio-button
          class="check-icon"
          *ngIf="obs.secondStep.passed && !obs.mobile"
          [color]="'#A8A8A8'"
          [cursorPointer]="false"
        ></norby-radio-button>
        <p class="signup-step" [class.active]="obs.secondStep.state">
          Company Info
        </p>
        <norby-arrow class="arrow"></norby-arrow>
      </li>
      <li class="step-group">
        <norby-radio-button
          class="check-icon"
          *ngIf="obs.thirdStep.passed && !obs.mobile"
          [color]="'#A8A8A8'"
          [cursorPointer]="false"
        ></norby-radio-button>
        <p class="signup-step" [class.active]="obs.thirdStep.state">
          Signup Completion
        </p>
        <norby-arrow class="arrow"></norby-arrow>
      </li>
      <li class="step-group">
        <p class="signup-step" [class.active]="obs.fourthStep.state">
          Email Verification
        </p>
      </li>
    </ul>
    <norby-first-step
      [currentStep]="obs.firstStep"
      (firstStepData)="obs.goToNextStep($event)"
      (mixpanelEvent)="
        sendMixpanelEvent('click_continue_first_signup_step', 'signup')
      "
    ></norby-first-step>
    <norby-second-step
      [currentStep]="obs.secondStep"
      (secondStepData)="obs.onBoardNewUser($event)"
      (mixpanelEvent)="
        sendMixpanelEvent('click_continue_second_signup_step', 'signup')
      "
    ></norby-second-step>
    <norby-third-step
      [currentStep]="obs.thirdStep"
      (thirdStepData)="obs.goToNextStep($event)"
      (mixpanelEvent)="
        sendMixpanelEvent('click_continue_third_signup_step', 'signup')
      "
    ></norby-third-step>
    <norby-fourth-step
      *ngIf="obs.fourthStep.state"
      [currentStep]="obs.fourthStep"
    ></norby-fourth-step>
  </div>
  <div class="info-box-container">
    <norby-bg-blob
      [position]="{ bottom: '-64px', right: '-55px', transform: 'scale(0.8)' }"
    ></norby-bg-blob>
    <norby-bg-blob
      [position]="{
        top: '-100px',
        left: '-82px',
        transform: 'rotate(180deg) scale(0.7)'
      }"
    ></norby-bg-blob>
    <div class="info-box-inner">
      <norby-bg-noise></norby-bg-noise>
      <norby-bg-letter></norby-bg-letter>
      <norby-bg-chat></norby-bg-chat>
      <norby-bg-chat-long></norby-bg-chat-long>
      <div class="info-box-title">
        AI-powered chatbot to enhance
        <div class="red-title">customer support / marketing / sales</div>
      </div>
      <div class="info-box-subtitle">
        — Create your first automation for free —
      </div>
    </div>
  </div>
</div>
