import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-client-icon',
  templateUrl: './client-icon.component.html',
})
export class ClientIconComponent {
  @Input() size: 'large' | 'small' = 'large';

  get sizeStyle(): object {
    return {
      height: this.size === 'large' ? '40px' : '16px',
      width: this.size === 'large' ? '40px' : '16px',
    };
  }
}
