import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Slug } from './../../../../@core/domain/Slug';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';
import { ProfileService } from './../../../../@core/services/profile.service';

@Component({
  selector: 'norby-slug-name',
  templateUrl: './slug-name.component.html',
  styleUrls: ['./slug-name.component.css'],
})
export class SlugNameComponent implements OnInit, OnChanges, OnDestroy {
  @Input() slug: Slug | undefined;
  @Input() disabled = true;
  name: FormControl;
  subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    protected ss: SlugsService,
    protected profileService: ProfileService,
  ) {
    this.name = this.fb.control<string>({ value: '', disabled: true }, [
      Validators.required,
    ]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.disabled?.currentValue !== undefined &&
      changes.disabled?.previousValue !== changes.disabled?.currentValue
    ) {
      changes.disabled.currentValue ? this.name.disable() : this.name.enable();
    }
  }

  ngOnInit(): void {
    if (this.slug) {
      this.name.setValue(this.slug.slug);
      this.name.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(
          (res) => !this.disabled && this.slug && this.slug.setName(res),
        );
      this.subscription.add(
        this.ss.selectedSlug$.subscribe((slug) => {
          this.name.setValue(slug?.slug);
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
