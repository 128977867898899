const zeroPad = (num: number): string => (num <= 9 ? `0${num}` : `${num}`);

const getYesterday = (): Date => {
  const now = new Date();
  now.setDate(now.getDate() - 1);
  return now;
};

const isToday = (source: Date, today: Date) => {
  return (
    today.getDate() === source.getDate() &&
    today.getMonth() === source.getMonth() &&
    today.getFullYear() === source.getFullYear()
  );
};

const isYesterday = (source: Date, yesterday: Date) => {
  return (
    yesterday.getDate() === source.getDate() &&
    yesterday.getMonth() === source.getMonth() &&
    yesterday.getFullYear() === source.getFullYear()
  );
};

export class NorbyDateFormat {
  private readonly date: Date;
  private readonly today: Date;
  private readonly yesterday: Date;

  constructor(toConvert: Date) {
    this.date = toConvert;
    this.today = new Date();
    this.yesterday = getYesterday();
  }

  public norbyDateFormat(): string {
    const hours = zeroPad(this.date.getHours());
    const minutes = zeroPad(this.date.getMinutes());
    let month;
    let day;
    let year;
    switch (true) {
      case isToday(this.date, this.today):
        return `Today, ${hours}:${minutes}`;
      case isYesterday(this.date, this.yesterday):
        return `Yesterday, ${hours}:${minutes}`;
      default:
        month = zeroPad(this.date.getMonth() + 1);
        day = zeroPad(this.date.getDate());
        year = this.date.getFullYear().toString().substr(2);
        return `${day}.${month}.${year} at ${hours}:${minutes}`;
    }
  }
}
