import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Authenticated } from './@core/guards/auth.guard';
import { CheckChangesGuard } from './@core/guards/check-changes.guard';
import { RoleGuard } from './@core/guards/role.guard';
import { ShouldChangePasswordGuard } from './@core/guards/shouldChangePassword.guard';
import { SlugsMainPageComponent } from './pages/automations-page/slugs-main-page/slugs-main-page.component';
import { ChatsPageComponent } from './pages/chats-page/chats-page.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetPasswordPageComponent } from './pages/set-password-page/set-password-page.component';
import { AttributesChatComponent } from './pages/settings-page/attributes-page/attributes-chat/attributes-chat.component';
import { AttributesUserComponent } from './pages/settings-page/attributes-page/attributes-user/attributes-user.component';
import { BillingPageComponent } from './pages/settings-page/billing-page/billing-page.component';
import { ProjectSettingsPageComponent } from './pages/settings-page/project-settings-page/project-settings-page.component';
import { SettingsPageMainComponent } from './pages/settings-page/settings-page-main/settings-page-main.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page/settings-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { TeamPageComponent } from './pages/team-page/team-page.component';
import { UserSearchPageComponent } from './pages/user-search-page/user-search-page.component';
import { VerifyEmailComponent } from './components/signup/verify-email/verify-email.component';
import { ReferralPageComponent } from './pages/referral-page/referral-page.component';

const ROUTES: Routes = [
  {
    path: '',
    canActivate: [Authenticated, ShouldChangePasswordGuard],
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'chats', pathMatch: 'full' },
      { path: 'chats', component: ChatsPageComponent },
      { path: 'users', component: UserSearchPageComponent },
      { path: 'team', component: TeamPageComponent },
      {
        path: 'automations',
        component: SlugsMainPageComponent,
        canDeactivate: [CheckChangesGuard],
        canActivate: [RoleGuard],
      },
      { path: 'referral', component: ReferralPageComponent },
      {
        path: 'settings',
        component: SettingsPageMainComponent,
        children: [
          { path: '', redirectTo: 'profile', pathMatch: 'full' },
          { path: 'profile', component: SettingsPageComponent },
          {
            path: 'project',
            component: ProjectSettingsPageComponent,
            canActivate: [RoleGuard],
          },
          {
            path: 'billing',
            component: BillingPageComponent,
            canActivate: [RoleGuard],
          },
          {
            path: 'attributes/user',
            component: AttributesUserComponent,
            canActivate: [RoleGuard],
          },
          {
            path: 'attributes/chat',
            component: AttributesChatComponent,
            canActivate: [RoleGuard],
          },
        ],
      },
    ],
  },
  { path: 'login', component: LoginPageComponent },
  { path: 'signup', component: SignupPageComponent },
  { path: 'email/verify/:email/:token', component: VerifyEmailComponent },
  { path: 'set_password', component: SetPasswordPageComponent },
  { path: 'forgot_password', component: ResetPasswordComponent },
  { path: 'password/reset/:token', component: NewPasswordComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
