import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GetAccessToken, GetSlugToken } from '../@core/services/auth.service';

export class JwtHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    const slugToken = GetSlugToken();
    const jwtToken = GetAccessToken();
    if (!jwtToken) {
      return next.handle(req);
    }

    if (this.alreadyHasToken(req)) {
      return next.handle(req);
    }

    if (req.url.includes(environment.botEndpoint)) {
      const modifiedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${slugToken}`),
      });
      return next.handle(modifiedReq);
    }
    const modifiedHeaderReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${jwtToken}`),
    });
    return next.handle(modifiedHeaderReq);
  }

  private alreadyHasToken(req: HttpRequest<unknown>): boolean {
    return !!req.headers.get('Authorization');
  }
}
