import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Slug } from '../../../../../@core/domain/Slug';
import { WidgetService } from '../../../../../@core/services/automations/widget.service';
import {
  Widget,
  WidgetDTO,
} from '../../../../../@core/types/Automations/Widgets/Widget';
import { UserLanguagesService } from '../../../../../@core/services/user-languages.service';
import { getIdByLocale } from '../../../../../@core/domain/Locale';

@Component({
  selector: 'norby-automation-widgets',
  templateUrl: './automation-widgets.component.html',
  styleUrls: ['./automation-widgets.component.css'],
})
export class AutomationWidgetsComponent implements OnInit, OnChanges {
  @Input() selectedSlug: Slug | undefined;

  widgets: Widget[] = [];

  constructor(
    protected ws: WidgetService,
    private ls: UserLanguagesService,
  ) {}

  ngOnInit() {
    if (this.selectedSlug) {
      this.selectedSlug.widgets$.subscribe((widgets) => {
        this.widgets = widgets
          .filter((w) => !!w[this.ls.currentLanguage$.value])
          .map((w) => Object.values(w)[0])
          .sort((a, b) => a.id - b.id);

        if (this.widgets.length === 0) {
          this.addWidgetForm();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSlug?.currentValue) {
      this.widgets = (
        changes.selectedSlug.currentValue as unknown as Slug
      ).widgets$.value
        .filter((w) => !!w[this.ls.currentLanguage$.value])
        .map((w) => Object.values(w)[0])
        .sort((a, b) => a.id - b.id);
    }
    if (this.widgets.length === 0) {
      this.addWidgetForm();
    }
  }

  addWidgetForm() {
    this.widgets?.push(undefined as unknown as Widget);
  }

  createWidget(e: Widget, i: number) {
    this.widgets[i] = e;
    this.selectedSlug &&
      this.selectedSlug.addToWidgets(
        { [this.ls.currentLanguage$.value]: e },
        i,
      );
  }

  updateWidget(widget: Widget, index: number) {
    this.widgets[index] = widget;
    if (this.selectedSlug) {
      const widgetDTO: WidgetDTO = {
        ...widget,
        widget: JSON.stringify(widget.widget),
      };
      const langId = getIdByLocale(this.ls.currentLanguage$.value);
      this.selectedSlug.updateWidget(widgetDTO, langId);
    }
  }

  removeWidget(i: number) {
    const copy = this.widgets;
    copy.splice(i, 1);
    const langId = getIdByLocale(this.ls.currentLanguage$.value);
    this.selectedSlug && this.selectedSlug.removeFromWidgets(i, langId);

    if (!this.widgets.length) {
      this.addWidgetForm();
    }
  }

  removeAllWidgets() {
    this.widgets = [];
    this.selectedSlug && this.selectedSlug.removeAllWidgets();
  }
}
