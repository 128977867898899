<div class="no-chat-placeholder">
  <svg
    class="placeholder-image"
    width="100%"
    height="370px"
    viewBox="0 0 504 406"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M464.236 214.072C456.148 170.407 413.877 147.089 385.683 114.236C361.493 86.0476 346.45 41.9945 311.095 35.6222C275.889 29.2769 251.223 73.3132 216.905 83.8161C180.482 94.9635 138.113 75.3886 106.813 98.1431C70.4286 124.594 31.1229 167.688 37.7331 214.072C44.4921 261.5 102.045 276.165 136.574 306.974C160.868 328.652 179.979 356.18 209.803 367.606C240.316 379.296 272.334 374.357 304.639 371.364C347.617 367.382 397.194 378.516 428.856 347.311C461.463 315.175 472.834 260.49 464.236 214.072Z"
      fill="#DEE1EB"
      fill-opacity="0.2"
    />
    <path
      d="M189.5 260H161H148C140.268 260 134 253.732 134 246V235.5V208"
      stroke="#96A1CB"
      stroke-opacity="0.94"
      stroke-width="2"
      stroke-linecap="round"
      stroke-dasharray="2 5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M367.396 28C374.005 28 379.413 33.408 379.413 40.0177V132.153C379.413 138.763 374.005 144.171 367.396 144.171H171.307C169.905 144.171 168.703 145.272 168.603 146.675L166.9 163.7C166.7 166.103 163.896 167.205 161.993 165.803L138.458 147.075C136.055 145.172 133.15 144.171 130.146 144.171H111.018C104.408 144.171 99 138.763 99 132.153V40.0177C99 33.408 104.408 28 111.018 28H239.207H367.396Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M253.631 66.4613H123.623C120.241 66.4613 117.535 63.7556 117.535 60.3735C117.535 56.9914 120.241 54.2858 123.623 54.2858H253.631C257.013 54.2858 259.718 56.9914 259.718 60.3735C259.854 63.6203 257.013 66.4613 253.631 66.4613Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M328.848 66.4613H272.705C269.323 66.4613 266.617 63.7556 266.617 60.3735C266.617 56.9914 269.323 54.2858 272.705 54.2858H328.848C332.23 54.2858 334.936 56.9914 334.936 60.3735C334.936 63.6203 332.23 66.4613 328.848 66.4613Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M155.55 86.8897H123.623C120.241 86.8897 117.535 84.1841 117.535 80.802C117.535 77.4199 120.241 74.7142 123.623 74.7142H155.685C159.067 74.7142 161.773 77.4199 161.773 80.802C161.773 84.1841 158.932 86.8897 155.55 86.8897Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M282.987 86.8897H172.866C169.484 86.8897 166.778 84.1841 166.778 80.802C166.778 77.4199 169.484 74.7142 172.866 74.7142H282.987C286.369 74.7142 289.075 77.4199 289.075 80.802C289.075 84.1841 286.369 86.8897 282.987 86.8897Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M233.473 107.453H123.352C119.97 107.453 117.264 104.747 117.264 101.365C117.264 97.9829 119.97 95.2772 123.352 95.2772H233.473C236.855 95.2772 239.561 97.9829 239.561 101.365C239.561 104.612 236.855 107.453 233.473 107.453Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M253.63 107.453H252.007C248.624 107.453 245.919 104.747 245.919 101.365C245.919 97.9829 248.624 95.2772 252.007 95.2772H253.63C257.012 95.2772 259.718 97.9829 259.718 101.365C259.853 104.612 257.012 107.453 253.63 107.453Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M308.961 107.453H272.705C269.323 107.453 266.617 104.747 266.617 101.365C266.617 97.9829 269.323 95.2772 272.705 95.2772H308.961C312.343 95.2772 315.049 97.9829 315.049 101.365C315.184 104.612 312.343 107.453 308.961 107.453Z"
      fill="#DFE2EB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M158.087 178.221C151.477 178.221 146.069 183.629 146.069 190.239V238.309C146.069 244.919 151.477 250.327 158.087 250.327H354.176C355.578 250.327 356.78 251.429 356.88 252.831L358.583 269.856C358.783 272.259 361.587 273.361 363.49 271.959L387.025 253.231C389.428 251.328 392.332 250.327 395.337 250.327H414.465C421.075 250.327 426.483 244.919 426.483 238.309V190.239C426.483 183.629 421.075 178.221 414.465 178.221H158.087Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M310.312 283.375C316.921 283.375 322.329 288.783 322.329 295.393V343.464C322.329 350.074 316.921 355.482 310.312 355.482H171.307C169.905 355.482 168.703 356.583 168.603 357.985L166.9 375.01C166.7 377.414 163.896 378.515 161.993 377.113L138.458 358.386C136.055 356.483 133.15 355.482 130.146 355.482H111.018C104.408 355.482 99 350.074 99 343.464V295.393C99 288.783 104.408 283.375 111.018 283.375H310.312Z"
      fill="white"
    />
    <path
      opacity="0.7"
      d="M223.732 229.477H175.03C171.648 229.477 168.942 226.772 168.942 223.39C168.942 220.007 171.648 217.302 175.03 217.302H223.732C227.114 217.302 229.82 220.007 229.82 223.39C229.955 226.772 227.114 229.477 223.732 229.477Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M334.529 229.477H244.43C241.048 229.477 238.343 226.772 238.343 223.39C238.343 220.007 241.048 217.302 244.43 217.302H334.529C337.912 217.302 340.617 220.007 340.617 223.39C340.617 226.772 337.912 229.477 334.529 229.477Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M296.92 207.292H174.894C171.512 207.292 168.807 204.586 168.807 201.204C168.807 197.822 171.512 195.116 174.894 195.116H296.92C300.302 195.116 303.008 197.822 303.008 201.204C303.008 204.586 300.302 207.292 296.92 207.292Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M319.107 207.292H316.13C312.748 207.292 310.043 204.586 310.043 201.204C310.043 197.822 312.748 195.116 316.13 195.116H319.107C322.489 195.116 325.194 197.822 325.194 201.204C325.194 204.586 322.489 207.292 319.107 207.292Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M379.714 207.292H338.723C335.341 207.292 332.635 204.586 332.635 201.204C332.635 197.822 335.341 195.116 338.723 195.116H379.714C383.096 195.116 385.802 197.822 385.802 201.204C385.937 204.586 383.232 207.292 379.714 207.292Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M124.121 335.763H213.273C216.655 335.763 219.361 333.057 219.361 329.675C219.361 326.293 216.655 323.587 213.273 323.587H124.121C120.739 323.587 118.033 326.293 118.033 329.675C117.898 333.057 120.603 335.763 124.121 335.763Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M206.915 313.578H272.798C276.18 313.578 278.886 310.872 278.886 307.49C278.886 304.108 276.18 301.402 272.798 301.402H206.915C203.533 301.402 200.827 304.108 200.827 307.49C200.827 310.872 203.533 313.578 206.915 313.578Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M184.728 313.578H187.704C191.086 313.578 193.792 310.872 193.792 307.49C193.792 304.108 191.086 301.402 187.704 301.402H184.728C181.346 301.402 178.64 304.108 178.64 307.49C178.64 310.872 181.346 313.578 184.728 313.578Z"
      fill="#DFE2EB"
    />
    <path
      opacity="0.7"
      d="M124.121 313.578H165.112C168.494 313.578 171.199 310.872 171.199 307.49C171.199 304.108 168.494 301.402 165.112 301.402H124.121C120.739 301.402 118.033 304.108 118.033 307.49C117.898 310.872 120.603 313.578 124.121 313.578Z"
      fill="#DFE2EB"
    />
    <path
      d="M382 168H410.5H417C428.046 168 437 176.954 437 188V195V226.5"
      stroke="#96A1CB"
      stroke-opacity="0.94"
      stroke-width="2"
      stroke-linecap="round"
      stroke-dasharray="2 5"
    />
    <path
      d="M211 271H165.811H147C133.745 271 123 260.255 123 247V232.365L123 189"
      stroke="#96A1CB"
      stroke-opacity="0.94"
      stroke-width="2"
      stroke-linecap="round"
      stroke-dasharray="2 5"
    />
  </svg>
  <p class="no-chat">You have not chosen a chat yet</p>
</div>
