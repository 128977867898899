import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-mark',
  templateUrl: './mark.component.html',
  styleUrls: ['./mark.component.css'],
})
export class MarkComponent {
  @Input() positionStyles = {
    top: '0px',
    right: '0px',
    position: 'absolute',
    transform: 'translate(50% 50%)',
    zIndex: 9999,
  };
}
