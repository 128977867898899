import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-statistic-number',
  templateUrl: './statistic-number.component.html',
  styleUrls: ['./statistic-number.component.css'],
})
export class StatisticNumberComponent {
  @Input() statNumber = 0;
  @Input() title = '';
  @Input() important = false;
  @Input() isPercent = false;
  @Input() isTime = false;
}
