import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignUpStep } from 'src/app/pages/signup-page/signup-page.component';
import { OnboardingService } from '../../../../@core/services/onboarding.service';

@Component({
  selector: 'norby-third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['./third-step.component.css'],
})
export class ThirdStepComponent {
  @Input() currentStep: SignUpStep = { name: '', state: false, passed: false };
  @Output() thirdStepData = new EventEmitter();
  @Output() mixpanelEvent = new EventEmitter();

  constructor(protected obs: OnboardingService) {}
}
