import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'norby-attribute-list-item',
  templateUrl: './attribute-list-item.component.html',
  styleUrls: ['./attribute-list-item.component.css'],
})
export class AttributeListItemComponent {
  @Input() name = '';
  @Input() value: string | number | boolean = '';

  constructor(private toastr: ToastrService) {}

  get userData(): string | number | boolean {
    return this.value;
  }

  async copyWidgetCode(): Promise<void> {
    this.toastr.success('The value was copied');
    await navigator.clipboard.writeText(this.userData.toString());
  }
}
