import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Profile, Roles } from '../../domain/Profile';
import CreateForm from '../../types/CreateForm';
import { ProfileInfoDto } from '../../types/ProfileInfo';
import { Role } from './../../domain/Profile';

export interface AllAgentsResponse {
  managers: ProfileInfoDto[];
  agents: ProfileInfoDto[];
}

@Injectable({
  providedIn: 'root',
})
export class AgentsApiService {
  baseUrl = environment.endpoint;

  constructor(private http: HttpClient) {}

  public getAllAgentDTOs(): Observable<AllAgentsResponse> {
    return this.http.get<AllAgentsResponse>(
      `${this.baseUrl}/api/panel/support/all`,
    );
  }

  create(form: CreateForm) {
    return this.http.post(`${this.baseUrl}/api/panel/manager/support`, form);
  }

  assign(role: Role, uniqid: string, agentUid: string): void {
    const url =
      role === 'Support Manager'
        ? `${this.baseUrl}/api/panel/manager/assign`
        : `${this.baseUrl}/api/panel/support/chat/assign`;
    this.http
      .post(url, { uniqid, agentUid }, { observe: 'response' })
      .subscribe({
        next: (response) => {
          console.log('Got response from assign: ', response);
        },
        error: (err) => {
          console.log(
            `Error assigning chat: ${uniqid} to agent ${agentUid} ${
              (err as Error).message
            }`,
          );
        },
      });
  }

  assignChat(role: Role, uniqid: string, agentUid: string) {
    const url =
      role === 'Support Manager'
        ? `${this.baseUrl}/api/panel/manager/assign`
        : `${this.baseUrl}/api/panel/support/chat/assign`;
    return this.http.post(url, { uniqid, agentUid }, { observe: 'response' });
  }

  removeAgent(uuid: string): Observable<Profile> {
    return this.http.delete<Profile>(
      `${this.baseUrl}/api/panel/manager/support/${uuid}`,
    );
  }

  public updateRole(uuid: string, role: object): Observable<Profile> {
    return this.http.put<Profile>(
      `${this.baseUrl}/api/panel/manager/support/${uuid}`,
      role,
    );
  }

  public loadRoles(): Observable<Roles> {
    return this.http.get<Roles>(`${this.baseUrl}/api/panel/manager/role`);
  }
}
