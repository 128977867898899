<a [href]="link" target="_blank">
  <svg
    class="inner-svg"
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
      stroke="var(--gray76)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5351 14.1136C10.5351 14.2203 10.6216 14.3068 10.7283 14.3068H12.4953C12.602 14.3068 12.6885 14.2203 12.6885 14.1136C12.6999 13.0057 13.0976 12.4886 13.9953 11.9489C15.0578 11.3182 15.751 10.483 15.751 9.14773C15.751 7.15909 14.1487 6 11.8931 6C10.2244 6 8.80492 6.70663 8.29658 8.18006C8.08304 8.79899 8.63632 9.35227 9.29106 9.35227C9.91882 9.35227 10.387 8.7776 10.8356 8.33842C11.1093 8.07045 11.4875 7.93182 11.8817 7.93182C12.6487 7.93182 13.2681 8.44318 13.2681 9.23295C13.2681 9.97727 12.7283 10.4716 12.0294 10.9148C11.0749 11.517 10.5408 12.125 10.5351 14.1136ZM11.6544 17.9432C12.3817 17.9432 13.0124 17.3352 13.0181 16.5795C13.0124 15.8352 12.3817 15.2273 11.6544 15.2273C10.9044 15.2273 10.2851 15.8352 10.2908 16.5795C10.2851 17.3352 10.9044 17.9432 11.6544 17.9432Z"
      fill="var(--gray76)"
    />
  </svg>
</a>
