import { Component, ElementRef, HostListener, Input } from '@angular/core';

export type Position = {
  top: string;
  right: string;
};

@Component({
  selector: 'norby-three-dots',
  templateUrl: './three-dots.component.html',
  styleUrls: ['./three-dots.component.css'],
})
export class ThreeDotsComponent {
  public isOpen = false;
  @Input() lastIndex = true;

  constructor(private ref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  public selectToggle(): void {
    this.isOpen = !this.isOpen;
  }
}
