<div class="role-wrapper">
  <h3 class="change-title">{{ title }}</h3>
  <ng-container *ngFor="let available_role of availableRoles">
    <div
      class="role-option"
      (click)="selectRole.emit(available_role.key)"
      [attr.data-role-id]="available_role.key"
    >
      <label for="role_{{ available_role.key }}" class="role-title">
        <input
          id="role_{{ available_role.key }}"
          class="role-indicator"
          type="radio"
          value="{{ available_role.key }}"
        />{{ available_role.value }}</label
      >
      <norby-radio-button
        [cursorPointer]="true"
        [checked]="role === available_role.value"
        [color]="hoveredRole === available_role.key ? 'white' : 'var(--grayF1)'"
      ></norby-radio-button>
    </div>
  </ng-container>
</div>
