<div class="search" [style]="{ display: enabled ? 'flex' : 'none' }">
  <div class="search-attribute-top">
    <h3 class="search-caption">Where</h3>
    <norby-search-select
      [hasHeader]="true"
      [chatOptions]="chatAttributeNames | async"
      [userOptions]="userAttributeNames | async"
      [currentValue]="(selectedAttribute | async)?.name || null"
      [placeHolder]="'An attribute ...'"
      (selectedOption)="setSelectedAttribute($event)"
    ></norby-search-select>
    <norby-search-reset (click)="reset()"></norby-search-reset>
  </div>
  <div class="search-condition">
    <div class="search-attribute-bottom">
      <ng-container *ngIf="selectedFilterConditions | async as conditions">
        <norby-search-select
          *ngIf="conditions.length"
          [chatOptions]="isChatAttributeSelected ? conditions : []"
          [userOptions]="!isChatAttributeSelected ? conditions : []"
          [currentValue]="currentValue"
          (selectedOption)="setCondition($event)"
          [placeHolder]="'A condition ...'"
        ></norby-search-select>
      </ng-container>
      <ng-container *ngIf="showFilterConditionValue | async">
        <ng-container *ngIf="selectedAttribute | async as attribute">
          <norby-search-select
            [chatOptions]="isChatAttributeSelected ? ['true', 'false'] : []"
            [userOptions]="!isChatAttributeSelected ? ['true', 'false'] : []"
            [placeHolder]="'A value ...'"
            *ngIf="attribute.data_type === 'boolean'"
            (selectedOption)="setConditionValue($event)"
          >
          </norby-search-select>
          <norby-debounce-input
            *ngIf="
              attribute.data_type !== 'date' &&
              attribute.data_type !== 'boolean'
            "
            [placeholder]="'A value ...'"
            (delayedInput)="setConditionValue($event)"
          >
          </norby-debounce-input>
          <norby-datepicker
            class="datepicker-search-input"
            [mode]="'single'"
            *ngIf="attribute.data_type === 'date'"
            (selectedOneDate)="setConditionValue($event)"
          >
          </norby-datepicker>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
