import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Slug } from '../../../@core/domain/Slug';
import { PaymentService } from '../../../@core/services/automations/payment.service';
import { SlugsService } from '../../../@core/services/automations/slugs.service';
import { WidgetService } from '../../../@core/services/automations/widget.service';
import { UserLanguagesService } from '../../../@core/services/user-languages.service';
import { ProfileService } from './../../../@core/services/profile.service';
import { Trigger } from '../../../@core/types/Automations/TriggerTypes';

@Component({
  selector: 'norby-slug-window',
  templateUrl: './slug-window.component.html',
  styleUrls: ['./slug-window.component.css'],
})
export class SlugWindowComponent implements OnInit, OnDestroy {
  reminder = false;

  readonly subscriptions: Subscription = new Subscription();
  readonly paidNotificationCheckBox = new UntypedFormControl();

  constructor(
    protected ss: SlugsService,
    protected ws: WidgetService,
    protected ps: PaymentService,
    protected uls: UserLanguagesService,
    protected profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.paidNotificationCheckBox.valueChanges.subscribe((res) => {
        if (res) {
          this.reminder = res;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.ss.selectedSlug$.next(undefined);
  }

  createManualSlugWithTrigger(triggerData: { trigger: Trigger }): void {
    if (!this.ps.paymentStatuses$.value?.paid_reminder) {
      this.reminder = true;
    }
    document
      .getElementById('nameContainer')
      ?.scrollIntoView({ behavior: 'smooth' });
    document.getElementById('slugNameInput')?.focus();
    const emptySlug = Slug.emptyManualSlug(triggerData.trigger);
    this.ss.currentTab$.next('Disabled');
    this.uls.setInitialLanguageValues();
    this.ss.selectedSlug$.next(emptySlug);
    this.ss.slugCreationStep$.next('closed');
    this.ws.reset();
  }

  setReminder(state: boolean): void {
    if (this.reminder && state) {
      this.ps.setReminder().subscribe(() => {
        if (this.ps.paymentStatuses$.value) {
          this.ps.paymentStatuses$.next({
            ...this.ps.paymentStatuses$.value,
            paid_reminder: true,
          });
        }
      });
    } else {
      if (this.ps.paymentStatuses$.value) {
        this.ps.paymentStatuses$.next({
          ...this.ps.paymentStatuses$.value,
          paid_reminder: true,
        });
      }
    }
    this.reminder = false;
  }
}
