import { NgZone } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { TitleNotificationService } from '../services/title-notification.service';
import ChatDTO from '../types/ChatModel';
import Participant from '../types/Participant';
import Chat from './Chat';
import ChatMessageFactory from './ChatMessageFactory';

interface ChatSettings {
  mine: boolean;
  open: boolean;
  unreadMessage: boolean;
}

const defaultSettings: ChatSettings = {
  mine: true,
  open: true,
  unreadMessage: true,
};

export class ChatsFactory {
  zone: NgZone;

  constructor(zone: NgZone) {
    this.zone = zone;
  }

  fromChatDTO(
    dto: ChatDTO,
    options: ChatSettings = { mine: false, open: true, unreadMessage: false },
    ts: TitleNotificationService,
    unreadMessageTotal: ChatService['unreadMessagesTotal$'],
  ): Chat {
    const lastMessage = dto.last_message
      ? ChatMessageFactory.fromDTO(dto.last_message)
      : undefined;

    return new Chat(
      options.mine,
      options.open,
      this.zone,
      dto.chat_messages
        // For some reason backend sends confirmed as flag.
        // We assume, that incoming messages are always confirmed.
        .map((messageDTO) => ChatMessageFactory.fromDTO(messageDTO))
        .map(Chat.setupFlags),
      dto.uid,
      dto.id,
      dto.participant.name || dto.participant.generated_name,
      dto.participant.email,
      lastMessage,
      options.unreadMessage || dto.viewed_by_support,
      dto.is_client_online,
      dto.viewed_by_support,
      ts,
      dto.participant,
      unreadMessageTotal,
    );
  }

  myChat(
    dto: ChatDTO,
    ts: TitleNotificationService,
    unreadMessageTotal: ChatService['unreadMessagesTotal$'],
  ): Chat {
    return this.fromChatDTO(
      dto,
      { mine: true, open: true, unreadMessage: !dto.viewed_by_support },
      ts,
      unreadMessageTotal,
    );
  }

  openChat(
    dto: ChatDTO,
    ts: TitleNotificationService,
    unreadMessageTotal: ChatService['unreadMessagesTotal$'],
  ): Chat {
    return this.fromChatDTO(
      dto,
      { mine: false, open: true, unreadMessage: !dto.viewed_by_support },
      ts,
      unreadMessageTotal,
    );
  }

  closedChat(
    dto: ChatDTO,
    ts: TitleNotificationService,
    unreadMessageTotal: ChatService['unreadMessagesTotal$'],
  ): Chat {
    return this.fromChatDTO(
      dto,
      { mine: false, open: false, unreadMessage: false },
      ts,
      unreadMessageTotal,
    );
  }

  botChat(
    dto: ChatDTO,
    ts: TitleNotificationService,
    unreadMessageTotal: ChatService['unreadMessagesTotal$'],
  ): Chat {
    return this.fromChatDTO(
      dto,
      { mine: false, open: false, unreadMessage: !dto.viewed_by_support },
      ts,
      unreadMessageTotal,
    );
  }

  fakeDefaultChat(
    ts: TitleNotificationService,
    unreadMessageTotal: ChatService['unreadMessagesTotal$'],
    { mine, open, unreadMessage }: ChatSettings = defaultSettings,
    uid?: string,
  ): Chat {
    const chat: Chat = new Chat(
      mine,
      open,
      this.zone,
      [],
      '1',
      '1',
      'Test Chat',
      '',
      undefined,
      unreadMessage,
      true,
      false,
      ts,
      {} as Participant,
      unreadMessageTotal,
    );
    if (uid) {
      chat.uid = uid;
    }
    return chat;
  }
}
