import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { validateLinkUrl } from '../../../../../@core/validators/linkValidator';
import { Widget } from '../../../../../@core/types/Automations/Widgets/Widget';
import { ButtonDetails } from '../../../../../@core/types/Automations/Widgets/ButtonDetails';

@Component({
  selector: 'norby-button-widget',
  templateUrl: './button-widget.component.html',
  styleUrls: ['./button-widget.component.css'],
})
export class ButtonWidgetComponent implements OnChanges {
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() widgetDetails: Widget | undefined;

  widgetButtonForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    protected ss: SlugsService,
  ) {
    this.widgetButtonForm = this.fb.group({
      name: ['', Validators.required],
      link: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails?.currentValue) {
      const widget = changes.widgetDetails.currentValue as Widget;
      if (
        widget.widget &&
        (widget.widget as ButtonDetails).name !== undefined &&
        (widget.widget as ButtonDetails).link !== undefined
      ) {
        this.widgetButtonForm.setValue({
          name: (widget.widget as ButtonDetails).name,
          link: (widget.widget as ButtonDetails).link,
        });
      }
    }
  }

  changeButtonWidget(): void {
    if (this.widgetButtonForm.valid) {
      const { name, link } = this.widgetButtonForm.getRawValue();
      const buttonWidget = { name, link: validateLinkUrl(link) };
      this.save.emit(buttonWidget);
    }
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removeButton(): void {
    this.remove.emit();
  }
}
