<div class="select-container">
  <div class="label-wrapper">
    <label class="select-label" *ngIf="label">{{ label }}</label>
    <div class="sub-label">{{ subLabel }}</div>
  </div>
  <div class="select-wrapper" (click)="toggle()">
    <div
      class="crumbs-box"
      *ngIf="enabledLanguagesCount$ | async as enabledLanguagesCount"
    >
      <ng-container *ngFor="let language of userLanguages$ | async">
        <div
          [class.disabled-crumb]="enabledLanguagesCount === 1"
          class="crumb-item"
          *ngIf="language.active === 1"
        >
          <div class="crumb-item-text">{{ language.name }}</div>
          <norby-bin
            class="ml-position"
            (remove)="fireRemove(language)"
            [showBin]="true"
            [disabled]="enabledLanguagesCount === 1"
            [stroke]="
              enabledLanguagesCount === 1
                ? 'var(--gray76)'
                : 'var(--nrb-ob-color)'
            "
            [item]="'Language'"
          ></norby-bin>
        </div>
      </ng-container>
    </div>
    <input
      [readOnly]="true"
      class="select-input"
      [value]="currentValue"
      placeholder="{{ placeholder }}"
    />
    <norby-expose-button
      [color]="
        (disabledLanguagesCount$ | async) === 0 ? 'var(--gray76)' : 'black'
      "
      class="select-arrow"
      [isOpen]="searchSelectState"
    ></norby-expose-button>
  </div>
  <ng-container *ngIf="searchSelectState">
    <div
      *ngIf="userLanguages$"
      class="options-container"
      [style]="{ top: topPositioning }"
    >
      <h1 *ngIf="!!optionsLabel" class="options-label">{{ optionsLabel }}</h1>
      <ng-container *ngFor="let option of userLanguages$ | async">
        <div
          class="option-wrapper"
          *ngIf="option.active === 0"
          (click)="setValue(option)"
        >
          <label
            for="select-option-{{ option.short_name }}"
            class="option-label"
          >
            <input
              type="radio"
              class="option-value"
              [formControl]="control"
              [value]="option"
              id="select-option-{{ option.short_name }}"
            />
            {{ option.name }}
          </label>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div *ngIf="control.invalid && (control.dirty || control.touched)">
    <div class="select-error" *ngIf="control.errors?.required">
      {{ name }} is required
    </div>
    <div class="select-error" *ngIf="control.errors?.apiError">
      {{ control.errors?.apiError.value }}
    </div>
  </div>
</div>
