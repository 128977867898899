import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Locale } from '../domain/Locale';
import { Language } from '../types/Automations/Language';
import { UserLanguagesApiService } from './api/user-languages-api.service';
import { SpinnerService } from './spinner.service';

export type MultiLanguages = Record<Locale, number>;

@Injectable({
  providedIn: 'root',
})
export class UserLanguagesService {
  public languagesTabs$: BehaviorSubject<Language> = new BehaviorSubject(
    {} as Language,
  );

  public userLanguages$ = new BehaviorSubject<Language[]>([]);
  public languageId$ = new BehaviorSubject<number>(1);
  public disabledLanguagesCount$ = new BehaviorSubject<number>(0);
  public enabledLanguagesCount$ = new BehaviorSubject<number>(0);
  public currentLanguage$ = new BehaviorSubject<Locale>('en');
  public languageOptions$ = this.uls
    .getSettingsLanguages()
    .pipe(
      map((languages) =>
        languages.map((lang) => ({ key: lang.short_name, value: lang.name })),
      ),
    );

  isLoading$ = this.spinnerService.show$;

  constructor(
    private uls: UserLanguagesApiService,
    private spinnerService: SpinnerService,
  ) {
    this.uls.getUserLanguages().subscribe((x: Language[]) => {
      this.userLanguages$.next(x);
      this.currentLanguage$.next(x[0].short_name);
      this.getDisabledLanguagesCount(this.disabledLanguagesCount$, x);
      this.getEnabledLanguagesCount(this.enabledLanguagesCount$, x);
    });
  }

  public setSelectedLang(language: Language): void {
    switch (language.name) {
      case 'English':
        this.currentLanguage$.next('en');
        this.languageId$.next(1);
        break;
      case 'Russian':
        this.currentLanguage$.next('ru');
        this.languageId$.next(2);
        break;
      case 'Estonian':
        this.currentLanguage$.next('ee');
        this.languageId$.next(3);
        break;
      case 'Italian':
        this.currentLanguage$.next('it');
        this.languageId$.next(4);
        break;
      case 'French':
        this.currentLanguage$.next('fr');
        this.languageId$.next(5);
        break;
      case 'German':
        this.currentLanguage$.next('de');
        this.languageId$.next(6);
        break;
      case 'Ukrainian':
        this.currentLanguage$.next('ua');
        this.languageId$.next(7);
        break;
      case 'Spanish':
        this.currentLanguage$.next('es');
        this.languageId$.next(8);
        break;
      case 'Finnish':
        this.currentLanguage$.next('fi');
        this.languageId$.next(9);
        break;
      case 'Swedish':
        this.currentLanguage$.next('sv');
        this.languageId$.next(10);
        break;
      case 'Danish':
        this.currentLanguage$.next('da');
        this.languageId$.next(11);
        break;
      case 'Norwegian':
        this.currentLanguage$.next('no');
        this.languageId$.next(12);
        break;
      case 'Polish':
        this.currentLanguage$.next('pl');
        this.languageId$.next(13);
        break;
      case 'Portuguese':
        this.currentLanguage$.next('pt');
        this.languageId$.next(14);
        break;
      case 'Turkish':
        this.currentLanguage$.next('tr');
        this.languageId$.next(15);
        break;
      case 'Chinese':
        this.currentLanguage$.next('zh');
        this.languageId$.next(16);
        break;
      case 'Japanese':
        this.currentLanguage$.next('ja');
        this.languageId$.next(17);
        break;
      case 'Arabic':
        this.currentLanguage$.next('ar');
        this.languageId$.next(18);
        break;
    }
  }

  public setInitialLanguageValues(): void {
    this.currentLanguage$.next(
      this.getActiveLanguageShortName(this.userLanguages$.value),
    );
    this.languagesTabs$.next(
      this.getActiveFullLanguage(this.userLanguages$.value),
    );
    this.languageId$.next(this.getActiveIdLanguage(this.userLanguages$.value));
  }

  public getLanguages(): MultiLanguages {
    return {
      en: 1,
      ru: 2,
      ee: 3,
      it: 4,
      fr: 5,
      de: 6,
      ua: 7,
      es: 8,
      fi: 9,
      sv: 10,
      da: 11,
      no: 12,
      pl: 13,
      pt: 14,
      tr: 15,
      zh: 16,
      ja: 17,
      ar: 18,
    };
  }

  updateLanguage($event: Language, active: number): void {
    const deletedLanguage = { ...$event, active };
    const index = this.userLanguages$.value.findIndex(
      (x) => x.id === deletedLanguage.id,
    );
    this.userLanguages$.value[index] = deletedLanguage;
    this.spinnerService.open();
    this.uls
      .removeLanguage({ data: [...this.userLanguages$.value] })
      .pipe(
        switchMap(() => {
          return this.uls.getUserLanguages();
        }),
      )
      .subscribe({
        next: (res) => {
          this.userLanguages$.next(res);
          this.getDisabledLanguagesCount(this.disabledLanguagesCount$, res);
          this.getEnabledLanguagesCount(this.enabledLanguagesCount$, res);
          this.spinnerService.close();
        },
        complete: () => this.spinnerService.close(),
      });
  }

  private getDisabledLanguagesCount(
    subject: BehaviorSubject<number>,
    x: Language[],
  ): void {
    const count = x.filter((lang) => lang.active === 0).length;
    subject.next(count);
  }

  private getEnabledLanguagesCount(
    subject: BehaviorSubject<number>,
    x: Language[],
  ): void {
    const count = x.filter((lang) => lang.active === 1).length;
    subject.next(count);
  }

  private getActiveLanguageShortName(langs: Language[]): Locale {
    const activeLangs = langs.filter((l) => l.active === 1);
    return activeLangs[0].short_name;
  }

  public getActiveFullLanguage(langs: Language[]): Language {
    const activeLangs = langs.filter((l) => l.active === 1);
    return activeLangs[0];
  }

  private getActiveIdLanguage(langs: Language[]): number {
    const activeLangs = langs.filter((l) => l.active === 1);
    return activeLangs[0].id;
  }
}
