import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProfileService } from '../services/profile.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(
    private ps: ProfileService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    const role = localStorage.getItem('role');
    if (role === 'Support Manager') {
      return of(true);
    } else {
      this.router.navigate(['/']);
      return of(false);
    }
  }
}
