<div class="header-wrapper">
  <div class="main-title">
    <h3 class="title">Automations</h3>
    <span
      *ngIf="
        (this.ss.freeSlugsCount | async)?.free_automatic_slugs as freeFlugsCount
      "
      class="creation-box-notice"
      >{{ freeFlugsCount }} free left</span
    >
  </div>
  <norby-add-new-button (addNew)="ss.createSlug()"></norby-add-new-button>
</div>
