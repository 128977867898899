<section class="chat-page-container">
  <div *ngIf="showChats" class="chat-page-sidebar">
    <ng-container *ngIf="chatListTab$ | async as tab">
      <div class="attributes-search">
        <div class="flex header">
          <div class="flex">
            <h3
              *ngIf="
                chatListTab$.value === 'my' || chatListTab$.value === 'open'
              "
            >
              Chats
            </h3>
            <h3
              *ngIf="
                chatListTab$.value === 'bot' || chatListTab$.value === 'closed'
              "
            >
              {{ chatListTab$.value | titlecase }} Chats
            </h3>
            <norby-update-button
              [loading]="chatService.isLoading$ | async"
              [tab]="tab"
            ></norby-update-button>
          </div>
          <norby-search-button
            (click)="toggleSearch()"
            [isOpen]="searchEnabled"
          ></norby-search-button>
        </div>
        <norby-search
          [chatListTab]="chatListTab$"
          [enabled]="searchEnabled"
        ></norby-search>
        <div class="select-chat">
          <div class="chat-type-selector">
            <button
              class="tab_selector"
              (click)="setChatsTab('my')"
              [ngClass]="{ active: (chatListTab$ | async) === 'my' }"
            >
              My ({{ chatService.myChatsTotal$ | async }})
            </button>
            <button
              class="tab_selector"
              (click)="setChatsTab('open')"
              [ngClass]="{ active: (chatListTab$ | async) === 'open' }"
            >
              Open ({{ chatService.openChatsTotal$ | async }})
            </button>
          </div>
          <norby-closed-dropdown
            (selectEvent)="selectClosed($event)"
          ></norby-closed-dropdown>
        </div>
      </div>
      <div
        (scrolled)="chatService.loadFilteredChats(tab)"
        *ngIf="tab"
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        class="chat-list"
        infinite-scroll
      >
        <div *ngIf="tab === 'my'">
          <ng-container
            *ngFor="
              let myChat of chatService.myChats$ | async;
              trackBy: trackByKey
            "
          >
            <norby-chat-card
              (chatSelected)="selectChat($event)"
              [chat]="myChat"
              [selectedId]="selectedChat?.id"
            >
            </norby-chat-card>
            <div class="separator"></div>
          </ng-container>
          <ng-container *ngIf="(chatService.isLoading$ | async) !== true">
            <div *ngIf="(chatService.myChatsTotal$ | async) === 0">
              <norby-no-chats-arrow></norby-no-chats-arrow>
            </div>
          </ng-container>
        </div>
        <div *ngIf="tab === 'open'">
          <ng-container *ngFor="let chat of chatService.openChats$ | async">
            <norby-chat-card
              (chatSelected)="selectChat($event)"
              [chat]="chat"
              [selectedId]="selectedChat?.id"
            >
            </norby-chat-card>
            <div class="separator"></div>
          </ng-container>
          <div
            *ngIf="(chatService.openChatsTotal$ | async) === 0"
            class="open-chat-service"
          >
            <div class="no-chats-to-show-text">
              <p><strong>No chats to show</strong></p>
            </div>
          </div>
        </div>
        <div *ngIf="tab === 'closed'">
          <ng-container
            *ngFor="
              let chat of chatService.closedChats$ | async;
              trackBy: trackByKey
            "
          >
            <norby-chat-card
              (chatSelected)="selectChat($event)"
              [chat]="chat"
              [selectedId]="selectedChat?.id"
            >
            </norby-chat-card>
            <div class="separator"></div>
          </ng-container>
          <div
            *ngIf="(chatService.closedChats$ | async)?.length === 0"
            class="open-chat-service"
          >
            <div class="no-chats-to-show-text">
              <p><strong>No chats to show</strong></p>
            </div>
          </div>
        </div>
        <div *ngIf="tab === 'bot'">
          <ng-container
            *ngFor="
              let chat of chatService.botChats$ | async;
              trackBy: trackByKey
            "
          >
            <norby-chat-card
              (chatSelected)="selectChat($event)"
              [chat]="chat"
              [selectedId]="selectedChat?.id"
            >
            </norby-chat-card>
            <div class="separator"></div>
          </ng-container>
          <div
            *ngIf="(chatService.botChats$ | async)?.length === 0"
            class="open-chat-service"
          >
            <div class="no-chats-to-show-text">
              <p><strong>No chats to show</strong></p>
            </div>
          </div>
        </div>
        <norby-spinner *ngIf="chatService.isLoading$ | async"></norby-spinner>
      </div>
    </ng-container>
  </div>
  <div *ngIf="showChatMainContainer" class="chat-main-container">
    <norby-no-chat-stub *ngIf="!selectedChat"></norby-no-chat-stub>
    <ng-container *ngIf="selectedChat && profile">
      <div class="chat-header">
        <norby-client-details></norby-client-details>
        <div *ngIf="!selectedChat.open && chatListTab$.value === 'closed'">
          <button (click)="reopenChat(selectedChat)" class="main-button">
            Reopen
          </button>
        </div>
        <div *ngIf="selectedChat.open" class="flex align-center">
          <!-- @TODO  Move dropdown to smart component and inject all dependencies through service-->
          <norby-assign-dropdown
            (assignEvent)="assignTo(selectedChat, $event)"
            [agents]="(agents | async) || []"
            [managers]="(managers | async) || []"
            [profile]="profile"
          >
          </norby-assign-dropdown>
          <div
            *ngIf="
              profile.role === 'Support Agent' && selectedChat?.mine === false
            "
          >
            <button (click)="assignToMe(selectedChat)" class="main-button">
              Assign to me
            </button>
          </div>
          <div
            *ngIf="
              profile.role === 'Support Manager' ||
              (profile.role === 'Support Agent' && selectedChat?.mine)
            "
          >
            <button (click)="closeChat(selectedChat)" class="main-button">
              Close
            </button>
          </div>
        </div>
      </div>
      <div class="mobile-chat-header">
        <div class="mobile-chat-header-top">
          <div class="mob-top-left">
            <button (click)="toggleShowChats()" class="back-to-chat-button">
              <img alt="back" src="assets/avatars/back-arrow-button.svg" />
            </button>
            <norby-client-details></norby-client-details>
          </div>
          <button (click)="showAttributes()" class="open-user-details">
            <img alt="user-info" src="assets/avatars/roll-details-button.svg" />
          </button>
        </div>
        <div
          *ngIf="chatListTab$.value !== 'bot'"
          class="mobile-chat-header-bottom"
        >
          <div
            *ngIf="
              profile.role === 'Support Agent' && selectedChat?.mine === false
            "
          >
            <button (click)="assignToMe(selectedChat)" class="main-button">
              Assign to me
            </button>
          </div>
          <div *ngIf="selectedChat?.open">
            <norby-assign-dropdown
              (assignEvent)="assignTo(selectedChat, $event)"
              [agents]="(agents | async) || []"
              [managers]="(managers | async) || []"
              [profile]="profile"
            >
            </norby-assign-dropdown>
          </div>
          <div class="mobile-bottom-buttons">
            <button
              (click)="chatService.reopenChat(selectedChat)"
              *ngIf="selectedChat.open === false"
              class="main-button"
            >
              Reopen
            </button>
            <button
              (click)="closeChat(selectedChat)"
              *ngIf="
                (profile.role === 'Support Manager' ||
                  (profile.role === 'Support Agent' && selectedChat?.mine)) &&
                selectedChat?.open
              "
              class="main-button"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div class="chat-main-inner">
        <div class="chat-window">
          <div
            #scroller
            (scroll)="scrolled()"
            [scrollTop]="scroller.scrollHeight"
            class="chat-window-full"
          >
            <norby-blue-bar
              [style]="{
                position: 'absolute',
                top: '0px',
                width: '100%',
                'z-index': 2
              }"
              [visible]="
                (selectedChat.online$ | async) === false &&
                (selectedChat.email$ | async) !== ''
              "
            >
              The client has left the chat. He’ll be notified of new messages by
              his email.
            </norby-blue-bar>
            <norby-blue-bar
              [style]="{
                position: 'absolute',
                top: '0px',
                width: '100%',
                'z-index': 2
              }"
              [visible]="selectedChat.showConnectionError || false"
            >
              Trying to connect you to the server… Wait or try to chat later.
            </norby-blue-bar>
            <div
              [style.marginBottom]="selectedChat && selectedChat.mine"
              class="chat-messages"
            >
              <ng-container *ngFor="let message of selectedChat?.messages">
                <norby-chat-message #msg [message]="message">
                </norby-chat-message>
              </ng-container>

              <div *ngIf="selectedChat?.typingObservable | async">
                <div class="typing_container">
                  <div class="typing_message">Typing...</div>
                  <norby-client-icon [size]="'large'"></norby-client-icon>
                </div>
              </div>
            </div>
          </div>
          <norby-message-form
            (newEmail)="sendEmail($event)"
            (newEmailImage)="sendImageMessage($event, true)"
            (newImage)="sendImageMessage($event, false)"
            (newMessage)="send($event)"
            *ngIf="shouldBeVisible() && chatListTab$.value !== 'closed'"
            [email]="selectedChat.email$ | async"
            [isOnline]="selectedChat.online$ | async"
            [profile]="profile"
            [selectedChat]="selectedChat"
          ></norby-message-form>
        </div>
        <div class="chat-attributes">
          <norby-chat-attributes
            *ngIf="selectedChat && isUserAttributesOpen"
            [chat]="selectedChat"
            [email]="selectedChat.email$ | async"
            [name]="selectedChat.name$ | async"
            [profile]="profile"
          >
          </norby-chat-attributes>
        </div>
      </div>
    </ng-container>
  </div>
</section>
