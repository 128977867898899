import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class Authenticated {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.auth.isAuthenticated.pipe(
      switchMap((authenticated) => {
        if (authenticated) {
          return of(true);
        }
        this.router.navigate(['/login']);
        return of(false);
      }),
    );
  }
}
