import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teamAssignStrConverter',
})
export class TeamAssignStrConverterPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (value === 'team_assign') {
      return 'Team assigning'.toUpperCase();
    }
    return value;
  }
}
