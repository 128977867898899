import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'norby-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
})
export class SpinnerComponent implements OnInit {
  @Input() color = 'var(--nrb-ob-color)';
  @Input() width = 34;
  @Input() height = 34;
  @Input() margin = '16px auto';
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.style.setProperty(
      '--spinner-color',
      this.color,
    );
  }
}
