import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BillingStatus } from '../../types/Automations/BillingStatus';
import { Country } from '../../types/Automations/Country';
import { Invoice } from '../../types/Automations/Invoice';
import { Limits } from '../../types/Automations/Limits';
import { PromoCode } from '../../types/Automations/PromoCode';
import {
  AddressDTO,
  BillingAddressResponse,
} from '../../types/BillingAddressResponse';
import { CompanyDetailsDTO } from '../../types/CompanyDetails';
import { CompanyDetailsResponse } from '../../types/CompanyDetailsResponse';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  url = `${environment.botEndpoint}/api`;

  constructor(private http: HttpClient) {}

  public getLimits(): Observable<Limits> {
    return this.http.get<Limits>(`${this.url}/user/limits`);
  }

  public getBillingPortal(): Observable<string> {
    return this.http.get<string>(`${this.url}/billing-portal`);
  }

  public getSubscriptionStatuses(): Observable<BillingStatus> {
    return this.http.get<BillingStatus>(`${this.url}/subscription-statuses`);
  }

  public getInvoices(): Observable<Invoice[]> {
    return this.http
      .get<{ data: Invoice[] }>(`${this.url}/invoice`)
      .pipe(map((res) => res.data));
  }

  public getPromoCode(): Observable<PromoCode> {
    return this.http.get<PromoCode>(`${this.url}/promo-code`);
  }

  public setReminderStatus(): Observable<boolean> {
    return this.http.post<boolean>(`${this.url}/user/paid-reminder`, {});
  }

  public setPromoCode(code: { code: string }): Observable<{ code: string }> {
    return this.http.post<{ code: string }>(`${this.url}/add-promo-code`, code);
  }

  public getCountries(): Observable<Country[]> {
    return this.http
      .get<{ data: Country[] }>(`${this.url}/countries`)
      .pipe(map((res) => res.data));
  }

  public setAddress(body: AddressDTO): Observable<BillingAddressResponse> {
    return this.http
      .post<{ data: BillingAddressResponse }>(`${this.url}/user/address`, body)
      .pipe(map((res) => res.data));
  }

  public getAddress(): Observable<BillingAddressResponse> {
    return this.http
      .get<{ data: BillingAddressResponse }>(`${this.url}/user/address`)
      .pipe(map((res) => res.data));
  }

  public setCompanyDetails(
    body: CompanyDetailsDTO,
  ): Observable<CompanyDetailsResponse> {
    return this.http
      .post<{
        data: CompanyDetailsResponse;
      }>(`${this.url}/user/company-details`, body)
      .pipe(map((res) => res.data));
  }

  public getCompanyDetails(): Observable<CompanyDetailsResponse> {
    return this.http
      .get<{ data: CompanyDetailsResponse }>(`${this.url}/user/company-details`)
      .pipe(map((res) => res.data));
  }

  public getBillingInfoLink(): Observable<string> {
    return this.http.get<string>(`${this.url}/billing-portal/update`);
  }
}
