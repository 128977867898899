import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'norby-offline-bar',
  templateUrl: './offline-bar.component.html',
  styleUrls: ['./offline-bar.component.css'],
})
export class OfflineBarComponent {
  @Input() isOnline: Observable<boolean> = of(false);
  @Input() email: Observable<string> = of('');
}
