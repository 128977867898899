import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css'],
})
export class CommentComponent {
  @Input() isVisible = false;
}
