<ng-container *ngIf="chat">
  <div
    class="container"
    (click)="select()"
    [ngClass]="{ red: chat.id === selectedId }"
    [attr.data-chat-id]="chat.id"
  >
    <norby-viewed [viewed]="chat.viewed$ | async"></norby-viewed>
    <div class="chat-card">
      <div class="flex">
        <h4 *ngIf="chat" class="truncate">
          {{ chat.name$ | async }}
        </h4>
        <div class="time">
          <span *ngIf="chat.lastMessage$ | async as lastMessage">
            {{ lastMessage.full_date | date: "dd.MM.YY HH:mm" }}
          </span>
        </div>
      </div>
      <div
        *ngIf="chat.lastMessage$ | async as lastMessage"
        class="chat-card-last-message"
      >
        <div class="chat-card-avatar-container">
          <norby-agent-avatar
            *ngIf="
              lastMessage.author === 'manager' || lastMessage.author === 'agent'
            "
            [avatar]="lastMessage.agent?.avatar"
            [size]="'small'"
            [showIndicator]="false"
          >
          </norby-agent-avatar>
          <norby-client-icon
            [size]="'small'"
            *ngIf="lastMessage.author === 'client'"
          ></norby-client-icon>
          <div *ngIf="lastMessage.author === 'bot'">
            <norby-bot-icon [size]="'small'"></norby-bot-icon>
          </div>
        </div>
        <div class="message truncate">{{ textMessage | async }}</div>
      </div>
    </div>
  </div>
</ng-container>
