import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'norby-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.css'],
})
export class TextAreaComponent {
  @Input() label = '';
  @Input() maxLength = 0;
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = '';
  @Input() rows = 3;
  @Input() cols = 0;
  @Input() name = 'This field';
  @Input() textareaWidth = '387px';
}
