<div class="attributes-modal__wrapper">
  <h4 class="attributes-modal__title">{{ notificationTitle }}</h4>
  <div class="attributes-modal__text">{{ deleteNotification }}</div>
  <div class="attributes-modal__buttons">
    <button class="secondary-button" (click)="closePopup.emit()">
      {{ cancelAction }}
    </button>
    <button class="main-button" (click)="remove.emit()">
      {{ submitAction }}
    </button>
  </div>
</div>
