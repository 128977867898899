import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectOption } from './../../select/select.component';

@Component({
  selector: 'norby-choose-actions',
  templateUrl: './choose-actions.component.html',
  styleUrls: ['./choose-actions.component.css'],
})
export class ChooseActionsComponent {
  @Input() title = '';
  @Input() role = '';
  @Input() availableRoles: SelectOption[] = [];
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Output() selectRole = new EventEmitter();

  hoveredRole: string | null = null;

  @HostListener('mouseover', ['$event']) onMouseOver(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const roleOption = target.closest('.role-option');
    if (roleOption) {
      const roleId = roleOption.getAttribute('data-role-id');
      if (roleId) {
        this.hoveredRole = roleId;
      }
    }
  }

  @HostListener('mouseout', ['$event']) onMouseOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const roleOption = target.closest('.role-option');
    if (roleOption) {
      const roleId = roleOption.getAttribute('data-role-id');
      if (roleId) {
        this.hoveredRole = null;
      }
    }
  }
}
