<button
  type="button"
  (click)="addNew.emit()"
  [attr.badgeContent]="badgeContent"
  [style.background-color]="nonActive ? '#F6AFBC' : bgColor"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [ngStyle]="
    profileService.showAutomationGuide ? { zIndex: 1123 } : { zIndex: 1121 }
  "
>
  <svg
    class="inner-svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M20.75 14.3431C20.75 13.9289 20.4142 13.5931 20 13.5931C19.5858 13.5931 19.25 13.9289 19.25 14.3431H20.75ZM19.25 25.6568C19.25 26.0711 19.5858 26.4068 20 26.4068C20.4142 26.4068 20.75 26.0711 20.75 25.6568H19.25ZM14.3431 19.25C13.9289 19.25 13.5931 19.5858 13.5931 20C13.5931 20.4142 13.9289 20.75 14.3431 20.75V19.25ZM25.6568 20.75C26.0711 20.75 26.4068 20.4142 26.4068 20C26.4068 19.5858 26.0711 19.25 25.6568 19.25V20.75ZM19.25 14.3431V25.6568H20.75V14.3431H19.25ZM14.3431 20.75H25.6568V19.25H14.3431V20.75Z"
      [attr.fill]="plusColor"
    />
  </svg>
</button>
