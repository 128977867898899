<div class="page-container">
  <div class="page card">
    <h3>Referral Cabinet</h3>
    <div class="page-content-box">
      <div class="flex">
        <h4>Link</h4>
        <div class="flex">
          <div class="page-content-value">
            {{ pss.referralLink$ | async }}
          </div>
          <norby-small-copy-button
            (click)="copyItemValue()"
          ></norby-small-copy-button>
        </div>
      </div>
    </div>
  </div>
</div>
