<div
  class="slug-search-wrapper"
  norbyClickOutside
  (clickOutside)="showOptions = false"
>
  <input
    [formControl]="slugName"
    class="slug-search-input"
    type="text"
    placeholder="Search by names …"
  />
  <norby-union-icon
    *ngIf="!showOptions"
    [isDotVisible]="(ss.filteredType$ | async) !== 'all'"
    (showOptions)="showOptions = true"
  ></norby-union-icon>
  <norby-expose-button
    *ngIf="showOptions"
    (click)="showOptions = false"
    [isOpen]="showOptions"
    [background]="'var(--grayF1)'"
  ></norby-expose-button>
  <ul *ngIf="showOptions" class="slug-search-list">
    <ng-container *ngFor="let option of slugsFilterValues | keyvalue">
      <li
        class="slug-search-list-item"
        (click)="selectFilter(option.key)"
        [attr.data-key]="option.key"
      >
        <div class="item-inner">
          {{ option.value }}
          <norby-radio-button
            [color]="
              option.key === (ss.filteredType$ | async)
                ? 'var(--nrb-secondary-color)'
                : option.key === hoveredOptionKey
                  ? 'white'
                  : 'var(--grayF1)'
            "
          ></norby-radio-button>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
