import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  url = `${environment.endpoint}/api/password`;

  constructor(private http: HttpClient) {}

  resetPassword(plainPassword: string, token: string): Observable<boolean> {
    return this.http
      .post(
        `${this.url}/reset/${token}`,
        { plainPassword },
        { observe: 'response' },
      )
      .pipe(map((response) => response.status === 200));
  }

  sendResetEmail(email: string): Observable<boolean> {
    return this.http
      .post(`${this.url}/reset`, { email }, { observe: 'response' })
      .pipe(map((response) => response.status === 200));
  }
}
