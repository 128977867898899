import { Component } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'norby-toast',
  styles: [
    `
      :host {
        display: block;
        background: #ffffff;
        box-shadow:
          0 -2px 16px rgba(0, 0, 0, 0.06),
          0 4px 16px rgba(0, 0, 0, 0.06),
          2px 1px 32px rgba(0, 0, 0, 0.06) !important;
        border-radius: 4px !important;
        padding: 0 !important;
      }

      .toast-cont {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.1px;
        margin: 12px 16px;
        color: #000000;
      }
    `,
  ],
  template: `
    <div class="toast-cont">
      {{ message }}
    </div>
  `,
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast {}
