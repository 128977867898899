<div class="container">
  <form [formGroup]="newButtonForm">
    <div class="chat-button-input">
      <input
        [readOnly]="true"
        maxlength="18"
        formControlName="name"
        class="input-name button-name"
        type="text"
        placeholder="Edit the button…"
      />
    </div>
  </form>
  <div class="widget-input">
    <div class="chat-button-subtitle">
      The button appears in chats and allows your customers to start a new
      conversation
    </div>
    <norby-bin
      class="ml-auto"
      (remove)="removeNewChatButton()"
      [showBin]="true"
      [item]="'Widget'"
    ></norby-bin>
  </div>
</div>
