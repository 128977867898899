import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-blue-bar',
  templateUrl: './blue-bar.component.html',
  styleUrls: ['./blue-bar.component.css'],
})
export class BlueBarComponent {
  @Input() visible = false;
}
