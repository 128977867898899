import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Attribute } from '../types/Attribute';
import { AttributesApiService } from './api/attributes-api.service';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {
  public loader$ = new BehaviorSubject(false);
  public allUserAttributes$ = new BehaviorSubject<Attribute[]>([]);
  public allChatsAttributes$ = new BehaviorSubject<Attribute[]>([]);
  public errorMessage$ = new BehaviorSubject<string>('');

  protected catchErrorMessages(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400: {
        return `Http failure response`;
      }
      case 404: {
        return 'Attribute not found';
      }
      case 409: {
        return `You already have an attribute with this name`;
      }
      case 422: {
        return `Attribute limit reached`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }

  constructor(private attributesApi: AttributesApiService) {}

  public loadUserAttributes(): void {
    this.loader$.next(true);
    this.attributesApi.getCustomUserAttributes().subscribe(
      (values) => {
        this.allUserAttributes$.next(values);
        this.loader$.next(false);
      },
      () => {
        this.loader$.next(false);
      },
    );
  }

  public createUserAttribute(attribute: Attribute): void {
    this.attributesApi.addAttribute(attribute, 'user').subscribe({
      next: (value) => {
        this.allUserAttributes$.next([value, ...this.allUserAttributes$.value]);
      },
      error: (error) => {
        if (error) {
          this.errorMessage$.next(this.catchErrorMessages(error));
        }
      },
    });
  }

  public updateUserAttribute(
    attributeUuid: string,
    attributeObject: object,
  ): void {
    this.attributesApi
      .editAttribute(attributeUuid, attributeObject, 'user')
      .subscribe({
        next: (value) => {
          this.allUserAttributes$.next([
            ...this.allUserAttributes$.value.map((next) =>
              next.uuid === value.uuid ? value : next,
            ),
          ]);
        },
        error: (error) => {
          if (error) {
            this.errorMessage$.next(this.catchErrorMessages(error));
          }
        },
      });
  }

  public deleteUserAttribute(attributeUuid: string): void {
    this.attributesApi.removeAttribute(attributeUuid, 'user').subscribe({
      next: () => {
        this.allUserAttributes$.next(
          this.allUserAttributes$.value.filter(
            (attr) => attr.uuid !== attributeUuid,
          ),
        );
      },
      error: (error) => {
        if (error) {
          this.errorMessage$.next(this.catchErrorMessages(error));
        }
      },
    });
  }

  // Chat attributes in settings

  public loadChatAttributes(): void {
    this.loader$.next(true);
    this.attributesApi.getCustomChatAttributes().subscribe(
      (values) => {
        this.allChatsAttributes$.next(values);
        this.loader$.next(false);
      },
      () => {
        this.loader$.next(false);
      },
    );
  }

  public createChatAttribute(attribute: Attribute): void {
    this.attributesApi.addAttribute(attribute, 'chat').subscribe({
      next: (value) => {
        this.allChatsAttributes$.next([
          value,
          ...this.allChatsAttributes$.value,
        ]);
      },
      error: (error) => {
        if (error) {
          this.errorMessage$.next(this.catchErrorMessages(error));
        }
      },
    });
  }

  public updateChatAttribute(
    attributeUuid: string,
    attributeObject: object,
  ): void {
    this.attributesApi
      .editAttribute(attributeUuid, attributeObject, 'chat')
      .subscribe({
        next: (value) => {
          this.allChatsAttributes$.next([
            ...this.allChatsAttributes$.value.map((next) =>
              next.uuid === value.uuid ? value : next,
            ),
          ]);
        },
        error: (error) => {
          if (error) {
            this.errorMessage$.next(this.catchErrorMessages(error));
          }
        },
      });
  }

  public deleteChatAttribute(attributeUuid: string): void {
    this.attributesApi.removeAttribute(attributeUuid, 'chat').subscribe({
      next: () => {
        this.allChatsAttributes$.next(
          this.allChatsAttributes$.value.filter(
            (attr) => attr.uuid !== attributeUuid,
          ),
        );
      },
      error: (error) => {
        if (error) {
          this.errorMessage$.next(this.catchErrorMessages(error));
        }
      },
    });
  }
}
