<!--    Third step-->
<form
  *ngIf="currentStep.name === 'thirdStep' && currentStep.state"
  class="info-form"
>
  <div class="complition-text-box">
    Now you can set the widget up on your website. The script can be placed
    before the <strong>&lt;/body&gt;</strong> tag.
  </div>
  <norby-widget-code-box
    [widgetKey]="obs.apiKey"
    [hasBorder]="true"
  ></norby-widget-code-box>
  <p class="complition-notice-box">
    We have sent a copy of the widget snippet and all additional information to
    your email.
  </p>
  <button (click)="thirdStepData.emit({ stepData: {} })" class="main-button">
    Continue
  </button>
</form>
