<div class="shadow-container">
  <div class="shadow-popup">
    <div class="card-header">
      <h4 class="card-title">🙌 Welcome to Norby!</h4>
      <norby-close-icon
        class="cursor-pointer"
        (click)="close()"
      ></norby-close-icon>
    </div>
    <div class="card-text">
      Click <strong>Need Help?</strong> to start setting up Norby or to get any
      help in future
    </div>
  </div>
</div>
