import { Component, Input } from '@angular/core';
import { UserDetails } from '../../@core/types/UserDetails';

@Component({
  selector: 'norby-user-widget',
  templateUrl: './user-widget.component.html',
  styleUrls: ['./user-widget.component.css'],
})
export class UserWidgetComponent {
  @Input() isExpanded = true;
  @Input() user: UserDetails | null = null;
}
