import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SearchFilterConditions } from '../../types/FilterConditions';
import { UserDetails } from '../../types/UserDetails';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  private baseUrl = `${environment.endpoint}/api/panel/support/user/filter`;

  constructor(private http: HttpClient) {}

  getUsers(
    page: number,
    limit: number,
    filterConditions?: SearchFilterConditions,
  ): Observable<{ items: UserDetails[] }> {
    const body = filterConditions
      ? { page, limit, ...filterConditions }
      : { page, limit };
    return this.http.post<{ items: UserDetails[] }>(`${this.baseUrl}`, body);
  }
}
