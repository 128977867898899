import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { MixpanelService } from '../../../@core/services/mixpanel.service';

@Component({
  selector: 'norby-closed-dropdown',
  templateUrl: './closed-dropdown.component.html',
  styleUrls: ['./closed-dropdown.component.css'],
})
export class ClosedDropdownComponent {
  isOpen = false;
  @Output() selectEvent = new EventEmitter<'closed' | 'bot' | 'open' | 'my'>();

  constructor(
    private ref: ElementRef,
    private mx: MixpanelService,
  ) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  selectClosed(): void {
    this.mx.track('click_closed_chats', 'chats');
    this.selectEvent.emit('closed');
    this.isOpen = false;
  }

  selectBot(): void {
    this.mx.track('click_bot_chats', 'chats');
    this.selectEvent.emit('bot');
    this.isOpen = false;
  }
}
