import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { States } from 'src/app/@core/types/Automations/States';
import { PaymentService } from '../../../../@core/services/automations/payment.service';
import { ProjectSettingsService } from '../../../../@core/services/settings/project-settings.service';
import { SpinnerService } from '../../../../@core/services/spinner.service';
import { Country } from '../../../../@core/types/Automations/Country';
import { BillingAddress } from '../../../../@core/types/BillingAddress';
import { CompanyDetails } from '../../../../@core/types/CompanyDetails';

@Component({
  selector: 'norby-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.css'],
})
export class BillingInfoComponent implements OnInit, OnDestroy {
  @Output() sendBillingDetails = new EventEmitter<{
    address: BillingAddress;
    companyDetails: CompanyDetails;
  }>();

  public countries$ = this.ps.countries$;
  public updateLink$ = this.ps.updateLink$;
  public subscription = new Subscription();
  public userBillingAddress$ = this.pps.projectSettings.userBillingAddress$;
  public companyDetails$ = this.pps.projectSettings.companyDetails$;
  private selectedCountry: Country | null = null;

  public billingDetailsForm: UntypedFormGroup;

  constructor(
    private ps: PaymentService,
    private fb: UntypedFormBuilder,
    private ss: SpinnerService,
    private pps: ProjectSettingsService,
  ) {
    this.ps.initPaymentStatuses();
    this.billingDetailsForm = this.fb.group({
      country: [null, Validators.required],
      city: [null, Validators.required],
      state: [''],
      address: [null, Validators.required],
      code: [null, Validators.required],
      regNumber: [''],
      vatNumber: [''],
    });
  }

  ngOnInit(): void {
    this.subscription.add(
      this.billingDetailsForm.valueChanges
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(() => {
          if (this.billingDetailsForm.valid) {
            this.emitBillingDetails();
          }
        }),
    );
    this.getUserAddress();
    this.getCompanyDetails();
  }

  public setCountry($event: Country): void {
    this.billingDetailsForm.controls.country.setValue($event);
    this.selectedCountry = $event;
  }

  getCurrentModel(): void {
    const billingAddress = this.userBillingAddress$.value;
    const companyDetails = this.companyDetails$.value;

    this.billingDetailsForm.patchValue({
      country: billingAddress?.country,
      city: billingAddress?.city,
      state: billingAddress?.state ? billingAddress.state : '',
      address: billingAddress?.line1,
      code: billingAddress?.postal_code,
      regNumber: companyDetails?.reg_number ? companyDetails.reg_number : '',
      vatNumber: companyDetails?.vat_number ? companyDetails.vat_number : '',
    });
  }

  getUserAddress(): void {
    this.ss.open();
    this.ps.getUserAddress().subscribe(
      (a) => {
        this.pps.getUserBillingAddress(a);
        this.getCurrentModel();
        this.ss.close();
      },
      () => {
        this.ss.close();
      },
    );
  }

  getCompanyDetails(): void {
    this.ss.open();
    this.ps.getCompanyDetails().subscribe(
      (d) => {
        this.pps.getCompanyDetails(d);
        this.getCurrentModel();
        this.ss.close();
      },
      () => {
        this.ss.close();
      },
    );
  }

  get isCountriesWithState(): boolean {
    if (this.selectedCountry) {
      return !!this.countries$.value?.findIndex(
        (el) => el.has_state === this.selectedCountry?.has_state,
      );
    } else if (this.userBillingAddress$.value) {
      return !!this.countries$.value?.findIndex(
        (el) =>
          el.has_state === this.userBillingAddress$.value?.country.has_state,
      );
    }
    return false;
  }

  setState($event: States): void {
    this.billingDetailsForm.controls.state.setValue($event);
  }

  emitBillingDetails(): void {
    const addressDetails: BillingAddress = {
      country: this.billingDetailsForm.value.country,
      city: this.billingDetailsForm.value.city,
      state: this.billingDetailsForm.value.state,
      address: this.billingDetailsForm.value.address,
      code: this.billingDetailsForm.value.code,
    };

    const companyDetails: CompanyDetails = {
      reg_number: this.billingDetailsForm.value.regNumber,
      vat_number: this.billingDetailsForm.value.vatNumber,
    };

    this.sendBillingDetails.emit({
      address: addressDetails,
      companyDetails: companyDetails,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
