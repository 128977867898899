import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MixpanelService } from '../../../../@core/services/mixpanel.service';

@Component({
  selector: 'norby-attributes-header',
  templateUrl: './attributes-header.component.html',
  styleUrls: ['./attributes-header.component.css'],
})
export class AttributesHeaderComponent {
  public title = 'Add Attribute';
  @Input() attributeHeaderTitle = '';
  @Input() attributeHeaderDescription = '';
  @Output() openAddAttributeModal = new EventEmitter();

  constructor(private mx: MixpanelService) {}

  sendMixpanelEvent(): void {
    this.mx.track(
      'click_attributes_help',
      `settings/attributes/${this.attributeHeaderTitle}`,
      { key: 'where', value: `${this.attributeHeaderTitle} attributes` },
    );
  }
}
