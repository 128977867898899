<div class="attributes-header">
  <div class="attributes-header__wrapper">
    <h3 class="attributes-header__title">
      {{ attributeHeaderTitle }} Attributes
      <a (click)="sendMixpanelEvent()" class="title-icon" target="_blank">
        <norby-question-circle
          [link]="
            'https://norby-io.notion.site/Chat-and-User-Attributes-1369c62106894c5aafa1135e21557efe'
          "
        ></norby-question-circle>
      </a>
    </h3>
    <div class="attributes-header__subtitle">
      {{ attributeHeaderDescription }}
    </div>
  </div>
  <norby-accent-button
    (click)="openAddAttributeModal.emit()"
    [title]="title"
  ></norby-accent-button>
</div>
