import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ProfileUpdateValues } from '../../domain/Profile';
import { ProfileInfoDto } from '../../types/ProfileInfo';

@Injectable({
  providedIn: 'root',
})
export class ProfileApiService {
  url = environment.endpoint + '/api/panel/profile';

  constructor(private http: HttpClient) {}

  getInfo(): Observable<ProfileInfoDto> {
    return this.http.get<ProfileInfoDto>(`${this.url}/info`);
  }

  update(data: ProfileUpdateValues): Observable<HttpResponse<null>> {
    const formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('email', data.email);
    formData.append(
      'emailNotification',
      data.emailNotification ? 'true' : 'false',
    );
    formData.append(
      'assignNotification',
      data.assignNotification ? 'true' : 'false',
    );
    formData.append('status', data.status);
    formData.append('showGuide', data.showGuide ? 'true' : 'false');
    formData.append(
      'showAutomationGuideModal',
      data.showAutomationGuideModal ? 'true' : 'false',
    );

    if (data.avatar) {
      formData.append('avatar', data.avatar as unknown as File);
    }
    if (data.newPassword) {
      formData.append('password', data.newPassword);
    }

    return this.http.post<HttpResponse<null>>(`${this.url}/update`, formData);
  }
}
