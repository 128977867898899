<button>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.3334 8.99984C17.3334 13.6022 13.6024 17.3332 9.00002 17.3332C4.39765 17.3332 0.666687 13.6022 0.666687 8.99984C0.666687 4.39746 4.39765 0.666504 9.00002 0.666504C13.6024 0.666504 17.3334 4.39746 17.3334 8.99984ZM7.03033 5.96955C6.73744 5.67665 6.26256 5.67665 5.96967 5.96955C5.67678 6.26244 5.67678 6.73731 5.96967 7.03021L7.93934 8.99988L5.96967 10.9695C5.67678 11.2624 5.67678 11.7373 5.96967 12.0302C6.26256 12.3231 6.73744 12.3231 7.03033 12.0302L9 10.0605L10.9697 12.0302C11.2626 12.3231 11.7374 12.3231 12.0303 12.0302C12.3232 11.7373 12.3232 11.2624 12.0303 10.9695L10.0607 8.99988L12.0303 7.03021C12.3232 6.73731 12.3232 6.26244 12.0303 5.96955C11.7374 5.67665 11.2626 5.67665 10.9697 5.96955L9 7.93922L7.03033 5.96955Z"
      [attr.fill]="color"
    />
  </svg>
</button>
