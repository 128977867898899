<div class="search-select__wrapper" (click)="toggle()">
  <div class="search-select__inner">
    <h4 class="search-select__text" *ngIf="currentValue">
      {{ currentValue }}
    </h4>
    <h4
      class="search-select__text--placeholder"
      *ngIf="!currentValue && !!placeHolder"
    >
      {{ placeHolder }}
    </h4>
    <norby-expose-button
      [isOpen]="searchSelectState"
      [background]="'var(--grayFA)'"
    ></norby-expose-button>
  </div>
  <div *ngIf="searchSelectState" class="search-select__list">
    <ng-container *ngFor="let group of optionGroups">
      <h2 *ngIf="hasHeader" class="attribute-header">{{ group.label }}</h2>
      <ul class="condition-list">
        <li
          *ngFor="let option of group.options"
          class="search-select__item"
          (click)="selectOption(option)"
        >
          {{ option }}
        </li>
      </ul>
    </ng-container>
  </div>
</div>
