import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Attribute } from '../types/Attribute';
import { AttributesApiService } from './api/attributes-api.service';

@Injectable({
  providedIn: 'root',
})
export class AttributesStateService {
  private userAttributes = new BehaviorSubject<Attribute[]>([]);
  private charAttributes = new BehaviorSubject<Attribute[]>([]);

  constructor(private as: AttributesApiService) {}

  loadAttributes(chatUid: string, participantUid: string): void {
    this.as
      .getChatAttributesList(chatUid)
      .subscribe((attributes) => this.charAttributes.next(attributes));
    this.as
      .getParticipantAttributesList(participantUid)
      .subscribe((attributes) => this.userAttributes.next(attributes));
  }

  get userAttributes$(): Observable<Attribute[]> {
    return this.userAttributes.asObservable();
  }

  get chatAttributes$(): Observable<Attribute[]> {
    return this.charAttributes.asObservable();
  }
}
