<svg
  class="mr-fix-position"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7.91667 5.83333H12.083L9.65319 9.30595C10.0529 9.30595 10.4464 9.40452 10.7988 9.59292C11.1513 9.78133 11.4519 10.0538 11.674 10.3861C11.896 10.7184 12.0327 11.1003 12.0718 11.4981C12.111 11.8958 12.0515 12.2971 11.8985 12.6664C11.7456 13.0356 11.5039 13.3614 11.195 13.615C10.886 13.8686 10.5193 14.042 10.1273 14.12C9.73533 14.1979 9.33016 14.178 8.94769 14.062C8.56523 13.946 8.21728 13.7374 7.93466 13.4548M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
    stroke="var(--gray76)"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
