import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getLocaleById } from '../../domain/Locale';
import { Answer, Answers } from '../../types/Automations/Answer';
import { UserLanguagesService } from '../user-languages.service';

@Injectable({
  providedIn: 'root',
})
export class AnswersService {
  constructor(private uls: UserLanguagesService) {}
  public answersState$ = new BehaviorSubject<Answers | null>(null);

  public changeAnswers(answers: Answer[]): void {
    const answersData = this.getAnswersWithLangId(answers);
    const shortLang = getLocaleById(this.uls.languageId$.value);
    const multiLanguageAnswer = { [shortLang]: answersData };
    const newAnswersState = {
      ...this.answersState$.value,
      ...multiLanguageAnswer,
    };
    this.answersState$.next(newAnswersState);
  }

  public getAnswersWithLangId(
    answers: Answer[],
  ): { language_id: number; text: string }[] {
    const answersWithId: { language_id: number; text: string }[] = [];
    for (const answer of answers) {
      const { text } = answer;
      const formattedObj = { text, language_id: this.uls.languageId$.value };
      answersWithId.push(formattedObj);
    }
    return answersWithId;
  }

  getAllAnswers(): number {
    let totalAnswers = 0;
    if (this.answersState$.value) {
      for (const [, value] of Object.entries(this.answersState$.value)) {
        if (value) {
          totalAnswers = totalAnswers + value.length;
        }
      }
    }
    return totalAnswers;
  }
}
