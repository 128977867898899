import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[norbyImagePreload]',
})
export class ImagePreloadDirective {
  defaultSrc = '/assets/avatars/agent-default.svg';

  constructor(private el: ElementRef) {}

  @HostListener('load') onLoad(): void {
    const width = this.el.nativeElement.width;
    const height = this.el.nativeElement.height;

    if (width > height) {
      this.el.nativeElement.style.width = 'unset';
      this.el.nativeElement.style.height = '100%';
    } else {
      this.el.nativeElement.style.width = '100%';
      this.el.nativeElement.style.height = 'unset';
    }
  }

  @HostListener('error') onError(): void {
    this.el.nativeElement.src = this.defaultSrc;
  }
}
