import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginCheckResponse } from '../types/LoginCheck';
import { LoginDecodedToken } from '../types/LoginDecodedToken';
import { SignUpUserData } from '../types/SignUpUserData';
import { AuthApiService } from './api/auth-api.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isRefreshingToken = false;
  token = '';
  private jwtHelper = new JwtHelperService();
  public isAuthenticated = new BehaviorSubject<boolean>(this.isTokenValid());

  constructor(
    private authApi: AuthApiService,
    private ps: ProfileService,
    private router: Router,
  ) {}

  login(email: string, password: string): Observable<LoginCheckResponse> {
    return this.authApi.login({ email, password }).pipe(
      tap({
        next: (response) => {
          if (response && response.token) {
            this.setTokens(response);
            this.isAuthenticated.next(true);
          } else {
            console.error('Invalid response from login:', response);
          }
        },
        error: (error) => {
          console.error('Login error:', error);
          if (error.status === 403) {
            console.error(
              'Email not verified. Please verify your email before logging in.',
            );
          }
        },
      }),
    );
  }

  public verifyEmailToken(token: string): Observable<LoginCheckResponse> {
    return this.authApi.verifyEmailToken(token).pipe(
      tap({
        next: (response) => {
          if (response && response.token) {
            this.setTokens(response);
            this.isAuthenticated.next(true);
          } else {
            console.error('Invalid response from login:', response);
          }
        },
        error: (error) => {
          console.error('Invalid response from login:');
          if (error.status === 403) {
            console.error(
              'Email not verified. Please verify your email before logging in.',
            );
          }
        },
      }),
    );
  }
  resendVerificationEmail(email: string): Observable<void> {
    return this.authApi.resendVerificationEmail(email);
  }

  refresh(refreshToken: string): Observable<LoginCheckResponse> {
    return this.authApi.refresh(refreshToken).pipe(
      tap((response) => {
        this.setTokens(response);
        this.isAuthenticated.next(true);
        this.isRefreshingToken = false;
      }),
    );
  }

  logout(): void {
    this.isAuthenticated.next(false);
    localStorage.clear();
    this.ps.profile$.next(null);
    this.router.navigateByUrl('/login');
  }

  logoutAndRedirectToSignUp(): void {
    this.isAuthenticated.next(false);
    localStorage.clear();
    this.ps.profile$.next(null);
    this.router.navigateByUrl('/signup');
  }

  signUp(userData: SignUpUserData): Observable<{ api_key: string }> {
    return this.authApi.signup(userData);
  }

  public isTokenValid(): boolean {
    const token = GetAccessToken();
    if (token !== null) {
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  private setTokens(response: LoginCheckResponse): void {
    if (response && response.token) {
      SetAccessToken(response.token);
      SetSlugToken(response.slug_token || '');
      SetRefreshToken(response.refresh_token || '');

      const decodedToken: LoginDecodedToken | null = this.jwtHelper.decodeToken(
        response.token,
      );
      if (decodedToken) {
        SetUID(decodedToken.uid || '');
        SetMerchantApi(decodedToken.merchant?.api_key || '');
        this.isAuthenticated.next(true);
      }
    } else {
      console.error('Invalid response format:', response);
    }
  }
}

export function GetAccessToken(): string | null {
  return localStorage.getItem('at');
}

export function GetMerchantApi(): string | null {
  return localStorage.getItem('ma');
}

export function GetSlugToken(): string | null {
  return localStorage.getItem('st');
}

export function GetRefreshToken(): string | null {
  return localStorage.getItem('rf');
}

export function GetUID(): string | null {
  return localStorage.getItem('uid');
}

function SetRefreshToken(token: string): void {
  localStorage.setItem('rf', token);
}

function SetMerchantApi(key: string): void {
  localStorage.setItem('ma', key);
}

function SetUID(uid: string): void {
  localStorage.setItem('uid', uid);
}

function SetAccessToken(token: string): void {
  localStorage.setItem('at', token);
}

function SetSlugToken(token: string): void {
  localStorage.setItem('st', token);
}
