import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordsMatchValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const password = control.get('newPassword');
  const confirm = control.get('confirmPassword');

  return String(password?.value || '') !== String(confirm?.value || '')
    ? { passwordsNotMatch: true }
    : null;
};
