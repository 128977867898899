import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { WidgetService } from '../../../../../@core/services/automations/widget.service';
import { UserLanguagesService } from '../../../../../@core/services/user-languages.service';
import { PredefinedAnswersForm } from '../../../../../@core/types/Automations/Widgets/PredefinedAnswersForm';
import { PredefinedAnswersRequestData } from '../../../../../@core/types/Automations/Widgets/PredefinedAnwersRequestData';
import { Widget } from '../../../../../@core/types/Automations/Widgets/Widget';
import { validateLinkUrl } from '../../../../../@core/validators/linkValidator';

@Component({
  selector: 'norby-answers-widget',
  templateUrl: './answers-widget.component.html',
  styleUrls: ['./answers-widget.component.css'],
})
export class AnswersWidgetComponent implements OnInit, OnChanges {
  @Input() widgetDetails: Widget | undefined;
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();

  predefinedAnswersForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    protected ws: WidgetService,
    protected ss: SlugsService,
    private uls: UserLanguagesService,
  ) {
    this.predefinedAnswersForm = this.fb.group({
      messages: this.fb.array([]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetDetails.currentValue) {
      this.widgetDetails?.widget &&
        'messages' in this.widgetDetails.widget &&
        this.setInitialControls(
          this.widgetDetails.widget as unknown as PredefinedAnswersRequestData,
        );
    }
  }

  ngOnInit(): void {
    if (!this.widgetDetails) {
      this.addAnswerInput();
    }
  }

  addAnswerInput(): void {
    this.ss.savingWidget = false;
    this.formArrayAnswersInformation.push(
      this.fb.group({
        answer: ['', Validators.required],
        link: [''],
      }),
    );
  }

  savePredefinedAnswers(): void {
    if (this.predefinedAnswersForm.valid) {
      const messages = this.combineMessagesWithLinks(
        this.predefinedAnswersForm.getRawValue(),
      );
      this.save.emit(messages);
      this.ss.savingWidget = false;
    }
  }

  updatePredefinedAnswers(answersData: PredefinedAnswersForm): void {
    const messages = this.combineMessagesWithLinks(answersData);
    this.update.emit({
      id: this.widgetDetails?.id,
      widget: messages,
      type: this.widgetDetails?.type,
      language_id: this.uls.languageId$.value,
    });
  }

  get formArrayAnswersInformation(): UntypedFormArray {
    return this.predefinedAnswersForm.get('messages') as UntypedFormArray;
  }

  setInitialControls(data: PredefinedAnswersRequestData): void {
    const messages = data.messages;
    const messagesUrl = data.urlsForAnswersByIndex;
    const answersControls: UntypedFormGroup[] = [];
    messages?.forEach((message, index) => {
      answersControls.push(
        this.fb.group({
          answer: message,
          link: messagesUrl?.[index],
        }),
      );
    });
    this.formArrayAnswersInformation.controls = answersControls;
  }

  combineMessagesWithLinks(
    answers: PredefinedAnswersForm,
  ): PredefinedAnswersRequestData {
    const messages: string[] = [];
    const urls = [];
    for (const [, value] of answers.messages.entries()) {
      const { answer, link } = value;
      messages.push(answer);
      link ? urls.push(validateLinkUrl(link)) : urls.push(null);
    }
    return { messages: [...messages], urlsForAnswersByIndex: urls };
  }

  closeModalWindow(): void {
    if (this.ss.savingWidget) {
      this.ss.savingWidget = false;
    }
  }

  removePredefinedAnswer(i: number): void {
    this.formArrayAnswersInformation.removeAt(i);

    if (this.formArrayAnswersInformation.length === 0) {
      this.remove.emit();
    } else {
      this.updatePredefinedAnswers(this.predefinedAnswersForm.getRawValue());
    }
  }

  removeAll(): void {
    this.remove.emit();
  }
}
