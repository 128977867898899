import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalMinutesToTimeString',
})
export class TotalMinutesToTimeStringPipe implements PipeTransform {
  transform(totalMinutes: number): string {
    const hours = totalMinutes / 60;
    const rHours = Math.floor(hours);
    const minutes = Math.round((hours - rHours) * 60);

    return rHours > 0 ? `${rHours}h` : '' + (minutes > 0) ? `${minutes}m` : '';
  }
}
