<div [style]="sizeStyle">
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2745 34.7339C11.722 33.5611 7.12038 28.6489 6.37325 23.1078C6.13553 21.3061 6.28835 18.8245 6.74682 17.2098C7.95241 12.9605 11.739 9.06813 15.967 7.75935C17.3594 7.33442 17.5122 7.53839 16.7991 8.96615C16.2897 10.02 16.2048 10.088 15.0331 10.6659C11.0598 12.5696 8.61463 16.5299 8.59765 21.0512C8.59765 21.952 8.71651 23.0908 8.86934 23.7707C9.25988 25.3685 10.3806 27.5611 11.3145 28.6149L12.0956 29.4818L13.7766 29.1928C15.0501 28.9719 16.035 28.9209 17.9367 28.9889C20.7215 29.0908 22.3516 29.4308 24.7628 30.3996L26.257 31.0115L27.0041 30.4846C28.872 29.2098 30.7058 26.5243 31.385 24.0937C31.7586 22.7679 31.7076 18.8925 31.3001 17.7197C30.1794 14.4562 28.0909 11.9747 25.3571 10.6829C24.8477 10.4449 24.3892 10.173 24.3383 10.105C24.2873 10.037 24.3552 9.54406 24.4571 9.05114C24.6099 8.42224 24.7628 8.09929 24.9495 8.0653C25.408 7.98031 27.8022 9.3061 29.0248 10.3259C31.1303 12.0596 32.9472 14.8812 33.6943 17.5497C33.9151 18.3826 34 19.2835 34 21.1192C34 23.2268 33.932 23.7707 33.5924 24.8585C31.3511 32.0144 24.3892 36.2297 17.2745 34.7339Z"
      fill="var(--nrb-ob-color)"
    />
    <path
      d="M19.0575 28.0711C18.4122 27.9861 17.2236 27.7481 16.4255 27.5612C14.8124 27.1702 11.4673 25.8274 11.1447 25.4365C11.0089 25.2665 10.9749 24.8076 11.0598 23.8558L11.1787 22.496L10.5334 22.445L9.88818 22.394L9.93912 21.1702C9.97308 20.4903 10.041 19.6235 10.1089 19.2155C10.2448 18.4846 10.2448 18.4846 10.924 18.5186L11.6202 18.5356L11.8239 16.7339C11.9428 15.7311 12.0786 14.8642 12.1465 14.7963C12.3843 14.5583 14.354 14.1334 16.1369 13.9294C17.8349 13.7424 17.9537 13.7084 17.9537 13.3515C17.9537 12.8756 18.1745 12.7566 18.9726 12.7566L19.6348 12.7736L20.1442 11.2268L20.6536 9.69709L19.414 9.81607C18.633 9.88406 18.1236 9.86706 18.0556 9.74808C17.9537 9.59511 21.3668 2.16733 21.7064 1.82738C21.8082 1.7424 21.9271 1.7424 22.029 1.84438C22.1309 1.94637 21.9611 2.9492 21.6045 4.61493C21.2819 6.05969 21.0102 7.30049 21.0102 7.38547C21.0102 7.45346 21.5026 7.52145 22.1139 7.52145C22.9289 7.52145 23.2516 7.58944 23.3195 7.75941C23.3704 7.89539 23.1667 9.10219 22.878 10.411C22.5723 11.7368 22.3856 12.8756 22.4535 12.9266C22.5214 12.9946 22.878 13.1135 23.2685 13.2155C23.9308 13.3685 23.9817 13.4195 23.9477 13.9124C23.8968 14.4223 23.9308 14.4393 24.9665 14.7283C26.2061 15.0682 29.0757 16.309 29.2965 16.581C29.3984 16.6999 29.3984 17.2269 29.3135 17.8897C29.2455 18.5016 29.1437 19.3175 29.0927 19.7084L29.0078 20.4223L29.6361 20.4733L30.2644 20.5243L30.2134 22.292C30.1625 24.3147 30.0436 24.5866 29.2116 24.4337C28.5833 24.3147 28.5833 24.3487 28.4135 26.0314C28.3626 26.5413 28.2267 27.0682 28.1079 27.1872C27.4287 27.8671 21.6554 28.428 19.0575 28.0711ZM23.761 25.3515C24.7288 25.2495 25.5778 25.1136 25.6458 25.0456C25.7646 24.9266 26.4438 19.4875 26.4438 18.5526C26.4438 18.1617 26.308 18.0767 25.1363 17.6688C22.9799 16.9379 21.7403 16.6999 19.3971 16.581C17.5802 16.496 14.8464 16.6999 14.6087 16.9209C14.5238 17.0229 13.8615 23.5498 13.9295 23.7538C14.0653 24.0767 17.2576 25.0286 19.0914 25.2835C21.2309 25.5725 21.6554 25.5725 23.761 25.3515Z"
      fill="var(--nrb-ob-color)"
    />
    <path
      d="M23.2177 21.884L22.8272 21.4591L22.2499 21.816C21.7235 22.156 21.6556 22.156 21.265 21.901C20.6198 21.4761 20.7217 20.9152 21.5537 20.2013C22.5725 19.3345 23.2177 19.3515 24.0837 20.3033C24.8139 21.1022 24.9157 21.5951 24.4403 22.02C23.9988 22.4109 23.6762 22.3769 23.2177 21.884Z"
      fill="var(--nrb-ob-color)"
    />
    <path
      d="M17.9538 21.357C17.4613 20.9321 17.4104 20.9321 17.1217 21.1871C16.7482 21.527 16.018 21.544 15.7633 21.2211C15.3728 20.7622 15.6275 20.0993 16.3746 19.5384C17.3594 18.8415 17.9877 18.8755 18.8197 19.6913C19.6518 20.4902 19.7367 20.8811 19.2273 21.391C18.7179 21.901 18.616 21.901 17.9538 21.357Z"
      fill="var(--nrb-ob-color)"
    />
  </svg>
</div>
