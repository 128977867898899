import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtHeaderInterceptor } from './jwt-header-interceptor';
import { RefreshTokenInterceptor } from './refresh-token.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtHeaderInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
];
