<article class="product" *ngIf="product">
  <figure>
    <img *ngIf="product.img" [src]="product.img" alt="" />
    <div *ngIf="!product.img" class="image-stub">
      <norby-picture-icon />
    </div>
    <section class="priceAbsolute" *ngIf="product.price">
      <span class="oldPrice" *ngIf="product.old_price">
        {{ product.currency || "" }}{{ product.old_price }}
      </span>
      {{ product.currency || "" }}{{ product.price }}
    </section>
  </figure>
  <section class="content">
    <h1>{{ product.title }}</h1>
    <p *ngIf="product.description">{{ product.description }}</p>
    <p class="no-description-stub" *ngIf="!product.description"></p>
    <norby-product-button [button]="product.button"></norby-product-button>
  </section>
  <norby-cancel-icon
    (remove)="cancelEvent.emit()"
    [visible]="isVisibleCancel"
    [right]="'-12px'"
    [top]="'-12px'"
  ></norby-cancel-icon>
</article>
