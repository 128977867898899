import { Injectable } from '@angular/core';
import { AttributeType } from '../types/Attribute';
import {
  BooleanFilterConditions,
  DateFilterConditions,
  FilledFilterConditions,
  FilterConditions,
  NumberFilterConditions,
  SearchCallback,
  SearchFilterConditions,
  TextFilterConditions,
} from '../types/FilterConditions';

const numberConditions: NumberFilterConditions = {
  has_any_value: 'has any value',
  is: 'is',
  is_not: 'is not',
  is_unknown: 'is unknown',
  less_than: 'less than',
  more_than: 'more than',
};
const booleanFilterConditions: BooleanFilterConditions = {
  has_any_value: 'has any value',
  is: 'is',
  is_unknown: 'is unknown',
};
const textFilterConditions: TextFilterConditions = {
  contains: 'contains',
  has_any_value: 'has any value',
  is: 'is',
  is_not: 'is not',
  is_unknown: 'is unknown',
};
const dateFilterConditions: DateFilterConditions = {
  is: 'is',
  is_not: 'is not',
  after: 'after',
  before: 'before',
  is_unknown: 'is unknown',
  has_any_value: 'has any value',
};
const filterConditions: FilterConditions = {
  boolean: booleanFilterConditions,
  date: dateFilterConditions,
  float: numberConditions,
  integer: numberConditions,
  string: textFilterConditions,
};

@Injectable({
  providedIn: 'root',
})
export class FilterConditionsService {
  conditions = filterConditions;

  conditionNameByType(type: AttributeType): string[] {
    return Object.values(this.conditions[type]) || [];
  }

  conditionKeyByTypeAndValue(type: AttributeType, value: string): string {
    const typeConditions = this.conditions[type];
    const conditionKeys = Object.keys(typeConditions);
    return (
      conditionKeys.find(
        (key) => typeConditions[key as keyof typeof typeConditions] === value,
      ) || ''
    );
  }

  conditionNeedsValue(option: string): boolean {
    return option.search(/is unknown|has any value/gi) < 0;
  }

  runRequestIfConditionsAreFilled(
    conditionsObject: FilledFilterConditions,
    callback: SearchCallback,
  ): void {
    const { uuid, value, conditionType, condition } = conditionsObject;
    if (
      uuid &&
      conditionType &&
      condition &&
      (!this.conditionNeedsValue(condition) || value)
    ) {
      const conditionKey = this.conditionKeyByTypeAndValue(
        conditionType,
        condition,
      );
      if (conditionKey) {
        const searchFilterConditions: SearchFilterConditions = {
          uuid,
          condition: conditionKey,
        };
        if (this.conditionNeedsValue(condition)) {
          searchFilterConditions.value = value;
        }
        callback(searchFilterConditions);
      }
    }
  }
}
