<svg
  *ngIf="size === 'small' && fill === 'red'"
  width="10"
  height="10"
  viewBox="0 0 10 10"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style="cursor: default"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5ZM5.5 2.5C5.5 2.22386 5.27614 2 5 2C4.72386 2 4.5 2.22386 4.5 2.5V5.5C4.5 5.77614 4.72386 6 5 6C5.27614 6 5.5 5.77614 5.5 5.5V2.5ZM5.5 7.5C5.5 7.22386 5.27614 7 5 7C4.72386 7 4.5 7.22386 4.5 7.5V7.505C4.5 7.78114 4.72386 8.005 5 8.005C5.27614 8.005 5.5 7.78114 5.5 7.505V7.5Z"
    fill="#d21639"
  ></path>
</svg>
<svg
  *ngIf="size === 'large' && fill === 'red'"
  width="20"
  height="20"
  viewBox="0 0 10 10"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style="cursor: default"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5ZM5.5 2.5C5.5 2.22386 5.27614 2 5 2C4.72386 2 4.5 2.22386 4.5 2.5V5.5C4.5 5.77614 4.72386 6 5 6C5.27614 6 5.5 5.77614 5.5 5.5V2.5ZM5.5 7.5C5.5 7.22386 5.27614 7 5 7C4.72386 7 4.5 7.22386 4.5 7.5V7.505C4.5 7.78114 4.72386 8.005 5 8.005C5.27614 8.005 5.5 7.78114 5.5 7.505V7.5Z"
    fill="#d21639"
  ></path>
</svg>
<svg
  *ngIf="size === 'small' && fill === 'yellow'"
  width="10"
  height="10"
  viewBox="0 0 10 10"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style="cursor: default"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5ZM5.5 2.5C5.5 2.22386 5.27614 2 5 2C4.72386 2 4.5 2.22386 4.5 2.5V5.5C4.5 5.77614 4.72386 6 5 6C5.27614 6 5.5 5.77614 5.5 5.5V2.5ZM5.5 7.5C5.5 7.22386 5.27614 7 5 7C4.72386 7 4.5 7.22386 4.5 7.5V7.505C4.5 7.78114 4.72386 8.005 5 8.005C5.27614 8.005 5.5 7.78114 5.5 7.505V7.5Z"
    fill="#BA560D"
  ></path>
</svg>
<svg
  *ngIf="size === 'large' && fill === 'yellow'"
  width="20"
  height="20"
  viewBox="0 0 10 10"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style="cursor: default"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5ZM5.5 2.5C5.5 2.22386 5.27614 2 5 2C4.72386 2 4.5 2.22386 4.5 2.5V5.5C4.5 5.77614 4.72386 6 5 6C5.27614 6 5.5 5.77614 5.5 5.5V2.5ZM5.5 7.5C5.5 7.22386 5.27614 7 5 7C4.72386 7 4.5 7.22386 4.5 7.5V7.505C4.5 7.78114 4.72386 8.005 5 8.005C5.27614 8.005 5.5 7.78114 5.5 7.505V7.5Z"
    fill="#BA560D"
  ></path>
</svg>
