import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-cancel-icon',
  templateUrl: './cancel-icon.component.html',
  styleUrls: ['./cancel-icon.component.css'],
})
export class CancelIconComponent {
  @Output() remove = new EventEmitter<void>();
  @Input() top = '0px';
  @Input() right = '0px';
  @Input() visible = false;
  @Input() item = 'Item';

  showModal = false;

  fireRemove($event: MouseEvent): void {
    $event.stopPropagation();
    this.showModal = true;
  }

  confirmRemove(): void {
    this.remove.emit();
    this.showModal = false;
  }

  cancelRemove(): void {
    this.showModal = false;
  }
}
