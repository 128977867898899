import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-attribute-remove-popup',
  templateUrl: './attribute-remove-popup.component.html',
  styleUrls: ['./attribute-remove-popup.component.css'],
})
export class AttributeRemovePopupComponent {
  @Input() notificationTitle = '';
  @Input() currentUrl = '';
  @Input() deleteNotification = '';
  @Input() cancelAction = '';
  @Input() submitAction = '';
  @Output() closePopup = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() openRemoveAttributeModal = new EventEmitter();
}
