import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-external-link-icon',
  templateUrl: './external-link-icon.component.html',
  styleUrls: ['./external-link-icon.component.css'],
})
export class ExternalLinkIconComponent {
  @Output() sendMixpanelEvent = new EventEmitter();
  @Input() url: string | null = null;
  @Input() disabledColor = false;
  color = 'var(--gray76)';

  onHoverColor(): void {
    this.color = 'black';
  }

  offHoverColor(): void {
    this.color = 'var(--gray76)';
  }

  check($event: MouseEvent): void {
    if (this.url === null) {
      $event.preventDefault();
    }
    this.sendMixpanelEvent.emit();
  }
}
