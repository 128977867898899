import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import ChatMessage from '../../../@core/types/ChatMessage';

@Component({
  selector: 'norby-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  @Input() message: ChatMessage | undefined;

  formatMessage(message: string): string {
    const boldedText = message.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    const italicizedText = boldedText.replace(/\*(.*?)\*/g, '<em>$1</em>');
    const underlinedText = italicizedText.replace(/__(.*?)__/g, '<u>$1</u>');
    const strikethroughText = underlinedText.replace(/~~(.*?)~~/g, '<s>$1</s>');
    const subscriptText = strikethroughText.replace(
      /<sub>(.*?)<\/sub>/g,
      '<sub>$1</sub>',
    );
    const superscriptText = subscriptText.replace(
      /<sup>(.*?)<\/sup>/g,
      '<sup>$1</sup>',
    );
    const boldItalicText = superscriptText.replace(
      /\*\*\*(.*?)\*\*\*/g,
      '<strong><em>$1</em></strong>',
    );
    const linkedText = boldItalicText.replace(
      /<a href="(.*?)">(.*?)<\/a>/g,
      '<a href="$1">$2</a>',
    );
    const formattedText = linkedText
      .replace(/^### (.+)$/gm, '<h3>$1</h3>')
      .replace(/^## (.+)$/gm, '<h2>$1</h2>')
      .replace(/^# (.+)$/gm, '<h1>$1</h1>');

    return this.replaceMarkdownLinks(formattedText);
  }

  private replaceMarkdownLinks(text: string): string {
    const markdownLinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g;
    const mailtoLinkRegex = /\[([^\]]+)\]\(mailto:([^\s)]+)\)/g;

    let replacedText = text.replace(
      markdownLinkRegex,
      (match: string, linkText: string, url: string) => {
        return `<a href="${url}" target="_blank">${linkText}</a>`;
      },
    );

    replacedText = replacedText.replace(
      mailtoLinkRegex,
      (match: string, linkText: string, email: string) => {
        return `<a href="mailto:${email}">${linkText}</a>`;
      },
    );

    return this.parseFormattedText(replacedText);
  }

  private parseFormattedText(formattedText: string): string {
    const formatRegex: RegExp =
      /\*\*(.*?)\*\*|\*(.*?)\*|__(.*?)__|~~(.*?)~~|<sub>(.*?)<\/sub>|<sup>(.*?)<\/sup>|^### (.*?)$|^## (.*?)$|^# (.*?)$/gm;

    const linkRegex: RegExp =
      /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1(?:[^>]*?)>(.*?)<\/a>|((?:(?:https?:\/\/)|(?:www\.))[\w-]+(?:\.[\w-]+)+(?:\.[a-zA-Z]{2,})?(?:\/[\w@?^=%&/~+#-]*)?)/gi;

    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;

    let currentIndex = 0;
    let processedText: string = formattedText.replace(
      formatRegex,
      (
        match: string,
        bold: string,
        italic: string,
        underline: string,
        strikethrough: string,
        subscript: string,
        superscript: string,
        h3: string,
        h2: string,
        h1: string,
        offset: number,
      ) => {
        let formatted: string = match;

        currentIndex = offset + match.length;

        if (bold) {
          formatted = `<strong>${bold}</strong>`;
        } else if (italic) {
          formatted = `<em>${italic}</em>`;
        } else if (underline) {
          formatted = `<u>${underline}</u>`;
        } else if (strikethrough) {
          formatted = `<s>${strikethrough}</s>`;
        } else if (subscript) {
          formatted = `<sub>${subscript}</sub>`;
        } else if (superscript) {
          formatted = `<sup>${superscript}</sup>`;
        } else if (h3) {
          formatted = `<h3>${h3}</h3>`;
        } else if (h2) {
          formatted = `<h2>${h2}</h2>`;
        } else if (h1) {
          formatted = `<h1>${h1}</h1>`;
        }

        return formatted;
      },
    );

    processedText = processedText.replace(emailRegex, (match) => {
      const anchorCheck = new RegExp(`href="mailto:${match}"`, 'i');
      if (anchorCheck.test(processedText)) {
        return match;
      }
      return `<a href="mailto:${match}">${match}</a>`;
    });

    processedText = processedText.replace(
      linkRegex,
      (
        match: string,
        href: string,
        url: string,
        linkText: string,
        urlWithoutAnchor: string,
        offset: number,
      ) => {
        if (formattedText.substring(currentIndex, offset).includes('<a')) {
          return match;
        }

        currentIndex = offset + match.length;

        if (href && url) {
          if (!url.startsWith('http') && !url.startsWith('mailto')) {
            url = `https://${url}`;
          }
          return `<a href="${url}" target="_blank">${linkText}</a>`;
        } else if (urlWithoutAnchor) {
          if (
            urlWithoutAnchor.startsWith('www') &&
            !urlWithoutAnchor.startsWith('mailto')
          ) {
            urlWithoutAnchor = `https://${urlWithoutAnchor}`;
          }
          return `<a href="${urlWithoutAnchor}" target="_blank">${urlWithoutAnchor}</a>`;
        } else {
          return match;
        }
      },
    );

    return processedText;
  }

  convertMessage(message: string): string {
    return message.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '');
  }
}
