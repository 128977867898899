/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AttributeFormType } from '../../../pages/settings-page/attributes-page/attribute/attribute.component';
import { Attribute } from '../../types/Attribute';
import { FilterConditions } from '../../types/FilterConditions';

@Injectable({
  providedIn: 'root',
})
export class AttributesApiService {
  baseUrl = environment.endpoint;

  constructor(private http: HttpClient) {}

  public getDefaultChatAttributes(): Observable<Attribute[]> {
    return this.http.get<Attribute[]>(`${this.baseUrl}/api/chat/attribute`);
  }

  public getDefaultUserAttributes(): Observable<Attribute[]> {
    return this.http.get<Attribute[]>(`${this.baseUrl}/api/user/attribute`);
  }

  public getCustomChatAttributes(): Observable<Attribute[]> {
    return this.http.get<Attribute[]>(
      `${this.baseUrl}/api/chat/attribute/custom`,
    );
  }

  public getCustomUserAttributes(): Observable<Attribute[]> {
    return this.http.get<Attribute[]>(
      `${this.baseUrl}/api/user/attribute/custom`,
    );
  }

  public addAttribute(
    attributeObj: Attribute,
    attributeCategory: string,
  ): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/${attributeCategory}/attribute`,
      attributeObj,
    );
  }

  public removeAttribute(
    attributeUuid: string,
    attributeCategory: string,
  ): Observable<Attribute> {
    return this.http.delete<Attribute>(
      `${this.baseUrl}/api/${attributeCategory}/attribute/${attributeUuid}`,
    );
  }

  public editAttribute(
    attributeUuid: string,
    attributeObject: AttributeFormType,
    attributeCategory: string,
  ): Observable<Attribute> {
    return this.http.put<Attribute>(
      `${this.baseUrl}/api/${attributeCategory}/attribute/${attributeUuid}`,
      {
        name: attributeObject.name,
        description: attributeObject.description,
      },
    );
  }

  public getChatAttributesList(chatUID: string): Observable<Attribute[]> {
    return this.http.get<Attribute[]>(
      `${this.baseUrl}/api/chat/${chatUID}/attribute`,
    );
  }

  public getParticipantAttributesList(
    participantUid: string,
  ): Observable<Attribute[]> {
    return this.http.get<Attribute[]>(
      `${this.baseUrl}/api/user/${participantUid}/attribute`,
    );
  }

  public getFilterConditionsList(): Observable<FilterConditions> {
    return this.http.get<FilterConditions>(
      `${this.baseUrl}/api/panel/support/filter/condition`,
    );
  }
}
