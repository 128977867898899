<norby-slug-wrapper
  [title]="'Widgets'"
  [subtitle]="'Add widgets to the answer (product cards, buttons).'"
>
  <div class="widget-container" *ngFor="let widget of widgets; let i = index">
    <norby-widget-wrapper
      *ngIf="selectedSlug"
      [slugId]="selectedSlug.id"
      [widget]="widget"
      (saveWidget)="createWidget($event, i)"
      (updateWidget)="updateWidget($event, i)"
      (deleteWidget)="removeWidget(i)"
    >
    </norby-widget-wrapper>
  </div>
  <div class="multi-widget-actions">
    <button (click)="addWidgetForm()" class="main-button" type="button">
      Add Widget
    </button>
    <norby-remove-all-bin
      *ngIf="widgets.length > 1"
      [showBin]="true"
      (remove)="removeAllWidgets()"
    ></norby-remove-all-bin>
  </div>
</norby-slug-wrapper>
