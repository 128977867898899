import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PasswordService } from '../../@core/services/api/password.service';
import { AuthService } from '../../@core/services/auth.service';

@Component({
  selector: 'norby-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent {
  resetForm: UntypedFormGroup = this.fb.group({ email: '' });
  showModal = false;

  constructor(
    private fb: UntypedFormBuilder,
    private ps: PasswordService,
    private as: AuthService,
  ) {}

  send(): void {
    const formValue = this.resetForm.value as { [p: string]: string };
    if (formValue.email) {
      this.ps.sendResetEmail(formValue.email).subscribe(
        (success) => {
          if (success) {
            this.showModal = true;
          }
        },
        (err) => {
          if (err) {
            this.showModal = false;
          }
        },
      );
    }

    formValue.email = '';
  }

  gotToLogin(): void {
    this.as.logout();
  }
}
