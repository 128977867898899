import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { SpinnerService } from '../../@core/services/spinner.service';

@Component({
  selector: 'norby-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Output() closeModal = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private sps: SpinnerService,
  ) {}

  ngOnInit(): void {
    // Append modal to the body to ensure it has the highest z-index
    this.renderer.appendChild(document.body, this.el.nativeElement);
    this.sps.show$.next(false);
  }

  ngOnDestroy(): void {
    // Remove modal from body when destroyed
    this.renderer.removeChild(document.body, this.el.nativeElement);
  }
}
