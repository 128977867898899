import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoginCheckRequest, LoginCheckResponse } from '../../types/LoginCheck';
import { SignUpUserData } from '../../types/SignUpUserData';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  url = environment.endpoint + '/api';

  constructor(private http: HttpClient) {}

  login(form: LoginCheckRequest): Observable<LoginCheckResponse> {
    return this.http.post<LoginCheckResponse>(`${this.url}/login_check`, form);
  }

  verifyEmailToken(token: string): Observable<LoginCheckResponse> {
    return this.http.get<LoginCheckResponse>(
      `${this.url}/email/verify/${token}`,
    );
  }

  refresh(refreshToken: string): Observable<LoginCheckResponse> {
    return this.http.post<LoginCheckResponse>(`${this.url}/token/refresh`, {
      refresh_token: refreshToken,
    });
  }

  signup(userData: SignUpUserData): Observable<{ api_key: string }> {
    return this.http.post<{ api_key: string }>(
      `${this.url}/public/onboard`,
      userData,
    );
  }

  resendVerificationEmail(email: string): Observable<void> {
    return this.http.post<void>(`${this.url}/email/verify`, {
      email,
    });
  }
}
