import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AttributesService } from '../../../../@core/services/attributes.service';
import { MixpanelService } from '../../../../@core/services/mixpanel.service';
import { Attribute } from '../../../../@core/types/Attribute';

@Component({
  selector: 'norby-attributes-chat',
  templateUrl: './attributes-chat.component.html',
  styleUrls: ['./attributes-chat.component.css'],
})
export class AttributesChatComponent implements OnInit {
  public attributeHeaderTitle = 'Chat';
  public attributeHeaderDescription = 'Add context to every chat with Norby';
  public createAttribute = false;
  public loader$: Observable<boolean> = this.attributesService.loader$;
  public showDeleteModal = false;
  public attributeUuid = '';

  constructor(
    private attributesService: AttributesService,
    private mx: MixpanelService,
  ) {}

  ngOnInit(): void {
    this.attributesService.loadChatAttributes();
  }

  public getCurrentUuid(event: string): void {
    this.attributeUuid = event;
    this.showDeleteModal = true;
  }

  get chatAttributes$(): Observable<Attribute[]> {
    return this.attributesService.allChatsAttributes$.asObservable();
  }

  public addChatAttribute(event: Attribute): void {
    this.mx.track('add_chat_attribute', 'settings/attributes/chat');
    this.attributesService.createChatAttribute(event);
  }

  public deleteChatAttribute(): void {
    this.attributesService.deleteChatAttribute(this.attributeUuid);
    this.showDeleteModal = false;
  }

  public updateChatAttributeTitle(form: {
    attributeUuid: string;
    formValues: object;
  }): void {
    this.attributesService.updateChatAttribute(
      form.attributeUuid,
      form.formValues,
    );
  }

  public updateChatAttributeDescription(form: {
    attributeUuid: string;
    formValues: object;
  }): void {
    this.attributesService.updateChatAttribute(
      form.attributeUuid,
      form.formValues,
    );
  }
}
