<svg
  (click)="isOpen.emit()"
  class="dots-button"
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="14" cy="20" r="2" fill="black" />
  <circle cx="20" cy="20" r="2" fill="black" />
  <circle cx="26" cy="20" r="2" fill="black" />
</svg>
