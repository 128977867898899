import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PromoCode } from '../../../@core/types/Automations/PromoCode';

@Component({
  selector: 'norby-promo-code-notification',
  templateUrl: './promo-code-notification.component.html',
  styleUrls: ['./promo-code-notification.component.css'],
})
export class PromoCodeNotificationComponent {
  @Input() promoCode$ = new BehaviorSubject<PromoCode>({
    amount_off: 0,
    code: null,
    duration: 'forever',
    duration_in_months: null,
    extend_trial: 0,
    percent_off: '',
  });
  @Output() openPromoCodePopup = new EventEmitter();

  setAmount(): number {
    return this.promoCode$.value.amount_off
      ? this.promoCode$.value.amount_off / 100
      : 0;
  }

  isForeverSubscription(): boolean {
    return !!(
      this.promoCode$.value.code && this.promoCode$.value.duration === 'forever'
    );
  }

  isRepeatingSubscription(): boolean {
    return !!(
      this.promoCode$.value.code &&
      this.promoCode$.value.duration === 'repeating'
    );
  }
}
