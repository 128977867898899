<button
  [type]="type"
  class="accent-button"
  [disabled]="disabled"
  [style]="isLoading ? { pointerEvents: 'none' } : {}"
>
  <svg
    *ngIf="isLoading"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    class="spinner rotate"
  >
    <path
      d="M25 13C25 19.6274 19.6274 25 13 25M1 13C1 6.37258 6.37258 1 13 1"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <span class="accent-button-title" *ngIf="!isLoading">{{ title }}</span>
</button>
