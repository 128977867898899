<svg
  width="28"
  height="28"
  viewBox="0 0 28 28"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20.7783 7.12114L15.1214 12.778M20.7783 7.12114L21.1318 11.0102M20.7783 7.12114L16.8892 6.76758M7.12137 20.778L12.7783 15.1212M7.12137 20.778L6.76782 16.889M7.12137 20.778L11.0105 21.1316"
    stroke="var(--gray76)"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
