import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SignUpStep } from '../../../../pages/signup-page/signup-page.component';
import { OnboardingService } from '../../../../@core/services/onboarding.service';

@Component({
  selector: 'norby-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.css'],
})
export class SecondStepComponent implements OnInit, OnDestroy {
  @Input() currentStep: SignUpStep = { name: '', state: false, passed: false };
  @Output() secondStepData = new EventEmitter();
  @Output() mixpanelEvent = new EventEmitter();

  private subscription = new Subscription();

  public urlRegex =
    // eslint-disable-next-line no-useless-escape
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  form = this.fb.group({
    name: ['', Validators.required],
    url: [
      '',
      [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern(this.urlRegex),
      ],
    ],
  });

  constructor(
    private fb: NonNullableFormBuilder,
    protected obs: OnboardingService,
  ) {}

  goToNextStep(): void {
    this.mixpanelEvent.emit();
    if (this.form.valid) {
      this.secondStepData.emit({
        currentStep: { ...this.currentStep, passed: true },
        stepData: this.form.value,
      });
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.form.controls.name.valueChanges.subscribe(() =>
        this.obs.nameError$.next(''),
      ),
    );
    this.subscription.add(
      this.form.controls.url.valueChanges.subscribe(() =>
        this.obs.urlError$.next(''),
      ),
    );
  }

  ngOnDestroy(): void {
    this.obs.urlError$.next('');
    this.obs.nameError$.next('');
    this.subscription.unsubscribe();
  }
}
