import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'norby-widget-select',
  templateUrl: './widget-select.component.html',
  styleUrls: ['./widget-select.component.css'],
})
export class WidgetSelectComponent {
  public searchSelectState = false;
  @Input() currentValue: string | null = null;
  @Input() placeHolder: string | null = null;
  @Input() chatAttributesOptions: string[] | null = null;
  @Input() userAttributesOptions: string[] | null = null;
  @Output() selectedOption = new EventEmitter();

  constructor(private ref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.searchSelectState = false;
    }
  }

  toggle(): void {
    this.searchSelectState = !this.searchSelectState;
  }

  public getValue(option: string): void {
    this.currentValue = option;
    this.selectedOption.emit(option);
    this.searchSelectState = false;
  }
}
