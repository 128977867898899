import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadWidgetService {
  private renderer: Renderer2;
  private widgetInitialized: boolean = false;
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  initializeChatWidget(apiKey: string | null): void {
    if (this.widgetInitialized) {
      return;
    }
    const script = this.renderer.createElement('script');
    script.innerHTML = `
      (function (w, d, s, o, f, js, fjs) {
        w[o] =
          w[o] ||
          function () {
            (w[o].q = w[o].q || []).push(arguments);
          };
        (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(window, document, "script", "nw", "${environment.widgetUrl}");
      nw("init", {
        apiKey: "${apiKey || environment.apiKey}",
        serviceBaseUrl: "${environment.endpoint}",
        imageTransferEnabled: true,
        side: "left",
        marginSideDesktop: 48,
      });
    `;
    this.renderer.appendChild(this.document.body, script);
    this.widgetInitialized = true;
  }
}
