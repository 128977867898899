import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaymentService } from '../../../@core/services/automations/payment.service';
import { MixpanelService } from '../../../@core/services/mixpanel.service';
import { ProfileService } from '../../../@core/services/profile.service';
import { SettingsPageService } from '../../../@core/services/settings-page.service';
import { ProjectSettingsService } from '../../../@core/services/settings/project-settings.service';
import { SpinnerService } from '../../../@core/services/spinner.service';

@Component({
  selector: 'norby-project-settings-page',
  templateUrl: './project-settings-page.component.html',
  styleUrls: ['./project-settings-page.component.css'],
})
export class ProjectSettingsPageComponent implements OnInit, OnDestroy {
  public widgetCode = '';
  private sub = new Subscription();

  constructor(
    protected ss: SettingsPageService,
    protected spinnerService: SpinnerService,
    private ps: PaymentService,
    private mx: MixpanelService,
    protected pss: ProjectSettingsService,
    protected profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.ps.getUserLimits().subscribe((limits) => {
        this.pss.getAutomationLimits(limits);
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getCode(code: string): void {
    this.widgetCode = code;
  }

  sendMixPanelEvent(event: string, page: string): void {
    this.mx.track(event, page);
  }

  addMixPanelEvent(): void {
    this.mx.track('click_how_to_customize_widget', 'settings/project');
  }
}
