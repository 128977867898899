<div class="message_text">
  <div>{{ parsed.description }}</div>
  <div class="flex align-center">
    <input
      [disabled]="true"
      class="button-input"
      [placeholder]="parsed.inputs.placeholder"
    />
    <norby-send-button [disabled]="true" />
  </div>
</div>
