import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService, GetRefreshToken } from '../@core/services/auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            const refreshToken = GetRefreshToken();
            if (!refreshToken) {
              this.auth.logout();
              return;
            }
            return this.auth.refresh(refreshToken).pipe(
              switchMap(() => {
                return next.handle(request);
              }),
              catchError((error) => {
                this.auth.logout();
                return of('Refresh Error ' + error);
              }),
            );
          }
          return;
        },
      }),
    );
  }
}
