<div class="no-chats-to-show">
  <div class="arrow-container">
    <svg
      class="arrow"
      fill="none"
      height="279"
      viewBox="0 0 103 279"
      width="103"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83508 8.42795C1.97297 8.04224 2.12995 7.65977 2.30638 7.28177C4.51722 2.54523 9.31199 -0.0775706 14.2447 0.111974C14.5254 0.096959 14.8086 0.0893601 15.094 0.08936L67.4702 0.0893555C68.2986 0.0893554 68.9702 0.760925 68.9702 1.58936C68.9702 2.41779 68.2986 3.08936 67.4702 3.08936L29.2309 3.08936C27.2002 3.08936 26.4269 5.82418 28.1244 6.93874C49.5196 20.9859 67.6481 39.8055 80.4417 61.6254C94.8798 86.2499 102.523 114.271 102.523 142.292C102.948 170.313 95.7291 197.909 82.1403 221.684C68.5516 245.46 49.0177 265.414 26.0867 279C48.1684 263.716 65.579 242.488 76.6199 218.713C87.6607 194.937 92.3319 168.614 89.784 142.716C87.6607 117.243 78.7431 92.6183 64.3051 71.8148C49.867 51.0114 34.0817 36.114 12 25.5C10.88 24.9775 8.76038 23.7497 6.86747 22.5794C5.46013 21.7093 3.49997 22.7393 3.49997 24.3939L3.49997 58.3214C3.49997 59.1498 2.8284 59.8214 1.99997 59.8214C1.17155 59.8214 0.499973 59.1498 0.499973 58.3214L0.499969 14.6814C0.499969 12.424 0.975366 10.3092 1.83508 8.42795Z"
        fill="var(--grayF1)"
      />
    </svg>
  </div>
  <div class="no-chats-to-show-text">
    <p><strong>No chats to show</strong></p>
    <p>Go to the tab "Open" above and assign a chat to yourself</p>
  </div>
</div>
