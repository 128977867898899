import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-bot-icon',
  templateUrl: './bot-icon.component.html',
  styleUrls: ['./bot-icon.component.css'],
})
export class BotIconComponent {
  @Input() size: 'large' | 'small' = 'large';

  get sizeStyle(): object {
    return {
      height: this.size === 'large' ? '40px' : '16px',
      width: this.size === 'large' ? '40px' : '16px',
    };
  }
}
