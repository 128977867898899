<svg
  width="12"
  height="11"
  viewBox="0 0 12 11"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6.60227 11L5.72727 10.1364L9.32955 6.53409H0V5.28409H9.32955L5.72727 1.69318L6.60227 0.818182L11.6932 5.90909L6.60227 11Z"
    fill="var(--gray76)"
  />
</svg>
