import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SlugsService } from '../services/automations/slugs.service';

@Injectable({
  providedIn: 'root',
})
export class CheckChangesGuard {
  constructor(private ss: SlugsService) {}

  canDeactivate(
    _component: unknown,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.ss.selectedSlug$.value?.hasUnsavedChanges) {
      this.ss.hasUnsavedChangesModal$.next(true);
      this.ss.setNextPage(nextState);
      return false;
    } else {
      this.ss.hasUnsavedChangesModal$.next(false);
      return true;
    }
  }
}
