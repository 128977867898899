import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '../../@core/services/api/password.service';
import { ProfileService } from '../../@core/services/profile.service';
import { SpinnerService } from '../../@core/services/spinner.service';

@Component({
  selector: 'norby-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css'],
})
export class NewPasswordComponent implements OnInit {
  token = '';
  newPasswordForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.minLength(6)]),
  });

  constructor(
    private route: ActivatedRoute,
    private ps: PasswordService,
    private profileService: ProfileService,
    protected spinnerService: SpinnerService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const params = this.route.snapshot.paramMap;
    this.token = params.get('token') || '';
  }

  send(): void {
    if (this.newPasswordForm.value.password !== '' && this.token !== '') {
      this.spinnerService.open();
      this.ps
        .resetPassword(this.newPasswordForm.value.password, this.token)
        .subscribe({
          next: () => {
            this.profileService.reload().subscribe({
              next: () => {
                this.router.navigateByUrl('/chats');
              },
            });
          },
          complete: () => {
            this.spinnerService.close();
          },
        });
    }
  }

  get password(): UntypedFormControl {
    return this.newPasswordForm.get('password') as UntypedFormControl;
  }
}
