import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductDetails } from '../../../@core/types/ProductDetails';

@Component({
  selector: 'norby-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent {
  @Input() productJSON: string | undefined;
  @Input() isVisibleCancel = false;
  @Output() cancelEvent = new EventEmitter();

  get product(): ProductDetails {
    const product = JSON.parse(this.productJSON || '{}');
    if (Object.prototype.hasOwnProperty.call(product, 'product')) {
      return product.product;
    }
    return JSON.parse(this.productJSON || '{}');
  }
}
