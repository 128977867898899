<form
  [formGroup]="widgetMessageForm"
  (change)="changeButtonWidget()"
  class="form"
>
  <div class="input-inner">
    <div class="required-flex">
      <input
        formControlName="message"
        class="button-input"
        type="text"
        placeholder="Enter the message text …"
      />
      <small class="required-asterisk">*</small>
    </div>
    <norby-bin
      class="widget-bin"
      (remove)="removeButton()"
      [showBin]="true"
      [item]="'Widget'"
    ></norby-bin>
  </div>
</form>
<norby-modal
  *ngIf="
    ss.savingWidget && !widgetMessageForm.valid && widgetMessageForm.touched
  "
  (closeModal)="closeModalWindow()"
  ><div>
    <h4 class="modal-title">Fill all required fields.</h4>
    <div class="modal-content">
      <p *ngIf="!widgetMessageForm.get('message')?.value">
        Field "Enter a message …" is required.
      </p>
    </div>
    <button class="main-button" (click)="closeModalWindow()">Got it!</button>
  </div></norby-modal
>
