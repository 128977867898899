import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnlineStatus, Profile, Roles } from '../domain/Profile';
import { ProfileInfoDto } from '../types/ProfileInfo';
import { AgentsApiService, AllAgentsResponse } from './api/agents-api.service';

@Injectable({
  providedIn: 'root',
})
export class AgentsService {
  loader$ = new BehaviorSubject(false);
  agents$: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([]);

  constructor(private aas: AgentsApiService) {}

  getAgents(): Observable<Profile[]> {
    return this.aas
      .getAllAgentDTOs()
      .pipe(
        map((result: AllAgentsResponse) =>
          result.agents.map((dto: ProfileInfoDto) => new Profile(dto)),
        ),
      );
  }

  getManagers(): Observable<Profile[]> {
    return this.aas
      .getAllAgentDTOs()
      .pipe(
        map((result: AllAgentsResponse) =>
          result.managers.map((dto: ProfileInfoDto) => new Profile(dto)),
        ),
      );
  }

  getAllTeam(): void {
    this.loader$.next(true);
    this.aas
      .getAllAgentDTOs()
      .pipe(
        map((result: AllAgentsResponse) =>
          [...result.managers, ...result.agents].map(
            (dto: ProfileInfoDto) => new Profile(dto),
          ),
        ),
      )
      .subscribe(
        (profiles) => {
          this.agents$.next(profiles);
          this.loader$.next(false);
        },
        () => {
          this.loader$.next(false);
        },
      );
  }

  getRoles(): Observable<Roles> {
    return this.aas.loadRoles();
  }

  changeRole(uuid: string, role: object): void {
    this.aas.updateRole(uuid, role).subscribe({
      next: () => {
        this.getAllTeam();
      },
      error: (error) => {
        console.log(error, 'Can not change a role');
      },
    });
  }

  deleteAgent(uuid: string): void {
    this.loader$.next(true);
    this.aas.removeAgent(uuid).subscribe({
      next: () => {
        this.agents$.next(this.agents$.value.filter((res) => res.uid !== uuid));
        this.loader$.next(false);
      },
      error: (error) => {
        this.loader$.next(false);
        console.log(error, 'Can not delete a support agent');
      },
    });
  }

  setOnlineStatus(uid: string, status: OnlineStatus): void {
    const current = this.agents$.value.map((nextAgent) => {
      if (nextAgent.uid === uid) {
        nextAgent.status = status;
        return nextAgent;
      }
      return nextAgent;
    });
    this.agents$.next(current);
  }
}
