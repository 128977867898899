import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PaymentService } from '../../../@core/services/automations/payment.service';
import { MixpanelService } from '../../../@core/services/mixpanel.service';
import { ProjectSettingsService } from '../../../@core/services/settings/project-settings.service';
import { Invoice } from '../../../@core/types/Automations/Invoice';
import { BillingAddress } from '../../../@core/types/BillingAddress';
import { CompanyDetails } from '../../../@core/types/CompanyDetails';

@Component({
  selector: 'norby-billing-page',
  templateUrl: './billing-page.component.html',
  styleUrls: ['./billing-page.component.css'],
})
export class BillingPageComponent implements OnInit {
  public userInvoices: Invoice[] = [];
  public billingPortalUrl = '';
  public isUserStripeSubscription = false;
  public isPromoCodeModal = false;
  public promoCode$ = this.pps.projectSettings.promoCode$;
  width = document.body.clientWidth;
  promoCodeForm: UntypedFormGroup;
  formError = '';

  constructor(
    private ps: PaymentService,
    private mx: MixpanelService,
    private fb: UntypedFormBuilder,
    private pps: ProjectSettingsService,
  ) {
    this.promoCodeForm = this.fb.group({
      code: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.ps.getBillingPortal().subscribe((url) => {
      if (url) {
        this.billingPortalUrl = url;
      }
    });
    this.ps
      .getInvoices()
      .subscribe((invoicesResponse) => (this.userInvoices = invoicesResponse));
    this.ps.paymentStatuses$.subscribe((res) => {
      if (res) {
        this.isUserStripeSubscription = res.subscription;
      }
    });
    this.uploadPromoCode();
  }

  sendStripeEvent(): void {
    this.mx.track('click_manage_in_stripe', 'settings/billing');
  }

  sendInvoiceEvent(): void {
    this.mx.track('click_open_invoice', 'settings/billing');
  }

  getControl(controlName: string): UntypedFormControl {
    return this.promoCodeForm.controls[controlName] as UntypedFormControl;
  }

  getPromoCode(): void {
    if (this.promoCodeForm.valid) {
      this.mx.track('click_apply_promo_code', 'billings', {
        key: 'code',
        value: this.promoCodeForm.controls.code.value,
      });
      this.ps.setPromoCode(this.promoCodeForm.value).subscribe({
        next: () => {
          this.isPromoCodeModal = false;
          this.uploadPromoCode();
        },
        error: (err) => {
          if (err.error.errors) {
            this.formError = err.error.errors.code[0];
          } else {
            this.formError = err.error.error;
          }
        },
      });
    }
  }

  uploadPromoCode(): void {
    this.ps.getPromoCode().subscribe((result) => {
      this.pps.getPromoCodes(result);
    });
  }

  openPromoCodePopup(isOpenModal: boolean): void {
    this.isPromoCodeModal = isOpenModal;
  }

  updateBillingDetails($event: {
    address: BillingAddress;
    companyDetails: CompanyDetails;
  }): void {
    this.ps.setAddress($event.address);
    this.ps.setCompanyDetails($event.companyDetails);
  }
}
