<svg
  class="mr-fix-position"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.08034 7.10753C8.19325 6.83965 8.36097 6.59836 8.57272 6.39918C8.78447 6.2 9.03556 6.04734 9.30984 5.95102C9.58412 5.8547 9.87553 5.81684 10.1653 5.83989C10.4551 5.86294 10.7369 5.94638 10.9925 6.08485C11.2481 6.22332 11.4719 6.41375 11.6495 6.6439C11.8271 6.87404 11.9546 7.13881 12.0237 7.42118C12.0928 7.70354 12.1021 7.99725 12.051 8.28341C11.9998 8.56958 11.8893 8.84187 11.7266 9.08277L7.91667 14.1667L12.0823 14.1662M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
    stroke="var(--gray76)"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
