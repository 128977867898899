import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../@core/services/auth.service';
import { MixpanelService } from '../../@core/services/mixpanel.service';
import { OnboardingService } from '../../@core/services/onboarding.service';
import { SpinnerService } from '../../@core/services/spinner.service';

export type SignUpStep = {
  name: string;
  state: boolean;
  passed: boolean;
};

@Component({
  selector: 'norby-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.css'],
})
export class SignupPageComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private as: AuthService,
    protected obs: OnboardingService,
    private mx: MixpanelService,
    protected spinnerService: SpinnerService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        const utmId = params['utm_id'];
        if (utmId) {
          this.obs.onBoardingData.utmId = utmId;
        }
      });
    this.as.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.router.navigateByUrl('');
        }
      });
    this.obs.currentStep = this.obs.firstStep;
    window.onresize = () => (this.obs.mobile = window.innerWidth <= 370);
  }

  ngOnDestroy(): void {
    this.obs.resetSteps();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sendMixpanelEvent(event: string, page: string): void {
    this.mx.track(event, page, {
      key: 'status',
      value: this.obs.getSendStatus(),
    });
  }
}
