<ng-container *ngIf="ss.slugCreationStep$ | async as slugCreationCard">
  <div *ngIf="!(ss.selectedSlug$ | async)" class="empty-list-stub-choose">
    <div class="empty-list-text">
      <p>Choose an automation to see the editor here</p>
      <p class="bold">or</p>
      <button class="main-button" (click)="ss.createSlug()">
        Create a new one
      </button>
    </div>
  </div>
  <div
    class="empty-list-stub"
    *ngIf="(profileService.isLegacyUser$ | async) || (ss.selectedSlug$ | async)"
  >
    <norby-slug-type-creation
      *ngIf="slugCreationCard === 'opened'"
    ></norby-slug-type-creation>
    <norby-slug-manual-trigger
      *ngIf="slugCreationCard === 'trigger-step'"
      (createManualSlugEvent)="createManualSlugWithTrigger($event)"
    ></norby-slug-manual-trigger>
  </div>
</ng-container>
<ng-container *ngIf="ss.selectedSlug$ | async as selectedSlug">
  <norby-slug-content-header />
  <section class="main-container" id="nameContainer">
    <norby-automatic-slug
      *ngIf="selectedSlug.type === 'automatic'"
    ></norby-automatic-slug>
    <norby-manual-slug
      *ngIf="selectedSlug.type === 'manual'"
    ></norby-manual-slug>
  </section>
</ng-container>

<!--Payment notification modal-->
<norby-modal
  *ngIf="reminder && !!(ps.paymentStatuses$ | async)?.subscription"
  (closeModal)="setReminder(false)"
>
  <h4 class="modal-title">The Automation Amount</h4>
  <p class="modal-content">
    We will charge you more for every new active automation. To track the
    automation amount, go to
    <a href="/settings/project" class="amount-url">Project Settings</a>.
  </p>
  <label class="checkbox-container">
    <input [formControl]="paidNotificationCheckBox" type="checkbox" />
    <span class="checkmark"></span>
    Don’t show it again
  </label>
  <div class="remove-actions">
    <button class="secondary-button" (click)="setReminder(false)">
      Cancel
    </button>
    <button class="main-button" (click)="setReminder(true)">Continue</button>
  </div>
</norby-modal>
