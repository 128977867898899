<svg
  *ngIf="!!checked"
  [style]="cursorPointer ? { cursor: 'pointer' } : { cursor: 'auto' }"
  fill="none"
  height="20"
  viewBox="0 0 20 20"
  width="20"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13 8L9 12"
    stroke="var(--nrb-secondary-color)"
    stroke-linecap="round"
    stroke-width="2"
  />
  <path
    d="M7 10L9 12"
    stroke="var(--nrb-secondary-color)"
    stroke-linecap="round"
    stroke-width="2"
  />
  <path
    d="M19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10Z"
    stroke="var(--nrb-secondary-color)"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
  />
</svg>
<svg
  *ngIf="!checked"
  [style]="cursorPointer ? { cursor: 'pointer' } : { cursor: 'auto' }"
  fill="none"
  height="20"
  viewBox="0 0 20 20"
  width="20"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    [attr.stroke]="color"
    d="M13 8L9 12"
    stroke-linecap="round"
    stroke-width="2"
  />
  <path
    [attr.stroke]="color"
    d="M7 10L9 12"
    stroke-linecap="round"
    stroke-width="2"
  />
  <path
    [attr.stroke]="color"
    d="M19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10Z"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
  />
</svg>
