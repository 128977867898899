import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, map } from 'rxjs';
import { Slug } from '../../../@core/domain/Slug';
import { AnswersService } from '../../../@core/services/automations/answers.service';
import { PaymentService } from '../../../@core/services/automations/payment.service';
import { SlugsService } from '../../../@core/services/automations/slugs.service';
import { MixpanelService } from '../../../@core/services/mixpanel.service';
import { SpinnerService } from '../../../@core/services/spinner.service';
import { ProfileService } from '../../../@core/services/profile.service';

@Component({
  selector: 'norby-slugs-sidebar',
  templateUrl: './slugs-sidebar.component.html',
  styleUrls: ['./slugs-sidebar.component.css'],
})
export class SlugsSidebarComponent implements OnInit, OnDestroy {
  currentSlug: Slug | undefined;
  isLoadingBots = true;
  isMobile = false;

  @HostListener('window:resize') onResize() {
    window.innerWidth <= 600 || window.innerHeight <= 440
      ? (this.isMobile = true)
      : (this.isMobile = false);
  }

  constructor(
    public spinnerService: SpinnerService,
    private as: AnswersService,
    protected ss: SlugsService,
    protected ps: PaymentService,
    protected prs: ProfileService,
    private mx: MixpanelService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.ss.resetCounter();
    this.ss.getBotsList().subscribe(() => {
      this.isLoadingBots = false;
      if (this.ss.filteredType$.value === 'all') {
        this.initBotsList(Number(this.ss.botOptions[0].key));
      } else {
        this.ss.loadFilteredSlugs({
          botId: this.ss.selectedBotId$.value,
          type: this.ss.filteredType$.value,
          slug: null,
        });
      }
    });
    this.ss.selectedSlug$.next(undefined);
  }

  readonly showSpinner = combineLatest([
    this.ss.spinnerSidebar$,
    this.ps.hasNotIncompleteAndEndedPayments,
    this.ps.hasIncompleteAndEndedPayments,
    this.ps.hasNotPaymentEnded,
  ]).pipe(
    map(
      ([
        showSpinner,
        hasNotIncompleteAndEndedPayments,
        hasIncompleteAndEndedPayments,
        hasNotPaymentEnded,
      ]) =>
        showSpinner &&
        (hasNotPaymentEnded ||
          hasIncompleteAndEndedPayments ||
          hasNotIncompleteAndEndedPayments),
    ),
  );

  readonly showActiveSlugsTab = combineLatest([
    this.ss.currentTab$,
    this.ps.hasNotIncompleteAndEndedPayments,
    this.ps.hasIncompleteAndEndedPayments,
    this.ps.hasNotPaymentEnded,
  ]).pipe(
    map(
      ([
        currentTab,
        hasNotIncompleteAndEndedPayments,
        hasIncompleteAndEndedPayments,
        hasNotPaymentEnded,
      ]) =>
        currentTab === 'Active' &&
        (hasNotIncompleteAndEndedPayments ||
          hasIncompleteAndEndedPayments ||
          !hasNotPaymentEnded),
    ),
  );

  private initBotsList(botId: number): void {
    this.ss.spinnerSidebar$.next(true);
    this.ss.getEnabledSlugs(botId).subscribe();
    this.ss.getDisabledSlugs(botId).subscribe();
    this.ss.getFreeSlugsCount().subscribe();
  }

  public loadBotData(bot: { key: number }): void {
    this.mx.track('click_choose_bot', 'automations');
    this.ss.selectedSlug$.next(undefined);
    this.spinnerService.open();
    this.initBotsList(bot.key);
    this.spinnerService.close();
  }

  getPositionStyle() {
    if (window.innerWidth <= 600 || window.innerHeight <= 440) {
      return this.prs.showAutomationGuide ? 'absolute' : 'fixed';
    }
    return 'relative';
  }

  ngOnDestroy(): void {
    this.ss.enabledSlugs$.next([]);
    this.ss.disabledSlugs$.next([]);
  }

  leaveWithoutSave(): void {
    if (this.ss.nextRouteState) {
      (this.ss.selectedSlug$.value as Slug).hasUnsavedChanges = false;
      this.router.navigateByUrl(this.ss.nextRouteState.url);
      this.ss.hasUnsavedChangesModal$.next(false);
    } else if (this.ss.nextRouteState === undefined) {
      this.currentSlug && this.ss.selectSlug(this.currentSlug);
      this.ss.hasUnsavedChangesModal$.next(false);
    }
  }

  leaveWithSave(): void {
    const slug = this.ss.selectedSlug$.value;
    if (slug) {
      if (slug.id !== 0) {
        if (slug.type === 'manual') {
          this.ss.updateFullManualSlug(slug);
        } else if (slug.type === 'automatic') {
          this.ss.updateFullAutoSlug(slug);
        }
      } else {
        if (slug.type === 'manual') {
          this.ss.createFullManualSlug(slug);
        } else if (slug.type === 'automatic') {
          this.ss.createFullAutoSlug(slug);
        }
      }
    }
    this.currentSlug && this.ss.selectSlug(this.currentSlug);
  }

  closeLeavingWithoutSaveModal(): void {
    this.ss.hasUnsavedChangesModal$.next(false);
  }

  getSlugData($event: Slug): void {
    const slug = this.ss.selectedSlug$.value;
    if (!slug?.hasUnsavedChanges) {
      this.ss.selectedSlug$.next(this.currentSlug);
    } else {
      this.ss.hasUnsavedChangesModal$.next(true);
    }
    if (this.ss.nextRouteState) {
      this.ss.nextRouteState = undefined;
    }
    this.ss.slugCreationStep$.next('closed');
    this.currentSlug = $event;
    if (this.ss.selectedSlug$.value?.hasUnsavedChanges) {
      this.ss.hasUnsavedChangesModal$.next(true);
    } else {
      this.ss.selectSlug($event);
    }
  }

  closePopup(): void {
    this.ss.subscriptionError$.next('');
    this.currentSlug && this.currentSlug.updateActiveState(false);
  }

  get isDisabled(): boolean {
    const currentSlug = this.ss.selectedSlug$.value;
    if (currentSlug?.type === 'manual') {
      return (
        !currentSlug.slug ||
        (currentSlug.getCountWidgets() === 0 && this.as.getAllAnswers() === 0)
      );
    } else if (currentSlug?.type === 'automatic') {
      return !(currentSlug?.slug && currentSlug?.content.content);
    }
    return false;
  }
}
