import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AnswersService } from '../../../../@core/services/automations/answers.service';
import { Slug } from './../../../../@core/domain/Slug';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';
import { MixpanelService } from './../../../../@core/services/mixpanel.service';
import { UploadWidgetService } from '../../../../@core/services/automations/upload-widget.service';
import { AuthService } from '../../../../@core/services/auth.service';

@Component({
  selector: 'norby-slug-content-header',
  templateUrl: './slug-content-header.component.html',
  styleUrls: ['./slug-content-header.component.css'],
})
export class SlugContentHeaderComponent {
  constructor(
    private authService: AuthService,
    private as: AnswersService,
    private mx: MixpanelService,
    protected ss: SlugsService,
    private uploadWidgetService: UploadWidgetService,
  ) {}

  removeMark = false;
  isOpenModal = false;
  merchantApiKey: string | null = null;
  private widgetInitialized: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.authService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.merchantApiKey = localStorage.getItem('ma');
          if (!this.widgetInitialized) {
            this.uploadWidgetService.initializeChatWidget(this.merchantApiKey);
            this.widgetInitialized = true;
          }
        }
      });
  }

  isDisabled(slug: Slug): boolean {
    if (!slug) {
      return false;
    }
    if (slug.type === 'manual') {
      return slug.getCountWidgets() === 0 && this.as.getAllAnswers() === 0;
    } else if (slug.type === 'automatic') {
      return !slug.content.content;
    }
    return true;
  }

  public toggleSlug(active: boolean, slug: Slug): void {
    this.mx.track('click_activation_switcher', 'automations', {
      key: 'action',
      value: active ? 'activating' : 'disabling',
    });
    if (slug?.approved) {
      this.ss.editSlugState(slug, active);
    }
  }

  public removeSlug(id: Slug['id']): void {
    this.mx.track('click_remove_automation', 'automations');
    this.ss.deleteSlug(id);
    this.isOpenModal = false;
  }

  saveSlug(slug: Slug): void {
    this.uploadWidgetService.initializeChatWidget(this.merchantApiKey);
    if (slug.type === 'manual') {
      slug?.id === 0
        ? this.ss.createFullManualSlug(slug)
        : this.ss.updateFullManualSlug(slug);
    } else if (slug.type === 'automatic') {
      slug?.id === 0
        ? this.ss.createFullAutoSlug(slug)
        : this.ss.updateFullAutoSlug(slug);
    }
  }

  showAutomationList(): void {
    this.ss.selectedSlug$.next(undefined);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
