import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SignUpStep } from '../../pages/signup-page/signup-page.component';
import { SignUpUserData } from '../types/SignUpUserData';
import { AuthService } from './auth.service';
import { SpinnerService } from './spinner.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { validateLinkUrl } from '../validators/linkValidator';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService implements OnDestroy {
  public apiKey = '';
  public show = false;
  private subscription = new Subscription();

  onBoardingData: SignUpUserData = {
    name: '',
    url: '',
    password: '',
    email: '',
  };
  currentStep: SignUpStep = { name: '', state: false, passed: false };
  steps: SignUpStep[] = [
    { name: 'firstStep', state: true, passed: false },
    { name: 'secondStep', state: false, passed: false },
    { name: 'thirdStep', state: false, passed: false },
    { name: 'fourthStep', state: false, passed: false },
  ];
  emailError$ = new BehaviorSubject<string>('');
  nameError$ = new BehaviorSubject<string>('');
  urlError$ = new BehaviorSubject<string>('');
  verificationLinkHasBeenReSend = false;
  mobile = false;

  get firstStep(): SignUpStep {
    return this.steps.find((step) => step.name === 'firstStep')!;
  }

  get secondStep(): SignUpStep {
    return this.steps.find((step) => step.name === 'secondStep')!;
  }

  get thirdStep(): SignUpStep {
    return this.steps.find((step) => step.name === 'thirdStep')!;
  }

  get fourthStep(): SignUpStep {
    return this.steps.find((step) => step.name === 'fourthStep')!;
  }

  constructor(
    private authService: AuthService,
    private spinner: SpinnerService,
    private gtm: GoogleTagManagerService,
  ) {}

  public goToNextStep(
    stepsData: { stepData?: Partial<SignUpUserData> } = {},
  ): void {
    const { stepData } = stepsData || {};
    this.currentStep.state = false;
    this.currentStep.passed = true;
    const nextStepIndex =
      this.steps.findIndex((step) => step.name === this.currentStep.name) + 1;
    if (nextStepIndex < this.steps.length) {
      this.currentStep = this.steps[nextStepIndex];
      this.currentStep.state = true;
      if (stepData) {
        this.onBoardingData = { ...this.onBoardingData, ...stepData };
      }
    }
  }

  private goToFirstPage() {
    this.secondStep.state = false;
    this.secondStep.passed = false;
    this.firstStep.state = true;
    this.currentStep = this.firstStep;
  }

  public onBoardNewUser(stepsData: { stepData: SignUpUserData }): void {
    this.spinner.open();
    const { stepData } = stepsData;
    this.onBoardingData = { ...this.onBoardingData, ...stepData };
    const responseBody = {
      ...this.onBoardingData,
      url: validateLinkUrl(this.onBoardingData.url),
    };
    const authSubs = this.authService.signUp(responseBody).subscribe({
      next: (res) => {
        this.gtm.pushTag({ event: 'registration' });
        this.apiKey = res.api_key;
        this.authService.isAuthenticated.next(false);

        this.secondStep.state = false;
        this.secondStep.passed = true;
        this.thirdStep.state = true;

        this.currentStep = this.thirdStep;
        this.onBoardingData = responseBody;
        this.spinner.close();
      },
      error: (error: HttpErrorResponse) => {
        const errorBody = error.error;
        if (errorBody.errors) {
          const validationErrors = errorBody.errors;
          Object.keys(validationErrors).forEach((err) => {
            switch (err) {
              case 'name':
                this.nameError$.next(validationErrors.name[0]);
                return true;
              case 'email':
                this.goToFirstPage();
                this.emailError$.next(validationErrors.email[0]);
                return true;
              case 'url':
                this.urlError$.next(validationErrors.url[0]);
                return true;
              default:
                return false;
            }
          });
        }
        if (errorBody.error) {
          this.emailError$.next(errorBody.error);
          this.goToFirstPage();
        }

        this.spinner.close();
      },
      complete: () => this.spinner.close(),
    });

    this.subscription.add(authSubs);
  }

  public resetSteps(): void {
    this.steps.forEach((step) => {
      step.state = step.name === 'firstStep';
      step.passed = false;
    });
    this.currentStep = this.steps[0];
  }

  public resendVerificationEmail(): void {
    this.spinner.open();
    const resetEmailSubs = this.authService
      .resendVerificationEmail(this.onBoardingData.email)
      .subscribe({
        next: () => {
          this.verificationLinkHasBeenReSend = true;
        },
        error: (err) => {
          console.error('Error resending verification email:', err);
          this.emailError$.next('Failed to resend verification email.');
        },
        complete: () => this.spinner.close(),
      });

    this.subscription.add(resetEmailSubs);
  }

  public getSendStatus(): string {
    return this.emailError$.value ||
      this.urlError$.value ||
      this.nameError$.value
      ? 'failure'
      : 'success';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
