import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SearchFilterConditions } from '../types/FilterConditions';
import { UserDetails } from '../types/UserDetails';
import { UsersApiService } from './api/users-api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  users$ = new BehaviorSubject<UserDetails[]>([]);
  page = 1;
  limit = 15;
  filterConditions: SearchFilterConditions | undefined = undefined;
  usersLoading$ = new BehaviorSubject(false);

  constructor(private ua: UsersApiService) {
    this.loadFilteredUsers();
  }

  setFilterConditions(conditions: SearchFilterConditions | undefined): void {
    this.filterConditions = conditions;
  }

  loadFilteredUsers(): void {
    this.usersLoading$.next(true);
    this.ua.getUsers(this.page++, this.limit, this.filterConditions).subscribe({
      next: (result) => {
        this.users$.next([...this.users$.getValue(), ...result.items]);
      },
      error: () => this.usersLoading$.next(false),
      complete: () => this.usersLoading$.next(false),
    });
  }

  reset(): void {
    this.users$.next([]);
    this.page = 1;
    this.limit = 15;
    this.filterConditions = undefined;
  }
}
