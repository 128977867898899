<div class="users">
  <div class="search-wrapper">
    <div class="search">
      <h2 class="search-header">Users</h2>
      <norby-search-user class="full-width"></norby-search-user>
    </div>
  </div>
  <div class="results-wrapper">
    <div
      class="results"
      infinite-scroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <norby-user-widget
        *ngFor="let user of users$ | async"
        [user]="user"
      ></norby-user-widget>
      <norby-spinner *ngIf="loading$ | async"></norby-spinner>
    </div>
  </div>
</div>
