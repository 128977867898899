import { Component, Input } from '@angular/core';
import { AvatarInfo } from '../../@core/types/ProfileInfo';

@Component({
  selector: 'norby-agent-avatar',
  templateUrl: './agent-avatar.component.html',
  styleUrls: ['./agent-avatar.component.css'],
})
export class AgentAvatarComponent {
  @Input() avatar: AvatarInfo | undefined;
  @Input() size: 'large' | 'small' = 'large';
  @Input() status: 'online' | 'away' | 'offline' = 'offline';
  @Input() showIndicator = true;

  get statusStyle(): object {
    const statusColors = {
      online: 'var(--nrb-secondary-color)',
      away: 'var(--away-status)',
      offline: 'transparent',
    };

    return {
      backgroundColor: statusColors[this.status],
      borderColor: this.status === 'offline' ? 'transparent' : 'white',
    };
  }
}
