<norby-navbar></norby-navbar>
<form class="container" (submit)="login()">
  <div class="card">
    <h3>Log In</h3>
    <norby-input
      [control]="getControl('email')"
      [hasSubmitted]="hasSubmitted"
      [formError]="(errorText | async) || ''"
      name="Email"
      label="Your work email"
      placeholder="Your work email..."
    >
    </norby-input>
    <norby-input
      [control]="getControl('password')"
      [hasSubmitted]="hasSubmitted"
      [eyeIcon]="true"
      [formError]="(errorText | async) || ''"
      [minLength]="6"
      name="Password"
      label="Your password"
      placeholder="6+ symbols"
      type="password"
    >
    </norby-input>
    <section class="forgot-password">
      <a
        (click)="mx.track('click_forgot_password', 'login')"
        href="/forgot_password"
        >Forgot Password?</a
      >
    </section>
    <button type="submit" class="main-button">Log in</button>
  </div>
</form>
<div *ngIf="spinnerService.show$ | async" class="loading_modal">
  <norby-spinner [color]="'#FFFFFF'"></norby-spinner>
</div>
