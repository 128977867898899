import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FilterConditionsService } from '../../../@core/services/filter-conditions.service';
import { UserSearchStateService } from '../../../@core/services/user-search-state.service';
import { UsersService } from '../../../@core/services/users.service';
import { SearchFilterConditions } from '../../../@core/types/FilterConditions';

@Component({
  selector: 'norby-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.css'],
})
export class SearchUserComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  constructor(
    protected uss: UserSearchStateService,
    private us: UsersService,
    private fc: FilterConditionsService,
  ) {}

  get userAttributeNames$(): Observable<string[]> {
    return this.uss.userAttributes$.pipe(
      map((attributes) => attributes.map((a) => a.name)),
    );
  }

  get showValueInput(): Observable<boolean> {
    return this.uss.selectedUserCondition$.pipe(
      distinctUntilChanged((p, n) => p === n),
      map((option) => this.fc.conditionNeedsValue(option)),
    );
  }

  get options$(): Observable<string[]> {
    return this.uss.selectedUserFilterConditions$.asObservable();
  }

  selectAttribute(name: string): void {
    this.uss.selectAttribute(name);
  }

  selectOption(name: string): void {
    this.uss.selectOption(name);
  }

  setOptionValue(value: string): void {
    this.uss.setOptionValue(value);
  }

  clearAll(): void {
    this.us.reset();
    this.us.loadFilteredUsers();
    this.us.setFilterConditions(undefined);
    this.uss.reset();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions = [
      combineLatest([
        this.uss.selectedUserAttribute$,
        this.uss.selectedUserCondition$,
        this.uss.selectedUserConditionValue$,
      ]).subscribe((result) => {
        const [attribute, condition, value] = result;
        this.fc.runRequestIfConditionsAreFilled(
          {
            uuid: attribute?.uuid,
            conditionType: attribute?.data_type,
            condition: condition || '',
            value: value || '',
          },
          (filterConditions: SearchFilterConditions) => {
            this.us.reset();
            this.us.setFilterConditions(filterConditions);
            this.us.loadFilteredUsers();
          },
        );
      }),
    ];
  }
}
