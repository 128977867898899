<div class="sidebar-tabs">
  <ng-container *ngFor="let option of tabsList">
    <div
      class="tab-item"
      [ngClass]="{ active: (ss.currentTab$ | async) === option }"
      (click)="setTab(option)"
    >
      {{ option }}
    </div>
  </ng-container>
</div>
