import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'norby-attribute-input',
  templateUrl: './attribute-input.component.html',
  styleUrls: ['./attribute-input.component.css'],
})
export class AttributeInputComponent {
  @Input() editField = false;
  @Input() attributeFieldName = '';
  @Input() createdAt = '';
  @Input() updatedAt = '';

  @Input() name = 'This field';
  @Input() inputWidth = '285px';
  @Input() maxLength = 0;
  @Input() type = '';
  @Input() control: UntypedFormControl = new UntypedFormControl();

  @Output() openEdit = new EventEmitter();
  @Output() editAttributeName = new EventEmitter();
}
