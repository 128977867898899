import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Autocomplete } from '../../../../@core/enums/Inputs';
import { MixpanelService } from '../../../../@core/services/mixpanel.service';
import { SignUpStep } from '../../../../pages/signup-page/signup-page.component';
import { OnboardingService } from '../../../../@core/services/onboarding.service';

@Component({
  selector: 'norby-first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['./first-step.component.css'],
})
export class FirstStepComponent implements OnInit, OnDestroy {
  @Input() currentStep: SignUpStep = { name: '', state: false, passed: false };
  @Input() crossedEye = false;
  @Output() firstStepData = new EventEmitter();
  @Output() mixpanelEvent = new EventEmitter();
  type = 'password';
  form: UntypedFormGroup;
  autocomplete = Autocomplete.newPassword;
  subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private mx: MixpanelService,
    protected obs: OnboardingService,
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  goToNextStep(): void {
    this.mixpanelEvent.emit();
    if (this.form.valid) {
      this.firstStepData.emit({
        currentStep: { ...this.currentStep, passed: true },
        stepData: this.form.value,
      });
    }
  }

  togglePasswordVisibility(): void {
    this.type === 'password' ? (this.type = 'text') : (this.type = 'password');
    this.crossedEye = !this.crossedEye;
  }

  ngOnInit() {
    this.subscription = this.form.controls.email.valueChanges.subscribe(() =>
      this.obs.emailError$.next(''),
    );
  }

  ngOnDestroy(): void {
    this.obs.emailError$.next('');
    this.subscription.unsubscribe();
  }

  sendMixPanelEvent(event: string, page: string): void {
    this.mx.track(event, page);
  }
}
