<div class="attributes-content__wrapper">
  <div class="attributes-content__name">
    <div class="attribute-title" (click)="openEdit.emit()" *ngIf="!editField">
      {{ attributeFieldName }}
    </div>
    <input
      norbyAutoFocus
      class="attribute-title__input"
      *ngIf="editField"
      [formControl]="control"
      [(ngModel)]="attributeFieldName"
      (focusout)="editAttributeName.emit()"
      (keyup.enter)="editAttributeName.emit()"
    />
    <div class="attribute-date">
      <small class="attribute-date__created"
        >Created {{ createdAt | date: "d. MMM аt H:mm" }}</small
      >&#8226;
      <small class="attribute-date__updated"
        >Updated {{ updatedAt | date: "d. MMM аt H:mm" }}</small
      >
    </div>
  </div>
  <div *ngIf="control.invalid && (control.dirty || control.touched)">
    <div class="input-error" *ngIf="control.errors?.required">
      {{ name }} is required
    </div>
    <div class="input-error" *ngIf="control.errors?.maxlength">
      {{ name }} is too long, no more than {{ maxLength }}
      characters
    </div>
    <div class="input-error" *ngIf="control.errors?.apiError">
      {{ control.errors?.apiError.value }}
    </div>
  </div>
</div>
