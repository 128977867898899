import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'norby-context-actions',
  templateUrl: './context-actions.component.html',
  styleUrls: ['./context-actions.component.css'],
})
export class ContextActionsComponent {
  @Output() optionAction = new EventEmitter();
}
