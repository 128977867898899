<div class="field-container">
  <div class="title-box">
    <h4 *ngIf="trigger !== 'greeting'" class="field-title">{{ title }}</h4>
    <h4 *ngIf="trigger === 'greeting'" class="field-title">Greeting</h4>
  </div>
  <div class="subtitle-box">
    <p
      *ngIf="trigger !== 'greeting' && trigger !== 'team_assign'"
      [class.field-subtitle]="subtitle"
    >
      {{ subtitle }}
    </p>
    <small *ngIf="title === 'Widgets'" class="content-tips"
      >Fields with the asterisk (*) are required</small
    ><small *ngIf="title === 'Content'" class="content-tips"
      >Text formatting will increase the length of the content</small
    >
  </div>
  <p *ngIf="trigger === 'greeting'" [class.field-subtitle]="subtitle">
    Answers clients receive opening a chat for the first time
  </p>
  <p *ngIf="trigger === 'team_assign'" [class.field-subtitle]="subtitle">
    Answers clients receive before the bot assigns a chat to the team
  </p>
  <p *ngIf="isNew" class="fields-mark">{{ mark }}</p>
  <ng-content></ng-content>
</div>
