import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Attribute } from '../types/Attribute';
import { AttributesApiService } from './api/attributes-api.service';
import { FilterConditionsService } from './filter-conditions.service';
import { FilterConditions } from '../types/FilterConditions';

@Injectable({
  providedIn: 'root',
})
export class UserSearchStateService {
  userAttributes$ = new BehaviorSubject<Attribute[]>([]);
  selectedUserAttribute$ = new BehaviorSubject<Attribute | null>(null);
  selectedUserFilterConditions$ = new BehaviorSubject<string[]>([]);
  allFilterConditions$ = new BehaviorSubject<FilterConditions | null>(null);
  selectedUserCondition$ = new BehaviorSubject('');
  selectedUserConditionValue$ = new BehaviorSubject('');

  constructor(
    private aas: AttributesApiService,
    private fc: FilterConditionsService,
  ) {
    this.loadAttributes();
  }

  loadAttributes(): void {
    this.aas.getDefaultUserAttributes().subscribe((attributes) => {
      this.userAttributes$.next(attributes);
    });
  }

  selectAttribute(name: string): void {
    const attribute = this.userAttributes$
      .getValue()
      .find((a) => a.name === name);
    if (attribute) {
      this.selectedUserAttribute$.next(attribute);
      this.selectedUserFilterConditions$.next(
        this.fc.conditionNameByType(attribute.data_type),
      );
    }
  }

  selectOption(option: string): void {
    this.selectedUserCondition$.next(option);
  }

  setOptionValue(value: string): void {
    this.selectedUserConditionValue$.next(value);
  }

  reset(): void {
    this.userAttributes$.next([]);
    this.selectedUserAttribute$.next(null);
    this.selectedUserFilterConditions$.next([]);
    this.selectedUserCondition$.next('');
    this.selectedUserConditionValue$.next('');
    this.loadAttributes();
  }
}
