<div class="modal-mask">
  <div class="modal-container">
    <div class="modal-header">
      <norby-close-icon
        class="close-modal-button"
        (click)="closeModal.emit()"
      ></norby-close-icon>
    </div>
    <ng-content></ng-content>
  </div>
</div>
