import { Component } from '@angular/core';
import { Locale } from '../../../@core/domain/Locale';
import { SettingsPageService } from '../../../@core/services/settings-page.service';
import { SpinnerService } from '../../../@core/services/spinner.service';
import { UserLanguagesService } from '../../../@core/services/user-languages.service';

@Component({
  selector: 'norby-languages-settings-page',
  templateUrl: './languages-settings-page.component.html',
  styleUrls: ['./languages-settings-page.component.css'],
})
export class LanguagesSettingsPageComponent {
  constructor(
    protected uls: UserLanguagesService,
    protected sps: SettingsPageService,
    private spinnerService: SpinnerService,
  ) {}

  setDefaultLanguage({ key }: { key: Locale }): void {
    this.spinnerService.open();
    this.sps.setDefaultLocale({ locale: key }).subscribe({
      next: () => {
        this.sps.locale$.next(key);
        this.spinnerService.close();
      },
      complete: () => {
        this.spinnerService.close();
      },
    });
  }
}
