import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-picture-icon',
  templateUrl: './picture-icon.component.html',
  styleUrls: ['./picture-icon.component.css'],
})
export class PictureIconComponent {
  @Input() color = 'var(--gray76)';
}
