import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-pointer-arrow',
  templateUrl: './pointer-arrow.component.html',
  styleUrls: ['./pointer-arrow.component.css'],
})
export class PointerArrowComponent {
  @Input() color = '#A8A8A8';
}
