/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FlatpickrOptions } from 'ngx-flatpickr-wrapper';

const BOTH_DATES = 2;
const ONE_DATE = 1;

@Component({
  selector: 'norby-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent implements OnInit {
  @Output() selectDateInterval = new EventEmitter();
  @Output() selectedOneDate = new EventEmitter();
  @Input() mode: 'single' | 'multiple' | 'range' = 'range';

  @ViewChild('datepicker', { static: false }) datepicker: any;

  isOpen = true;
  pickerOptions: FlatpickrOptions = {};

  ngOnInit() {
    this.pickerOptions = {
      mode: this.mode,
      dateFormat: 'd.m.Y',
      placeholder: '',
      disableMobile: true,
      position: 'below right',
      monthSelectorType: 'static',
      locale: {
        firstDayOfWeek: 1, // start week on Monday
        rangeSeparator: ' - ',
      },
      onClose: () => {
        this.isOpen = true;
      },
      onChange: (selectedDates: Array<Date>): void => {
        if (selectedDates.length === BOTH_DATES) {
          this.selectDateInterval.emit(selectedDates);
        } else if (selectedDates.length === ONE_DATE) {
          this.selectedOneDate.emit(selectedDates[0]);
        }
      },
    };
  }
  onClick() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.datepicker.flatpickr.close();
    }
  }
}
