<h4 class="languages-header">
  <p class="languages-header-title">Bot Languages</p>
  <norby-question-circle
    class="ml-fix-position"
    [link]="
      'https://www.notion.so/norby-io/Set-Up-Bot-Languages-c4b05a74891348a2a83980f3aaefbf18'
    "
  ></norby-question-circle>
</h4>
<div class="input-box" *ngIf="uls.languageOptions$ | async as languageOptions">
  <norby-select
    [currentValue]="sps.locale$ | async | localeConverter"
    (setChangeValue)="setDefaultLanguage($event)"
    [optionValues]="languageOptions"
    [label]="'The Default Language'"
    [subLabel]="'The bot uses it if the language wasn’t recognized'"
  ></norby-select>
</div>
