import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TotalStatistic } from '../../types/TotalStatistic';

@Injectable({
  providedIn: 'root',
})
export class StatisticsApiService {
  url = environment.endpoint + '/api/panel/statistic';

  constructor(private http: HttpClient) {}

  getTotalStats(startDate: Date, endDate: Date): Observable<TotalStatistic> {
    return this.http.get<TotalStatistic>(
      `${this.url}/total_statistic/${this.formatted(startDate, endDate)}`,
    );
  }

  private formatted(startDate: Date, endDate: Date): string {
    const format = (original: Date): string =>
      dayjs(original).format('DD.MM.YYYY');
    return `${format(startDate)}/${format(endDate)}`;
  }
}
