<h3>Project Settings</h3>
<h4 class="project-settings-subtitle">Access Keys</h4>
<div class="page-content-box">
  <ng-container>
    <div class="project-settings-inner">
      <div class="project-settings-name">API Secret</div>
      <div class="project-settings-value">
        {{ pss.projectSettings.apiKey$ | async }}
      </div>
    </div>
    <div class="project-settings-inner">
      <div class="project-settings-name">Project token</div>
      <div class="project-settings-value">
        {{ pss.projectSettings.projectToken$ | async }}
      </div>
    </div>
    <div class="project-settings-inner">
      <div class="project-settings-name">Telegram webhook</div>
      <div class="project-settings-value">
        {{ pss.projectSettings.telegramWebhook$ | async }}
      </div>
    </div>
  </ng-container>
</div>
<h4 class="project-settings-subtitle-fix-mt">Usage</h4>
<div class="page-content-box">
  <div class="project-settings-inner">
    <div class="project-settings-name">Manual Automations</div>
    <div
      class="project-settings-value"
      *ngIf="pss.projectSettings.automationLimits$ | async as limits"
    >
      {{ limits.manual_active }}
      active /
      <span class="project-settings-notice">
        {{ limits.manual_total }}
        total
      </span>
    </div>
  </div>
  <div class="project-settings-inner">
    <div class="project-settings-name">Automatic Automations</div>
    <div
      class="project-settings-value"
      *ngIf="pss.projectSettings.automationLimits$ | async as limits"
    >
      {{ limits.auto_active }} active /
      <span class="project-settings-notice">
        {{ limits.auto_total }} total
      </span>
    </div>
  </div>
  <div class="project-settings-inner">
    <div class="project-settings-notice">
      Every number affects your monthly price
    </div>
    <a
      (click)="
        sendMixPanelEvent('click_how_we_calculate_price', 'settings/project')
      "
      class="project-settings-link"
      href="https://www.notion.so/norby-io/How-We-Charge-Clients-84bb88a20953441e83bbfb2870341d5f"
      target="_blank"
      >How We Calculate the Price?</a
    >
  </div>
</div>
<norby-languages-settings-page
  *ngIf="profileService.profile$.value?.automation_version === 1"
></norby-languages-settings-page>
<h4 class="project-settings-subtitle-fix-mt">The widget code</h4>
<div class="page-content-box">
  <norby-widget-code-box
    (emitCodeText)="getCode($event)"
    [hasBorder]="false"
    [widgetKey]="pss.projectSettings.projectToken$ | async"
  ></norby-widget-code-box>
  <div class="project-settings-inner line-top">
    <div class="project-settings-notice">
      Put the code before the &lt;/body&gt; tag on your website
    </div>
    <a
      class="project-settings-link"
      (click)="addMixPanelEvent()"
      href="https://www.notion.so/norby-io/How-to-Customize-Widget-for-Your-Needs-0ea75994da6a4fdab9ff305025dd8275"
      target="_blank"
      >How to Customize the Widget?</a
    >
  </div>
</div>

<div *ngIf="spinnerService.show$ | async" class="loading_modal">
  <norby-spinner [color]="'#FFFFFF'"></norby-spinner>
</div>
