import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalSecondToTimeString',
})
export class TotalSecondToTimeStringPipe implements PipeTransform {
  transform(totalSeconds: number): unknown {
    const mins = totalSeconds / 60;
    const rMins = Math.floor(mins);
    const minutes = Math.round((mins - rMins) * 60);

    switch (true) {
      case rMins === 0:
        return `${minutes}s`;
      case minutes === 0:
        return `${rMins}m`;
      default:
        return `${rMins}m ${minutes}s`;
    }
  }
}
