import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatSearchStateService } from '../../../@core/services/chat-search-state.service';
import { ChatService } from '../../../@core/services/chat.service';
import { FilterConditionsService } from '../../../@core/services/filter-conditions.service';
import { Attribute, AttributeType } from '../../../@core/types/Attribute';
import { ChatListTabs } from '../../../@core/types/ChatListTabs';
import {
  FilledFilterConditions,
  SearchFilterConditions,
} from '../../../@core/types/FilterConditions';
import { Merchant } from '../../../@core/types/ProfileInfo';
import { UserSearchStateService } from '../../../@core/services/user-search-state.service';
import { UsersService } from '../../../@core/services/users.service';

export const fakeAttribute = (
  name: string,
  description: string,
  type?: AttributeType,
): Attribute => ({
  uuid: Math.floor(Math.random() * 10000).toString(),
  merchant: {} as Merchant,
  name,
  description,
  model: '',
  type: 'text',
  mode: 'readonly',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  data_type: type || 'string',
});

@Component({
  selector: 'norby-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() enabled = false;
  @Input() chatListTab = new BehaviorSubject<ChatListTabs>('my');
  subscriptions: Subscription[] = [];
  isChatAttributeSelected = true;
  currentValue = '';
  currentConditionValue = '';

  constructor(
    protected ss: ChatSearchStateService,
    protected uss: UserSearchStateService,
    private cs: ChatService,
    private us: UsersService,
    private fc: FilterConditionsService,
  ) {}

  ngOnInit(): void {
    this.subscriptions = [
      combineLatest([
        this.ss.selectedChatAttribute$,
        this.ss.selectedChatConditionValue$,
        this.ss.selectedChatCondition$,
        this.uss.selectedUserAttribute$,
        this.uss.selectedUserCondition$,
        this.uss.selectedUserConditionValue$,
        this.chatListTab,
        this.ss.allFilterConditions$,
        this.uss.allFilterConditions$,
      ]).subscribe((result) => {
        const [
          chatAttribute,
          chatValue,
          chatCondition,
          userAttribute,
          userValue,
          userCondition,
          tab,
        ] = result;
        if (tab) {
          const chatConditions: FilledFilterConditions | undefined =
            chatAttribute
              ? {
                  uuid: chatAttribute.uuid,
                  conditionType: chatAttribute.data_type,
                  condition: chatCondition || '',
                  value: chatValue || '',
                }
              : undefined;
          const userConditions: FilledFilterConditions | undefined =
            userAttribute
              ? {
                  uuid: userAttribute.uuid,
                  conditionType: userAttribute.data_type,
                  condition: userCondition || '',
                  value: userValue || '',
                }
              : undefined;

          const allConditions: FilledFilterConditions = {
            ...(chatConditions || {}),
            ...(userConditions || {}),
          };

          if (Object.keys(allConditions).length > 0) {
            this.fc.runRequestIfConditionsAreFilled(
              allConditions,
              (filterConditions: SearchFilterConditions) => {
                this.loadChatsWithFilterConditions(
                  <ChatListTabs>tab,
                  filterConditions,
                );
              },
            );
          }
        }
      }),
    ];
  }

  loadChatsWithFilterConditions(
    tab: ChatListTabs,
    conditions: SearchFilterConditions,
  ): void {
    this.cs.setSearchConditions(conditions);
    this.cs.loadFilteredChats(tab);
  }

  get attributeNames(): Observable<string[]> {
    return combineLatest([
      this.ss.chatAttributes$.pipe(
        map((attributes) => attributes.map((a) => a.name)),
      ),
      this.uss.userAttributes$.pipe(
        map((attributes) => attributes.map((a) => a.name)),
      ),
    ]).pipe(
      map(([chatAttributes, userAttributes]) => [
        ...chatAttributes,
        ...userAttributes,
      ]),
    );
  }

  get chatAttributeNames(): Observable<string[]> {
    return this.ss.chatAttributes$.pipe(
      map((attributes) => attributes.map((a) => a.name)),
    );
  }

  get userAttributeNames(): Observable<string[]> {
    return this.uss.userAttributes$.pipe(
      map((attributes) => attributes.map((a) => a.name)),
    );
  }

  get selectedFilterConditions(): Observable<string[]> {
    return combineLatest([
      this.ss.currentFilterConditions,
      this.uss.selectedUserFilterConditions$.asObservable(),
    ]).pipe(
      map(([chatConditions, userConditions]) => [
        ...chatConditions,
        ...userConditions,
      ]),
    );
  }

  get selectedAttribute(): Observable<Attribute | null> {
    return combineLatest([
      this.ss.selectedChatAttribute$.asObservable(),
      this.uss.selectedUserAttribute$,
    ]).pipe(
      map(([chatAttribute, userAttribute]) => chatAttribute || userAttribute),
    );
  }

  get showFilterConditionValue(): Observable<boolean> {
    return combineLatest([
      this.ss.selectedChatCondition$,
      this.uss.selectedUserCondition$,
    ]).pipe(
      map(([chatCondition, userCondition]) =>
        this.fc.conditionNeedsValue(chatCondition || userCondition || ''),
      ),
    );
  }

  setSelectedAttribute(attributeName: string): void {
    this.setConditionValue(null);
    this.setCondition(null);
    this.currentValue = '';
    this.currentConditionValue = '';

    const isChatAttribute = this.ss.chatAttributes$.value.some(
      (attr) => attr.name === attributeName,
    );

    if (isChatAttribute) {
      this.ss.setSelectedChatAttributeByName(attributeName);
      this.isChatAttributeSelected = true;
    } else {
      this.uss.selectAttribute(attributeName);
      this.isChatAttributeSelected = false;
    }
  }

  setCondition(name: string | null): void {
    if (this.isChatAttributeSelected) {
      this.ss.setSelectedChatCondition(name);
    } else {
      name && this.uss.selectOption(name);
    }

    if (name) {
      this.currentValue = name;
    }
  }

  setConditionValue(name: string | null): void {
    if (this.isChatAttributeSelected) {
      this.ss.setSelectedChatConditionValue(name);
    } else {
      name && this.uss.setOptionValue(name);
    }

    if (name) {
      this.currentConditionValue = name;
    }
  }

  reset(): void {
    this.ss.resetChatSearch();
    this.cs.resetAndReload();
    this.uss.reset();
    this.us.reset();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
