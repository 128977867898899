<div class="container">
  <h2 class="notice-title">Your Subscription Is Inactive</h2>
  <p class="notice-text">
    Without an active subscription, you can’t activate more automations than
    available for free. You still can edit them in the “Disabled” tab. Pay to
    lift this restriction.
  </p>
  <button
    class="main-button-full-width"
    *ngIf="ps.paymentStatuses$ | async as paymentStatuses"
  >
    <a
      target="_blank"
      (click)="sendMixPanelEvent()"
      [href]="paymentStatuses.billing_page"
      >Pay now</a
    >
  </button>
</div>
