import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SelectOption } from 'src/app/components/select/select.component';
import { Profile } from '../../@core/domain/Profile';
import { Autocomplete } from '../../@core/enums/Inputs';
import { AgentsService } from '../../@core/services/agents.service';
import { AgentsApiService } from '../../@core/services/api/agents-api.service';
import { MixpanelService } from '../../@core/services/mixpanel.service';
import { AgentInviteError } from '../../@core/types/AgentInviteError';

@Component({
  selector: 'norby-team-page',
  templateUrl: './team-page.component.html',
  styleUrls: ['./team-page.component.css'],
})
export class TeamPageComponent {
  role = 'Choose a role';
  invitedShow = false;
  form: UntypedFormGroup;
  loader$ = this.agentsService.loader$;
  agents$: BehaviorSubject<Profile[]> = this.agentsService.agents$;
  inviteSending = false;
  isOpenModal = false;
  roleOptions!: SelectOption[];
  currentValue: string | undefined = 'Support Agent';
  errorModal = false;
  autocomplete = Autocomplete.newPassword;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private agentsService: AgentsService,
    private aas: AgentsApiService,
    private mx: MixpanelService,
  ) {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(5)]],
      role: ['ROLE_MANAGER', Validators.required],
    });
    this.agentsService.getAllTeam();
    this.agentsService.getRoles().subscribe((roles) => {
      this.roleOptions = Object.entries(roles).map(([roleType, role]) => ({
        key: roleType,
        value: role,
      }));
    });
  }

  getOptionValue({ key, value }: SelectOption): void {
    this.currentValue = value;
    this.form.get('role')?.setValue(key);
  }

  public closeRemoveWindow(): void {
    this.isOpenModal = false;
  }

  public changeRole(uuid: string, role: string): void {
    this.agentsService.changeRole(uuid, { role });
  }

  toggleInvitedShow(): void {
    this.invitedShow = !this.invitedShow;
  }

  getControl(controlName: string): UntypedFormControl {
    return this.form.controls[controlName] as UntypedFormControl;
  }

  public removeAgent(agentUuid: string): void {
    this.mx.track('clicked_remove_agent', 'team');
    this.agentsService.deleteAgent(agentUuid);
    this.isOpenModal = false;
  }

  send(): void {
    if (this.form.valid) {
      this.mx.track('clicked_invite', 'teams');
      this.inviteSending = true;
      this.aas.create(this.form.value).subscribe({
        next: () => {
          this.invitedShow = true;
          this.inviteSending = false;
          this.form.reset();
          this.agentsService.getAllTeam();
        },
        error: (err: HttpErrorResponse) => {
          this.inviteSending = false;
          const error: AgentInviteError = err.error;
          // Get email and username errors if they exist
          const emailError = error.errors?.email?.pop() || '';
          const userError = error.errors?.username?.pop() || '';
          if (err.status === 409) {
            const text = err.error.error;
            this.getControl('email').setErrors({ apiError: { value: text } });
          }
          if (err.status === 422 || err.status === 400) {
            this.errorModal = true;
          }
          if (emailError) {
            this.getControl('email').setErrors({
              apiError: { value: emailError },
            });
          }
          if (userError) {
            this.getControl('username').setErrors({
              apiError: { value: userError },
            });
          }
        },
      });
    }
  }
}
