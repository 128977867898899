<div class="select-container">
  <div class="label-wrapper">
    <label *ngIf="label" class="select-label">{{ label }}</label>
    <div class="sub-label">{{ subLabel }}</div>
  </div>
  <div
    (click)="toggle()"
    class="select-wrapper"
    [style.background-color]="background"
  >
    <input
      [readOnly]="true"
      [value]="currentValue"
      [style.color]="textColor"
      class="select-input"
      placeholder="{{ placeholder }}"
    />
    <norby-expose-button
      [isOpen]="searchSelectState"
      class="select-arrow"
      [color]="arrowColor"
      [background]="arrowBgColor"
    ></norby-expose-button>
  </div>
  <ng-container *ngIf="searchSelectState">
    <div
      *ngIf="optionValues"
      [style]="{ top: topPositioning }"
      class="options-container"
    >
      <h1 *ngIf="!!optionsLabel" class="options-label">{{ optionsLabel }}</h1>
      <div
        (click)="setValue(option)"
        *ngFor="let option of optionValues"
        class="option-wrapper"
      >
        <label class="option-label" for="select-option-{{ option.key }}">
          <input
            [formControl]="control"
            [value]="option.value"
            class="option-value"
            id="select-option-{{ option.key }}"
            type="radio"
          />
          {{ option.value }}
        </label>
      </div>
    </div>
  </ng-container>
  <div *ngIf="control.invalid && (control.dirty || control.touched)">
    <div *ngIf="control.errors?.required" class="select-error">
      {{ name }} is required
    </div>
    <div *ngIf="control.errors?.apiError" class="select-error">
      {{ control.errors?.apiError.value }}
    </div>
  </div>
</div>
