<div class="input-container">
  <label>{{ label }}</label>
  <div class="input-element">
    <input
      (input)="clearApiError()"
      [autocomplete]="autocomplete"
      [formControl]="control"
      [minLength]="minLength"
      [placeholder]="placeholder"
      [type]="type"
      class="input-enabled"
    />
    <norby-eye-icon
      *ngIf="eyeIcon"
      (click)="togglePassword()"
      [isOpen]="crossedEye"
    ></norby-eye-icon>
    <div class="flex align-start">
      <div
        *ngIf="hasSubmitted && control.invalid && !formError"
        class="input-error"
      >
        <div *ngIf="control.errors?.required">{{ name }} is required</div>
        <div *ngIf="control.errors?.minlength">
          {{ name }} must be {{ minLength }} characters long
        </div>
        <div *ngIf="control.errors?.maxlength">
          {{ name }} is too long, no more than {{ maxLength }} characters
        </div>
        <div *ngIf="control.errors?.email">Email has wrong format</div>
      </div>
      <div *ngIf="control.errors?.apiError" class="input-error">
        {{ control.errors?.apiError.value }}
      </div>
      <div *ngIf="formError" class="input-error">{{ formError }}</div>
    </div>
  </div>
</div>
