import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Profile } from '../../@core/domain/Profile';

@Component({
  selector: 'norby-assign-dropdown',
  templateUrl: './assign-dropdown.component.html',
  styleUrls: ['./assign-dropdown.component.css'],
})
export class AssignDropdownComponent {
  assignMenuOpen = false;
  searchText = '';

  @Input() profile: Profile = Profile.emptyProfile();
  @Input() managers: Profile[] = [];
  @Input() agents: Profile[] = [];
  @Output() assignEvent = new EventEmitter<string>();

  constructor(private ref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.assignMenuOpen =
        (event.target as HTMLElement).className ===
        'agent-search ng-untouched ng-pristine ng-valid';
    }
  }

  toggleAssignMenu(): void {
    this.assignMenuOpen = !this.assignMenuOpen;
  }

  assignTo(uid: string): void {
    this.assignEvent.emit(uid);
  }
}
