import { BehaviorSubject } from 'rxjs';
import { Answers } from '../types/Automations/Answer';
import { Question, QuestionsDTO } from '../types/Automations/Question';
import { Content, SlugDTO } from '../types/Automations/SlugModel';
import {
  MultiLangWidgets,
  MultiLangWidgetsDTO,
} from '../types/Automations/Widgets/MultiLangWidgets';
import { Widget, WidgetDTO } from '../types/Automations/Widgets/Widget';
import { Trigger } from '../types/Automations/TriggerTypes';
import { getLocaleById, Locale } from './Locale';

export class Slug {
  botId: number;
  id: number;
  slug: string;
  approved: boolean;
  answerFirst: BehaviorSubject<string>;
  active: boolean;
  questions: BehaviorSubject<QuestionsDTO>;
  questionsCount: BehaviorSubject<number>;
  selected: boolean;
  answers: Answers | null;
  isNew: boolean;
  widget$: BehaviorSubject<MultiLangWidgets | null>;
  widgets$: BehaviorSubject<MultiLangWidgets[]>;
  hasUnsavedChanges: boolean;
  type: string;
  content: Content;
  trigger$: BehaviorSubject<Trigger | null>;

  public static emptyManualSlug(trigger?: Trigger): Slug {
    return new Slug({
      id: 0,
      bot_id: 0,
      slug: '',
      approved: 0,
      answer_first: '',
      active: 0,
      questions: {
        en: [],
        ru: [],
        ee: [],
        it: [],
        fr: [],
        de: [],
        ua: [],
        es: [],
        fi: [],
        sv: [],
        da: [],
        no: [],
        pl: [],
        pt: [],
        tr: [],
        zh: [],
        ja: [],
        ar: [],
      },
      questions_count: 0,
      selected: false,
      answers: {
        en: [],
        ru: [],
        ee: [],
        it: [],
        fr: [],
        de: [],
        ua: [],
        es: [],
        fi: [],
        sv: [],
        da: [],
        no: [],
        pl: [],
        pt: [],
        tr: [],
        zh: [],
        ja: [],
        ar: [],
      },
      widget: undefined,
      widgets: undefined,
      isNew: true,
      hasUnsavedChanges: false,
      type: 'manual',
      content: { content: '', created_at: '', updated_at: '' },
      trigger: trigger ? trigger : null,
    });
  }

  public static emptyAutomaticSlug(): Slug {
    return new Slug({
      id: 0,
      bot_id: 0,
      slug: '',
      approved: 0,
      answer_first: '',
      active: 0,
      questions: {
        en: [],
        ru: [],
        ee: [],
        it: [],
        fr: [],
        de: [],
        ua: [],
        es: [],
        fi: [],
        sv: [],
        da: [],
        no: [],
        pl: [],
        pt: [],
        tr: [],
        zh: [],
        ja: [],
        ar: [],
      },
      questions_count: 0,
      selected: false,
      answers: {
        en: [],
        ru: [],
        ee: [],
        it: [],
        fr: [],
        de: [],
        ua: [],
        es: [],
        fi: [],
        sv: [],
        da: [],
        no: [],
        pl: [],
        pt: [],
        tr: [],
        zh: [],
        ja: [],
        ar: [],
      },
      widget: undefined,
      widgets: undefined,
      isNew: true,
      hasUnsavedChanges: false,
      type: 'automatic',
      content: { content: '', created_at: '', updated_at: '' },
      trigger: null,
    });
  }

  public static emptySlugDTO(): SlugDTO {
    return {
      id: 1,
      bot_id: 1,
      slug: '',
      approved: 0,
      answer_first: 'answer text',
      active: 0,
      questions: {
        en: [],
        ru: [],
        ee: [],
        it: [],
        fr: [],
        de: [],
        ua: [],
        es: [],
        fi: [],
        sv: [],
        da: [],
        no: [],
        pl: [],
        pt: [],
        tr: [],
        zh: [],
        ja: [],
        ar: [],
      },
      questions_count: 1,
      selected: false,
      answers: {
        en: [{ id: 1, slug_id: 1, language_id: 1, text: 'answer text' }],
        ru: [],
        ee: [],
        it: [],
        fr: [],
        de: [],
        ua: [],
        es: [],
        fi: [],
        sv: [],
        da: [],
        no: [],
        pl: [],
        pt: [],
        tr: [],
        zh: [],
        ja: [],
        ar: [],
      },
      widget: undefined,
      widgets: undefined,
      isNew: true,
      hasUnsavedChanges: false,
      type: 'automatic',
      content: { content: '', created_at: '', updated_at: '' },
      trigger: 'greeting',
    };
  }

  constructor(slugDTO: SlugDTO) {
    this.botId = slugDTO.bot_id;
    this.id = slugDTO.id;
    this.slug = slugDTO.slug;
    this.approved = !!slugDTO.approved;
    this.answerFirst = new BehaviorSubject<string>(slugDTO.answer_first);
    this.active = !!slugDTO.active;
    this.questions = new BehaviorSubject<QuestionsDTO>(slugDTO.questions ?? {});
    this.questionsCount = new BehaviorSubject<number>(slugDTO.questions_count);
    this.selected = slugDTO.selected;
    this.answers = slugDTO.answers;
    this.isNew = !this.id;
    this.widget$ = new BehaviorSubject<MultiLangWidgets | null>(
      this.widgetJSONConverter(slugDTO.widget),
    );
    this.widgets$ = new BehaviorSubject<MultiLangWidgets[] | []>(
      (slugDTO.widgets ?? [])
        .map((widget) => this.widgetJSONConverter(widget))
        .filter((widget): widget is MultiLangWidgets => widget !== null),
    );
    this.hasUnsavedChanges = false;
    this.type = slugDTO.type;
    this.content = slugDTO.content;
    this.trigger$ = new BehaviorSubject(slugDTO.trigger);
  }

  setUnsavedChanges(state: boolean): void {
    this.hasUnsavedChanges = state;
  }

  updateActiveState(state: boolean): void {
    this.active = state;
  }

  updateAnswersState(answers: Answers | null, languageId: number): void {
    const lang = getLocaleById(languageId);
    const text = answers?.[lang]?.[0]?.text;
    if (text) {
      this.answerFirst.next(text.replace(/<\/?[^>]+(>|$)/g, ''));
    }
    this.answers = answers;
  }

  setName(name: string): void {
    if (this.slug !== name) {
      this.setUnsavedChanges(true);
      this.slug = name;
    }
  }

  setContent(content: string): void {
    this.setUnsavedChanges(true);
    this.content = { ...this.content, content };
  }

  addQuestion(question: Partial<Question>, lang: Locale): void {
    this.setUnsavedChanges(true);
    const questionsCount = this.questionsCount.value + 1;
    this.questionsCount.next(questionsCount);
    const langArray = this.questions.value[lang];
    langArray.push(question as Question);
  }

  removeQuestion(lang: Locale, questionId: number): void {
    this.setUnsavedChanges(true);
    const questionsCount = this.questionsCount.value - 1;
    this.questionsCount.next(questionsCount);
    this.questions.value[lang].splice(
      this.questions.value[lang].findIndex((el) => el.id === questionId),
      1,
    );
  }

  removeQuestionForm(formId: number, lang: Locale): void {
    this.setUnsavedChanges(true);
    const questionsCount = this.questionsCount.value - 1;
    this.questionsCount.next(questionsCount);
    this.questions.value[lang].splice(
      this.questions.value[lang].findIndex((el) => el.formId === formId),
      1,
    );
  }

  editQuestion(status: number, questionId: number, lang: Locale): void {
    this.setUnsavedChanges(true);
    const question = this.findQuestion(questionId);
    const newState = { ...question, active: status };
    this.questions.value[lang].splice(
      this.questions.value[lang].findIndex((el) => el.id === questionId),
      1,
      newState,
    );
  }

  addToWidgets(widget: Partial<Record<Locale, Widget>>, i: number): void {
    this.setUnsavedChanges(true);
    const copy = structuredClone(this.widgets$.value);
    if (copy.at(i)) {
      copy.splice(i, 1, widget);
      this.widgets$.next(copy);
    } else {
      this.widgets$.next([...this.widgets$.value, widget]);
    }
  }

  removeFromWidgets(i: number): void {
    this.setUnsavedChanges(true);
    const copy = structuredClone(this.widgets$.value);
    copy.splice(i, 1);
    this.widgets$.next(copy);
  }

  removeAllWidgets() {
    this.setUnsavedChanges(true);
    this.widgets$.next([]);
  }

  addWidget(widgetDto: MultiLangWidgetsDTO): void {
    this.setUnsavedChanges(true);
    this.widget$.next(this.widgetJSONConverter(widgetDto));
  }

  updateWidget(widgetDto: WidgetDTO, lang: number): void {
    this.setUnsavedChanges(true);
    const shortLang = getLocaleById(lang);
    const widgetWithShortKeyName = this.addShortLangForWidget([widgetDto]);
    const updatedWidget = this.widgetJSONConverter(widgetWithShortKeyName);
    if (updatedWidget) {
      this.widget$.next({
        ...this.widget$.value,
        [shortLang]: updatedWidget[shortLang],
      });
    }
  }

  removeWidget(): void {
    this.setUnsavedChanges(true);
    this.widget$.next(null);
  }

  findQuestion(questionId: number): Question {
    const questionValues = Object.values(this.questions.value);
    let question!: Question;
    for (const questions of questionValues) {
      const desired = questions.find(
        (el: { id: number }) => el.id === questionId,
      );
      if (desired) {
        question = desired;
      }
    }
    return question;
  }

  getAllAnswersCount(): number {
    let totalAnswers = 0;
    if (this.answers) {
      for (const [, value] of Object.entries(this.answers)) {
        if (value) {
          totalAnswers = totalAnswers + value.length;
        }
      }
    }
    return totalAnswers;
  }

  getCountWidgets(): number {
    let totalWidgets = 0;
    if (this.widget$.value) {
      for (const [, value] of Object.entries(this.widget$.value)) {
        if (value) {
          totalWidgets = 1;
        }
      }
    }
    return totalWidgets;
  }

  addShortLangForWidget(widgets: WidgetDTO[] | WidgetDTO): MultiLangWidgetsDTO {
    const resultWidget = {} as MultiLangWidgetsDTO;
    if (Array.isArray(widgets)) {
      for (const w of widgets) {
        const shortLang = getLocaleById(w.language_id);
        resultWidget[shortLang] = { ...w };
      }
      return resultWidget;
    }
    const sl = getLocaleById(widgets.language_id);
    resultWidget[sl] = { ...widgets };
    return resultWidget;
  }

  widgetJSONConverter(data?: MultiLangWidgetsDTO): MultiLangWidgets | null {
    if (!data || typeof data !== 'object') {
      return null;
    }
    const resultWidget = {} as MultiLangWidgets;
    for (const key of Object.keys(data)) {
      const widgetDto = data[key as Locale];
      if (Array.isArray(widgetDto)) {
        return null;
      }
      resultWidget[key as Locale] = {
        ...widgetDto,
        widget:
          typeof widgetDto.widget === 'string'
            ? JSON.parse(widgetDto.widget)
            : widgetDto.widget,
      };
    }
    return resultWidget;
  }
}
