import { Injectable, NgZone } from '@angular/core';
import { Socket } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import Chat from '../domain/Chat';
import { ChatsFactory } from '../domain/ChatsFactory';
import { OnlineStatus } from '../domain/Profile';
import ChatDTO from '../types/ChatModel';
import { SupportStatusEventData } from '../types/SupportStatusEventData';
import { AgentsService } from './agents.service';
import { GetAccessToken, GetMerchantApi, GetUID } from './auth.service';
import { ChatService } from './chat.service';
import { ProfileService } from './profile.service';
import { TitleNotificationService } from './title-notification.service';

@Injectable({
  providedIn: 'root',
})
export class SupportSocketService {
  private socket: Socket | undefined;
  private chatsFactory: ChatsFactory;

  constructor(
    private ngZone: NgZone,
    private profileService: ProfileService,
    private chatService: ChatService,
    private ts: TitleNotificationService,
    private agentService: AgentsService,
  ) {
    this.chatsFactory = new ChatsFactory(ngZone);
  }

  get isManager(): boolean {
    return (
      !!this.profileService.profile$.value &&
      this.profileService.profile$.value?.role === 'Support Manager'
    );
  }

  findChat(uid: string, chats: Chat[]): Chat | undefined {
    return chats.find((c) => c.uid === uid);
  }

  public connect(io: CallableFunction): void {
    try {
      const socketUrl = `${environment.endpoint}/support`;
      this.socket = io(socketUrl, {
        query: {
          support: GetUID(),
          token: GetAccessToken(),
          apiKey: GetMerchantApi(),
        },
        transports: ['websocket', 'polling'],
        forceNew: true,
      });

      this.socket?.on('connect', () => {
        console.log(`Successfully connected to ${socketUrl}`);
      });

      this.socket?.on('connect_error', (error) => {
        console.log(`Error connecting to ${socketUrl}: ${error.message}`);
      });

      this.socket?.on('freeChat', (chatDTO: ChatDTO) => {
        this.ngZone.run(() => {
          const chat = this.chatsFactory.openChat(
            chatDTO,
            this.ts,
            this.chatService.unreadMessagesTotal$,
          );
          this.chatService.addToOpen(chat);
          if (this.isManager) {
            this.ts.start();
          }
        });
      });

      this.socket?.on('newChat', (chatDTO: ChatDTO) => {
        this.ngZone.run(() => {
          const chat = this.chatsFactory.myChat(
            chatDTO,
            this.ts,
            this.chatService.unreadMessagesTotal$,
          );
          this.chatService.removeFromOpen(chat);
          if (
            chatDTO.agent?.uid !== undefined &&
            chatDTO.agent?.uid === this.profileService.profile$.value?.uid
          ) {
            this.chatService.addToMy(chat);
          }
        });
      });

      this.socket?.on('closed', (event: { uid: string }) => {
        this.ngZone.run((): void => {
          let chat: Chat | undefined;

          chat = this.findChat(event.uid, this.chatService.openChats$.value);
          if (chat) {
            this.chatService.removeChat(chat, 'open');
          }
          chat = this.findChat(event.uid, this.chatService.myChats$.value);
          if (chat) {
            this.chatService.removeChat(chat, 'my');
          }
        });
      });

      this.socket?.on('statusChange', (data: SupportStatusEventData) => {
        this.changeOnlineStatus(data.uuid, data.status);
      });
    } catch (error: unknown) {
      console.log(
        `Error connecting to support socket: ${JSON.stringify(error)}`,
      );
    }
  }

  public setIsOnline(status: OnlineStatus): void {
    if (!this.profileService.profile$.value) {
      return;
    }
    const { uid } = this.profileService.profile$.value;

    this.socket?.emit(
      'statusChange',
      { uuid: uid, status },
      (result: { status: 'ok' | 'error' }) => {
        if (result.status === 'ok') {
          this.changeOnlineStatus(uid, status);
        }
      },
    );
  }

  private changeOnlineStatus(uid: string, status: OnlineStatus): void {
    this.profileService.setAwayStatus(status);
    this.agentService.setOnlineStatus(uid, status);
  }

  public sendViewEvent(roomUuid: string | undefined): void {
    this.socket?.emit('supportRoomView', { roomUuid });
  }
}
