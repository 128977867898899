import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from '../@core/domain/Profile';

@Pipe({
  name: 'agentSearchFilter',
})
export class AgentSearchFilterPipe implements PipeTransform {
  transform(items: Profile[], searchText: string): Profile[] {
    if (!items && !searchText) {
      return items;
    }
    return items.filter((it) =>
      it.firstName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
    );
  }
}
