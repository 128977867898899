import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { languages } from '../../../languages';

@Component({
  selector: 'norby-widget-code-box',
  templateUrl: './widget-code-box.component.html',
  styleUrls: ['./widget-code-box.component.css'],
})
export class WidgetCodeBoxComponent implements OnChanges {
  @Input() hasBorder = false;
  @Input() widgetKey: string | null = '626a30c619879';
  @Input() hideCopyButton = false;
  @Output() emitCodeText = new EventEmitter();

  constructor(private toastr: ToastrService) {}

  get widgetCode(): string {
    return `<script>
(function (w, d, s, o, f, js, fjs) {
        w[o] = w[o] || function () {
            (w[o].q = w[o].q || []).push(arguments) };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1;
        fjs.parentNode.insertBefore(js, fjs) }
    (window, document, 'script', 'nw', '${environment.widgetUrl}'));
        nw('init', {
            apiKey: '${this.widgetKey}',
            serviceBaseUrl: '${environment.endpoint}',
            lang: 'en',
            languageList: [${languages
              .map((el) =>
                JSON.stringify(el.short_name.toUpperCase()).replace(/"/g, "'"),
              )
              .sort((a, b) => a.localeCompare(b))}],
            mainColor: '#3E247E',
            popupBotAvatarColor: '#3E247E',
            multichat: true,
            botName: 'Bot',
            imageTransferEnabled: true,
            side: 'right',
            marginSideDesktop: 80,
            marginBottomDesktop: 48,
            marginSideMobile: 16,
            marginBottomMobile: 16
        });
    </script>`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetKey.currentValue !== changes.widgetKey.previousValue) {
      this.emitCodeText.emit(this.widgetCode);
    }
  }

  async copyWidgetCode(): Promise<void> {
    this.toastr.success('The widget code was copied');
    await navigator.clipboard.writeText(this.widgetCode);
  }
}
