<div class="voice-container">
  <svg
    width="140"
    height="90"
    viewBox="0 0 140 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.25 0C136.069 0 140 3.87769 140 8.63185V81.3674C140 86.1221 136.069 89.9998 131.25 89.9998H8.74982C3.93095 89.9998 0 86.1221 0 81.3674V8.63185C0 3.87769 3.93036 0 8.74982 0H131.25Z"
      fill="var(--grayF1)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.1279 44.9435L3.24121 88.0664C4.74722 89.2743 6.66528 90 8.74957 90H131.249C133.715 90 135.948 88.9844 137.541 87.3562L87.663 44.7351L53.1279 44.9435Z"
      fill="#EAEAEA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.21289 1.95617L53.1276 44.9433L70.1637 59.6146L87.6628 44.7349L137.368 2.47061C135.788 0.943441 133.627 0 131.25 0H8.74993C6.65218 0 4.72299 0.734429 3.21289 1.95617Z"
      fill="#FCFCFC"
    />
  </svg>
</div>
