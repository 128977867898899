export type Locale =
  | 'en'
  | 'ru'
  | 'ee'
  | 'it'
  | 'fr'
  | 'de'
  | 'ua'
  | 'es'
  | 'fi'
  | 'sv'
  | 'da'
  | 'no'
  | 'pl'
  | 'pt'
  | 'tr'
  | 'zh'
  | 'ja'
  | 'ar';

export function getLocaleById(id: number): Locale {
  switch (id) {
    case 1:
      return 'en';
    case 2:
      return 'ru';
    case 3:
      return 'ee';
    case 4:
      return 'it';
    case 5:
      return 'fr';
    case 6:
      return 'de';
    case 7:
      return 'ua';
    case 8:
      return 'es';
    case 9:
      return 'fi';
    case 10:
      return 'sv';
    case 11:
      return 'da';
    case 12:
      return 'no';
    case 13:
      return 'pl';
    case 14:
      return 'pt';
    case 15:
      return 'tr';
    case 16:
      return 'zh';
    case 17:
      return 'ja';
    case 18:
      return 'ar';
    default:
      return 'en';
  }
}

export function getIdByLocale(lang: Locale): number {
  switch (lang) {
    case 'en':
      return 1;
    case 'ru':
      return 2;
    case 'ee':
      return 3;
    case 'it':
      return 4;
    case 'fr':
      return 5;
    case 'de':
      return 6;
    case 'ua':
      return 7;
    case 'es':
      return 8;
    case 'fi':
      return 9;
    case 'sv':
      return 10;
    case 'da':
      return 11;
    case 'no':
      return 12;
    case 'pl':
      return 13;
    case 'pt':
      return 14;
    case 'tr':
      return 15;
    case 'zh':
      return 16;
    case 'ja':
      return 17;
    case 'ar':
      return 18;
    default:
      return 1;
  }
}
