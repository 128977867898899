<svg
  width="20"
  height="18"
  viewBox="0 0 20 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5.75 16.5H18.5M5.75 16.5H1.5V1.5H18.5V16.5M5.75 16.5C5.90741 13.6875 10.3778 7.70625 13.7778 8.45625C17.1778 9.20625 18.5 13.875 18.5 16.5M7.875 6.14587C7.875 7.00882 7.17545 7.70837 6.3125 7.70837C5.44955 7.70837 4.75 7.00882 4.75 6.14587C4.75 5.28293 5.44955 4.58337 6.3125 4.58337C7.17545 4.58337 7.875 5.28293 7.875 6.14587Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
