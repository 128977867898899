<div class="settings-sidebar">
  <h3>Settings</h3>
  <h4
    (click)="
      sendMixpanelEvent({
        mixpanelEvent: 'click_profile_settings',
        page: 'settings/profile'
      })
    "
    routerLink="/settings/"
  >
    Profile
  </h4>
  <norby-accordion
    (sendEvent)="sendMixpanelEvent($event)"
    *ngIf="profileRole === 'Support Manager'"
    [accordionItems]="accordionItems"
    [accordionTitle]="accordionTitle"
  ></norby-accordion>
</div>
