import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from '@angular/core';
import { GuideService } from '../../../@core/services/automations/guide.service';

@Component({
  selector: 'norby-need-help-main',
  templateUrl: './need-help-main.component.html',
  styleUrls: ['./need-help-main.component.css'],
})
export class NeedHelpMainComponent {
  @ViewChild('popup') private popup: ElementRef | undefined;
  @Output() closePopupEvent = new EventEmitter();
  @Output() copyEvent = new EventEmitter();
  bodyText = '</body>';
  firstStep$ = this.gs.firstStep$;
  secondStep$ = this.gs.secondStep$;
  thirdStep$ = this.gs.thirdStep$;
  currentPopupStep$ = this.gs.currentPopupStep$;
  isHidden$ = this.gs.isHiddenNotification$;

  constructor(private gs: GuideService) {}

  get isGuideCompleted(): boolean {
    if (localStorage.getItem('guideCompleted') === 'true') {
      return !!localStorage.getItem('guideCompleted');
    }
    return false;
  }

  closePopup(): void {
    this.closePopupEvent.emit();
  }

  getCode(code: string): void {
    this.gs.widgetCode$.next(code);
  }

  goToSecondStep($event: Event): void {
    $event.stopPropagation();
    this.gs.goToSecondStep();
  }

  goToThirdStep($event: Event): void {
    $event.stopPropagation();
    this.gs.goToThirdStep();
  }

  completeSetUp(): void {
    this.gs.completeSetUp();
    this.closePopupEvent.emit();
    localStorage.setItem('guideCompleted', 'true');
  }

  openStep(step: string): void {
    if (
      !this.firstStep$.value.isClickable ||
      !this.secondStep$.value.isClickable ||
      !this.thirdStep$.value.isClickable
    ) {
      return;
    }
    switch (step) {
      case 'first':
        this.gs.openFirstStep();
        break;
      case 'second':
        this.gs.openSecondStep();
        break;
      case 'third':
        this.gs.openThirdStep();
        break;
    }
  }

  get apiKey(): string | null {
    return localStorage.getItem('ma');
  }

  @HostListener('window:click', ['$event'])
  clickOut($event: Event): void {
    if (this.popup) {
      const container = this.popup.nativeElement;
      const currEl = $event.target;
      if (!container.contains(currEl)) {
        this.closePopupEvent.emit();
      }
    }
  }
}
