import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { StatisticsApiService } from '../../@core/services/api/statistics-api.service';
import { TotalStatistic } from '../../@core/types/TotalStatistic';

@Component({
  selector: 'norby-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css'],
})
export class StatisticComponent implements OnInit {
  startDate = new Date();
  endDate = new Date();
  totalStats: TotalStatistic = {
    closedTotal: 0,
    processedTotal: 0,
    totalOpen: 0,
    totalUnusefulChats: 0,
    supportAvgFirstAnswer: 0,
    totalClosedChatsByBot: 0,
    norbyScore: 0,
  };

  constructor(private statisticsApiService: StatisticsApiService) {}

  selectDates(dates: Array<Date>): void {
    [this.startDate, this.endDate] = dates;
    this.updateStatistics();
  }

  updateStatistics(): void {
    this.statisticsApiService
      .getTotalStats(this.startDate, this.endDate)
      .pipe(
        tap(
          (stats: TotalStatistic) =>
            (this.totalStats = {
              closedTotal: stats.closedTotal,
              processedTotal: stats.processedTotal,
              totalOpen: stats.totalOpen,
              totalUnusefulChats: stats.totalUnusefulChats,
              supportAvgFirstAnswer: stats.supportAvgFirstAnswer,
              totalClosedChatsByBot: stats.totalClosedChatsByBot,
              norbyScore: parseFloat(stats.norbyScore.toFixed(2)),
            }),
        ),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.updateStatistics();
  }
}
