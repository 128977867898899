import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from '../../@core/services/profile.service';
import { SpinnerService } from '../../@core/services/spinner.service';

@Component({
  selector: 'norby-set-password-page',
  templateUrl: './set-password-page.component.html',
  styleUrls: ['./set-password-page.component.css'],
})
export class SetPasswordPageComponent {
  loadingState$ = this.ps.loadingState$;

  changePasswordForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  constructor(
    private ps: ProfileService,
    private fb: UntypedFormBuilder,
    private router: Router,
    protected spinnerService: SpinnerService,
  ) {}

  get passwordControl(): UntypedFormControl {
    return this.changePasswordForm.controls.password as UntypedFormControl;
  }

  changePassword(): void {
    this.spinnerService.open();
    this.ps.changePassword(this.passwordControl.value, () => {
      this.ps.reload().subscribe({
        next: () => {
          this.router.navigateByUrl('/chats');
        },
        complete: () => {
          this.spinnerService.close();
        },
      });
    });
  }
}
