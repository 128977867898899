<div *ngIf="displayAlert" role="alert" class="billing-status-container">
  <div
    *ngIf="isSubscriptionCanceled"
    class="notification-content orange-notification white-text"
  >
    The subscription was canceled.
    <a
      target="_blank"
      (click)="sendMixPanelEvent('canceled warning')"
      [href]="paymentStatuses?.billing_page"
      >Pay Now</a
    >
  </div>
  <div
    *ngIf="isPaymentOverdue"
    class="notification-content yellow-notification black-text"
  >
    The payment is overdue.
    <a
      target="_blank"
      (click)="sendMixPanelEvent('overdue warning')"
      [href]="paymentStatuses?.billing_page"
      >Pay Now</a
    >
  </div>
</div>
