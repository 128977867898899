<norby-question-card [cardText]="question?.question">
  <div class="border-stub"></div>
  <div class="clock">
    <norby-clock-icon
      (mouseenter)="isQuestionApprovedMark = true"
      (mouseleave)="isQuestionApprovedMark = false"
      *ngIf="!question?.approved"
      [style]="{ 'margin-right': '26px', 'margin-top': '2px' }"
      [color]="'#A8A8A8'"
    ></norby-clock-icon>
    <norby-mark
      *ngIf="!question?.approved && isQuestionApprovedMark"
      [positionStyles]="{
        top: '35px',
        right: '-10px',
        position: 'absolute',
        transform: 'translate(0 0)',
        zIndex: 9999
      }"
      >The question isn’t approved</norby-mark
    >
  </div>
  <div class="bin">
    <norby-bin
      (mouseenter)="isQuestionRemoveMark = true"
      (mouseleave)="isQuestionRemoveMark = false"
      [showBin]="true"
      (remove)="questionId.emit(question)"
      [disabled]="!!question?.approved"
      [item]="'Question'"
    >
    </norby-bin>
    <norby-mark
      *ngIf="!!question?.approved && isQuestionRemoveMark"
      [positionStyles]="{
        top: '35px',
        right: '-10px',
        position: 'absolute',
        transform: 'translate(0, 0)',
        zIndex: 9999
      }"
      >You can’t delete approved questions</norby-mark
    >
  </div>
</norby-question-card>
