import { KeyValue } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TriggerValue } from '../../../../@core/types/Automations/TriggerTypes';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';

@Component({
  selector: 'norby-trigger-select',
  templateUrl: './trigger-select.component.html',
  styleUrls: ['./trigger-select.component.css'],
})
export class TriggerSelectComponent {
  @Input() placeholder = '';
  @Input() disabledButton = true;
  @Input() currentValue: string | undefined = '';
  @Input() optionsLabel = '';
  @Input() topPositioning = '78px';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Output() setOptionValue = new EventEmitter();
  @Output() setButtonState = new EventEmitter();
  triggerOptionValues$ = this.ss.triggerTypes$;
  public isOpen = false;

  constructor(
    private ref: ElementRef,
    private ss: SlugsService,
  ) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  public setValue(option: KeyValue<string, TriggerValue>): void {
    if (option.value.exists) {
      return;
    }
    const { key, value } = option;
    this.currentValue = value.name;
    this.setOptionValue.emit(key);
    this.isOpen = false;
    this.setButtonState.emit(false);
  }
}
