import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-need-help-shadow',
  templateUrl: './need-help-shadow.component.html',
  styleUrls: ['./need-help-shadow.component.css'],
})
export class NeedHelpShadowComponent {
  @Input() isShadowPopup = true;
  @Output() closeEvent = new EventEmitter();

  close(): void {
    this.closeEvent.emit();
  }
}
