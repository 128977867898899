import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AttributesService } from '../../../../@core/services/attributes.service';

export type AttributeFormType = {
  name?: string;
  description?: string | null;
  dataType?: string;
};

@Component({
  selector: 'norby-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.css'],
})
export class AttributeComponent {
  @Input() attributeUuid = '';
  @Input() name = '';
  @Input() description = '';
  @Input() createdAt = '';
  @Input() updatedAt = '';
  @Output() showDeleteModalWindow = new EventEmitter();
  @Output() updateAttributeName = new EventEmitter();
  @Output() updateAttributeDescription = new EventEmitter();
  public showBin = false;
  public editDescription = false;
  public editName = false;
  public attributeForm: UntypedFormGroup;

  constructor(
    private attributesService: AttributesService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.attributeForm = this.formBuilder.group({
      name: ['name', [Validators.required, Validators.maxLength(255)]],
      description: ['', [Validators.maxLength(499)]],
    });
  }

  getControl(controlName: string): UntypedFormControl {
    return this.attributeForm.controls[controlName] as UntypedFormControl;
  }

  public editAttributeName(): void {
    if (this.attributeForm.valid) {
      const attributeObject = this.attributeForm.value;
      attributeObject.name = this.getControl('name').value;
      attributeObject.description = this.description;
      this.editName = false;
      this.updateAttributeName.emit({
        attributeUuid: this.attributeUuid,
        formValues: attributeObject,
      });
    }
  }

  public editAttributeDescription(): void {
    if (this.attributeForm.valid) {
      const attributeObject = this.attributeForm.value;
      attributeObject.name = this.name;
      attributeObject.description = this.getControl('description').value;
      this.updateAttributeDescription.emit({
        attributeUuid: this.attributeUuid,
        formValues: attributeObject,
      });
      this.editDescription = false;
    }
  }
}
