import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Country } from '../../@core/types/Automations/Country';
import { States } from '../../@core/types/Automations/States';

@Component({
  selector: 'norby-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.css'],
})
export class SelectCountryComponent {
  @Input() countriesWithStates$ = new BehaviorSubject<Country[] | null>(null);
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() currentValue: string | undefined = '';
  @Input() topPositioning = '78px';
  @Input() optionsLabel = '';
  @Output() setChangeValue = new EventEmitter();

  public searchSelectState = false;

  get states(): States[] | null {
    if (this.countriesWithStates$?.value) {
      return this.countriesWithStates$?.value[0].states;
    }
    return null;
  }

  setStateValue(option: States): void {
    this.currentValue = option.state;
    this.setChangeValue.emit(option);
    this.searchSelectState = false;
  }
}
