<!--    Second step-->
<form
  *ngIf="currentStep.name === 'secondStep' && currentStep.state"
  [formGroup]="form"
  (ngSubmit)="goToNextStep()"
  class="info-form"
>
  <div class="form-group">
    <label class="form-label" for="company-name">Company name</label>
    <input
      class="form-input"
      formControlName="name"
      id="company-name"
      placeholder="Enter here…"
    />
    <div
      *ngIf="
        form.controls.name.invalid &&
        (form.controls.name.dirty || form.controls.name.touched)
      "
      class="input-error"
    >
      <div *ngIf="form.controls.name.errors?.required">Name is required.</div>
    </div>
    <div class="input-error" *ngIf="obs.nameError$ | async">
      {{ obs.nameError$ | async }}
    </div>
  </div>
  <div class="form-group">
    <label class="form-label" for="company-web">Company website</label>
    <input
      class="form-input"
      formControlName="url"
      type="text"
      id="company-web"
      placeholder="company.com"
    />
    <div
      *ngIf="
        form.controls.url.invalid &&
        (form.controls.url.dirty || form.controls.url.touched)
      "
      class="input-error"
    >
      <div *ngIf="form.controls.url.errors?.required">
        Company website is required.
      </div>
      <div *ngIf="form.controls.url.errors?.pattern">
        It should be a web page.
      </div>
    </div>
    <div class="input-error" *ngIf="obs.urlError$ | async">
      {{ obs.urlError$ | async }}
    </div>
  </div>
  <button
    [disabled]="
      form.invalid || (obs.urlError$ | async) || (obs.nameError$ | async)
    "
    class="main-button"
  >
    Continue
  </button>
</form>
