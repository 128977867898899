<norby-navbar [showMenu]="false"></norby-navbar>
<div class="wrapper">
  <form class="form" [formGroup]="changePasswordForm">
    <h1>Set your new password</h1>
    <p>
      You logged in with the one‐off password. Before start, you have to set a
      new one.
    </p>
    <norby-input
      [control]="passwordControl"
      [eyeIcon]="true"
      [minLength]="6"
      [name]="'Password'"
      [type]="'password'"
      class="margin"
      label="Your new password"
      placeholder="6+ symbols"
    >
    </norby-input>
    <button (click)="changePassword()" class="main-button" type="submit">
      Set New Password
    </button>
  </form>
</div>
<div *ngIf="loadingState$ | async" class="loading_modal">
  <norby-spinner [color]="'#FFFFFF'"></norby-spinner>
</div>
