import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { OnlineStatus } from '../../../@core/domain/Profile';

@Component({
  selector: 'norby-chooser-form',
  templateUrl: './chooser-form.component.html',
  styleUrls: ['./chooser-form.component.css'],
})
export class ChooserFormComponent {
  @Input() status: OnlineStatus = 'away';
  @Output() toggleOnlineStatusEvent = new EventEmitter<OnlineStatus>();

  hasHoverOnline = false;
  hasHoverAway = false;

  @HostListener('mouseover', ['$event']) onMouseOver(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.id === 'online' || target.closest('#online')) {
      this.hasHoverOnline = true;
    } else if (target.id === 'away' || target.closest('#away')) {
      this.hasHoverAway = true;
    }
  }

  @HostListener('mouseout', ['$event']) onMouseOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.id === 'online' || target.closest('#online')) {
      this.hasHoverOnline = false;
    } else if (target.id === 'away' || target.closest('#away')) {
      this.hasHoverAway = false;
    }
  }

  toggleAwayStatus(status: OnlineStatus): void {
    this.toggleOnlineStatusEvent.emit(status);
  }
}
