<div class="select-container">
  <div class="label-wrapper">
    <label class="select-label" *ngIf="label">{{ label }}</label>
    <div class="sub-label">{{ subLabel }}</div>
  </div>
  <div class="select-wrapper" (click)="open()">
    <form [formGroup]="form">
      <input
        formControlName="country"
        autocomplete="off"
        (keyup)="searchOnKeyUp($event)"
        [readOnly]="!searchSelectState"
        class="select-input"
        [value]="currentValue"
        #autocompleteInput
        placeholder="{{ placeholder }}"
      />
    </form>
    <norby-expose-button
      class="select-arrow"
      [isOpen]="searchSelectState"
      [background]="'var(--grayF1)'"
    ></norby-expose-button>
  </div>
  <div
    *ngIf="
      form.controls.country.invalid &&
      (form.controls.country.dirty || form.controls.country.touched)
    "
    class="error"
  >
    <div *ngIf="form.controls.country.errors?.required">Required field</div>
  </div>
  <ng-container *ngIf="searchSelectState">
    <!--    Country values from server -->
    <div
      *ngIf="!(results$ | async)?.length"
      class="options-container"
      [style]="{ top: topPositioning }"
    >
      <h1 *ngIf="!!optionsLabel" class="options-label">{{ optionsLabel }}</h1>
      <div
        class="option-wrapper"
        *ngFor="let option of optionValues | async"
        (click)="setValue(option)"
      >
        <label for="select-option-{{ option.id }}" class="option-label">
          <input
            type="radio"
            class="option-value"
            [formControl]="control"
            [value]="option.country"
            id="select-option-{{ option.id }}"
          />
          {{ option.country }}
        </label>
      </div>
    </div>
    <!--    Filtered countries-->
    <div
      *ngIf="(results$ | async)?.length"
      class="options-container filtered"
      [style]="{ top: topPositioning }"
    >
      <h1 *ngIf="!!optionsLabel" class="options-label">{{ optionsLabel }}</h1>
      <div
        class="option-wrapper"
        *ngFor="let option of results$ | async"
        (click)="setValue(option)"
      >
        <label for="select-option-{{ option.id }}" class="option-label">
          <input
            type="radio"
            class="option-value"
            [formControl]="control"
            [value]="option.country"
            id="select-option-{{ option.id }}"
          />
          {{ option.country }}
        </label>
      </div>
    </div>
  </ng-container>
  <div *ngIf="control.invalid && (control.dirty || control.touched)">
    <div class="error" *ngIf="control.errors?.required">
      {{ name }} is required
    </div>
    <div class="error" *ngIf="control.errors?.apiError">
      {{ control.errors?.apiError.value }}
    </div>
  </div>
</div>
