import { Component } from '@angular/core';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';
import { SlugLiteralStatus } from '../../../../@core/types/Automations/SlugLiteralStatus';

@Component({
  selector: 'norby-slugs-tabs',
  templateUrl: './slugs-tabs.component.html',
  styleUrls: ['./slugs-tabs.component.css'],
})
export class SlugsTabsComponent {
  readonly tabsList: SlugLiteralStatus[] = ['Active', 'Disabled'];

  constructor(protected ss: SlugsService) {}

  setTab(tab: SlugLiteralStatus): void {
    this.ss.currentTab$.next(tab);
  }
}
