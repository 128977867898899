import { BehaviorSubject } from 'rxjs';
import { Limits } from '../types/Automations/Limits';
import { PromoCode } from '../types/Automations/PromoCode';
import { BillingAddressResponse } from '../types/BillingAddressResponse';
import { Locale } from './Locale';
import { CompanyDetailsResponse } from '../types/CompanyDetailsResponse';

export class ProjectSettings {
  public apiKey$ = new BehaviorSubject<string>('');
  public projectToken$ = new BehaviorSubject<string>('');
  public telegramWebhook$ = new BehaviorSubject<string>('');
  public slugToken$ = new BehaviorSubject<string>('');
  public locale$ = new BehaviorSubject<Locale>('en');
  public automationLimits$ = new BehaviorSubject<Limits>({
    manual_active: 0,
    manual_total: 0,
    auto_active: 0,
    auto_total: 0,
  });
  public promoCode$ = new BehaviorSubject<PromoCode>({
    amount_off: 0,
    code: null,
    duration: 'forever',
    duration_in_months: null,
    extend_trial: 0,
    percent_off: '',
  });
  public userBillingAddress$: BehaviorSubject<BillingAddressResponse | null> =
    new BehaviorSubject<BillingAddressResponse | null>(null);
  public companyDetails$: BehaviorSubject<CompanyDetailsResponse | null> =
    new BehaviorSubject<CompanyDetailsResponse | null>(null);
}
