<svg
  routerLink="/"
  (click)="logoAction.emit()"
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="#FF2D54"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.2745 34.7338C11.722 33.561 7.12038 28.6488 6.37325 23.1077C6.13553 21.306 6.28835 18.8244 6.74682 17.2097C7.95241 12.9604 11.739 9.06804 15.967 7.75926C17.3594 7.33433 17.5122 7.53829 16.7991 8.96606C16.2897 10.0199 16.2048 10.0879 15.0331 10.6658C11.0598 12.5695 8.61463 16.5298 8.59765 21.0511C8.59765 21.9519 8.71651 23.0907 8.86934 23.7706C9.25988 25.3684 10.3806 27.561 11.3145 28.6148L12.0956 29.4817L13.7766 29.1927C15.0501 28.9718 16.035 28.9208 17.9367 28.9888C20.7215 29.0908 22.3516 29.4307 24.7628 30.3995L26.257 31.0114L27.0041 30.4845C28.872 29.2097 30.7058 26.5242 31.385 24.0936C31.7586 22.7678 31.7076 18.8924 31.3001 17.7196C30.1794 14.4562 28.0909 11.9746 25.3571 10.6828C24.8477 10.4448 24.3892 10.1729 24.3383 10.1049C24.2873 10.0369 24.3552 9.54396 24.4571 9.05105C24.6099 8.42215 24.7628 8.0992 24.9495 8.06521C25.408 7.98022 27.8022 9.306 29.0248 10.3258C31.1303 12.0596 32.9472 14.8811 33.6943 17.5497C33.9151 18.3825 34 19.2834 34 21.1191C34 23.2267 33.932 23.7706 33.5924 24.8584C31.3511 32.0143 24.3892 36.2296 17.2745 34.7338Z"
  />
  <path
    d="M19.0575 28.071C18.4122 27.986 17.2236 27.748 16.4255 27.561C14.8124 27.1701 11.4673 25.8273 11.1447 25.4364C11.0089 25.2664 10.9749 24.8075 11.0598 23.8556L11.1787 22.4959L10.5334 22.4449L9.88818 22.3939L9.93912 21.1701C9.97308 20.4902 10.041 19.6233 10.1089 19.2154C10.2448 18.4845 10.2448 18.4845 10.924 18.5185L11.6202 18.5355L11.8239 16.7338C11.9428 15.731 12.0786 14.8641 12.1465 14.7961C12.3843 14.5582 14.354 14.1332 16.1369 13.9293C17.8349 13.7423 17.9537 13.7083 17.9537 13.3514C17.9537 12.8754 18.1745 12.7565 18.9726 12.7565L19.6348 12.7735L20.1442 11.2267L20.6536 9.69697L19.414 9.81595C18.633 9.88394 18.1236 9.86694 18.0556 9.74796C17.9537 9.59499 21.3668 2.16721 21.7064 1.82726C21.8082 1.74228 21.9271 1.74228 22.029 1.84426C22.1309 1.94624 21.9611 2.94908 21.6045 4.6148C21.2819 6.05957 21.0102 7.30036 21.0102 7.38535C21.0102 7.45334 21.5026 7.52133 22.1139 7.52133C22.9289 7.52133 23.2516 7.58932 23.3195 7.75929C23.3704 7.89527 23.1667 9.10207 22.878 10.4109C22.5723 11.7366 22.3856 12.8754 22.4535 12.9264C22.5214 12.9944 22.878 13.1134 23.2685 13.2154C23.9308 13.3684 23.9817 13.4194 23.9477 13.9123C23.8968 14.4222 23.9308 14.4392 24.9665 14.7281C26.2061 15.0681 29.0757 16.3089 29.2965 16.5808C29.3984 16.6998 29.3984 17.2267 29.3135 17.8896C29.2455 18.5015 29.1437 19.3174 29.0927 19.7083L29.0078 20.4222L29.6361 20.4732L30.2644 20.5242L30.2134 22.2919C30.1625 24.3146 30.0436 24.5865 29.2116 24.4335C28.5833 24.3146 28.5833 24.3486 28.4135 26.0313C28.3626 26.5412 28.2267 27.0681 28.1079 27.1871C27.4287 27.867 21.6554 28.4279 19.0575 28.071ZM23.761 25.3514C24.7288 25.2494 25.5778 25.1134 25.6458 25.0455C25.7646 24.9265 26.4438 19.4874 26.4438 18.5525C26.4438 18.1616 26.308 18.0766 25.1363 17.6687C22.9799 16.9378 21.7403 16.6998 19.3971 16.5808C17.5802 16.4959 14.8464 16.6998 14.6087 16.9208C14.5238 17.0228 13.8615 23.5497 13.9295 23.7537C14.0653 24.0766 17.2576 25.0285 19.0914 25.2834C21.2309 25.5724 21.6554 25.5724 23.761 25.3514Z"
  />
  <path
    d="M23.2175 21.8839L22.8269 21.459L22.2496 21.816C21.7232 22.1559 21.6553 22.1559 21.2648 21.9009C20.6195 21.476 20.7214 20.9151 21.5534 20.2012C22.5722 19.3344 23.2175 19.3514 24.0835 20.3032C24.8136 21.1021 24.9155 21.595 24.4401 22.0199C23.9986 22.4109 23.676 22.3769 23.2175 21.8839Z"
  />
  <path
    d="M17.9538 21.357C17.4613 20.9321 17.4104 20.9321 17.1217 21.1871C16.7482 21.527 16.018 21.544 15.7633 21.2211C15.3728 20.7622 15.6275 20.0993 16.3746 19.5384C17.3594 18.8415 17.9877 18.8755 18.8197 19.6913C19.6518 20.4902 19.7367 20.8811 19.2273 21.391C18.7179 21.901 18.616 21.901 17.9538 21.357Z"
  />
</svg>
