<div class="search-form">
  <h2 class="form-caption">Where</h2>
  <norby-search-select
    class="full-width"
    [options]="userAttributeNames$ | async"
    [currentValue]="(uss.selectedUserAttribute$ | async)?.name || null"
    [placeHolder]="'An attribute...'"
    (selectedOption)="selectAttribute($event)"
  ></norby-search-select>
  <ng-container *ngIf="options$ | async as options">
    <norby-search-select
      [options]="options"
      [placeHolder]="'A condition...'"
      *ngIf="options.length"
      (selectedOption)="selectOption($event)"
    ></norby-search-select>
  </ng-container>
  <ng-container *ngIf="showValueInput | async">
    <ng-container
      *ngIf="(uss.selectedUserAttribute$ | async)?.data_type as type"
    >
      <norby-search-select
        [options]="['true', 'false']"
        *ngIf="type === 'boolean'"
        [placeHolder]="'A value...'"
        (selectedOption)="setOptionValue($event)"
      ></norby-search-select>
      <norby-debounce-input
        *ngIf="type !== 'boolean' && type !== 'date'"
        [placeholder]="'A value...'"
        (delayedInput)="setOptionValue($event)"
      >
      </norby-debounce-input>
      <norby-datepicker
        [mode]="'single'"
        *ngIf="type === 'date'"
        (selectedOneDate)="setOptionValue($event)"
      >
      </norby-datepicker>
    </ng-container>
  </ng-container>
  <norby-search-reset (click)="clearAll()"></norby-search-reset>
</div>
