<div class="attributes-content__description">
  <div
    class="attributes-content__text"
    *ngIf="!editDescription"
    (click)="openEdit.emit()"
  >
    {{ attributeDescription }}
  </div>
  <!--    If have empty description field-->
  <div
    *ngIf="
      attributeDescription === '' ||
      (attributeDescription === null && !editDescription)
    "
    class="attributes-content__text empty-description"
    (click)="openEdit.emit()"
  >
    {{ "Add the description" }}
  </div>
  <textarea
    *ngIf="editDescription"
    rows="2"
    norbyAutoFocus
    class="attributes-content__input"
    [maxLength]="500"
    [formControl]="control"
    [(ngModel)]="attributeDescription"
    (focusout)="editUserAttributeDescription.emit()"
  ></textarea>
</div>
<div
  class="input-error"
  *ngIf="control.invalid && (control.dirty || control.touched)"
>
  <div class="error-control" *ngIf="control.errors?.maxlength">
    {{ name }} is too long, no more than {{ maxLength }} characters
  </div>
  <div *ngIf="control.errors?.apiError">
    {{ control.errors?.apiError.value }}
  </div>
</div>
