import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageCompressionService {
  public compress = async (file: File): Promise<File> => {
    if (this.isNotTooLarge(file)) {
      return file;
    }
    try {
      const res = await this.convertFile(file);
      if (res) {
        const resized = await this.create(res as string);
        return await this.convertUrl(resized, file.name);
      } else {
        return res as File;
      }
    } catch (e) {
      throw new Error('Cannot compress an image');
    }
  };

  public isNotTooLarge(file: File): boolean {
    return file.size < 1024 * 1024 * 3;
  }

  private create = async (base64Str: string): Promise<string> => {
    return await this.draw(base64Str);
  };

  private draw = async (base64Str: string): Promise<string> => {
    return await new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        resolve(this.resize(img, 1920, 1080));
      };
    });
  };

  public resize = (
    img: HTMLImageElement,
    MAX_WIDTH: number,
    MAX_HEIGHT: number,
  ): Promise<string> => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL('image/jpeg'));
    });
  };

  private convertUrl = async (
    dataUrl: string,
    fileName: string,
  ): Promise<File> => {
    try {
      const res: Response = await fetch(dataUrl);
      const blob: Blob = await res.blob();
      return new File([blob], fileName, { type: 'image/jpeg' });
    } catch (e) {
      throw new Error('Error while converting dataURL string to file');
    }
  };

  private convertFile = async (file: File) => {
    return await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
  };
}
