import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription, throwError } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  AuthService,
  GetRefreshToken,
} from '../../@core/services/auth.service';
import { ProfileService } from '../../@core/services/profile.service';
import { SpinnerService } from '../../@core/services/spinner.service';

@Component({
  selector: 'norby-layout-component',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  every2minutes = interval(1000 * 60 * 2);

  constructor(
    private auth: AuthService,
    protected profileService: ProfileService,
    protected ss: SpinnerService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.auth.isAuthenticated
        .pipe(
          filter((isAuthenticated) => isAuthenticated),
          switchMap(() => this.profileService.reload()),
        )
        .subscribe({
          error: (err) => {
            console.error(`Error loading profile: ${err.message}`);
            this.auth.logout();
          },
        }),
    );
    this.subs.push(
      this.every2minutes
        .pipe(
          switchMap(() => {
            const token = GetRefreshToken();
            if (token) {
              return this.auth.refresh(token);
            }
            return throwError(() => new Error('No refresh token found'));
          }),
        )
        .subscribe(() => this.cdRef.detectChanges()),
    );
    this.ss.show$.subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
