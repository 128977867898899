<div *ngIf="!(promoCode$ | async)?.code" class="promo-bar">
  Have a promotion code?
  <span class="bar-link" (click)="openPromoCodePopup.emit(true)"
    >Apply now</span
  >
</div>
<div *ngIf="isForeverSubscription()" class="promo-bar enabled">
  {{ (promoCode$ | async)?.code }} is applied:
  <span *ngIf="(promoCode$ | async)?.extend_trial" class="trial-data">
    extended trial ({{
      (promoCode$ | async)?.extend_trial | fromNumberToMonth
    }}) +</span
  >
  {{ (promoCode$ | async)?.percent_off }}% off for lifetime
</div>
<div *ngIf="isRepeatingSubscription()" class="promo-bar enabled">
  {{ (promoCode$ | async)?.code }} is applied:
  <span *ngIf="(promoCode$ | async)?.extend_trial" class="trial-data">
    extended trial ({{
      (promoCode$ | async)?.extend_trial | fromNumberToMonth
    }}) +</span
  >
  €&nbsp;{{ setAmount() | number: "1.2-2" }} of your next payments
</div>
