import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export type SelectOption = { key: number | string; value: string };

@Component({
  selector: 'norby-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent {
  @Input() optionValues: SelectOption[] | null = [];
  @Input() optionsLabel = '';
  @Input() label = '';
  @Input() subLabel = '';
  @Input() name = 'This field';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = '';
  @Input() currentValue: string | undefined = '';
  @Input() topPositioning = '78px';
  @Input() textColor = '';
  @Input() arrowColor = 'black';
  @Input() arrowBgColor = 'var(--grayF1)';
  @Input() background = '';
  @Output() setChangeValue = new EventEmitter();
  public searchSelectState = false;

  constructor(private ref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.searchSelectState = false;
    }
  }

  toggle(): void {
    this.searchSelectState = !this.searchSelectState;
  }

  public setValue({ key, value }: SelectOption): void {
    this.currentValue = value;
    this.setChangeValue.emit({ key, value });
    this.searchSelectState = false;
  }
}
