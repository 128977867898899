<ng-container *ngIf="ss.selectedSlug$ | async as selectedSlug">
  <div class="header-container">
    <norby-blue-bar
      [visible]="
        !selectedSlug.approved &&
        !selectedSlug.active &&
        selectedSlug.type === 'manual'
      "
    >
      <norby-clock-icon
        [style]="{ 'margin-right': '10px' }"
        [color]="'#FFFFFF'"
      ></norby-clock-icon>
      After we approve the automation, you will be able to activate it. You can
      edit still.
    </norby-blue-bar>
    <norby-blue-bar [visible]="!selectedSlug.active && selectedSlug.approved">
      It’s disabled. Activate it so the bot could use it.
    </norby-blue-bar>
    <div class="automation-header">
      <div class="title-wrapper">
        <button class="back-to-atm-button" (click)="showAutomationList()">
          <img src="assets/avatars/back-arrow-button.svg" alt="back" />
        </button>
        <h4 class="title">Automation Editor</h4>
        <span *ngIf="selectedSlug?.type === 'automatic'" class="title-info"
          >Auto</span
        >
        <ng-container
          *ngIf="
            selectedSlug?.type === 'manual' &&
            (selectedSlug?.trigger$ | async) as trigger
          "
        >
          <span *ngIf="trigger === 'greeting'" class="title-info">{{
            "greeting" | uppercase
          }}</span>
          <span *ngIf="trigger === 'questions'" class="title-info">{{
            "questions" | uppercase
          }}</span>
          <span *ngIf="trigger === 'team_assign'" class="title-info">{{
            "TEAM ASSIGNING" | uppercase
          }}</span>
        </ng-container>
      </div>
      <div class="actions">
        <button
          *ngIf="selectedSlug"
          [disabled]="isDisabled(selectedSlug)"
          (click)="saveSlug(selectedSlug)"
          class="save-button desktop"
        >
          Save
        </button>
        <div class="switcher">
          <norby-switcher
            (toggle)="toggleSlug($event, selectedSlug)"
            [label]="'Active'"
            [switchedOn]="selectedSlug.active"
            [enabled]="selectedSlug.approved"
          ></norby-switcher>
        </div>
        <div class="border-stub"></div>
        <div class="bin">
          <norby-bin
            [stroke]="
              selectedSlug.type === 'manual'
                ? 'var(--gray76)'
                : 'var(--nrb-ob-color)'
            "
            (mouseenter)="removeMark = true"
            (mouseleave)="removeMark = false"
            (remove)="removeSlug(selectedSlug.id)"
            [showBin]="true"
            [disabled]="selectedSlug.approved && selectedSlug.type === 'manual'"
            [item]="'Automation'"
          >
          </norby-bin>
          <norby-mark
            *ngIf="
              selectedSlug.approved &&
              selectedSlug.type === 'manual' &&
              removeMark
            "
            [positionStyles]="{
              top: '35px',
              right: '10px',
              position: 'absolute',
              transform: 'translate(0, 0)',
              zIndex: 9999
            }"
            >You can’t delete approved automations</norby-mark
          >
        </div>
      </div>
      <button
        *ngIf="selectedSlug"
        [disabled]="isDisabled(selectedSlug)"
        (click)="saveSlug(selectedSlug)"
        class="save-button mobile-save"
      >
        Save
      </button>
    </div>
  </div>
</ng-container>
