import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { io } from 'socket.io-client';
import { Profile } from 'src/app/@core/domain/Profile';
import { AuthService } from '../../@core/services/auth.service';
import { GuideService } from '../../@core/services/automations/guide.service';
import { PaymentService } from '../../@core/services/automations/payment.service';
import { MixpanelService } from '../../@core/services/mixpanel.service';
import { NavTab } from '../../@core/types/NavTab';
import { ProfileService } from './../../@core/services/profile.service';
import { SupportSocketService } from './../../@core/services/support-socket.service';

interface NavItem {
  name: string;
  tab: NavTab;
  mixpanelEvent: string;
  props?: { key: string; value: string };
}

@Component({
  selector: 'norby-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() showMenu = true;
  @Output() tabChange = new EventEmitter();

  sub = new Subscription();
  isOpenGuidePopup$ = this.gs.isOpenPopup$;
  isShadowPopup: boolean | undefined = undefined;
  currentTab: NavTab = 'chats';
  slugToken = localStorage.getItem('st');
  menuOpen = false;
  isNeedHelpOpen = false;
  profile: Profile | undefined = undefined;

  readonly navBar: NavItem[] = [
    {
      name: 'Chats',
      tab: 'chats',
      mixpanelEvent: 'click_chats_link',
    },
    /* Hide the Users page to the time */
    // {
    //   name: 'Users',
    //   tab: 'users',
    //   mixpanelEvent: 'click_users_link',
    // },
    {
      name: 'My Team',
      tab: 'team',
      mixpanelEvent: 'click_my_team_link',
    },
    {
      name: 'Automations',
      tab: 'automations',
      mixpanelEvent: 'click_automations',
      props: { key: 'where', value: 'header' },
    },
    {
      name: 'Referral',
      tab: 'referral',
      mixpanelEvent: 'click_referral',
    },
  ];

  constructor(
    public authService: AuthService,
    protected profileService: ProfileService,
    private router: Router,
    private ref: ElementRef,
    protected socketService: SupportSocketService,
    private mx: MixpanelService,
    private gs: GuideService,
    protected ps: PaymentService,
  ) {}

  @HostListener('document: click', ['$event'])
  clickOut(event: Event): void {
    const target = event.target as HTMLElement;
    if (
      !this.ref.nativeElement.contains(event.target) ||
      target.className === 'responsive-flex' ||
      target.className === 'container' ||
      target.className === 'name' ||
      target.className === 'role' ||
      (this.menuOpen && target.className === 'agent-navbar')
    ) {
      this.menuOpen = false;
    }

    if (this.isNeedHelpOpen) {
      this.isNeedHelpOpen = false;
    }
  }

  ngOnInit(): void {
    this.socketService.connect(io);
    this.sub = this.profileService.profile$.subscribe({
      next: (profile) => {
        if (profile) {
          if (profile.shouldChangePassword) {
            this.router.navigateByUrl('set_password');
          }
          this.isShadowPopup = profile?.showGuide;
          this.profile = profile;
        }
      },
    });
  }

  clicked(option: NavItem): void {
    if (option.tab !== this.currentTab) {
      this.currentTab = option.tab;
      this.tabChange.emit(option.tab);
    }
  }

  sendMixpanelEvent(
    event: string,
    page: string,
    props?: { key: string; value: string } | undefined,
  ): void {
    this.mx.track(event, page, props);
    this.menuOpen = false;
    if (page === 'automations' && this.profile?.show_automation_guide_modal) {
      this.profileService.showAutomationGuide = true;
    }
  }

  openUserGuide($event: Event): void {
    $event.stopPropagation();
    if (this.isShadowPopup) {
      this.closeShadowPopup(false);
    }

    this.isNeedHelpOpen = !this.isNeedHelpOpen;

    if (this.isNeedHelpOpen) {
      this.isOpenGuidePopup$.next(true);
      this.menuOpen = false;
    } else {
      this.isOpenGuidePopup$.next(false);
    }
  }

  closeShadowPopup(guideStatus: boolean): void {
    this.isShadowPopup = false;
    this.profileService.updateGuideStatus(guideStatus);
  }

  closeGuidePopup(): void {
    this.isOpenGuidePopup$.next(false);
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  logout(): void {
    this.mx.track('click_log_out', this.router.url);
    this.authService.logout();
    this.toggleMenu();
    // TODO: fix this crutch
    document.location.reload();
  }

  navigateToSettings(): void {
    this.mx.track('click_settings', this.router.url);
    this.router.navigate(['/settings']);
    this.menuOpen = false;
  }

  navigateToReferral(): void {
    this.mx.track('click_referral', this.router.url);
    this.router.navigate(['/referral']);
    this.menuOpen = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
