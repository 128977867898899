import { Component } from '@angular/core';
import { ProjectSettingsService } from '../../@core/services/settings/project-settings.service';
import { SettingsPageService } from '../../@core/services/settings-page.service';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'norby-referral-page',
  templateUrl: './referral-page.component.html',
  styleUrls: ['./referral-page.component.css'],
})
export class ReferralPageComponent {
  constructor(
    public pss: ProjectSettingsService,
    private sps: SettingsPageService,
    private ts: ToastrService,
  ) {
    this.sps.getProjectSettings();
  }

  async copyItemValue(): Promise<void> {
    try {
      const link = await firstValueFrom(this.pss.referralLink$);
      link && (await navigator.clipboard.writeText(link));
      this.ts.success('The link was copied');
    } catch (err) {
      console.error('Failed to copy the link:', err);
    }
  }
}
