import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Profile } from '../../../@core/domain/Profile';
import { MixpanelService } from '../../../@core/services/mixpanel.service';
import { ProfileService } from '../../../@core/services/profile.service';
import { AvatarInfo } from '../../../@core/types/ProfileInfo';
import { passwordsMatchValidator } from '../../../directives/passwords-match.directive';

const { required } = Validators;

@Component({
  selector: 'norby-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.css'],
})
export class SettingsPageComponent implements OnInit, OnDestroy {
  imgUrl: string | undefined = '';
  avatar: AvatarInfo | undefined;
  modalOpen = false;
  updatingData = false;
  profile: Profile | null;
  passwordsNotMatchError = '';
  sub = new Subscription();
  profileForm: UntypedFormGroup = this.formBuilder.group(
    {
      firstName: [null, required],
      lastName: [null, required],
      newPassword: [null],
      confirmPassword: [null],
      avatar: [null],
      email: [{ value: '', disabled: true }, required],
      emailNotification: [true],
      assignNotification: [false],
    },
    {
      validators: passwordsMatchValidator,
    },
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileService: ProfileService,
    private mx: MixpanelService,
    public router: Router,
  ) {
    this.profile = this.profileService.profile$.value;
    this.imgUrl = this.profile?.avatar?.path;
  }

  private get fileReader(): FileReader {
    const reader = new FileReader();
    reader.onload = () => {
      this.imgUrl = reader.result as string;
    };
    return reader;
  }

  ngOnInit(): void {
    this.sub = this.profileService.profile$.subscribe((profile) => {
      this.profile = profile;
      this.getCurrentModel();
    });
  }

  getControl(controlName: string): UntypedFormControl {
    return this.profileForm.controls[controlName] as UntypedFormControl;
  }

  onFileSelect(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (!target || !target.files || !target.files.length) {
      return;
    }

    const file = target?.files[0] as File;
    const reader = this.fileReader;
    this.profileForm.get('avatar')?.setValue(file);
    reader.readAsDataURL(file);
  }

  getCurrentModel(): void {
    if (this.profile) {
      this.profileForm.patchValue({
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        email: this.profile.email,
        emailNotification: this.profile.emailNotification,
        assignNotification: this.profile.assignNotification,
      });
      this.imgUrl = this.profile?.avatar?.path;
    }
  }

  setEmailNotification($incomingState: boolean, value: string): void {
    this.getControl(value).setValue($incomingState);
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  submit($submitEvent: Event): void {
    $submitEvent.preventDefault();
    this.mx.track('click_save_settings', 'settings/profile');
    if (this.profileForm.valid) {
      this.passwordsNotMatchError = '';
      this.profileService.updateProfile(this.profileForm.value)?.subscribe({
        complete: () => {
          this.updatingData = false;
          this.modalOpen = true;
        },
      });
    } else {
      if (this.profileForm.errors?.passwordsNotMatch) {
        this.passwordsNotMatchError = 'Passwords do not match!';
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
