import { Component, Input } from '@angular/core';
import { SignUpStep } from '../../../../pages/signup-page/signup-page.component';
import { OnboardingService } from '../../../../@core/services/onboarding.service';
import { timer } from 'rxjs';

@Component({
  selector: 'norby-fourth-step',
  templateUrl: './fourth-step.component.html',
  styleUrl: './fourth-step.component.css',
})
export class FourthStepComponent {
  @Input() currentStep: SignUpStep = { name: '', state: false, passed: false };
  linkHasBeenSend = false;

  constructor(protected obs: OnboardingService) {}

  resendLink(): void {
    this.obs.resendVerificationEmail();
    this.linkHasBeenSend = true;

    timer(30000).subscribe(() => {
      this.linkHasBeenSend = false;
    });
  }

  closeModal(): void {
    this.obs.verificationLinkHasBeenReSend = false;
  }
}
