<svg
  [style]="{ margin: margin }"
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 34 34"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="rotate spinner"
>
  <path
    d="M33 17C33 25.8366 25.8366 33 17 33M1 17C1 8.16344 8.16344 1 17 1"
    stroke="var(--spinner-color)"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
