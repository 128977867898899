<svg
  [ngStyle]="margin"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7.91724 6.59762C7.57262 6.82743 7.47954 7.29309 7.70934 7.63771C7.93915 7.98233 8.40481 8.07541 8.74943 7.8456L7.91724 6.59762ZM10.4152 5.83333H11.1652C11.1652 5.55672 11.013 5.30256 10.7691 5.17206C10.5252 5.04155 10.2293 5.05588 9.99914 5.20934L10.4152 5.83333ZM9.66523 14.1667C9.66523 14.5809 10.001 14.9167 10.4152 14.9167C10.8294 14.9167 11.1652 14.5809 11.1652 14.1667H9.66523ZM8.74943 7.8456L10.8313 6.45732L9.99914 5.20934L7.91724 6.59762L8.74943 7.8456ZM9.66523 5.83333V14.1667H11.1652V5.83333H9.66523ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25Z"
    fill="black"
  />
</svg>
