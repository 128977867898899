<ng-container *ngIf="ss.selectedSlug$ | async as selectedSlug"
  ><norby-slug-name
    [slug]="selectedSlug"
    [disabled]="!!selectedSlug.approved"
  ></norby-slug-name>
  <div [class.disabled]="selectedSlug.isNew">
    <norby-langs-tab
      *ngIf="profileService.profile$.value?.automation_version === 1"
      [selectedSlug]="selectedSlug"
      (selectedLang)="uls.setSelectedLang($event)"
    ></norby-langs-tab>
    <norby-slug-questions-wrapper
      *ngIf="(selectedSlug?.trigger$ | async) === 'questions'"
      [selectedSlug]="selectedSlug"
      (remove)="sas.changeAnswers($event)"
      (question)="sqs.createQuestion($event, selectedSlug)"
    ></norby-slug-questions-wrapper>
    <div class="down-arrow">
      <norby-down-arrow-button
        *ngIf="(selectedSlug?.trigger$ | async) === 'questions'"
      >
      </norby-down-arrow-button>
    </div>
    <ng-container *ngFor="let lang of selectedSlug.answers | keyvalue">
      <div
        *ngIf="
          lang.value &&
          (lang.key ? (uls.currentLanguage$ | async) === lang.key : true)
        "
      >
        <norby-slug-answer
          [selectedSlug]="selectedSlug"
          [selectedSlugTrigger$]="selectedSlug.trigger$"
          [answers]="lang.value"
          [answersState]="selectedSlug.answers"
          (removeAnswer)="sas.changeAnswers($event)"
          (answersValues)="changeAnswers($event)"
        ></norby-slug-answer>
      </div>
    </ng-container>
    <div class="down-arrow">
      <norby-down-arrow-button></norby-down-arrow-button>
    </div>
    <norby-automation-widgets
      [selectedSlug]="selectedSlug"
    ></norby-automation-widgets>
  </div>
</ng-container>
