import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from '../../../../@core/types/Automations/Question';

@Component({
  selector: 'norby-question-box',
  templateUrl: './question-box.component.html',
  styleUrls: ['./question-box.component.css'],
})
export class QuestionBoxComponent {
  @Input() question: Question | undefined;
  @Output() questionId = new EventEmitter();
  isQuestionRemoveMark = false;
  isQuestionApprovedMark = false;
}
