<div class="textarea-container">
  <label>{{ label }}</label>
  <div class="textarea-element">
    <textarea
      class="textarea-enabled"
      [rows]="rows"
      [maxLength]="maxLength"
      [formControl]="control"
      [placeholder]="placeholder"
      [ngStyle]="{ width: textareaWidth }"
    ></textarea>
  </div>
  <div *ngIf="control.invalid && (control.dirty || control.touched)">
    <div class="textarea-error" *ngIf="control.errors?.required">
      {{ name }} is required
    </div>
    <div class="textarea-error" *ngIf="control.errors?.maxlength">
      {{ name }} is too long, no more than {{ maxLength }} characters
    </div>
    <div class="textarea-error" *ngIf="control.errors?.apiError">
      {{ control.errors?.apiError.value }}
    </div>
  </div>
</div>
