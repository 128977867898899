<svg
  width="8"
  height="37"
  viewBox="0 0 8 37"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7 33H1V1H7M7 33L4.5 36M7 33L4.5 30"
    [attr.stroke]="color"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
