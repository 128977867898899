import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../@core/services/auth.service';
import { SpinnerService } from '../../../@core/services/spinner.service';
import { OnboardingService } from '../../../@core/services/onboarding.service';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ProfileService } from '../../../@core/services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Profile } from '../../../@core/domain/Profile';

@Component({
  selector: 'norby-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
})
export class VerifyEmailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private ps: ProfileService,
    protected spinnerService: SpinnerService,
    protected obs: OnboardingService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (params) => {
      const token = params.get('token');
      if (token) {
        await this.verifyEmail(token);
      } else {
        console.error('Missing email or token in URL parameters');
      }
    });
  }

  async verifyEmail(token: string): Promise<void> {
    this.spinnerService.open();
    this.authService
      .verifyEmailToken(token)
      .pipe(
        switchMap(() => this.ps.reload()),
        switchMap(() => this.ps.profile$),
        catchError((error) => {
          this.errorHandler(error);
          return of(null);
        }),
      )
      .subscribe({
        next: async (profile: Profile | null) => {
          await this.router.navigateByUrl(
            profile?.show_automation_guide_modal ? '' : '/automations',
          );
          this.spinnerService.close();
        },
        complete: () => this.spinnerService.close(),
      });
  }

  private errorHandler(error: Error) {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 400:
          console.error('Invalid token format or bad request.');
          break;
        case 404:
          console.error('Token not found or invalid URL.');
          break;
        case 410:
          console.error('Token expired.');
          break;
        case 401: // Unauthorized access
        case 403: // Forbidden access
          console.error('Invalid token provided.');
          return of(null); // Return null to handle in the subscription
        default:
          console.error(
            'Unexpected error during email verification:',
            error.message,
          );
      }
    } else {
      console.error('Error during email verification', error.message);
    }
    return of(null); // Return null to handle in the subscription
  }
}
