<div class="modal-container">
  <div class="modal-window">
    <div class="modal-text">
      <p class="desktop-finger">👈️</p>
      <p>Click here to create your first automation</p>
      <p class="mobile-finger">👆</p>
    </div>
    <norby-close-icon (click)="closeModal.emit()"></norby-close-icon>
  </div>
</div>
