import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../@core/services/auth.service';
import { MixpanelService } from '../../@core/services/mixpanel.service';
import { ProfileService } from '../../@core/services/profile.service';
import { SpinnerService } from '../../@core/services/spinner.service';

@Component({
  selector: 'norby-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent {
  hasSubmitted = false;
  errorText = new BehaviorSubject<string>('');
  loginForm: UntypedFormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    if (event) {
      this.hasSubmitted = false;
    }
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    protected spinnerService: SpinnerService,
    private auth: AuthService,
    private router: Router,
    private ps: ProfileService,
    protected mx: MixpanelService,
  ) {}

  getControl(controlName: string): UntypedFormControl {
    return this.loginForm.controls[controlName] as UntypedFormControl;
  }

  login(): void {
    this.hasSubmitted = true;
    this.mx.track('click_login', 'login');
    if (!this.loginForm.valid) {
      return;
    }
    this.errorText.next('');
    this.spinnerService.open();

    this.auth
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe({
        next: () => {
          this.ps.reload().subscribe({
            next: (profile) => {
              this.spinnerService.close();
              this.navigateOut(profile.shouldChangePassword);
            },
            error: (err) => {
              this.handleApiError(err);
              this.spinnerService.close();
            },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.spinnerService.close();
          this.handleApiError(error);
        },
        complete: () => {
          this.spinnerService.close();
        },
      });
  }

  private handleApiError(error: HttpErrorResponse): void {
    if (error.error && error.error.error) {
      this.errorText.next('Invalid email or password.');

      // Set API error for email field
      if (error.error.error.includes('email')) {
        this.getControl('email').setErrors({
          apiError: { value: 'Invalid email' },
        });
      }

      // Set API error for password field
      if (error.error.error.includes('password')) {
        this.getControl('password').setErrors({
          apiError: { value: 'Invalid password' },
        });
      }
    } else if (error.error.errors) {
      const { email, password } = error.error.errors;

      // Set API error for email field
      if (email && Array.isArray(email)) {
        this.getControl('email').setErrors({
          apiError: { value: email.join(' ') },
        });
      }

      // Set API error for password field
      if (password && Array.isArray(password)) {
        this.getControl('password').setErrors({
          apiError: { value: password.join(' ') },
        });
      }
    } else {
      this.errorText.next('Unknown error');
    }
  }

  private navigateOut(shouldChangePassword: boolean): void {
    if (shouldChangePassword) {
      this.router.navigateByUrl('set_password');
    } else {
      this.router.navigateByUrl('');
    }
  }
}
