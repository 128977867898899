<div class="widget-select__wrapper" (click)="toggle()">
  <div class="widget-select__inner">
    <input
      class="widget-select__input"
      [readOnly]="true"
      [placeholder]="placeHolder"
      [value]="currentValue"
    />
    <norby-expose-button
      class="ml-auto"
      [isOpen]="searchSelectState"
    ></norby-expose-button>
  </div>
  <ul *ngIf="searchSelectState" class="widget-select__list">
    <p class="attributes-title">Chat Attributes</p>
    <li
      *ngFor="let option of chatAttributesOptions"
      class="widget-select__item"
      (click)="getValue(option)"
    >
      {{ option }}
    </li>
    <p class="attributes-title">User Attributes</p>
    <li
      *ngFor="let option of userAttributesOptions"
      class="widget-select__item"
      (click)="getValue(option)"
    >
      {{ option }}
    </li>
  </ul>
</div>
