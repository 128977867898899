<div class="editor-languages">
  <div class="select-lang">
    <norby-select
      [optionValues]="uls.languageOptions$ | async"
      [currentValue]="uls.currentLanguage$ | async | localeConverter"
      (setChangeValue)="
        selectCheckedLanguageByShortName($event.key, selectedSlug)
      "
    ></norby-select>
  </div>
  <div class="languages-tabs">
    <ng-container *ngFor="let option of langs$ | async">
      <div
        class="lang-item"
        [ngClass]="{ active: (activeLink$ | async) === option }"
      >
        <h4
          class="lang-title"
          [class.disabled-language]="option.active === 0"
          (click)="changeLangTab(option, selectedSlug)"
        >
          {{ option.name }}
        </h4>
        <ng-container
          *ngFor="let languages of selectedSlug?.questions | async | keyvalue"
        >
          <div
            *ngIf="
              option.short_name === languages.key &&
              languages.value?.length !== 0
            "
            [class.disabled-language]="option.active === 0"
            class="lang-counter"
          >
            {{ languages.value.length }}
          </div>
        </ng-container>
      </div>
      <norby-mark
        *ngIf="isOpenMark"
        [positionStyles]="{
          top: '-42px',
          right: '0px',
          position: 'absolute',
          transform: 'translate(0, 0)',
          zIndex: 9999
        }"
      >
        The language is disabled.
        <a class="go-to-settings" [routerLink]="'/settings/project'"
          >Go to the Setting</a
        >
        to enable.
      </norby-mark>
    </ng-container>
  </div>
  <a class="language-link" [routerLink]="'/settings/project'"
    >Set Up Languages</a
  >
</div>
