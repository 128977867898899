import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'norby-guide-modal',
  templateUrl: './guide-modal.component.html',
  styleUrl: './guide-modal.component.css',
})
export class GuideModalComponent {
  @Output() closeModal = new EventEmitter();
}
