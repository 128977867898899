import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserLanguagesService } from '../../../../../@core/services/user-languages.service';
import { Widget } from '../../../../../@core/types/Automations/Widgets/Widget';

@Component({
  selector: 'norby-new-chat-button-widget',
  templateUrl: './new-chat-button-widget.component.html',
  styleUrls: ['./new-chat-button-widget.component.css'],
})
export class NewChatButtonWidgetComponent implements OnInit {
  private selectedLang = this.uls.currentLanguage$;
  @Input() widgetDetails: Widget | undefined;
  @Output() save = new EventEmitter();
  @Output() remove = new EventEmitter();
  newButtonForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private uls: UserLanguagesService,
  ) {
    this.newButtonForm = this.fb.group({
      name: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (!this.widgetDetails) {
      setTimeout(() => {
        if (this.newButtonForm.valid) {
          this.save.emit({ new_chat_button: this.newButtonForm.value });
        }
      }, 0);
    }

    switch (this.selectedLang.value) {
      case 'en':
        return this.newButtonForm.setValue({ name: 'Start New Chat' });
      case 'ru':
        return this.newButtonForm.setValue({ name: 'Начать новый чат' });
      case 'ee':
        return this.newButtonForm.setValue({ name: 'Alusta Uut Vestlust' });
      case 'it':
        return this.newButtonForm.setValue({ name: 'Avvia una nuova chat' });
      case 'fr':
        return this.newButtonForm.setValue({
          name: 'Démarrer une nouvelle discussion',
        });
      case 'de':
        return this.newButtonForm.setValue({ name: 'Neuen Chat starten' });
      case 'ua':
        return this.newButtonForm.setValue({ name: 'Розпочати новий чат' });
      case 'es':
        return this.newButtonForm.setValue({ name: 'Iniciar nuevo chat' });
      case 'fi':
        return this.newButtonForm.setValue({ name: 'Aloita uusi chat' });
      case 'sv':
        return this.newButtonForm.setValue({ name: 'Starta ny chatt' });
      case 'da':
        return this.newButtonForm.setValue({ name: 'Start ny chat' });
      case 'no':
        return this.newButtonForm.setValue({ name: 'Start ny chat' });
      case 'pl':
        return this.newButtonForm.setValue({ name: 'Rozpocznij nowy czat' });
      case 'pt':
        return this.newButtonForm.setValue({ name: 'Iniciar novo bate-papo' });
      case 'tr':
        return this.newButtonForm.setValue({ name: 'Yeni Sohbet Başlat' });
      case 'zh':
        return this.newButtonForm.setValue({ name: '開始新聊天' });
      case 'ja':
        return this.newButtonForm.setValue({
          name: '新しいチャットを開始する',
        });
      case 'ar':
        return this.newButtonForm.setValue({
          name: 'ابدأ محادثة جديدة',
        });
      default:
        return this.newButtonForm.setValue({ name: 'Start New Chat' });
    }
  }

  removeNewChatButton(): void {
    this.remove.emit();
  }
}
