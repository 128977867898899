<div class="voice-container">
  <svg
    width="302"
    height="157"
    viewBox="0 0 302 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4998 35C27.1647 35 34.9998 27.165 34.9998 17.5C34.9998 7.83502 27.1647 0 17.4998 0C7.83477 0 -0.000244141 7.83502 -0.000244141 17.5C-0.000244141 27.165 7.83477 35 17.4998 35Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M290 17C296.6 17 302 22.4696 302 29.1548V122.341C302 129.026 296.6 134.496 290 134.496H94.1998C92.7998 134.496 91.5998 135.61 91.4998 137.028L89.7998 154.248C89.5998 156.678 86.7997 157.793 84.8997 156.375L61.3997 137.433C58.9998 135.509 56.0998 134.496 53.0998 134.496H33.9998C27.3997 134.496 21.9998 129.026 21.9998 122.341V29.1548C21.9998 22.4696 27.3997 17 33.9998 17H162H290Z"
      fill="#EFEFEF"
    />
    <path
      d="M176.402 55.9H46.5864C43.2093 55.9 40.5076 53.1634 40.5076 49.7428C40.5076 46.3221 43.2093 43.5856 46.5864 43.5856H176.402C179.78 43.5856 182.481 46.3221 182.481 49.7428C182.616 53.0266 179.78 55.9 176.402 55.9Z"
      fill="white"
    />
    <path
      d="M251.509 55.9H195.449C192.072 55.9 189.37 53.1634 189.37 49.7428C189.37 46.3221 192.072 43.5856 195.449 43.5856H251.509C254.886 43.5856 257.588 46.3221 257.588 49.7428C257.588 53.0266 254.886 55.9 251.509 55.9Z"
      fill="white"
    />
    <path
      d="M78.4663 76.5615H46.5864C43.2093 76.5615 40.5076 73.8249 40.5076 70.4043C40.5076 66.9836 43.2093 64.2471 46.5864 64.2471H78.6014C81.9785 64.2471 84.6802 66.9836 84.6802 70.4043C84.6802 73.8249 81.8434 76.5615 78.4663 76.5615Z"
      fill="white"
    />
    <path
      d="M205.716 76.5615H95.7568C92.3797 76.5615 89.678 73.8249 89.678 70.4043C89.678 66.9836 92.3797 64.2471 95.7568 64.2471H205.716C209.093 64.2471 211.794 66.9836 211.794 70.4043C211.794 73.8249 209.093 76.5615 205.716 76.5615Z"
      fill="white"
    />
    <path
      d="M156.274 97.3591H46.3154C42.9383 97.3591 40.2366 94.6225 40.2366 91.2019C40.2366 87.7812 42.9383 85.0447 46.3154 85.0447H156.274C159.651 85.0447 162.353 87.7812 162.353 91.2019C162.353 94.4857 159.651 97.3591 156.274 97.3591Z"
      fill="white"
    />
    <path
      d="M176.402 97.3591H174.781C171.404 97.3591 168.702 94.6225 168.702 91.2019C168.702 87.7812 171.404 85.0447 174.781 85.0447H176.402C179.779 85.0447 182.481 87.7812 182.481 91.2019C182.616 94.4857 179.779 97.3591 176.402 97.3591Z"
      fill="white"
    />
    <path
      d="M231.651 97.3591H195.449C192.072 97.3591 189.37 94.6225 189.37 91.2019C189.37 87.7812 192.072 85.0447 195.449 85.0447H231.651C235.028 85.0447 237.73 87.7812 237.73 91.2019C237.865 94.4857 235.028 97.3591 231.651 97.3591Z"
      fill="white"
    />
  </svg>
</div>
