import { Language } from './app/@core/types/Automations/Language';

export const languages: Language[] = [
  { id: 1, name: 'English', short_name: 'en', active: 1 },
  { id: 2, name: 'Русский', short_name: 'ru', active: 1 },
  { id: 3, name: 'Eesti', short_name: 'ee', active: 1 },
  { id: 4, name: 'Italiano', short_name: 'it', active: 1 },
  { id: 5, name: 'Français', short_name: 'fr', active: 1 },
  { id: 6, name: 'Deutsch', short_name: 'de', active: 1 },
  { id: 7, name: 'Українська', short_name: 'ua', active: 1 },
  { id: 8, name: 'Español', short_name: 'es', active: 1 },
  { id: 9, name: 'Suomi', short_name: 'fi', active: 1 },
  { id: 10, name: 'Svenska', short_name: 'sv', active: 1 },
  { id: 11, name: 'Dansk', short_name: 'da', active: 1 },
  { id: 12, name: 'Norsk', short_name: 'no', active: 1 },
  { id: 13, name: 'Polski', short_name: 'pl', active: 1 },
  { id: 14, name: 'Português', short_name: 'pt', active: 1 },
  { id: 15, name: 'Türkçe', short_name: 'tr', active: 1 },
  { id: 16, name: '中文', short_name: 'zh', active: 1 },
  { id: 17, name: '日本語', short_name: 'ja', active: 1 },
  { id: 18, name: 'العربية', short_name: 'ar', active: 1 },
];
