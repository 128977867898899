import { Component, Input, OnInit } from '@angular/core';
import ChatMessage from 'src/app/@core/types/ChatMessage';

@Component({
  selector: 'norby-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.css'],
})
export class ChatFormComponent implements OnInit {
  @Input() message!: ChatMessage['message'];

  parsed = { description: '', inputs: { placeholder: '' } };

  ngOnInit() {
    this.parsed = this.message && JSON.parse(this.message);
  }
}
