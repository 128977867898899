import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProfileService } from '../services/profile.service';

@Injectable({
  providedIn: 'root',
})
export class ShouldChangePasswordGuard {
  constructor(
    private ps: ProfileService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.ps.profile$.pipe(
      switchMap((profile) => {
        if (profile && profile.shouldChangePassword) {
          this.router.navigateByUrl('set_password');
          return of(false);
        } else {
          return of(true);
        }
      }),
    );
  }
}
