import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from '../../../@core/domain/Profile';
import { MixpanelService } from '../../../@core/services/mixpanel.service';

@Component({
  selector: 'norby-attributes-title',
  template: `
    <h6 class="header">
      {{ title
      }}<norby-cogs
        *ngIf="profile?.role === 'Support Manager'"
        (click)="goToSettings()"
      ></norby-cogs>
    </h6>
  `,
  styleUrls: ['./attributes-title.component.css'],
})
export class AttributesTitleComponent {
  @Input() title = '';
  @Input() route = '';
  @Input() profile: Profile | undefined;

  constructor(
    private router: Router,
    private mx: MixpanelService,
  ) {}

  async goToSettings(): Promise<void> {
    if (this.route) {
      const attributes = this.route.substr(this.route.length - 4);
      this.mx.track(
        attributes === 'user'
          ? 'click_user_attributes'
          : 'click_chat_attributes',
        'chat',
        { key: 'where', value: attributes },
      );
      await this.router.navigate([this.route]);
    }
  }
}
