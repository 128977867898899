import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-viewed',
  templateUrl: './viewed.component.html',
  styleUrls: ['./viewed.component.css'],
})
export class ViewedComponent {
  @Input() viewed: boolean | null = null;
}
