import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[norbyAutoWidthResize]',
})
export class AutoWidthResizeDirective implements AfterViewInit {
  constructor(private el: ElementRef) {
    this.resize();
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.setAttribute(
      'size',
      this.el.nativeElement.value.length,
    );
  }

  @HostListener('keyup')
  onKeyUp(): void {
    this.resize();
  }

  private resize(): void {
    this.el.nativeElement.setAttribute(
      'size',
      this.el.nativeElement.value.length || 12,
    );
  }
}
