import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProfileService } from '../../../@core/services/profile.service';

@Component({
  selector: 'norby-add-new-button',
  templateUrl: './add-new-button.component.html',
  styleUrls: ['./add-new-button.component.css'],
})
export class AddNewButtonComponent {
  @Input() plusColor = 'var(--nrb-ob-color)';
  @Input() bgColor = 'var(--nrb-secondary-color)';
  @Input() hoverColor = 'var(--nrb-secondary-dark-color)';
  @Input() nonActive = false;
  @Input() badgeContent = '';
  @Output() addNew = new EventEmitter();

  constructor(protected profileService: ProfileService) {}

  onMouseEnter() {
    [this.bgColor, this.hoverColor] = [this.hoverColor, this.bgColor];
  }

  onMouseLeave() {
    [this.bgColor, this.hoverColor] = [this.hoverColor, this.bgColor];
  }
}
