import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getLocaleById, Locale } from '../../domain/Locale';
import { Slug } from '../../domain/Slug';
import { Question } from '../../types/Automations/Question';
import { SlugsApiService } from '../api/slugs-api.service';
import { UserLanguagesService } from '../user-languages.service';
import { SlugsService } from './slugs.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  public questionError$ = new BehaviorSubject<string | null>(null);

  constructor(
    private sas: SlugsApiService,
    private ss: SlugsService,
    private uls: UserLanguagesService,
  ) {}

  createQuestion = (question: string, slug: Slug) => {
    const lang = getLocaleById(this.uls.languageId$.value);
    const formId = this.getTotalQuestionsLength(slug, lang);
    const questionData: Pick<
      Question,
      'slug_id' | 'language_id' | 'question' | 'active' | 'approved' | 'formId'
    > = {
      slug_id: slug.id,
      language_id: this.uls.languageId$.value,
      question,
      active: 0,
      approved: 0,
      formId,
    };
    const pattern = new RegExp(question, 'gi');
    const langArray = slug.questions.value[lang];
    const isTwin = langArray.some((q: { question: string }) =>
      pattern.test(q.question),
    );
    if (isTwin) {
      this.questionError$.next('This question is already exist');
      return;
    }
    this.questionError$.next(null);
    slug.addQuestion(questionData, lang);
  };

  deleteQuestion = (question: Question, slug: Slug) => {
    const lang = getLocaleById(this.uls.languageId$.value);
    if (question.id) {
      slug.removeQuestion(lang, question.id);
    } else if (question.formId || question.formId === 0) {
      slug.removeQuestionForm(question.formId, lang);
    }
  };

  public editQuestionState(
    status: number,
    questionId: number,
    slug: Slug,
  ): void {
    const lang = getLocaleById(questionId);
    slug.editQuestion(status, questionId, lang);
  }

  protected getTotalQuestionsLength(slug: Slug, lang: Locale): number {
    return slug.questions.value[lang].length;
  }
}
