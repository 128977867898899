import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localeConverter',
})
export class LocaleConverterPipe implements PipeTransform {
  transform(value: unknown): string {
    switch (value) {
      case 'en':
        return 'English';
      case 'ru':
        return 'Russian';
      case 'ee':
        return 'Estonian';
      case 'it':
        return 'Italian';
      case 'fr':
        return 'French';
      case 'de':
        return 'German';
      case 'ua':
        return 'Ukrainian';
      case 'es':
        return 'Spanish';
      case 'fi':
        return 'Finnish';
      case 'sv':
        return 'Swedish';
      case 'da':
        return 'Danish';
      case 'no':
        return 'Norwegian';
      case 'pl':
        return 'Polish';
      case 'pt':
        return 'Portuguese';
      case 'tr':
        return 'Turkish';
      case 'zh':
        return 'Chinese';
      case 'ja':
        return 'Japanese';
      case 'ar':
        return 'Arabic';
      default:
        return 'English';
    }
  }
}
