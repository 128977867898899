import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleNotificationService {
  originalTitle: string | null = null;
  handle: number | null = null;
  MESSAGE = 'New Message!';

  constructor(private ts: Title) {}

  start(): void {
    if (this.inProgress) {
      return;
    }

    this.originalTitle = document.title;
    this.handle = window.setInterval(() => {
      this.ts.setTitle(
        this.ts.getTitle() === this.originalTitle || ''
          ? this.MESSAGE
          : this.originalTitle || '',
      );
    }, 600);
  }

  stop(): void {
    if (!this.originalTitle || !this.handle) {
      return;
    }
    this.ts.setTitle(this.originalTitle);
    clearInterval(this.handle);
    this.handle = null;
    this.originalTitle = null;
  }

  get inProgress(): boolean {
    return this.handle !== null;
  }

  get inBrowserTab(): boolean {
    return document.hasFocus();
  }
}
