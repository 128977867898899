<a
  class="common-button"
  [ngStyle]="disabledColor ? { cursor: 'default' } : { cursor: 'pointer' }"
  (click)="check($event)"
  (mouseenter)="onHoverColor()"
  (mouseleave)="offHoverColor()"
  [href]="url"
  target="_blank"
>
  <svg
    width="24"
    height="24"
    class="inner-svg"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 12.8333V17.8333C17 18.2754 16.8244 18.6993 16.5118 19.0118C16.1993 19.3244 15.7754 19.5 15.3333 19.5H6.16667C5.72464 19.5 5.30072 19.3244 4.98816 19.0118C4.67559 18.6993 4.5 18.2754 4.5 17.8333V8.66667C4.5 8.22464 4.67559 7.80072 4.98816 7.48816C5.30072 7.17559 5.72464 7 6.16667 7H11.1667M14.5 4.5H19.5M19.5 4.5V9.5M19.5 4.5L10.3333 13.6667"
      [attr.stroke]="disabledColor ? '#A8A8A8' : color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</a>
