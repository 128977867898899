import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Country } from '../../@core/types/Automations/Country';

@Component({
  selector: 'norby-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.css'],
})
export class AutocompleteSelectComponent {
  @Input() optionValues: BehaviorSubject<Country[] | null> =
    new BehaviorSubject<Country[] | null>(null);
  @Input() optionsLabel = '';
  @Input() label = '';
  @Input() subLabel = '';
  @Input() name = 'This field';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = '';
  @Input() currentValue: string | undefined = '';
  @Output() setOptionValue = new EventEmitter();
  @Input() topPositioning = '78px';
  public searchSelectState = false;
  @ViewChild('autocompleteInput') public autocompleteInput:
    | ElementRef
    | undefined;
  public results$: BehaviorSubject<Country[] | null> = new BehaviorSubject<
    Country[] | null
  >(null);
  formControl: UntypedFormControl = new UntypedFormControl();
  form: UntypedFormGroup = this.fb.group({
    country: ['', Validators.required],
  });

  constructor(
    private ref: ElementRef,
    private fb: UntypedFormBuilder,
  ) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.searchSelectState = false;
    }
  }

  open(): void {
    this.searchSelectState = !this.searchSelectState;
    setTimeout(() => {
      this.autocompleteInput?.nativeElement.focus();
      this.form.reset();
      this.results$.next([]);
    }, 0);
  }

  public setValue(country: Country): void {
    this.currentValue = country.country;
    this.setOptionValue.emit(country);
    this.searchSelectState = false;
    this.form.controls.country.setValue(country.country);
  }

  public searchOnKeyUp(event: KeyboardEvent): void {
    const countryValue = (event.target as HTMLInputElement).value;
    if (this.optionValues.value && countryValue.length) {
      this.results$.next(
        this.searchFromArray(
          this.optionValues.value,
          this.capitalizeFirstLetter(countryValue),
        ),
      );
    } else {
      this.results$.next([]);
    }
  }

  searchFromArray(arr: Country[], regex: string): Country[] {
    const matches: Country[] = [];
    let i;
    for (i = 0; i < arr.length; i++) {
      if (arr[i].country.match(regex)) {
        matches.push(arr[i]);
      }
    }
    return matches;
  }

  public capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}
