import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import Chat from '../../../@core/domain/Chat';

@Component({
  selector: 'norby-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.css'],
})
export class ChatCardComponent {
  @Input() chat: Chat | undefined;
  @Input() selectedId: string | undefined;
  @Output() chatSelected = new EventEmitter();

  select(): void {
    if (this.chat) {
      this.chat.setViewed();
    }
    this.chatSelected.emit(this.chat);
  }

  parseMessage(message: string): string {
    try {
      const parsed = JSON.parse(message);
      return parsed.message || message;
    } catch (e) {
      return message;
    }
  }

  get textMessage(): Observable<string> {
    if (this.chat) {
      return this.chat.lastMessage$.pipe(
        map((lastMessage) => {
          const messageTextMap = {
            text: lastMessage?.message || '',
            image: lastMessage?.message || '',
            message: this.parseMessage(lastMessage?.message || ''),
            product: 'Product',
            product_list: 'Products list',
            button: 'Link button',
            new_chat_button: 'New chat button',
            none: 'Unknown message',
            form: 'Fill the form',
            predefined_answers: 'Choose answer...',
          };

          const temporaryDiv: HTMLDivElement = document.createElement('div');
          temporaryDiv.innerHTML = messageTextMap[lastMessage?.type || 'none'];
          return temporaryDiv.textContent || temporaryDiv.innerText || '';
        }),
      );
    }
    return of('');
  }
}
