import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Language } from '../../@core/types/Automations/Language';

@Component({
  selector: 'norby-select-with-crumbs',
  templateUrl: './select-with-crumbs.component.html',
  styleUrls: ['./select-with-crumbs.component.css'],
})
export class SelectWithCrumbsComponent {
  @Input() userLanguages$: BehaviorSubject<Language[]> = new BehaviorSubject<
    Language[]
  >([]);
  @Input() disabledLanguagesCount$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  @Input() enabledLanguagesCount$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  @Input() optionsLabel = '';
  @Input() label = '';
  @Input() subLabel = '';
  @Input() name = 'This field';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholder = '';
  @Input() currentValue: string | undefined = '';
  @Output() setOptionValue = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Input() topPositioning = '78px';
  public searchSelectState = false;
  public subscription = new Subscription();

  constructor(private ref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.searchSelectState = false;
    }
  }

  toggle(): void {
    if (this.disabledLanguagesCount$.value === 0) {
      return;
    }
    this.searchSelectState = !this.searchSelectState;
  }

  public setValue(e: Language): void {
    this.setOptionValue.emit(e);
    this.searchSelectState = false;
  }

  fireRemove(lang: Language): void {
    this.remove.emit(lang);
  }
}
