<div class="statistics-wrapper">
  <div class="flex">
    <h3>Statistics</h3>
    <norby-datepicker
      (selectDateInterval)="selectDates($event)"
    ></norby-datepicker>
  </div>
  <section class="statistics">
    <section class="numbers">
      <div class="row">
        <norby-statistic-number
          [important]="true"
          [isPercent]="true"
          [statNumber]="totalStats.norbyScore"
          [title]="'Score'"
        >
        </norby-statistic-number>
        <norby-statistic-number
          [statNumber]="totalStats.closedTotal"
          [title]="'Closed by team'"
        >
        </norby-statistic-number>
      </div>
      <div class="row">
        <norby-statistic-number
          [statNumber]="totalStats.totalClosedChatsByBot"
          [title]="'Closed by Bot'"
        >
        </norby-statistic-number>
        <norby-statistic-number
          [statNumber]="totalStats.processedTotal"
          [title]="'Assigned to agents'"
        >
        </norby-statistic-number>
        <norby-statistic-number
          [statNumber]="totalStats.supportAvgFirstAnswer"
          [isTime]="true"
          [title]="'Average time to agents response'"
        >
        </norby-statistic-number>
      </div>
    </section>
  </section>
</div>
