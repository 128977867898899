import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-small-copy-button',
  templateUrl: './small-copy-button.component.html',
  styleUrls: ['./small-copy-button.component.css'],
})
export class SmallCopyButtonComponent {
  @Input() disabled = false;
}
