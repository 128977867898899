import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import Chat from '../../@core/domain/Chat';
import ChatMessageFactory from '../../@core/domain/ChatMessageFactory';
import { Profile } from '../../@core/domain/Profile';
import { AgentsService } from '../../@core/services/agents.service';
import { ChatService } from '../../@core/services/chat.service';
import { ImageCompressionService } from '../../@core/services/image-compression.service';
import { MixpanelService } from '../../@core/services/mixpanel.service';
import { ProfileService } from '../../@core/services/profile.service';
import { SupportSocketService } from '../../@core/services/support-socket.service';
import { ChatListTabs } from '../../@core/types/ChatListTabs';
import ChatMessage from '../../@core/types/ChatMessage';
import { ImageInfo } from '../../@core/types/ImageInfo';
import { ChatSearchStateService } from '../../@core/services/chat-search-state.service';
import { UserSearchStateService } from '../../@core/services/user-search-state.service';
import { UsersService } from '../../@core/services/users.service';

dayjs.extend(calendar);

@Component({
  selector: 'norby-chats-page',
  templateUrl: './chats-page.component.html',
  styleUrls: ['./chats-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatsPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('msg') messagesElements: QueryList<ChatMessage> | undefined;
  chatsMenuOpen = false;
  agents: Observable<Profile[]> = new Observable<Profile[]>();
  managers: Observable<Profile[]> = new Observable<Profile[]>();
  chatListTab$ = new BehaviorSubject<ChatListTabs>('my');
  isNearBottom = true;
  subscriptions: Subscription[] = [];
  selectedChat: Chat | undefined;
  searchEnabled = false;
  profile: Profile | null;
  showChats = true;
  showChatMainContainer = true;
  isUserAttributesOpen = true;
  unreadMessagesCount = 0;

  @ViewChild('scroller', { static: false }) private scroller:
    | ElementRef
    | undefined;

  constructor(
    protected chatService: ChatService,
    private agentsService: AgentsService,
    private mx: MixpanelService,
    private ics: ImageCompressionService,
    private ps: ProfileService,
    private router: Router,
    private supportConnection: SupportSocketService,
    private ss: ChatSearchStateService,
    private us: UsersService,
    private uss: UserSearchStateService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.profile = null;
  }

  trackByKey(_index: number, item: Chat): string {
    return item.id;
  }

  ngOnInit(): void {
    this.agents = this.agentsService.getAgents();
    this.managers = this.agentsService.getManagers();

    if (this.scroller) {
      this.scroller.nativeElement.scrollTop =
        this.scroller.nativeElement.scrollHeight;
    }
    this.subscriptions = [
      this.chatService.selectedChat$.subscribe(
        (chat) => (this.selectedChat = chat),
      ),
      this.chatService.unreadMessagesTotal$.subscribe(
        (total) => (this.unreadMessagesCount = total),
      ),
      this.ps.profile$.subscribe((profile) => {
        if (this.profile && this.profile.shouldChangePassword) {
          this.router.navigateByUrl('set_password');
        }
        this.profile = profile;
      }),
      this.chatService.openChats$.subscribe((chats) => {
        chats.forEach((chat) => this.chatService.addToOpen(chat));
        this.cdRef.markForCheck();
      }),
    ];
    this.chatService.loadFilteredChats('any');

    this.selectedChat = undefined;
  }

  ngAfterViewInit(): void {
    this.messagesElements &&
      this.subscriptions.push(
        this.messagesElements.changes.subscribe(
          () => this.isNearBottom && this.scrollToBottom(),
        ),
      );
  }

  scrolled(): void {
    this.isNearBottom = this.isUserNearBottom();
  }

  // Extracted to separate method for tracking purpose
  assignToMe(chat: Chat): void {
    if (this.profile) {
      const { uid, role } = this.profile;
      this.mx.track('click_assign_to_me', 'chats');
      this.chatService.assignTo(chat, uid, uid, role);
    }
  }

  shouldBeVisible() {
    return (
      this.selectedChat &&
      this.profile &&
      this.selectedChat.socketConnected &&
      this.selectedChat.mine
    );
  }

  assignTo(chat: Chat, agentUid: string): void {
    if (!this.profile) {
      return;
    }
    const { uid, role } = this.profile;
    this.mx.track('clicked_assign_to_somebody', 'chats');
    this.showChatMainContainer = true;
    if (this.chatListTab$.value === 'my') {
      this.chatService.reassign(chat, agentUid, role);
    } else {
      if (agentUid === uid) {
        this.setChatsTab('my');
        this.chatService.removeFromOpen(chat);
        this.chatService.addToMy(chat);
        this.selectedChat = chat;
        this.selectedChat.open = true;
        this.selectChat(chat);
      }
      this.chatService.assignTo(chat, agentUid, uid, role);
    }
  }

  setChatsTab(tab: ChatListTabs): void {
    this.chatListTab$.next(tab);
    this.selectedChat = undefined;
  }

  selectClosed(event: 'closed' | 'bot' | 'open' | 'my'): void {
    this.setChatsTab(event);
    this.toggleChatsMenu();
    this.selectedChat = undefined;
  }

  toggleChatsMenu(): void {
    this.chatsMenuOpen = !this.chatsMenuOpen;
  }

  selectChat(chat: Chat | undefined): void {
    this.chatService.selectChat(chat);
    this.supportConnection.sendViewEvent(chat?.uid);
  }

  showAttributes(): void {
    this.isUserAttributesOpen = !this.isUserAttributesOpen;
  }

  closeChat(chat: Chat): void {
    this.chatService.closeChat(chat, this.chatListTab$.value).subscribe();
  }

  reopenChat(chat: Chat): void {
    this.chatService.reopenChat(chat);
    this.setChatsTab('open');
    this.selectChat(chat);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  send($message: string): void {
    if (this.shouldSend($message) && this.profile) {
      const chatMessage = ChatMessageFactory.agentMessage(
        this.profile,
        $message,
      );
      const confirmCallback = (result: { status: string }) => {
        chatMessage.confirmed?.next(result.status === 'ok');
        if (result.status !== 'ok') {
          this.failMessage(chatMessage, 'Failed to send message');
        }
      };
      this.selectedChat?.addMessage(chatMessage);
      this.selectedChat?.sendMessage(chatMessage, confirmCallback);
    }
  }

  sendEmail($message: string): void {
    if (this.shouldSend($message) && this.profile) {
      const chatMessage = ChatMessageFactory.agentMessage(
        this.profile,
        $message,
      );
      const confirmCallback = (result: { status: string }) => {
        chatMessage.confirmed?.next(result.status === 'ok');
        if (result.status !== 'ok') {
          this.failMessage(chatMessage, 'Failed to send message');
        }
      };
      this.selectedChat?.addMessage(chatMessage);
      this.selectedChat?.sendEmailMessage(chatMessage, confirmCallback);
    }
  }

  async sendImageMessage($file: File, byEmail: boolean) {
    if ($file && this.profile && this.selectedChat) {
      const image = await this.ics.compress($file);
      const imageMessage = ChatMessageFactory.agentImageMessage(
        this.profile,
        image,
      );
      this.selectedChat.addMessage(imageMessage);

      const confirmCallback = (result: {
        status: string;
        message?: { id: string; image: ImageInfo };
      }) => {
        if (result.status === 'ok') {
          if (result.message?.id) {
            imageMessage.id = result.message.id;
          }
          imageMessage.image?.next(
            result.message?.image || { path: '', preview_path: '' },
          );
        } else {
          this.failMessage(imageMessage, 'Error creating message in socket');
        }
      };

      if (byEmail) {
        this.selectedChat?.sendEmailMessage(imageMessage, confirmCallback);
      } else {
        this.selectedChat?.sendMessage(imageMessage, confirmCallback);
      }
    }
  }

  toggleSearch(): void {
    this.searchEnabled = !this.searchEnabled;
    if (this.searchEnabled) {
      this.ss.resetChatSearch();
      this.chatService.resetAndReload();
      this.uss.reset();
      this.us.reset();
    }
  }

  toggleShowChats(): void {
    if (!this.showChats) {
      this.showChats = !this.showChats;
    }
    this.selectedChat = undefined;
  }

  private scrollToBottom(): void {
    this.scroller?.nativeElement?.scroll({
      top: this.scroller?.nativeElement.scrollHeight,
    });
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position =
      this.scroller?.nativeElement?.scrollTop +
      this.scroller?.nativeElement?.offsetHeight;
    const height = this.scroller?.nativeElement.scrollHeight;
    return position > height - threshold;
  }

  private shouldSend(message: string | null): boolean {
    return message !== null && message.length > 0 && !!this.selectedChat;
  }

  private failMessage(message: ChatMessage, error: string): void {
    message.confirmed?.next(false);
    message.errorMessage.next(error);
  }
}
