<norby-navbar />
<section class="wrapper">
  <form class="form" [formGroup]="newPasswordForm">
    <section class="form-contents">
      <h1>Set Your New Password</h1>
      <norby-input
        placeholder="6+ symbols"
        label="Your new password"
        class="margin"
        [name]="'Password'"
        [minLength]="6"
        [eyeIcon]="true"
        [type]="'password'"
        [control]="password"
      />
      <button type="submit" class="main-button" (click)="send()">
        Set New Password
      </button>
    </section>
  </form>
</section>
<div *ngIf="spinnerService.show$ | async" class="loading_modal">
  <norby-spinner [color]="'#FFFFFF'" />
</div>
