<div class="voice-container">
  <svg
    width="358"
    height="127"
    viewBox="0 0 358 127"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M339.979 35C349.644 35 357.479 27.165 357.479 17.5C357.479 7.83502 349.644 0 339.979 0C330.315 0 322.479 7.83502 322.479 17.5C322.479 27.165 330.315 35 339.979 35Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5714 17C6.55714 17 0 23.3046 0 31.0101V87.0507C0 94.7563 6.55714 101.061 14.5714 101.061H252.329C254.029 101.061 255.486 102.345 255.607 103.98L257.671 123.827C257.914 126.629 261.314 127.914 263.621 126.279L292.157 104.447C295.071 102.228 298.593 101.061 302.236 101.061H325.429C333.443 101.061 340 94.7563 340 87.0507V31.0101C340 23.3046 333.443 17 325.429 17H14.5714Z"
      fill="#EFEFEF"
    />
    <path
      d="M94.4977 78.6807H35.5781C31.4864 78.6807 28.2131 75.459 28.2131 71.432C28.2131 67.4049 31.4864 64.1832 35.5781 64.1832H94.4977C98.5894 64.1832 101.863 67.4049 101.863 71.432C102.026 75.459 98.5894 78.6807 94.4977 78.6807Z"
      fill="white"
    />
    <path
      d="M228.54 78.6807H119.539C115.447 78.6807 112.174 75.459 112.174 71.432C112.174 67.4049 115.447 64.1832 119.539 64.1832H228.54C232.632 64.1832 235.905 67.4049 235.905 71.432C235.905 75.459 232.632 78.6807 228.54 78.6807Z"
      fill="white"
    />
    <path
      d="M183.041 52.2643H35.4145C31.3229 52.2643 28.0496 49.0427 28.0496 45.0156C28.0496 40.9886 31.3229 37.7669 35.4145 37.7669H183.041C187.133 37.7669 190.406 40.9886 190.406 45.0156C190.406 49.0427 187.133 52.2643 183.041 52.2643Z"
      fill="white"
    />
    <path
      d="M209.882 52.2643H206.281C202.19 52.2643 198.916 49.0427 198.916 45.0156C198.916 40.9886 202.19 37.7669 206.281 37.7669H209.882C213.973 37.7669 217.247 40.9886 217.247 45.0156C217.247 49.0427 213.973 52.2643 209.882 52.2643Z"
      fill="white"
    />
    <path
      d="M283.204 52.2643H233.614C229.522 52.2643 226.249 49.0427 226.249 45.0156C226.249 40.9886 229.522 37.7669 233.614 37.7669H283.204C287.296 37.7669 290.569 40.9886 290.569 45.0156C290.733 49.0427 287.46 52.2643 283.204 52.2643Z"
      fill="white"
    />
  </svg>
</div>
