<!--    Fist step-->
<form
  *ngIf="currentStep.name === 'firstStep' && currentStep.state"
  [formGroup]="form"
  (ngSubmit)="goToNextStep()"
  class="info-form"
>
  <div class="form-group">
    <label class="form-label" for="email">Email</label>
    <input
      class="form-input"
      formControlName="email"
      id="email"
      placeholder="Your work email..."
    />
    <div
      *ngIf="
        form.controls.email.invalid &&
        (form.controls.email.dirty || form.controls.email.touched)
      "
      class="input-error"
    >
      <div *ngIf="form.controls.email.errors?.required">Email is required.</div>
      <div *ngIf="form.controls.email.errors?.email">Email is not valid.</div>
    </div>
    <div class="input-error" *ngIf="obs.emailError$ | async">
      {{ obs.emailError$ | async }}
    </div>
  </div>
  <div class="form-group">
    <label class="form-label" for="password">Password</label>
    <input
      class="form-input"
      formControlName="password"
      [type]="type"
      id="password"
      minlength="5"
      placeholder="6+ symbols..."
      [autocomplete]="autocomplete"
    />
    <norby-eye-icon
      (click)="togglePasswordVisibility()"
      [isOpen]="crossedEye"
    ></norby-eye-icon>
    <div
      *ngIf="
        form.controls.password.invalid &&
        (form.controls.password.dirty || form.controls.password.touched)
      "
      class="input-error"
    >
      <div *ngIf="form.controls.password.errors?.required">
        Password is required.
      </div>
      <div *ngIf="form.controls.password.errors?.minlength">
        Password must be at least 6 characters long.
      </div>
    </div>
  </div>
  <p class="notice">
    By clicking Continue below, I agree to Norby’s
    <a
      target="_blank"
      (click)="sendMixPanelEvent('click_terms', 'signup')"
      href="https://norby.io/privacy-cookies"
      ><strong><u>Terms of Service</u></strong></a
    >
    and
    <a
      target="_blank"
      (click)="sendMixPanelEvent('click_privacy', 'signup')"
      href="https://norby.io/privacy-cookies"
      ><strong><u>Privacy Policy</u></strong></a
    >.
  </p>
  <button
    [disabled]="form.invalid || (this.obs.emailError$ | async)"
    class="main-button"
  >
    Continue
  </button>
</form>
