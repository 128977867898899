<div class="product-list-total">
  <div #contentBox norbyClickOutside (clickOutside)="closeBox()">
    <form
      [formGroup]="productForm"
      (change)="saveProducts()"
      class="products-box"
    >
      <ng-container formArrayName="product_list">
        <div
          *ngFor="
            let product of formArrayProductsInformation.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <article class="product-card">
            <figure class="image">
              <img
                *ngIf="product.get('img')?.value"
                class="product-image"
                [src]="product.get('img')?.value"
                alt="product-card"
              />
              <div
                #imageBox
                class="edit-box img-url"
                *ngIf="editImageBox && index === i"
              >
                <input
                  norbyAutoFocus
                  formControlName="img"
                  class="edit-input"
                  type="text"
                  placeholder="Image URL …"
                />
              </div>
              <label
                [class.is-image]="product.get('img')?.value"
                (click)="editImageLink(i)"
                class="img-label"
              >
                <norby-picture-icon
                  [color]="
                    !product.get('img')?.value ? 'var(--gray76)' : 'white'
                  "
                ></norby-picture-icon>
                <div *ngIf="!product.get('img')?.value" class="required-flex">
                  <p class="image-notice">Add an image … *</p>
                </div>
                <p
                  *ngIf="product.get('img')?.value"
                  class="image-notice change"
                >
                  Change the image …
                </p>
              </label>
              <input
                norbyAutoWidthResize
                formControlName="price"
                class="priceNormal"
                type="text"
                placeholder="Edit the price …"
              />
            </figure>
            <section class="card-content">
              <div class="required-flex">
                <input
                  formControlName="title"
                  class="input-name input-box button-title"
                  placeholder="Edit the title …"
                  type="text"
                />
                <small
                  *ngIf="!product.get('title')?.value"
                  class="required-asterisk"
                  >*</small
                >
              </div>
              <textarea
                formControlName="description"
                class="input-description input-box textarea-box"
                placeholder="Edit the description …"
              ></textarea>
              <div formGroupName="button">
                <button
                  type="button"
                  class="main-button"
                  (click)="editButtonLink(i)"
                >
                  <input
                    maxlength="25"
                    formControlName="name"
                    class="button-name"
                    placeholder="Edit the button … *"
                    type="text"
                  />
                </button>
                <div class="edit-box" *ngIf="editLinkBox && index === i">
                  <div class="required-flex">
                    <input
                      formControlName="link"
                      class="edit-input"
                      type="text"
                      placeholder="The button link …"
                    />
                    <small
                      *ngIf="!product.get('button.link')?.value"
                      class="required-asterisk"
                      >*</small
                    >
                  </div>
                </div>
              </div>
            </section>
            <norby-cancel-icon
              [visible]="true"
              [right]="'-12px'"
              [top]="'-12px'"
              (remove)="removeProduct(i)"
              [item]="'Product'"
            >
            </norby-cancel-icon>
          </article>
          <norby-modal
            *ngIf="ss.savingWidget && !productForm.valid"
            (closeModal)="closeModalWindow()"
            ><div>
              <h4 class="modal-title">
                At least one of required fields should be filled.
              </h4>
              <div class="modal-content">
                <p
                  *ngIf="
                    !product.get('img')?.value &&
                    !product.get('title')?.value &&
                    !product.get('button.name')?.value &&
                    !product.get('button.link')?.value
                  "
                >
                  Fill, please the "Add an image …" or "Edit the title …" or
                  "Edit the button …" (with "The button link …") fields.
                </p>
                <p
                  *ngIf="
                    (!product.get('button.name')?.value &&
                      product.get('button.link')?.value) ||
                    (product.get('button.name')?.value &&
                      !product.get('button.link')?.value)
                  "
                >
                  The "Edit the button …" and "The button link …" fields should
                  be filled together. Hit the button again and fill the button's
                  link field out.
                </p>
              </div>
              <button class="main-button" (click)="closeModalWindow()">
                Got it!
              </button>
            </div></norby-modal
          >
        </div>
      </ng-container>
      <div
        *ngIf="
          widgetType === 'product_list' ||
          (widgetDetails?.type === 'product_list' &&
            formArrayProductsInformation.length < 51)
        "
        class="add-remove"
      >
        <norby-add-new-button
          [bgColor]="'var(--grayFA)'"
          [hoverColor]="'var(--grayF1)'"
          [plusColor]="'var(--nrb-ob-color)'"
          (click)="addProductForm()"
        >
        </norby-add-new-button>
        <norby-remove-all-bin
          *ngIf="formArrayProductsInformation.controls.length > 1"
          (remove)="removeAll()"
          [showBin]="true"
          [items]="'Products'"
        ></norby-remove-all-bin>
      </div>
    </form>
  </div>
</div>
