import { Component } from '@angular/core';
@Component({
  selector: 'norby-send-icon',
  template: `<svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="var(--nrb-ob-color)" />
    <path
      d="M25 20H14M25 20L22.5 23M25 20L22.5 17"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
  styles: [
    `
      svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    `,
  ],
})
export class SendIconComponent {}
