<svg
  fill="none"
  stroke="var(--gray76)"
  stroke-width="2"
  height="24"
  viewBox="0 0 24 24"
  width="24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    *ngIf="!isOpen"
    d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    *ngIf="!isOpen"
    d="M3 12C5.36586 14.7393 8.31328 17 12 17C15.6867 17 18.6341 14.7393 21 12"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    *ngIf="!isOpen"
    d="M21 12C18.6341 9.53466 15.6867 7 12 7C8.31328 7 5.36586 9.53466 3 12"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    *ngIf="isOpen"
    d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    *ngIf="isOpen"
    d="M3 12C5.36586 14.7393 8.31328 17 12 17C15.6867 17 18.6341 14.7393 21 12"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    *ngIf="isOpen"
    d="M21 12C18.6341 9.53466 15.6867 7 12 7C8.31328 7 5.36586 9.53466 3 12"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    *ngIf="isOpen"
    d="M3 6L21 18"
    stroke-linecap="round"
    stroke-width="1.5"
  />
</svg>
