import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersService } from '../../@core/services/users.service';
import { UserDetails } from '../../@core/types/UserDetails';

@Component({
  selector: 'norby-user-search-page',
  templateUrl: './user-search-page.component.html',
  styleUrls: ['./user-search-page.component.css'],
})
export class UserSearchPageComponent {
  constructor(private us: UsersService) {}

  get users$(): Observable<UserDetails[]> {
    return this.us.users$;
  }

  get loading$(): Observable<boolean> {
    return this.us.usersLoading$.asObservable();
  }

  onScroll(): void {
    this.us.loadFilteredUsers();
  }
}
