<norby-slug-wrapper
  [title]="'Automation Name'"
  [mark]="(profileService.isLegacyUser$ | async) ? 'Required to continue' : ''"
  [isNew]="slug && slug.isNew"
>
  <form class="field-form">
    <input
      id="slugNameInput"
      class="field-input"
      type="text"
      [placeholder]="
        (profileService.isLegacyUser$ | async)
          ? 'Enter here...'
          : 'Generated automatically'
      "
      [formControl]="name"
    />
    <div class="input-error">
      <div *ngIf="(ss.error$ | async)?.has('slug.slug')">
        {{ (ss.error$ | async)?.get("slug.slug") }}
      </div>
    </div>
  </form>
</norby-slug-wrapper>
