import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Slug } from '../../../../@core/domain/Slug';
import { AnswersService } from '../../../../@core/services/automations/answers.service';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';
import { Answer, Answers } from '../../../../@core/types/Automations/Answer';
import { modules } from '../../../../pages/automations-page/slug-window/modules';
import { Trigger } from '../../../../@core/types/Automations/TriggerTypes';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'norby-slug-answer',
  templateUrl: './slug-answer.component.html',
  styleUrls: ['./slug-answer.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SlugAnswerComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() selectedSlugTrigger$: BehaviorSubject<Trigger | null> | undefined =
    new BehaviorSubject<Trigger | null>(null);
  @Input() selectedSlug: Slug | null | undefined = undefined;
  @Input() answers: Answer[] = [];
  @Input() answersState: Answers | null = null;

  @Output() answersValues = new EventEmitter();
  @Output() removeAnswer = new EventEmitter();

  @ViewChildren(QuillEditorComponent) quills:
    | QueryList<QuillEditorComponent>
    | undefined;

  answersForm: UntypedFormGroup;
  subscription = new Subscription();

  readonly quillModules = modules;

  constructor(
    private fb: UntypedFormBuilder,
    private as: AnswersService,
    protected ss: SlugsService,
  ) {
    this.answersForm = this.fb.group({
      answers: this.fb.array([], Validators.required),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.answers) {
      if (changes.answers.currentValue) {
        this.setInitialControls(this.answers);
      }
    }
    this.setFocusOnQuill();
  }

  ngOnInit(): void {
    if (this.answers?.length === 0) {
      this.addEditor();
    }
    if (this.answersState) {
      this.as.answersState$.next(this.answersState);
    }
    this.setFocusOnQuill();
  }

  ngAfterViewInit() {
    this.setFocusOnQuill();
  }

  setFocusOnQuill(): void {
    this.quills?.changes.subscribe(() => {
      this.focusLastEditor();
    });
    this.focusLastEditor();
  }

  focusLastEditor(): void {
    if (this.quills && this.quills.last) {
      this.quills.last.onEditorCreated.subscribe(() => {
        const editor = this.quills?.last.quillEditor;
        if (editor) {
          editor.focus();
          const length = editor.getLength();
          editor.setSelection(length, length);
        }
      });
    }
  }

  get editorsFormArray(): UntypedFormArray {
    return this.answersForm.controls.answers as UntypedFormArray;
  }

  addEditor(): void {
    this.editorsFormArray.push(
      this.fb.group({
        text: ['', Validators.required],
      }),
    );
  }

  setInitialControls(answers: Answer[] | null): void {
    if (answers) {
      const answersControls: UntypedFormGroup[] = [];
      answers.forEach((answer) => {
        answersControls.push(
          this.fb.group({
            text: answer.text,
            language_id: answer.language_id,
            id: answer.id,
            slug_id: answer.slug_id,
          }),
        );
      });
      this.editorsFormArray.controls = answersControls;
    }
  }

  removeEditor(i: number): void {
    if (i >= 0 && i < this.editorsFormArray.length) {
      this.editorsFormArray.removeAt(i);
      this.answersValues.emit(this.answersForm.getRawValue());
    }
  }

  getContentValue(): void {
    if (this.selectedSlug) {
      this.selectedSlug.setUnsavedChanges(true);
    }
    const { answers } = this.answersForm.getRawValue();
    this.as.changeAnswers(answers);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
