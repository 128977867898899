import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BillingStatus } from '../../types/Automations/BillingStatus';
import { Country } from '../../types/Automations/Country';
import { Invoice } from '../../types/Automations/Invoice';
import { Limits } from '../../types/Automations/Limits';
import { PromoCode } from '../../types/Automations/PromoCode';
import { BillingAddress } from '../../types/BillingAddress';
import { BillingAddressResponse } from '../../types/BillingAddressResponse';
import { PaymentApiService } from '../api/payment-api.service';
import { GetSlugToken } from '../auth.service';
import { CompanyDetails } from '../../types/CompanyDetails';
import { CompanyDetailsResponse } from '../../types/CompanyDetailsResponse';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public paymentStatuses$: BehaviorSubject<BillingStatus> =
    new BehaviorSubject<BillingStatus>(null);
  public countries$: BehaviorSubject<Country[] | null> = new BehaviorSubject<
    Country[] | null
  >(null);
  public countriesWithStates$: BehaviorSubject<Country[] | null> =
    new BehaviorSubject<Country[] | null>(null);
  public updateLink$ = new BehaviorSubject<string>('');

  constructor(private pas: PaymentApiService) {
    this.initPaymentStatuses();
  }

  initPaymentStatuses(): void {
    if (GetSlugToken()) {
      this.getSlugStatuses().subscribe((res) => {
        this.paymentStatuses$.next(res);
      });
      this.getCountries().subscribe((countries) => {
        this.countries$.next(countries);
        const countriesWithStates = countries.filter((s) => s.has_state);
        this.countriesWithStates$.next(countriesWithStates);
      });
      this.pas
        .getBillingInfoLink()
        .subscribe((res) => this.updateLink$.next(res));
    }
  }

  readonly hasNotIncompleteAndEndedPayments = this.paymentStatuses$.pipe(
    map((ps) => !ps?.incomplete_payment && !ps?.ended),
  );
  readonly hasIncompleteAndEndedPayments = this.paymentStatuses$.pipe(
    map((ps) => !!ps?.incomplete_payment && !!ps?.ended),
  );
  readonly hasNotPaymentEnded = this.paymentStatuses$.pipe(
    map((ps) => !ps?.ended),
  );
  readonly hasCompletePayment = this.paymentStatuses$.pipe(
    map((ps) => !ps?.incomplete_payment && !!ps?.ended),
  );

  getCountries(): Observable<Country[]> {
    return this.pas.getCountries();
  }

  setReminder(): Observable<boolean> {
    return this.pas.setReminderStatus();
  }

  getUserLimits(): Observable<Limits> {
    return this.pas.getLimits();
  }

  getBillingPortal(): Observable<string> {
    return this.pas.getBillingPortal();
  }

  getSlugStatuses(): Observable<BillingStatus> {
    return this.pas.getSubscriptionStatuses();
  }

  getInvoices(): Observable<Invoice[]> {
    return this.pas.getInvoices();
  }

  setPromoCode(code: { code: string }): Observable<{ code: string }> {
    return this.pas.setPromoCode(code);
  }

  getPromoCode(): Observable<PromoCode> {
    return this.pas.getPromoCode();
  }

  getUserAddress(): Observable<BillingAddressResponse> {
    return this.pas.getAddress();
  }

  setAddress(body: BillingAddress): void {
    const data = {
      city: body.city,
      state_id: body.state?.id ? String(body.state?.id) : '',
      country_id: String(body.country.id),
      line1: body.address,
      postal_code: body.code,
    };
    this.pas.setAddress(data).subscribe();
  }

  setCompanyDetails(body: CompanyDetails): void {
    const data = {
      reg_number: body.reg_number ? body.reg_number : '',
      vat_number: body.vat_number ? body.vat_number : '',
    };
    this.pas.setCompanyDetails(data).subscribe();
  }

  getCompanyDetails(): Observable<CompanyDetailsResponse> {
    return this.pas.getCompanyDetails();
  }
}
