import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from './@core/services/auth.service';
import { UploadWidgetService } from './@core/services/automations/upload-widget.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'norby-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  merchantApiKey: string | null = null;
  private widgetInitialized: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private authService: AuthService,
    private uploadWidgetService: UploadWidgetService,
  ) {}

  ngOnInit(): void {
    localStorage.debug = '-'; // Temporary removed for speed testing issues
    this.authService.isAuthenticated
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.merchantApiKey = localStorage.getItem('ma');
          if (!this.widgetInitialized) {
            this.uploadWidgetService.initializeChatWidget(this.merchantApiKey);
            this.widgetInitialized = true;
          }
        }
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const isAuthenticated = this.authService.isAuthenticated.value;
        if (!isAuthenticated && event.url.includes('/signup')) {
          // Allow navigation to signup page if not authenticated
        } else if (isAuthenticated && event.url.includes('/signup')) {
          // Logout and redirect to signup page if authenticated
          this.authService.logoutAndRedirectToSignUp();
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
