import { Component, Input } from '@angular/core';
import { Profile } from '../../@core/domain/Profile';

@Component({
  selector: 'norby-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.css'],
})
export class AgentCardComponent {
  @Input() agent: Profile | undefined;
}
