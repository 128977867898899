import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Locale } from '../../../../@core/domain/Locale';
import { Slug } from '../../../../@core/domain/Slug';
import { AnswersService } from '../../../../@core/services/automations/answers.service';
import { UserLanguagesService } from '../../../../@core/services/user-languages.service';
import { Language } from '../../../../@core/types/Automations/Language';

@Component({
  selector: 'norby-langs-tab',
  templateUrl: './langs-tab.component.html',
  styleUrls: ['./langs-tab.component.css'],
})
export class LangsTabComponent implements OnInit {
  @Output() selectedLang = new EventEmitter();
  @Input() selectedSlug: Slug | null | undefined = undefined;
  public langs$ = new BehaviorSubject<Language[]>([]);
  public activeLink$ = this.uls.languagesTabs$;
  isOpenMark = false;

  constructor(
    public uls: UserLanguagesService,
    private ass: AnswersService,
  ) {
    this.langs$ = this.uls.userLanguages$;
  }

  ngOnInit(): void {
    this.uls.languagesTabs$.next(
      this.uls.getActiveFullLanguage(this.langs$.value),
    );
  }

  selectCheckedLanguageByShortName(
    key: Locale,
    slug: Slug | null | undefined,
  ): void {
    const lang = this.langs$.value.find((el) => el.short_name === key);
    if (lang) {
      this.changeLangTab(lang, slug);
    }
  }

  changeLangTab(lang: Language, slug: Slug | null | undefined): void {
    if (lang.active === 0) {
      this.isOpenMark = true;
      setTimeout(() => (this.isOpenMark = false), 3000);
      return;
    }
    this.uls.languagesTabs$.next(lang);
    this.selectedLang.emit(lang);
    if (slug?.answers) {
      slug.updateAnswersState(
        this.ass.answersState$.value,
        this.uls.languageId$.value,
      );
    }
  }

  enabledLanguage(language: Language): void {
    this.isOpenMark = false;
    this.uls.updateLanguage(language, 1);
  }
}
