<button class="union-icon-wrapper" (click)="open($event)">
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="inner-svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.75 1C10.75 0.585786 10.4142 0.25 10 0.25C9.58579 0.25 9.25 0.585786 9.25 1V2C9.25 2.41421 9.58579 2.75 10 2.75C10.4142 2.75 10.75 2.41421 10.75 2V1ZM10.75 8C10.75 7.58579 10.4142 7.25 10 7.25C9.58579 7.25 9.25 7.58579 9.25 8V17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17V8ZM7.25 5C7.25 4.58579 7.58579 4.25 8 4.25H12C12.4142 4.25 12.75 4.58579 12.75 5C12.75 5.41421 12.4142 5.75 12 5.75H8C7.58579 5.75 7.25 5.41421 7.25 5ZM1 12.25C0.585786 12.25 0.25 12.5858 0.25 13C0.25 13.4142 0.585786 13.75 1 13.75H5C5.41421 13.75 5.75 13.4142 5.75 13C5.75 12.5858 5.41421 12.25 5 12.25H1ZM15 12.25C14.5858 12.25 14.25 12.5858 14.25 13C14.25 13.4142 14.5858 13.75 15 13.75H19C19.4142 13.75 19.75 13.4142 19.75 13C19.75 12.5858 19.4142 12.25 19 12.25H15ZM3 0.25C3.41421 0.25 3.75 0.585786 3.75 1V10C3.75 10.4142 3.41421 10.75 3 10.75C2.58579 10.75 2.25 10.4142 2.25 10V1C2.25 0.585786 2.58579 0.25 3 0.25ZM3 15.25C3.41421 15.25 3.75 15.5858 3.75 16V17C3.75 17.4142 3.41421 17.75 3 17.75C2.58579 17.75 2.25 17.4142 2.25 17V16C2.25 15.5858 2.58579 15.25 3 15.25ZM17.75 1C17.75 0.585786 17.4142 0.25 17 0.25C16.5858 0.25 16.25 0.585786 16.25 1V10C16.25 10.4142 16.5858 10.75 17 10.75C17.4142 10.75 17.75 10.4142 17.75 10V1ZM17.75 16C17.75 15.5858 17.4142 15.25 17 15.25C16.5858 15.25 16.25 15.5858 16.25 16V17C16.25 17.4142 16.5858 17.75 17 17.75C17.4142 17.75 17.75 17.4142 17.75 17V16Z"
      fill="black"
    />
  </svg>
  <span *ngIf="isDotVisible" class="union-icon-dot"></span>
</button>
