<form class="chooser-form-container">
  <h2>Your status</h2>
  <div (click)="toggleAwayStatus('online')" class="chooser-option" id="online">
    <label class="status-label" for="status-online">Online</label>
    <input
      (input)="toggleAwayStatus('online')"
      [checked]="status === 'online'"
      id="status-online"
      name="away"
      type="radio"
    />
    <norby-radio-button
      [checked]="status === 'online'"
      [cursorPointer]="true"
      [color]="hasHoverOnline ? 'white' : 'var(--grayF1)'"
    ></norby-radio-button>
  </div>
  <div (click)="toggleAwayStatus('away')" class="chooser-option" id="away">
    <label class="status-label" for="status-away">Away</label>
    <input
      (input)="toggleAwayStatus('away')"
      [checked]="status === 'away'"
      id="status-away"
      name="away"
      type="radio"
    />
    <norby-radio-button
      [checked]="status === 'away'"
      [cursorPointer]="true"
      [color]="hasHoverAway ? 'white' : 'var(--grayF1)'"
    ></norby-radio-button>
  </div>
</form>
