import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'norby-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css'],
})
export class LogoComponent {
  @Output() logoAction = new EventEmitter();
}
