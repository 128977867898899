<form (submit)="submit($event)">
  <h3>Profile</h3>
  <h4>Your Name (visible for teammates and in chats)</h4>
  <div class="margin-bottom">
    <norby-input
      label="Your first name"
      placeholder="Arlene"
      [control]="getControl('firstName')"
    ></norby-input>
  </div>
  <norby-input
    label="Your last name"
    placeholder="McCoy"
    [control]="getControl('lastName')"
  ></norby-input>
  <h4>Profile Photo</h4>
  <div class="img-row">
    <input type="file" hidden #photoInput (change)="onFileSelect($event)" />
    <norby-centered-image
      [url]="imgUrl || ''"
      [size]="80"
    ></norby-centered-image>
    <norby-accent-button
      [title]="'Choose New Photo'"
      (click)="photoInput.click()"
    ></norby-accent-button>
  </div>
  <h4>Email</h4>
  <norby-input
    id="email-input"
    label="Your work email"
    type="email"
    [control]="getControl('email')"
  >
  </norby-input>
  <h4>Change Password</h4>
  <div class="margin-bottom">
    <norby-input
      label="Your new password"
      [eyeIcon]="true"
      type="password"
      placeholder="6+ symbols"
      [control]="getControl('newPassword')"
    ></norby-input>
  </div>
  <div class="margin-bottom-24">
    <norby-input
      label="Confirm new password"
      [eyeIcon]="true"
      type="password"
      placeholder="6+ symbols"
      [formError]="passwordsNotMatchError"
      [control]="getControl('confirmPassword')"
    ></norby-input>
  </div>
  <h4>Email Notifications</h4>
  <div class="margin-bottom-16">
    <norby-switcher
      [switchedOn]="getControl('emailNotification').value"
      [enabled]="true"
      label="Email notifications about new messages when offline"
      (toggle)="setEmailNotification($event, 'emailNotification')"
    ></norby-switcher>
  </div>
  <div class="margin-bottom-16">
    <norby-switcher
      [switchedOn]="getControl('assignNotification').value"
      [enabled]="true"
      label="New chat was assigned to you when offline"
      (toggle)="setEmailNotification($event, 'assignNotification')"
    ></norby-switcher>
  </div>
  <norby-accent-button
    [title]="'Save'"
    [type]="'submit'"
    [isLoading]="updatingData"
  ></norby-accent-button>
</form>
<norby-modal *ngIf="modalOpen" (closeModal)="closeModal()">
  <h3>Saved Just Now</h3>
  <div class="modal-settings-page">You have updated your profile settings.</div>
  <button class="main-button" (click)="closeModal()">Got It</button>
</norby-modal>
