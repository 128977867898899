<ng-container *ngIf="ss.selectedSlug$ | async as selectedSlug">
  <norby-slug-name
    [slug]="selectedSlug"
    [disabled]="
      !!selectedSlug.approved || !(this.profileService.isLegacyUser$ | async)
    "
  ></norby-slug-name>
  <div class="textarea-wrapper">
    <norby-slug-textarea [selectedSlug]="selectedSlug" />
  </div>
  <ng-container *ngIf="(profileService.isLegacyUser$ | async) === false">
    <div class="down-arrow">
      <norby-down-arrow-button></norby-down-arrow-button>
    </div>
    <norby-automation-widgets
      [selectedSlug]="selectedSlug"
    ></norby-automation-widgets>
  </ng-container>
</ng-container>
