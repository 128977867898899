import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductDetails } from '../../../@core/types/ProductDetails';

@Component({
  selector: 'norby-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() productsJSON: string | undefined;
  @ViewChild('productList') productList:
    | {
        nativeElement: {
          scrollLeft: number;
          scrollTo: (arg0: { left: number }) => void;
          addEventListener: (arg0: string, arg1: () => void) => void;
          removeAllListeners: () => void;
          scrollWidth: number;
          clientWidth: number;
        };
      }
    | undefined;
  scrollPosition = new BehaviorSubject(0);
  leftVisible = new BehaviorSubject(false);
  rightVisible = new BehaviorSubject(true);

  get products(): string[] {
    const parsedData = JSON.parse(this.productsJSON || '[]');
    if (parsedData.product_list) {
      return parsedData.product_list.map((product: ProductDetails) =>
        JSON.stringify(product),
      );
    }
    return JSON.parse(this.productsJSON || '[]').map(
      (product: ProductDetails) => JSON.stringify(product),
    );
  }

  ngOnInit(): void {
    this.scrollPosition.next(this.productList?.nativeElement.scrollLeft || 0);
    this.productList?.nativeElement.scrollTo({
      left: this.scrollPosition.value,
    });
  }

  ngAfterViewInit(): void {
    this.productList?.nativeElement.addEventListener('scroll', () => {
      const scrollLeft = this.productList?.nativeElement.scrollLeft || 0;
      this.setVisibility(scrollLeft);
    });
    this.setVisibility(this.productList?.nativeElement.scrollLeft || 0);
  }

  ngOnDestroy(): void {
    this.productList?.nativeElement.removeAllListeners();
  }

  onScroll(scrollPos: number): void {
    this.setVisibility(scrollPos);
  }

  private setVisibility(scrollPos: number): void {
    this.leftVisible.next(scrollPos > 0);
    this.productList &&
      this.rightVisible.next(
        scrollPos <
          this.productList.nativeElement.scrollWidth -
            this.productList.nativeElement.clientWidth,
      );
  }
}
