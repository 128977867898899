import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PopupState } from '../../types/PopupSteps';

@Injectable({
  providedIn: 'root',
})
export class GuideService {
  currentPopupStep$: BehaviorSubject<PopupState | null> =
    new BehaviorSubject<PopupState | null>({
      step: 'first',
      isFeature: false,
      isCompleted: false,
      isClickable: false,
    });
  widgetCode$ = new BehaviorSubject<string>('');
  isHiddenNotification$ = new BehaviorSubject<boolean>(false);
  isOpenPopup$ = new BehaviorSubject<boolean>(false);
  firstStep$ = new BehaviorSubject<PopupState>({
    step: 'first',
    isFeature: false,
    isCompleted: false,
    isClickable: false,
  });
  secondStep$ = new BehaviorSubject<PopupState>({
    step: 'second',
    isFeature: true,
    isCompleted: false,
    isClickable: false,
  });
  thirdStep$ = new BehaviorSubject<PopupState>({
    step: 'third',
    isFeature: true,
    isCompleted: false,
    isClickable: false,
  });

  goToSecondStep(): void {
    this.isHiddenNotification$.next(true);
    this.currentPopupStep$.next(this.secondStep$.value);
    this.firstStep$.next({ ...this.firstStep$.value, isCompleted: true });
    this.secondStep$.next({ ...this.secondStep$.value, isFeature: false });
    navigator.clipboard.writeText(this.widgetCode$.value);
    setTimeout(() => this.isHiddenNotification$.next(false), 3000);
  }

  goToThirdStep(): void {
    this.currentPopupStep$.next(this.thirdStep$.value);
    this.secondStep$.next({ ...this.secondStep$.value, isCompleted: true });
    this.thirdStep$.next({ ...this.thirdStep$.value, isFeature: false });
  }

  completeSetUp(): void {
    this.firstStep$.next({
      step: 'first',
      isCompleted: true,
      isFeature: false,
      isClickable: true,
    });
    this.secondStep$.next({
      step: 'second',
      isCompleted: true,
      isFeature: false,
      isClickable: true,
    });
    this.thirdStep$.next({
      step: 'third',
      isCompleted: true,
      isFeature: false,
      isClickable: true,
    });
    this.currentPopupStep$.next(null);
  }

  openFirstStep(): void {
    this.currentPopupStep$.next(this.firstStep$.value);
  }

  openSecondStep(): void {
    this.currentPopupStep$.next(this.secondStep$.value);
  }

  openThirdStep(): void {
    this.currentPopupStep$.next(this.thirdStep$.value);
  }
}
