import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-slug-wrapper',
  templateUrl: './slug-wrapper.component.html',
  styleUrls: ['./slug-wrapper.component.css'],
})
export class SlugWrapperComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() trigger: null | string = null;
  @Input() mark = '';
  @Input() isNew: boolean | undefined;
}
