import { Component } from '@angular/core';
import { ProfileService } from '../../../../../@core/services/profile.service';
import { Answer } from '../../../../../@core/types/Automations/Answer';
import { AnswersService } from '../../../../../@core/services/automations/answers.service';
import { PaymentService } from '../../../../../@core/services/automations/payment.service';
import { QuestionsService } from '../../../../../@core/services/automations/questions.service';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { WidgetService } from '../../../../../@core/services/automations/widget.service';
import { UserLanguagesService } from './../../../../../@core/services/user-languages.service';

@Component({
  selector: 'norby-manual-slug',
  templateUrl: './manual-slug.component.html',
  styleUrls: ['./manual-slug.component.css'],
})
export class ManualSlugComponent {
  constructor(
    protected uls: UserLanguagesService,
    protected ss: SlugsService,
    protected sqs: QuestionsService,
    protected sas: AnswersService,
    protected ws: WidgetService,
    protected ps: PaymentService,
    protected profileService: ProfileService,
  ) {}

  public changeAnswers(multiAnswers: { answers: Answer[] }): void {
    const { answers } = multiAnswers;
    const answersArray = this.setAnswersToArray(answers);
    this.sas.changeAnswers(answersArray);
  }

  private setAnswersToArray(answers: Answer[]): Answer[] {
    const checkedAnswers = [];
    for (const answer of answers) {
      if (answer.text) {
        checkedAnswers.push(answer);
      }
    }
    return checkedAnswers;
  }
}
