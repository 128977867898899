import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChatService } from '../../../@core/services/chat.service';
import { ChatListTabs } from '../../../@core/types/ChatListTabs';

@Component({
  selector: 'norby-update-button',
  templateUrl: './update-button.component.html',
  styleUrls: ['./update-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateButtonComponent {
  @Input() tab: ChatListTabs | null = 'my';
  @Input() loading: boolean | null = false;

  constructor(private cs: ChatService) {}

  loadNext(): void {
    this.cs.loadFilteredChats(this.tab || 'my');
  }
}
