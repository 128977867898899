import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

type ValidationErrors = { [key: string]: boolean };

export function requiredFieldsValidator(...fields: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;

    const atLeastOneFilled = fields.some((field) => {
      const control = formGroup.get(field);
      return control && control.value && control.value.trim() !== '';
    });

    return atLeastOneFilled ? null : { atLeastOneRequired: true };
  };
}
