import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OnlineStatus, Profile } from '../../@core/domain/Profile';
import { AuthService } from '../../@core/services/auth.service';

@Component({
  selector: 'norby-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
})
export class AvatarComponent implements OnInit, AfterContentChecked {
  @Input() profile: Profile | undefined;
  @Output() tabChange = new EventEmitter();
  @Output() toggleOnlineStatusEvent = new EventEmitter<OnlineStatus>();

  storageOnlineStatus = localStorage.getItem('onlineStatus') as OnlineStatus;

  constructor(private auth: AuthService) {}

  ngOnInit() {
    if (this.profile && this.storageOnlineStatus) {
      this.profile.status = JSON.parse(this.storageOnlineStatus);
    }
  }

  ngAfterContentChecked() {
    const lsOnlineStatus = localStorage.getItem('onlineStatus') as OnlineStatus;

    if (this.auth.isAuthenticated.value && this.profile) {
      if (!lsOnlineStatus || this.profile.status !== 'away') {
        this.profile.status = 'online';
        localStorage.setItem('onlineStatus', JSON.stringify('online'));
      }

      if (this.profile.status === 'away') {
        localStorage.setItem('onlineStatus', JSON.stringify('away'));
      }
    }
  }
}
