<!-- eslint-disable max-len -->
<div class="creation-container">
  <div class="creation-header">
    <h1 class="header-title">Choose an Automation Type</h1>
    <div class="flex">
      <norby-question-circle
        [link]="
          'https://norby-io.notion.site/Automatizations-Overview-and-How-to-Set-Up-7f5726c63ba34f388bd3aa910a0310c5'
        "
      ></norby-question-circle>
      <norby-close-icon
        (click)="ss.slugCreationStep$.next('closed')"
        class="cursor-pointer"
      ></norby-close-icon>
    </div>
  </div>
  <div class="creation-content">
    <ng-container *ngFor="let card of slugsTypeData">
      <div
        (click)="actualCardForCreatingSlug = card.title"
        [class.active]="actualCardForCreatingSlug === card.title"
        class="creation-box"
      >
        <div class="creation-box-header">
          <h3 class="creation-box-title">{{ card.title }}</h3>
          <ng-container *ngIf="freeLeftSlugs; else spinner">
            <span
              *ngIf="
                freeLeftSlugs.free_automatic_slugs && card.title === 'Automatic'
              "
              class="creation-box-notice"
              >{{ freeLeftSlugs.free_automatic_slugs }} free left</span
            >
            <span
              *ngIf="freeLeftSlugs.free_manual_slugs && card.title === 'Manual'"
              class="creation-box-notice"
              >{{ freeLeftSlugs.free_manual_slugs }} free left</span
            >
          </ng-container>
          <ng-template #spinner>
            <norby-spinner [height]="20" [width]="20"></norby-spinner>
          </ng-template>
        </div>
        <p class="creation-box-subtitle">{{ card.subtitle }}</p>
        <ul class="creation-box-list">
          <ng-container *ngFor="let option of card.options">
            <li class="creation-box-item">
              <norby-radio-button
                *ngIf="option.state === 'enabled'"
                [color]="'var(--nrb-secondary-color)'"
                [cursorPointer]="false"
              ></norby-radio-button>
              <norby-search-reset
                *ngIf="option.state === 'disabled'"
                [color]="'var(--gray76)'"
              ></norby-search-reset>
              <p
                [class.disabled-option]="option.state === 'disabled'"
                class="creation-option"
              >
                {{ option.text }}
              </p>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </div>
  <button (click)="selectSlugType()" class="main-button-full-width">
    Choose {{ actualCardForCreatingSlug }}
  </button>
</div>
