import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-bin',
  templateUrl: './bin.component.html',
  styleUrls: ['./bin.component.css'],
})
export class BinComponent {
  @Output() remove = new EventEmitter<void>();
  @Input() showBin = false;
  @Input() disabled: boolean | undefined = false;
  @Input() color = 'var(--gray76)';
  @Input() stroke = this.disabled ? 'var(--gray76)' : 'var(--nrb-ob-color)';
  @Input() item = 'Item';

  showModal = false;

  fireRemove($event: MouseEvent): void {
    $event.stopPropagation();
    this.showModal = true;
  }

  confirmRemove(): void {
    this.remove.emit();
    this.showModal = false;
  }

  cancelRemove(): void {
    this.showModal = false;
  }
}
