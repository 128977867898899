import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import Chat from '../domain/Chat';
import { ChatsFactory } from '../domain/ChatsFactory';
import { Role } from '../domain/Profile';
import AllChatsPaginated from '../types/AllChatsPaginated';
import { ChatListTabs } from '../types/ChatListTabs';
import ChatDTO from '../types/ChatModel';
import { SearchFilterConditions } from '../types/FilterConditions';
import { AgentsApiService } from './api/agents-api.service';
import { ChatApiService } from './api/chat-api.service';
import { MixpanelService } from './mixpanel.service';
import { TitleNotificationService } from './title-notification.service';

@Injectable({ providedIn: 'root' })
export class ChatService {
  searchConditions: SearchFilterConditions | undefined;
  myChats$ = new BehaviorSubject<Chat[]>([]);
  openChats$ = new BehaviorSubject<Chat[]>([]);
  closedChats$ = new BehaviorSubject<Chat[]>([]);
  botChats$ = new BehaviorSubject<Chat[]>([]);
  selectedChat$ = new BehaviorSubject<Chat | undefined>(undefined);
  myChatsTotal$ = new BehaviorSubject(0);
  openChatsTotal$ = new BehaviorSubject(0);
  unreadMessagesTotal$ = new BehaviorSubject<number>(0);
  isLoading$ = new BehaviorSubject<boolean>(false);
  lastAddedChat: Chat | undefined;
  lastAddedChatsArray: Chat[] = [];
  chatsFactory: ChatsFactory;
  page = 1;
  limit = 15;

  constructor(
    private ngZone: NgZone,
    private cas: ChatApiService,
    private ts: TitleNotificationService,
    private aas: AgentsApiService,
    private mx: MixpanelService,
  ) {
    this.chatsFactory = new ChatsFactory(ngZone);
  }

  public removeFromOpen(chat: Chat): void {
    if (this.hasChatInOpen(chat)) {
      this.selectedChat$.next(undefined);
      this.openChats$.next(
        this.openChats$.value.filter((c) => c.uid !== chat.uid),
      );
      this.openChatsTotal$.next(this.openChatsTotal$.getValue() - 1);
    }
  }

  private removeFromMy(chat: Chat): void {
    this.selectedChat$.next(undefined);
    this.myChats$.next(this.myChats$.value.filter((c) => c.uid !== chat.uid));
    this.myChatsTotal$.next(this.myChatsTotal$.getValue() - 1);
  }

  private removeFromClosed(chat: Chat) {
    this.closedChats$.next(
      this.closedChats$.value.filter((c) => c.uid !== chat.uid),
    );
  }

  closeChat(chat: Chat, currentTab: ChatListTabs) {
    this.mx.track('clicked_close_chat', 'chats');
    this.selectChat(undefined);
    this.removeChat(chat, currentTab);
    return this.cas.disable(chat.uid).pipe(
      map((code) => {
        if (code !== 200) {
          this.rollbackClose(chat, currentTab);
        }
      }),
    );
  }

  reopenChat(chat: Chat) {
    chat.open = true;
    this.addToOpen(chat);
    this.removeFromClosed(chat);
    return this.cas
      .enable(chat.uid)
      .pipe(
        map((code) => {
          if (code !== 200) {
            this.removeChat(chat, 'open');
          }
        }),
      )
      .subscribe({ next: () => chat.connect() });
  }

  removeChat(chat: Chat, from: ChatListTabs): void {
    if (from === 'my') {
      this.removeFromMy(chat);
    }
    if (from === 'open') {
      this.removeFromOpen(chat);
    }

    chat.open = false;
    this.closedChats$.next([chat, ...this.closedChats$.value]);
  }

  private rollbackClose(chat: Chat, from: ChatListTabs): void {
    if (from === 'my') {
      this.myChats$.next([chat, ...this.myChats$.value]);
      this.myChatsTotal$.next(this.myChatsTotal$.getValue() + 1);
    }
    if (from === 'open') {
      this.openChats$.next([chat, ...this.openChats$.value]);
      this.openChatsTotal$.next(this.openChatsTotal$.getValue() + 1);
    }

    chat.open = true;
    this.closedChats$.next(
      this.closedChats$.value.filter((c) => c.uid !== chat.uid),
    );
  }

  public loadFilteredChats(tab: ChatListTabs): void {
    this.isLoading$.next(true);
    this.cas
      .getFilteredPaginatedChats(
        this.page++,
        this.limit,
        this.searchConditions?.uuid,
        this.searchConditions?.condition,
        this.searchConditions?.value,
      )
      .subscribe({
        next: (result) => this.allocateChatsToTabs(result, tab),
        error: (error) => {
          console.log('Error loading search', { error });
          this.isLoading$.next(false);
        },
        complete: () => this.isLoading$.next(false),
      });
  }

  public assignTo(
    chat: Chat,
    targetUid: string,
    currentUid: string,
    role: Role,
  ): void {
    this.aas.assignChat(role, chat.uid, targetUid).subscribe({
      next: (result) => {
        if (result.status === 200) {
          this.removeFromOpen(chat);
          if (targetUid === currentUid) {
            this.addToMy(chat);
            this.selectChat(chat);
            chat.open = true;
          } else {
            this.selectChat(undefined);
          }
        }
      },
      error: (err) => {
        console.error(
          `Error assigning chat: ${chat.uid} to agent ${targetUid} ${
            (err as Error).message
          }`,
        );
      },
    });
  }

  public reassign(chat: Chat, targetUid: string, role: Role): void {
    this.removeFromMy(chat);
    this.selectChat(undefined);
    this.aas.assign(role, chat.uid, targetUid);
  }

  public selectChat(next: Chat | undefined): void {
    const current = this.selectedChat$.value;
    if (current) {
      current.setViewed();
      current.selected = false;
    }
    if (next) {
      next.setViewed();
      next.selected = true;
      if (
        this.lastAddedChatsArray.includes(next) &&
        this.unreadMessagesTotal$.value >= 1
      ) {
        this.unreadMessagesTotal$.next(this.unreadMessagesTotal$.value - 1);
        if (this.unreadMessagesTotal$.value === 0) {
          this.ts.stop();
        }
      }
    }
    this.selectedChat$.next(next);
  }

  public reset(): void {
    this.myChats$.next([]);
    this.openChats$.next([]);
    this.closedChats$.next([]);
    this.botChats$.next([]);
    this.myChatsTotal$.next(0);
    this.openChatsTotal$.next(0);
  }

  public resetAndReload(): void {
    this.page = 1;
    this.setSearchConditions(undefined);
    this.reset();
    this.loadFilteredChats('any');
  }

  public addToMy(chat: Chat): void {
    if (!this.hasChatInMy(chat)) {
      chat.mine = true;
      chat.open = true;
      this.myChats$.next([chat, ...this.myChats$.value]);
      this.myChatsTotal$.next(this.myChatsTotal$.value + 1);
      this.ts.start();
      this.unreadMessagesTotal$.next(this.unreadMessagesTotal$.value + 1);
      this.lastAddedChat = chat;
      this.lastAddedChatsArray.push(this.lastAddedChat);
    }
  }

  public addToOpen(chat: Chat): void {
    if (!this.hasChatInOpen(chat)) {
      this.openChats$.next([chat, ...this.openChats$.value]);
      this.openChatsTotal$.next(this.openChatsTotal$.value + 1);
      this.ts.start();
      this.unreadMessagesTotal$.next(this.unreadMessagesTotal$.value + 1);
      this.lastAddedChat = chat;
      this.lastAddedChatsArray.push(this.lastAddedChat);
    }
  }

  public setSearchConditions(
    conditions: SearchFilterConditions | undefined,
  ): void {
    this.searchConditions = conditions;
    this.page = 1;
    this.reset();
  }

  private allocateChatsToTabs(
    chats: AllChatsPaginated,
    tab: ChatListTabs,
  ): void {
    const { assigned, free, disabled, bot } = chats;
    if (tab === 'any' || tab === 'my') {
      const { items, totalCount } = assigned;
      this.addMy(items);
      this.myChatsTotal$.next(totalCount);
    }
    if (tab === 'any' || tab === 'open') {
      const { items, totalCount } = free;
      this.addOpen(items);
      this.openChatsTotal$.next(totalCount);
    }
    if (tab === 'any' || tab === 'closed') {
      const { items } = disabled;
      this.addDisabled(items);
    }
    if (tab === 'any' || tab === 'bot') {
      const { items } = bot;
      this.addBot(items);
    }
  }

  private addDisabled(dtos: ChatDTO[]): void {
    const additional = dtos.map((dto) =>
      this.chatsFactory.closedChat(dto, this.ts, this.unreadMessagesTotal$),
    );
    this.closedChats$.next([...this.closedChats$.value, ...additional]);
  }

  private addMy(dtos: ChatDTO[]): void {
    const additional = dtos.map((dto) =>
      this.chatsFactory.myChat(dto, this.ts, this.unreadMessagesTotal$),
    );
    this.myChats$.next([...this.myChats$.value, ...additional]);
  }

  private addOpen(dtos: ChatDTO[]): void {
    const additional = dtos.map((dto) =>
      this.chatsFactory.openChat(dto, this.ts, this.unreadMessagesTotal$),
    );
    this.openChats$.next([...this.openChats$.value, ...additional]);
  }

  private addBot(dtos: ChatDTO[]): void {
    const additional = dtos.map((dto) =>
      this.chatsFactory.botChat(dto, this.ts, this.unreadMessagesTotal$),
    );
    this.botChats$.next([...this.botChats$.value, ...additional]);
  }

  private hasChatInMy(chat: Chat) {
    return this.myChats$.value.findIndex((c) => c.uid === chat.uid) !== -1;
  }

  private hasChatInOpen(chat: Chat) {
    return this.openChats$.value.findIndex((c) => c.uid === chat.uid) !== -1;
  }

  private removeEmptyChats(chats: Chat[]): Chat[] {
    const chatsWithUserMessages: Chat[] = [];
    for (const chat of chats) {
      const emptyChat = chat.messages.findIndex((el) => el.author === 'client');
      if (emptyChat !== -1) {
        chatsWithUserMessages.push(chat);
      }
    }
    return chatsWithUserMessages;
  }
}
