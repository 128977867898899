import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';
import { FreeLeftSlugs } from '../../../../@core/types/Automations/FreeLeftSlugs';
import { Slug } from './../../../../@core/domain/Slug';
import { WidgetService } from '../../../../@core/services/automations/widget.service';
import { MixpanelService } from './../../../../@core/services/mixpanel.service';

type CardType = {
  title: 'Automatic' | 'Manual';
  subtitle: string;
  freeLeft?: number;
  options: { state: 'disabled' | 'enabled'; text: string }[];
};

@Component({
  selector: 'norby-slug-type-creation',
  templateUrl: './slug-type-creation.component.html',
  styleUrls: ['./slug-type-creation.component.css'],
})
export class SlugTypeCreationComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public freeLeftSlugs: FreeLeftSlugs | undefined;
  public slugsTypeData: CardType[] = [
    {
      title: 'Automatic',
      freeLeft: 2,
      subtitle: 'Provide content the bot will use for automatic answers',
      options: [
        { state: 'enabled', text: 'Easy Setup' },
        { state: 'enabled', text: 'Advanced AI' },
        { state: 'enabled', text: 'Auto Translations' },
        { state: 'disabled', text: 'Precise Control' },
        { state: 'disabled', text: 'Triggers and Widgets' },
      ],
    },
    {
      title: 'Manual',
      subtitle: 'Add pairs User Action → Bot Answer manually',
      options: [
        { state: 'disabled', text: 'Easy Setup' },
        { state: 'disabled', text: 'Advanced AI' },
        { state: 'disabled', text: 'Auto Translations' },
        { state: 'enabled', text: 'Precise Control' },
        { state: 'enabled', text: 'Triggers and Widgets' },
      ],
    },
  ];
  actualCardForCreatingSlug = 'Automatic';

  constructor(
    protected ss: SlugsService,
    private mx: MixpanelService,
    private ws: WidgetService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.ss.freeSlugsCount.subscribe({
        next: (res: FreeLeftSlugs) => {
          this.freeLeftSlugs = res;
        },
      }),
    );
  }

  selectSlugType(): void {
    if (this.actualCardForCreatingSlug === 'Manual') {
      this.ss.getTriggerTypes();
      this.ss.slugCreationStep$.next('trigger-step');
    } else {
      this.mx.track('click_add_automatic_automation', 'automations');
      document
        .getElementById('nameContainer')
        ?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('slugNameInput')?.focus();
      this.ss.slugCreationStep$.next('closed');
      this.ss.currentTab$.next('Disabled');
      this.ss.selectedSlug$.next(Slug.emptyAutomaticSlug());
      this.ws.reset();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
