<norby-slug-wrapper
  [title]="'Questions'"
  [subtitle]="'Form a question in different variables'"
>
  <ng-container *ngFor="let lang of selectedSlug?.questions | async | keyvalue">
    <div *ngIf="(selectedLang | async) === lang.key">
      <ng-container *ngFor="let question of lang.value">
        <norby-question-box
          (questionId)="remove.emit($event)"
          [question]="question"
        ></norby-question-box>
      </ng-container>
      <div class="input-error" *ngIf="questionErrors$ | async">
        {{ questionErrors$ | async }}
      </div>
      <ng-container *ngIf="ss.error$ | async as serverError">
        <div
          class="input-error"
          *ngIf="serverError?.has('questions.1.question')"
        >
          {{ serverError?.get("questions.1.question") }}
        </div>
        <div
          class="input-error"
          *ngIf="serverError?.has('questions.2.question')"
        >
          {{ serverError?.get("questions.2.question") }}
        </div>
        <div
          class="input-error"
          *ngIf="serverError?.has('questions.3.question')"
        >
          {{ serverError?.get("questions.3.question") }}
        </div>
      </ng-container>
    </div>
  </ng-container>
  <form
    class="field-form"
    [formGroup]="questionForm"
    (ngSubmit)="addQuestion()"
  >
    <input
      class="field-input"
      type="text"
      placeholder="Type in a question…"
      [formControlName]="'question'"
    />
    <button class="secondary-button" [disabled]="!questionForm.valid">
      Add
    </button>
    <div
      class="input-error-question"
      *ngIf="
        questionForm.controls['question'].invalid &&
        (questionForm.controls['question'].dirty ||
          questionForm.controls['question'].touched)
      "
    >
      <div
        class="input-error-question"
        *ngIf="questionForm.controls['question'].errors?.maxlength"
      >
        The question cannot be longer than 255 characters
      </div>
    </div>
  </form>
</norby-slug-wrapper>
