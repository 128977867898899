import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-eye-icon',
  templateUrl: './eye-icon.component.html',
  styleUrls: ['./eye-icon.component.css'],
})
export class EyeIconComponent {
  @Input() isOpen = true;
  @Input() eyeIcon = false;
}
