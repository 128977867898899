import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import AllChatsPaginated from '../../types/AllChatsPaginated';

@Injectable({
  providedIn: 'root',
})
export class ChatApiService {
  url = `${environment.endpoint}/api/panel/support/chat`;

  constructor(private http: HttpClient) {}

  getFilteredPaginatedChats(
    page: number,
    limit: number,
    uuid?: string,
    condition?: string,
    value?: string,
  ): Observable<AllChatsPaginated> {
    return this.http.post<AllChatsPaginated>(`${this.url}/filter`, {
      condition,
      value,
      uuid,
      page,
      limit,
    });
  }

  assign(uniqid: string): Observable<object> {
    return this.http.post(`${this.url}/assign`, { uniqid });
  }

  enable(chatRoomId: string) {
    return this.http
      .post(`${this.url}/enable/${chatRoomId}`, {}, { observe: 'response' })
      .pipe(
        map((response) => response.status),
        catchError((e) => of(e.status)),
      );
  }

  disable(chatRoomId: string): Observable<number> {
    return this.http
      .post(`${this.url}/disable/${chatRoomId}`, {}, { observe: 'response' })
      .pipe(
        map((response) => response.status),
        catchError((e) => of(e.status)),
      );
  }

  public uploadChatImage(
    chatUid: string,
    msgUid: string,
    file: File,
  ): Observable<Response> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post(`${this.url}/image/${chatUid}/${msgUid}`, formData, {
        observe: 'response',
      })
      .pipe(catchError((err) => of(err)));
  }
}
