import { BehaviorSubject } from 'rxjs';
import ChatMessage from '../types/ChatMessage';
import ChatMessageDto from '../types/ChatMessageDto';
import { ImageInfo } from '../types/ImageInfo';
import { Profile } from './Profile';

export default class ChatMessageFactory {
  public static fromDTO(dto: ChatMessageDto): ChatMessage {
    return {
      agent: dto.agent ? new Profile(dto.agent) : undefined,
      author: dto.author,
      confirmed: new BehaviorSubject<boolean>(true),
      errorMessage: new BehaviorSubject(dto.errorMessage),
      full_date: dto.full_date,
      id: dto.id,
      image: new BehaviorSubject<ImageInfo | undefined>(dto.image),
      imageFile: dto.imageFile,
      message: dto.message,
      showAvatar: dto.showAvatar || true,
      time: dto.time,
      type: dto.type,
    } as ChatMessage;
  }

  public static agentMessage(profile: Profile, text: string): ChatMessage {
    return {
      agent: profile,
      author: profile.role === 'Support Agent' ? 'agent' : 'manager',
      full_date: new Date().toISOString(),
      id: '',
      message: text,
      showAvatar: false,
      time: new Date().toISOString(),
      type: 'text',
      confirmed: new BehaviorSubject<boolean>(false),
      errorMessage: new BehaviorSubject<string>(''),
    };
  }

  public static agentImageMessage(profile: Profile, file: File): ChatMessage {
    return {
      agent: profile,
      author: profile.role === 'Support Agent' ? 'agent' : 'manager',
      full_date: new Date().toISOString(),
      id: '',
      message: file.name,
      showAvatar: false,
      time: new Date().toISOString(),
      type: 'image',
      confirmed: new BehaviorSubject<boolean>(false),
      imageFile: file,
      image: new BehaviorSubject<ImageInfo | undefined>({
        path: '',
        preview_path: '',
      }),
      errorMessage: new BehaviorSubject<string>(''),
    };
  }
}
