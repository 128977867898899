import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSpaces',
})
export class RemoveSpacesPipe implements PipeTransform {
  transform(value: string | null): string | null {
    if (value) {
      return value
        .replace(/&amp;/g, ' ')
        .replace(/&nbsp;/g, ' ')
        .replace(/amp;/g, ' ')
        .replace(/nbsp;/g, ' ');
    } else {
      return null;
    }
  }
}
