<figure *ngIf="agent" class="card">
  <norby-agent-avatar
    [avatar]="agent.avatar"
    [status]="agent.status"
  ></norby-agent-avatar>
  <figcaption>
    <div class="name">{{ agent.firstName }} {{ agent.lastName }}</div>
    <div class="role">{{ agent.role }}</div>
  </figcaption>
</figure>
