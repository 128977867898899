import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-union-icon',
  templateUrl: './union-icon.component.html',
  styleUrls: ['./union-icon.component.css'],
})
export class UnionIconComponent {
  @Output() showOptions = new EventEmitter();
  @Input() isDotVisible = false;

  open(e: Event): void {
    e.stopPropagation();
    this.showOptions.emit();
  }
}
