<div *ngIf="isOpen" class="chat-attributes">
  <div class="attributes-wrapper">
    <div>
      <div class="attributes-card dark">
        <norby-attributes-title
          [profile]="profile"
          [route]="'/settings/attributes/chat'"
          [title]="'Chat Details'"
        ></norby-attributes-title>
        <ul class="card-list">
          <li *ngFor="let shortAttribute of chatAttributes | async">
            <norby-attribute-list-item
              [name]="shortAttribute.name"
              [value]="shortAttribute.value || ''"
            ></norby-attribute-list-item>
          </li>
        </ul>
      </div>
      <div class="attributes-card">
        <norby-attributes-title
          [profile]="profile"
          [route]="'/settings/attributes/user'"
          [title]="'User Details'"
        ></norby-attributes-title>
        <ul class="card-list">
          <li *ngFor="let shortAttribute of userAttributes | async">
            <norby-attribute-list-item
              [name]="shortAttribute.name"
              [value]="shortAttribute.value || ''"
            ></norby-attribute-list-item>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div (click)="setOpen(false)" *ngIf="isOpen" class="roll-button">
    <norby-roll></norby-roll>
  </div>
</div>
<div *ngIf="!isOpen" class="closed-attributes">
  <button (click)="setOpen(true)" class="expand-button">
    <norby-expand></norby-expand>
  </button>
</div>
<div *ngIf="!isOpen">
  <button (click)="setOpen(true)" class="open-user-attributes">
    <img alt="user-info" src="assets/avatars/roll-details-button.svg" />
  </button>
</div>
