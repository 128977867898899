import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.css'],
})
export class SwitcherComponent {
  @Input() enabled = true;
  @Input() switchedOn = true;
  @Input() label = '';
  @Input() switcherPosition: 'left' | 'right' = 'left';
  @Output() toggle = new EventEmitter<boolean>();

  public toggleSwitch(): void {
    if (this.enabled) {
      this.switchedOn = !this.switchedOn;
      this.toggle.emit(this.switchedOn);
    }
  }
}
