import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsPageService } from '../../../@core/services/settings-page.service';

type AccordionItem = {
  url?: string;
  title: string;
  text?: string;
  mixpanelEvent?: string;
};

@Component({
  selector: 'norby-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
})
export class AccordionComponent {
  public openContent$: BehaviorSubject<boolean>;
  @Input() accordionTitle = '';
  @Input() accordionItems: AccordionItem[] = [{ url: '', title: '' }];
  @Output() sendEvent = new EventEmitter();
  public normal = { transform: 'rotate(0deg)' };
  public rotated = { transform: 'rotate(180deg)' };

  constructor(private settingsService: SettingsPageService) {
    this.openContent$ = this.settingsService.showContent;
  }

  public onClick(item: AccordionItem): void {
    this.sendEvent.emit({ mixpanelEvent: item.mixpanelEvent, page: item.url });
    this.openContent$.next(false);
  }

  public toggleContent(): void {
    this.openContent$.next(!this.openContent$.value);
  }
}
