<button
  class="bin"
  type="button"
  *ngIf="showBin"
  (click)="fireRemove($event)"
  [disabled]="disabled"
  [style.stroke]="stroke"
>
  <svg
    class="inner-svg"
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 3.74365H17.4615M7 8V15M12 8V15M2.8291 3.80005H15.6325V17C15.6325 18.1045 14.1046 19 13 19H5C3.89543 19 2.8291 18.1045 2.8291 17V3.80005ZM11.9134 1H6.54813L5.57263 3.74359H12.8889L11.9134 1Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</button>

<!-- Modal Window -->
<norby-modal *ngIf="showModal" (closeModal)="cancelRemove()">
  <div>
    <h4 class="modal-title">Remove the {{ item }}?</h4>
    <p class="modal-content">
      You are going to remove this {{ item }}. Are you sure?
    </p>
    <div class="modal-flex">
      <button class="secondary-button" (click)="cancelRemove()">Cancel</button>
      <button class="main-button" (click)="confirmRemove()">Remove</button>
    </div>
  </div>
</norby-modal>
