<button
  class="expose-button"
  [ngStyle]="isHovered ? hoveredStyle : normalStyle"
  (mouseenter)="setHoverBackground(background)"
  (mouseleave)="clearHoverBackground()"
>
  <svg
    [style]="!isOpen ? normalStyle : rotatedStyle"
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.58578 0H1.41421C0.523302 0 0.0771336 1.07714 0.707098 1.70711L3.29289 4.29289C3.68341 4.68342 4.31658 4.68342 4.7071 4.29289L7.29289 1.70711C7.92285 1.07714 7.47668 0 6.58578 0Z"
      [attr.fill]="color"
    />
  </svg>
</button>
