import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-bg-blob',
  templateUrl: './bg-blob.component.html',
  styleUrls: ['./bg-blob.component.css'],
})
export class BgBlobComponent {
  @Input() position = {};
}
