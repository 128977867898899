<div
  *ngIf="countriesWithStates$ | async"
  class="options-container"
  [style]="{ top: topPositioning }"
>
  <h1 *ngIf="!!optionsLabel" class="options-label">{{ optionsLabel }}</h1>
  <div
    class="option-wrapper"
    *ngFor="let option of states"
    (click)="setStateValue(option)"
  >
    <label for="select-option-{{ option.state }}" class="option-label">
      <input
        type="radio"
        class="option-value"
        [formControl]="control"
        [value]="option.state"
        id="select-option-{{ option.state }}"
      />
      {{ option.state }}
    </label>
  </div>
</div>
