import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { Profile } from '../domain/Profile';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private mixpanel = mixpanel;

  constructor() {
    this.mixpanel.init(environment.mixpanelKey);
  }

  public track(
    event: string,
    page: string,
    props?: { key: string; value: string },
  ): void {
    this.mixpanel.track(event, {
      page,
      ...props,
    });
  }

  public trackForSaveSlug(
    event: string,
    page: string,
    props?: { key: string; value: string | number }[],
  ): void {
    this.mixpanel.track(event, {
      page,
      ...props,
    });
  }

  public init(profile: Profile): void {
    const { uid, companyName, role } = profile;

    this.mixpanel.identify(uid);
    this.mixpanel.people.set_once({
      user_id: uid,
      company_name: companyName,
      role,
    });
    this.mixpanel.register({ company_name: companyName });
  }
}
