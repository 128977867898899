<div class="select-container">
  <div class="select-wrapper" (click)="toggle()">
    <input
      [readOnly]="true"
      class="select-input"
      [value]="currentValue"
      placeholder="Choose a trigger..."
    />
    <norby-expose-button
      class="select-arrow"
      [isOpen]="isOpen"
    ></norby-expose-button>
  </div>
  <ng-container *ngIf="isOpen">
    <div
      *ngIf="triggerOptionValues$ | async as triggerOptionValues"
      class="options-container"
      [style]="{ top: topPositioning }"
    >
      <ng-container *ngFor="let option of triggerOptionValues | keyvalue">
        <div
          class="option-wrapper"
          [ngClass]="{ 'disabled-option': option.value.exists }"
          (click)="setValue(option)"
        >
          <label for="select-option-{{ option.key }}" class="option-label">
            <input
              type="radio"
              class="option-value"
              [formControl]="control"
              [value]="option.value.name"
              id="select-option-{{ option.key }}"
            />
            {{ option.value.name }}
          </label>
          <span *ngIf="option.value.exists" class="option-notice"
            >set up already</span
          >
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
