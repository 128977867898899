import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[norbyClickOutside]',
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  @Output() clickOutside = new EventEmitter();
  subscription: Subscription | undefined;

  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  private clickEvent$ = fromEvent(this.document, 'click').pipe(
    filter((event) => !this.isInside(event.target as HTMLElement)),
  );
  private enterKeyEvent$ = fromEvent<KeyboardEvent>(
    this.document,
    'keyup',
  ).pipe(filter((e) => e.key === 'Enter'));

  ngAfterViewInit(): void {
    this.subscription = merge(this.clickEvent$, this.enterKeyEvent$).subscribe(
      () => {
        this.clickOutside.emit();
      },
    );
  }

  isInside(elementToCheck: HTMLElement): boolean {
    return (
      elementToCheck === this.element.nativeElement ||
      this.element.nativeElement.contains(elementToCheck)
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
