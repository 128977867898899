/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Slug } from '../../domain/Slug';
import { AnswersRequestData } from '../../types/Automations/Answer';
import { Bot } from '../../types/Automations/Bot';
import { FreeLeftSlugs } from '../../types/Automations/FreeLeftSlugs';
import {
  Question,
  QuestionRequestData,
  QuestionRequestEditData,
} from '../../types/Automations/Question';
import { SearchSlugRequest } from '../../types/Automations/SearchSlugRequest';
import {
  SlugBody,
  SlugDTO,
  SlugEditData,
} from '../../types/Automations/SlugModel';
import { TriggerTypes } from '../../types/Automations/TriggerTypes';
import { WidgetDTO } from '../../types/Automations/Widgets/Widget';
import { WidgetRequestData } from '../../types/Automations/Widgets/WidgetRequest';
import { WidgetTypes } from '../../types/Automations/Widgets/WidgetTypes';

@Injectable({
  providedIn: 'root',
})
export class SlugsApiService {
  url = `${environment.botEndpoint}/api`;

  constructor(private http: HttpClient) {}

  public createSlug(slugBody: {
    slug: Pick<SlugDTO, 'bot_id' | 'slug'>;
  }): Observable<SlugDTO> {
    return this.http
      .post<{ data: SlugDTO }>(`${this.url}/slug`, slugBody)
      .pipe(map((res) => res.data));
  }

  public loadActiveSlugs(
    botId: number,
    page = 1,
    limit = 15,
  ): Observable<SlugDTO[]> {
    return this.http
      .get<{
        data: SlugDTO[];
      }>(`${this.url}/bot/${botId}/slugs?active=1&page=${page}&limit=${limit}`)
      .pipe(map((res) => res.data));
  }

  public loadDisabledSlugs(
    botId: number,
    page = 1,
    limit = 15,
  ): Observable<SlugDTO[]> {
    return this.http
      .get<{
        data: SlugDTO[];
      }>(`${this.url}/bot/${botId}/slugs?active=0&page=${page}&limit=${limit}`)
      .pipe(map((res) => res.data));
  }

  public filterSlugs(
    slugRequestData: SearchSlugRequest,
    page = 1,
    limit = 100,
  ): Observable<SlugDTO[]> {
    return this.http
      .get<{
        data: SlugDTO[];
      }>(
        `${this.url}/bot/${slugRequestData.botId}/slugs?type=${slugRequestData.type}&page=${page}&limit=${limit}`,
      )
      .pipe(map((res) => res.data));
  }

  public findSlug(slugRequestData: SearchSlugRequest): Observable<SlugDTO[]> {
    return this.http
      .get<{
        data: SlugDTO[];
      }>(
        `${this.url}/bot/${slugRequestData.botId}/slugs?type=${slugRequestData.type}&slug=${slugRequestData.slug}`,
      )
      .pipe(map((res) => res.data));
  }

  public getBotsList(): Observable<Bot[]> {
    return this.http
      .get<{ data: Bot[] }>(`${this.url}/bot/`)
      .pipe(map((res) => res.data));
  }

  public addQuestion(question: QuestionRequestData): Observable<Question> {
    return this.http
      .post<{ data: Question }>(`${this.url}/question`, question)
      .pipe(map((res) => res.data));
  }

  public removeQuestion(id: number): Observable<Question> {
    return this.http.delete<Question>(`${this.url}/question/${id}`);
  }

  public removeSlug(id: number): Observable<Slug> {
    return this.http.delete<Slug>(`${this.url}/slug/${id}`);
  }

  public updateSlugStatus(
    slug: Record<string, number | boolean>,
  ): Observable<Slug> {
    return this.http.put<Slug>(`${this.url}/slug/${slug.id}`, {
      slug: { active: slug.active },
    });
  }

  public updateQuestionStatus(
    question: QuestionRequestEditData,
  ): Observable<Question> {
    const { id } = question.question;
    const { active } = question.question;
    return this.http
      .put<{
        data: Question;
      }>(`${this.url}/question/${id}`, { question: { active } })
      .pipe(map((res) => res.data));
  }

  public updateSlugName(slugName: SlugEditData): Observable<SlugDTO> {
    const { slug_id } = slugName.slug;
    return this.http.put<SlugDTO>(`${this.url}/slug/${slug_id}`, slugName);
  }

  public updateAnswer(answer: AnswersRequestData): Observable<SlugDTO> {
    return this.http
      .post<{ data: SlugDTO }>(`${this.url}/answer/multiple`, answer)
      .pipe(map((res) => res.data));
  }

  public getWidgetTypes(): Observable<WidgetTypes> {
    return this.http.get<WidgetTypes>(`${this.url}/widget-types`);
  }

  public createWidget(widget: WidgetRequestData): Observable<WidgetDTO[]> {
    return this.http
      .post<any>(`${this.url}/widget`, widget)
      .pipe(map((res) => res.data));
  }

  public updateWidget(widget: WidgetRequestData): Observable<WidgetDTO> {
    const { id } = widget;
    return this.http
      .put<any>(`${this.url}/widget/${id}`, widget)
      .pipe(map((res) => res.data));
  }

  public deleteWidget(id: number) {
    return this.http
      .delete<any>(`${this.url}/widget/${id}`)
      .pipe(map((res) => res.data));
  }

  public createFullManualSlug(slugBody: object) {
    return this.http
      .post<any>(`${this.url}/full-slug`, slugBody)
      .pipe(map((res) => res.data));
  }

  public updateFullSlug(slugBody: object, slugId: number): Observable<SlugDTO> {
    return this.http
      .put<{ data: SlugDTO }>(`${this.url}/full-slug/${slugId}`, slugBody)
      .pipe(map((res) => res.data));
  }

  public getSingleSlug(id: number): Observable<SlugDTO> {
    return this.http
      .get<{ data: SlugDTO }>(`${this.url}/slug/${id}`)
      .pipe(map((res) => res.data));
  }

  public createFullAutoSlug(slugBody: SlugBody): Observable<object> {
    return this.http
      .post<any>(`${this.url}/automatic-slug`, slugBody)
      .pipe(map((res) => res.data));
  }

  public updateFullAutoSlug(
    slugBody: SlugBody,
    slugId: number,
  ): Observable<SlugDTO> {
    return this.http
      .put<{ data: SlugDTO }>(`${this.url}/automatic-slug/${slugId}`, slugBody)
      .pipe(map((res) => res.data));
  }

  public getCountOfFreeSlugs(): Observable<FreeLeftSlugs> {
    return this.http.get<FreeLeftSlugs>(`${this.url}/left-free-slugs`);
  }

  public getTriggerTypes(botId: number): Observable<TriggerTypes> {
    return this.http.get<TriggerTypes>(`${this.url}/trigger-types/${botId}`);
  }
}
