<div class="voice-container">
  <svg
    width="151"
    height="130"
    viewBox="0 0 151 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.1025 91.5923L48.5706 85.7559L68.2156 111.726C69.2318 113.069 69.0217 114.955 67.746 116.073L63.7677 119.564C63.0573 120.187 62.2093 120.459 61.2672 120.366C60.326 120.272 59.5561 119.838 58.9888 119.091L38.1025 91.5923Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M71.2267 15.1101C74.919 13.0516 79.6246 14.3494 81.6836 17.9939L112.398 72.3626C114.457 76.0071 113.12 80.6732 109.428 82.7316C105.982 84.6532 101.656 83.6506 99.4188 80.5463C85.8361 78.3677 67.6838 80.7927 49.9904 88.2886L50.7706 89.6703L37.7337 96.9383C29.8123 101.354 19.7167 98.5696 15.2999 90.7504C10.8823 82.9308 13.749 72.9189 21.6704 68.5027L34.7072 61.234L35.4874 62.6158C51.1459 51.5176 62.7219 37.4699 67.9257 24.8182C66.366 21.288 67.7549 17.0457 71.2267 15.1101Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.473 58.5518L134.098 58.6604C135.957 58.6784 137.484 57.1888 137.509 55.3494C137.533 53.5086 136.039 52.0063 134.182 51.9885L122.557 51.8799C120.697 51.8619 119.17 53.3524 119.146 55.191C119.121 57.0326 120.616 58.534 122.473 58.5518ZM109.13 28.1131L115.092 18.1987C116.045 16.6146 115.532 14.5658 113.938 13.6266C112.345 12.6884 110.27 13.2072 109.315 14.7939L103.353 24.7083C102.401 26.2922 102.913 28.3412 104.507 29.2804C106.1 30.2179 108.176 29.699 109.13 28.1131ZM122.4 40.181L132.026 34.8141C133.661 33.9033 134.246 31.8571 133.335 30.2444C132.424 28.631 130.361 28.0616 128.727 28.9731L119.101 34.3393C117.466 35.2508 116.881 37.2963 117.792 38.9097C118.703 40.5223 120.766 41.0917 122.4 40.181Z"
      fill="white"
    />
  </svg>
</div>
