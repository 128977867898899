<div class="accordion-container">
  <div (click)="toggleContent()" class="accordion-title__wrapper">
    <h4>{{ accordionTitle }}</h4>
    <button class="accordion-arrow">
      <svg
        [style]="(openContent$ | async) ? normal : rotated"
        class="inner-svg"
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9999 10L17.3999 14.5"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          d="M11.9999 10L6.59998 14.5"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
    </button>
  </div>
  <div *ngIf="openContent$ | async" class="accordion-content">
    <div *ngFor="let item of accordionItems" class="accordion-items">
      <a
        (click)="onClick(item)"
        class="accordion-content__link"
        routerLink="{{ item.url }}"
        routerLinkActive="active"
        >{{ item.title }}</a
      >
    </div>
  </div>
</div>
