import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-exclamation-mark',
  templateUrl: './exclamation-mark.component.html',
  styleUrls: ['./exclamation-mark.component.css'],
})
export class ExclamationMarkComponent {
  @Input() size: 'large' | 'small' = 'small';
  @Input() fill: 'red' | 'yellow' = 'red';
}
