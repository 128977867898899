import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Slug } from '../../../../@core/domain/Slug';
import { QuestionsService } from '../../../../@core/services/automations/questions.service';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';
import { UserLanguagesService } from './../../../../@core/services/user-languages.service';

@Component({
  selector: 'norby-slug-questions-wrapper',
  templateUrl: './slug-questions-wrapper.component.html',
  styleUrls: ['./slug-questions-wrapper.component.css'],
})
export class SlugQuestionsWrapperComponent implements OnDestroy {
  @Input() selectedSlug: Slug | null | undefined = undefined;
  @Output() remove = new EventEmitter();
  @Output() question = new EventEmitter();
  @Input() questionErrors$ = this.qs.questionError$;
  questionForm: UntypedFormGroup;
  selectedLang = this.uls.currentLanguage$;

  constructor(
    private fb: UntypedFormBuilder,
    private qs: QuestionsService,
    private uls: UserLanguagesService,
    protected ss: SlugsService,
  ) {
    this.questionForm = this.fb.group({
      question: ['', [Validators.required, Validators.maxLength(255)]],
    });
  }

  getControl(controlName: string): UntypedFormControl {
    return this.questionForm.controls[controlName] as UntypedFormControl;
  }

  addQuestion(): void {
    if (this.questionForm.valid) {
      const { question } = this.questionForm.value;
      this.question.emit(question);
      this.questionForm.reset();
    }
  }

  ngOnDestroy(): void {
    this.qs.questionError$.next(null);
  }
}
