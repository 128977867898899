import { Component } from '@angular/core';

@Component({
  selector: 'norby-number-one-icon',
  templateUrl: './number-one-icon.component.html',
  styleUrls: ['./number-one-icon.component.css'],
})
export class NumberOneIconComponent {
  margin = { 'margin-right': '15px' };
}
