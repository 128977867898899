<div class="list-item-wrapper">
  <div class="list-item-key">{{ name }}</div>
  <div class="flex">
    <div class="list-item">
      <button
        class="list-item-value"
        *ngIf="value && value !== 'Unknown'"
        (click)="copyWidgetCode()"
      >
        {{ value }}
      </button>
      <div
        class="list-item-value-unknown"
        *ngIf="!value || value === 'Unknown'"
      >
        Unknown
      </div>
    </div>
    <norby-small-copy-button
      (click)="copyWidgetCode()"
      [disabled]="!value || value === 'Unknown'"
    ></norby-small-copy-button>
  </div>
</div>
