<div class="trigger-card-container">
  <div class="trigger-card-header">
    <div class="trigger-card-title">Choose a Trigger</div>
    <norby-question-circle
      [link]="
        'https://norby-io.notion.site/Triggers-42c8182bbd194a8baee72924a9a94f19'
      "
    ></norby-question-circle>
  </div>
  <p class="trigger-card-description">
    Manual automations can be started only by a trigger (e.g., when a user asks
    something, or when they starts a chat)
  </p>
  <form [formGroup]="form" (ngSubmit)="checkTrigger()">
    <norby-trigger-select
      [topPositioning]="'53px'"
      [disabledButton]="disabledButton"
      (setButtonState)="setButtonOn($event)"
      (setOptionValue)="setFormValue($event)"
      [control]="getControl('trigger')"
    ></norby-trigger-select>
    <button [disabled]="disabledButton" class="main-button trigger-card-button">
      Continue to Set Up
    </button>
  </form>
</div>
<div class="trigger-actions">
  <div (click)="ss.slugCreationStep$.next('opened')" class="back-button">
    Back
  </div>
  <div (click)="ss.slugCreationStep$.next('closed')" class="cancel-button">
    Cancel
  </div>
</div>
