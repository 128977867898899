<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
    fill="var(--grayF1)"
  />
  <path
    d="M11.5 17.5L11.0199 18.0762C11.298 18.3079 11.702 18.3079 11.9801 18.0762L11.5 17.5ZM12.25 6.5C12.25 6.08579 11.9142 5.75 11.5 5.75C11.0858 5.75 10.75 6.08579 10.75 6.5L12.25 6.5ZM8.98014 14.4238C8.66193 14.1587 8.18901 14.2017 7.92383 14.5199C7.65866 14.8381 7.70165 15.311 8.01986 15.5762L8.98014 14.4238ZM14.9801 15.5762C15.2983 15.311 15.3413 14.8381 15.0762 14.5199C14.811 14.2017 14.3381 14.1587 14.0199 14.4238L14.9801 15.5762ZM12.25 17.5L12.25 6.5L10.75 6.5L10.75 17.5H12.25ZM11.9801 16.9238L8.98014 14.4238L8.01986 15.5762L11.0199 18.0762L11.9801 16.9238ZM11.9801 18.0762L14.9801 15.5762L14.0199 14.4238L11.0199 16.9238L11.9801 18.0762Z"
    fill="#A8A8A8"
  />
</svg>
