import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';
import { SlugFilterType } from '../../../../@core/types/Automations/SlugFilterType';

@Component({
  selector: 'norby-slug-search',
  templateUrl: './slug-search.component.html',
  styleUrls: ['./slug-search.component.css'],
})
export class SlugSearchComponent implements OnInit, OnDestroy {
  hasHoverOnline = false;
  hoveredOptionKey: string | null = '';
  constructor(protected ss: SlugsService) {}

  @HostListener('mouseover', ['$event']) onMouseOver(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const parentListItem = target.closest('li');
    if (parentListItem) {
      this.hoveredOptionKey = parentListItem.getAttribute('data-key');
      this.hasHoverOnline = true;
    }
  }

  @HostListener('mouseout', ['$event']) onMouseOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const parentListItem = target.closest('li');
    if (parentListItem) {
      this.hoveredOptionKey = null;
      this.hasHoverOnline = false;
    }
  }

  readonly slugsFilterValues: Record<SlugFilterType, string> = {
    all: 'Show All',
    automatic: 'Automatic Only',
    manual: 'Manual Only',
  };
  readonly slugName = new FormControl<string>('');
  readonly subscription: Subscription = new Subscription();

  showOptions = false;

  ngOnInit(): void {
    this.subscription.add(
      this.slugName.valueChanges
        .pipe(debounceTime(500))
        .subscribe((res) => this.findSlug(res)),
    );
  }

  filterSlugs(filterData: { slug: string | null; type: SlugFilterType }): void {
    const { slug, type } = filterData;
    !slug
      ? this.ss.loadFilteredSlugs({ type, slug })
      : this.ss.getSearchedSlug({ type, slug });
  }

  findSlug(slugName: string | null): void {
    !slugName
      ? this.filterSlugs({
          type: this.ss.filteredType$.value,
          slug: slugName,
        })
      : this.ss.getSearchedSlug({
          type: this.ss.filteredType$.value,
          slug: slugName,
        });
  }

  selectFilter(filterType: string): void {
    this.filterSlugs({
      slug: this.slugName.value,
      type: filterType as SlugFilterType,
    });
    this.showOptions = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
