import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MixpanelService } from '../../../@core/services/mixpanel.service';
import { ProfileService } from '../../../@core/services/profile.service';

@Component({
  selector: 'norby-settings-page-sidebar',
  templateUrl: './settings-page-sidebar.component.html',
  styleUrls: ['./settings-page-sidebar.component.css'],
})
export class SettingsPageSidebarComponent implements OnInit, OnDestroy {
  public accordionTitle = 'Company';
  public accordionItems = [
    {
      url: '/settings/project',
      title: 'Project Settings',
      mixpanelEvent: 'click_project_settings',
    },
    {
      url: '/settings/billing',
      title: 'Billing',
      mixpanelEvent: 'click_billing_settings',
    },
    {
      url: '/settings/attributes/user',
      title: 'User Attributes',
      mixpanelEvent: 'click_user_attributes',
    },
    {
      url: '/settings/attributes/chat',
      title: 'Chat Attributes',
      mixpanelEvent: 'click_chat_attributes',
    },
  ];
  public profileRole: string;
  sub = new Subscription();

  constructor(
    public router: Router,
    protected profileService: ProfileService,
    private mx: MixpanelService,
  ) {
    this.profileRole = profileService.profile$.value?.role || '';
  }

  ngOnInit(): void {
    this.sub = this.profileService.profile$.subscribe((profile) => {
      if (profile) {
        this.profileRole = profile.role;
      }
    });
  }

  sendMixpanelEvent($event: { mixpanelEvent: string; page: string }): void {
    const { mixpanelEvent, page } = $event;
    this.mx.track(mixpanelEvent, page, { key: 'where', value: 'settings' });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
