import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css'],
})
export class RadioButtonComponent {
  @Input() checked: boolean | null = false;
  @Input() color = 'var(--grayF1)';
  @Input() cursorPointer = true;
}
