import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.css'],
})
export class OnlineComponent {
  @Input() status: boolean | null = false;
}
