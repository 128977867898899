import { Component, EventEmitter, Input, Output } from '@angular/core';

const productCardWidth = 292;
const scrollAmount = (direction: string) =>
  direction === 'left' ? -productCardWidth : productCardWidth;

@Component({
  selector: 'norby-scroll-button',
  templateUrl: './scroll-button.component.html',
  styleUrls: ['./scroll-button.component.css'],
})
export class ScrollButtonComponent {
  @Input() direction: 'left' | 'right' | '' = '';
  @Input() ref: Element | undefined;
  @Output() scrolledNow: EventEmitter<number> = new EventEmitter();

  scrollClick(direction: string): void {
    if (this.ref) {
      this.scrolledNow.emit(this.ref.scrollLeft + scrollAmount(direction));
      this.ref.scrollBy({ left: scrollAmount(direction), behavior: 'smooth' });
    }
  }
}
