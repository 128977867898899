<svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    [attr.stroke]="color"
    d="M9 4.19994V8.99994L12.2 10.5999M17 8.99994C17 13.4182 13.4183 16.9999 9 16.9999C4.58172 16.9999 1 13.4182 1 8.99994C1 4.58166 4.58172 0.999939 9 0.999939C13.4183 0.999939 17 4.58166 17 8.99994Z"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
