import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.css'],
})
export class CopyButtonComponent {
  @Input() hidden = false;
}
