import { Component, Input } from '@angular/core';

type ContainerStyle = {
  width: string;
  height: string;
  flex: string;
};

@Component({
  selector: 'norby-centered-image',
  templateUrl: './centered-image.component.html',
  styleUrls: ['./centered-image.component.css'],
})
export class CenteredImageComponent {
  @Input() size = 0;
  @Input() url = '';

  get containerStyle(): ContainerStyle {
    const sizePx = `${this.size}px`;

    return {
      width: sizePx,
      height: sizePx,
      flex: `0 0 ${sizePx}`,
    };
  }
}
