<form
  [formGroup]="attributeForm"
  class="attributes-content"
  (mouseenter)="showBin = true"
  (mouseleave)="showBin = false"
>
  <norby-attribute-input
    [attributeFieldName]="name"
    [editField]="editName"
    [control]="getControl('name')"
    [createdAt]="createdAt"
    [updatedAt]="updatedAt"
    [maxLength]="255"
    [type]="'text'"
    (openEdit)="editName = true"
    (editAttributeName)="editAttributeName()"
  ></norby-attribute-input>
  <norby-attribute-textarea
    [attributeDescription]="description"
    [editDescription]="editDescription"
    [control]="getControl('description')"
    [maxLength]="500"
    [type]="'text'"
    [rows]="3"
    (openEdit)="editDescription = true"
    (editUserAttributeDescription)="editAttributeDescription()"
  ></norby-attribute-textarea>
  <norby-bin
    [style]="{ 'margin-right': '16px' }"
    [showBin]="showBin"
    (remove)="showDeleteModalWindow.emit(attributeUuid)"
    [item]="'Attribute'"
  ></norby-bin>
</form>
