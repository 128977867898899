import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'norby-attribute-textarea',
  templateUrl: './attribute-textarea.component.html',
  styleUrls: ['./attribute-textarea.component.css'],
})
export class AttributeTextareaComponent {
  @Input() editDescription = false;
  @Input() attributeDescription = '';
  @Input() maxLength = 0;
  @Input() type = '';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() name = 'Description';
  @Input() rows = 2;

  @Output() editUserAttributeDescription = new EventEmitter();
  @Output() openEdit = new EventEmitter();
}
