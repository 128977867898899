import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MixpanelService } from '../../../../@core/services/mixpanel.service';
import { PaymentService } from '../../../../@core/services/automations/payment.service';

@Component({
  selector: 'norby-slugs-notification',
  templateUrl: './slugs-notification.component.html',
  styleUrls: ['./slugs-notification.component.css'],
})
export class SlugsNotificationComponent {
  constructor(
    private mx: MixpanelService,
    private router: Router,
    protected ps: PaymentService,
  ) {}

  sendMixPanelEvent(): void {
    this.mx.track('click_pay_now', this.router.url, {
      key: 'where',
      value: 'active automations banner',
    });
  }
}
