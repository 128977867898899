import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromNumberToMonth',
})
export class FromNumberToMonthPipe implements PipeTransform {
  transform(value: unknown) {
    if (typeof value === 'number') {
      const month = Math.floor(value / 30);
      const reminder = Math.round((value / 30 - month) * 30);
      return (
        (month > 1 && month > 0 ? `${month} months ` : `${month} month `) +
        (reminder > 1 && reminder > 0
          ? `and ${reminder} days`
          : `and ${reminder} day`)
      );
    }
    return null;
  }
}
