import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MixpanelService } from '../../@core/services/mixpanel.service';
import { BillingStatus } from '../../@core/types/Automations/BillingStatus';

@Component({
  selector: 'norby-billing-status-alert[paymentStatuses]',
  templateUrl: './billing-status-alert.component.html',
  styleUrls: ['./billing-status-alert.component.css'],
})
export class BillingStatusAlertComponent implements OnChanges {
  @Input() paymentStatuses: BillingStatus = null;
  displayAlert = false;
  isSubscriptionCanceled = false;
  isPaymentOverdue = false;
  width = document.body.clientWidth;

  constructor(
    private mx: MixpanelService,
    private router: Router,
  ) {}

  ngOnChanges(): void {
    this.determinateAlertType();
    this.setDisplayAlert();
  }

  determinateAlertType = (): void => {
    const ps = this.paymentStatuses;
    this.isSubscriptionCanceled = !!(ps?.ended && !ps?.incomplete_payment);
    this.isPaymentOverdue = !!(ps?.incomplete_payment && ps?.ended);
  };

  setDisplayAlert = (): void => {
    this.displayAlert = this.isSubscriptionCanceled || this.isPaymentOverdue;
  };

  sendMixPanelEvent(value: string): void {
    this.mx.track('click_pay_now', this.router.url, { key: 'where', value });
  }
}
