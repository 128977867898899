import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MixpanelService } from '../../../../@core/services/mixpanel.service';
import { SlugsService } from '../../../../@core/services/automations/slugs.service';

@Component({
  selector: 'norby-slug-manual-trigger',
  templateUrl: './slug-manual-trigger.component.html',
  styleUrls: ['./slug-manual-trigger.component.css'],
})
export class SlugManualTriggerComponent {
  @Output() createManualSlugEvent = new EventEmitter();
  public disabledButton = true;
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private mx: MixpanelService,
    protected ss: SlugsService,
  ) {
    this.form = this.fb.group({
      trigger: '',
    });
  }

  setButtonOn(state: boolean): void {
    this.disabledButton = state;
  }

  getControl(controlName: string): FormControl {
    return this.form.controls[controlName] as FormControl;
  }

  checkTrigger(): void {
    this.mx.track('click_add_manual_automation', 'automations', {
      key: 'trigger',
      value: this.form.controls.trigger.value,
    });
    this.createManualSlugEvent.emit(this.form.value);
  }

  setFormValue(trigger: string): void {
    this.form.controls.trigger.setValue(trigger);
  }
}
