<div
  [ngClass]="{
    container: authService.isAuthenticated && profile,
    'login-container': !authService.isAuthenticated || !profile
  }"
  [ngStyle]="isNeedHelpOpen ? { zIndex: 1125 } : { zIndex: 1115 }"
>
  <norby-logo
    (logoAction)="sendMixpanelEvent('click_norby_logo', '')"
  ></norby-logo>
  <ul
    *ngIf="
      (authService.isAuthenticated | async) &&
      profile &&
      !profile.shouldChangePassword
    "
    class="responsive-flex"
  >
    <ng-container *ngIf="showMenu">
      <div class="main-menu-flex">
        <div class="main-menu-nav">
          <ng-container *ngFor="let option of navBar">
            <li
              (click)="
                sendMixpanelEvent(option.mixpanelEvent, option.tab, {
                  key: 'where',
                  value: 'header'
                })
              "
              *ngIf="
                (option.tab !== 'team' && option.tab !== 'automations') ||
                (option.tab === 'team' && profile.role === 'Support Manager') ||
                (option.tab === 'automations' &&
                  profile.role === 'Support Manager' &&
                  slugToken)
              "
              [routerLink]="[option.tab]"
              routerLinkActive="active"
            >
              {{ option.name }}
            </li>
          </ng-container>
          <li
            (click)="openUserGuide($event)"
            *ngIf="profile.role === 'Support Manager'"
            [ngClass]="{
              'active-button-styles':
                (isOpenGuidePopup$ | async) || isShadowPopup
            }"
          >
            Need Help?
          </li>
        </div>
        <norby-guide-modal
          *ngIf="
            profile.role === 'Support Manager' &&
            profileService.showAutomationGuide
          "
          (closeModal)="profileService.closeAutomationGuide()"
        ></norby-guide-modal>
        <norby-need-help-main
          (closePopupEvent)="closeGuidePopup()"
          *ngIf="
            profile.role === 'Support Manager' && (isOpenGuidePopup$ | async)
          "
        ></norby-need-help-main>
        <norby-need-help-shadow
          (closeEvent)="closeShadowPopup(false)"
          *ngIf="profile.role === 'Support Manager' && isShadowPopup"
        ></norby-need-help-shadow>
        <norby-billing-status-alert
          *ngIf="
            (ps.paymentStatuses$ | async) && profile.role === 'Support Manager'
          "
          [paymentStatuses]="ps.paymentStatuses$ | async"
          class="billing-status-full-screen"
        ></norby-billing-status-alert>
      </div>
      <div>
        <div *ngIf="profile" class="agent-navbar">
          <div class="agent-name">
            <div class="name">
              {{ profile.email }}
            </div>
            <div class="role">{{ profile.role }}</div>
          </div>
          <norby-avatar
            (toggleOnlineStatusEvent)="socketService.setIsOnline($event)"
            (click)="toggleMenu()"
            [profile]="profile"
            class="navbar-avatar"
          >
          </norby-avatar>
        </div>
      </div>
    </ng-container>
  </ul>
  <ul
    *ngIf="!(authService.isAuthenticated | async) && !profile"
    class="navbar mobile"
  >
    <ng-container>
      <li
        (click)="
          sendMixpanelEvent('click_login_link', 'login', {
            key: 'where',
            value: 'header'
          })
        "
        [routerLink]="['/login']"
        class="navbar-mobile-link"
        routerLinkActive="active"
      >
        Log In
      </li>
      <li
        (click)="
          sendMixpanelEvent('click_signup_link', 'signup', {
            key: 'where',
            value: 'header'
          })
        "
        [routerLink]="['/signup']"
        class="navbar-mobile-link"
        routerLinkActive="active"
      >
        Sign Up
      </li>
    </ng-container>
  </ul>
</div>
<div *ngIf="menuOpen && profile" class="main-menu-dropdown">
  <div *ngIf="showMenu">
    <norby-billing-status-alert
      *ngIf="
        (ps.paymentStatuses$ | async) && profile.role === 'Support Manager'
      "
      [paymentStatuses]="ps.paymentStatuses$ | async"
      class="billing-status"
    ></norby-billing-status-alert>
  </div>
  <div class="show-main-items">
    <ng-container *ngFor="let option of navBar">
      <li
        (click)="
          sendMixpanelEvent(option.mixpanelEvent, option.tab, {
            key: 'where',
            value: 'header'
          })
        "
        *ngIf="
          (option.tab !== 'team' && option.tab !== 'automations') ||
          (option.tab === 'team' && profile.role === 'Support Manager') ||
          (option.tab === 'automations' &&
            profile.role === 'Support Manager' &&
            slugToken)
        "
        [routerLink]="[option.tab]"
        class="dropdown-items"
        routerLinkActive="active"
      >
        {{ option.name }}
      </li>
    </ng-container>
    <li
      (click)="openUserGuide($event)"
      *ngIf="profile.role === 'Support Manager'"
      class="dropdown-items"
    >
      Need Help?
    </li>
  </div>
  <li (click)="navigateToSettings()" class="dropdown-items">Settings</li>
  <li (click)="navigateToReferral()" class="dropdown-items referral">
    Referral
  </li>
  <li (click)="logout()" class="dropdown-items">Log Out</li>
  <norby-chooser-form
    (toggleOnlineStatusEvent)="socketService.setIsOnline($event)"
    [status]="profile.status"
    (click)="menuOpen = false"
  ></norby-chooser-form>
</div>
