import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-expose-button',
  templateUrl: './expose-button.component.html',
  styleUrls: ['./expose-button.component.css'],
})
export class ExposeButtonComponent {
  @Input() isOpen = false;
  @Input() color = 'black';
  @Input() background = '';
  public cursorPointer = { cursor: 'pointer' };
  public normalStyle = { transform: 'rotate(0deg)', background: 'transparent' };
  public hoveredStyle = { transform: 'rotate(0deg)', background: '' };
  public isHovered = false;
  public rotatedStyle = { transform: 'rotate(180deg)' };

  setHoverBackground(background: string) {
    this.isHovered = true;
    this.hoveredStyle.background = background;
  }

  clearHoverBackground() {
    this.isHovered = false;
    this.hoveredStyle.background = '';
  }
}
