<div class="voice-container" [style]="position">
  <svg
    width="557"
    height="637"
    viewBox="0 0 557 637"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_3703_18882"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="557"
      height="637"
    >
      <rect width="557" height="637" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3703_18882)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M120.743 145.356C46.6243 231.76 68.3375 354.053 48.2777 463.423C31.0662 557.264 -32.5969 658.247 11.7826 739.428C55.9738 820.265 183.737 798.984 260.133 850.96C341.218 906.126 370.563 1022.6 466.548 1048.83C578.126 1079.32 727.65 1088.93 809.585 1001.12C893.366 911.331 829.692 772.284 835.44 653.488C839.484 569.902 863.582 487.314 838.228 409.11C812.287 329.098 750.713 272.794 692.56 212.784C615.197 132.95 557.355 15.5817 443.914 2.20489C327.087 -11.5713 199.536 53.502 120.743 145.356Z"
        fill="#F5F5F5"
      />
    </g>
  </svg>
</div>
