import { Component } from '@angular/core';
import { SlugsService } from '../../../../../@core/services/automations/slugs.service';
import { ProfileService } from './../../../../../@core/services/profile.service';

@Component({
  selector: 'norby-automatic-slug',
  templateUrl: './automatic-slug.component.html',
  styleUrls: ['./automatic-slug.component.css'],
})
export class AutomaticSlugComponent {
  constructor(
    protected profileService: ProfileService,
    protected ss: SlugsService,
  ) {}
}
