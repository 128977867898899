<div (click)="toggleAssignMenu()" class="assign-dropdown">
  <div>Assign to</div>
  <norby-expose-button
    [isOpen]="assignMenuOpen"
    [background]="'var(--grayF1)'"
  ></norby-expose-button>
  <section *ngIf="assignMenuOpen" class="menu">
    <input
      [(ngModel)]="searchText"
      autocomplete="off"
      class="agent-search"
      norbyAutoFocus
      placeholder="Search ..."
      role="searchbox"
      type="text"
    />
    <div class="agents-container">
      <h2 *ngIf="managers.length" id="managers-title">Managers</h2>
      <div class="agent-list">
        <div
          (click)="assignTo(manager.uid)"
          *ngFor="let manager of managers | agentSearchFilter: searchText"
          class="menu-item"
          data-manager
        >
          <norby-agent-card [agent]="manager"></norby-agent-card>
        </div>
      </div>
      <h2 *ngIf="agents.length" id="agents-title">Support Agents</h2>
      <div class="agent-list">
        <div
          (click)="assignTo(agent.uid)"
          *ngFor="let agent of agents | agentSearchFilter: searchText"
          class="menu-item"
          data-agent
        >
          <norby-agent-card [agent]="agent"></norby-agent-card>
        </div>
      </div>
    </div>
  </section>
</div>
