<div class="main-popup-container" #popup>
  <div class="main-popup-header">
    <div class="main-popup-title">Set up Norby</div>
    <norby-close-icon
      class="cursor-pointer"
      (click)="closePopup()"
    ></norby-close-icon>
  </div>

  <!--  First step-->
  <!--  Disabled state-->
  <div
    class="step-container step-margin"
    *ngIf="(firstStep$ | async)?.isCompleted || isGuideCompleted"
    (click)="openStep('first')"
  >
    <norby-radio-button
      class="mr-fix-position"
      [color]="'var(--gray76)'"
    ></norby-radio-button>
    <p
      class="step-text completed-step"
      [ngStyle]="
        (firstStep$ | async)?.isClickable
          ? { cursor: 'pointer' }
          : { cursor: 'auto' }
      "
    >
      Place the widget code before the
      <span class="red-style">{{ bodyText }}</span> tag and send your first
      message
    </p>
  </div>
  <!--  Not open step-->
  <div
    class="step-container step-margin"
    *ngIf="(firstStep$ | async)?.isFeature"
  >
    <norby-number-one-icon></norby-number-one-icon>
    <p class="step-text feature-step">
      Place the widget code before the
      <span class="red-style">{{ bodyText }}</span> tag and send your first
      message
    </p>
  </div>
  <!--  Open step-->
  <div
    class="step-one"
    *ngIf="(currentPopupStep$ | async)?.step === 'first'"
    [ngStyle]="
      !(firstStep$ | async)?.isClickable
        ? { margin: '28px 0px' }
        : { margin: '0px 0px 28px 0px' }
    "
  >
    <div
      class="step-container"
      *ngIf="!(firstStep$ | async)?.isClickable && !isGuideCompleted"
    >
      <norby-number-one-icon></norby-number-one-icon>
      <p class="step-text">
        Place the widget code before the
        <span class="red-style">{{ bodyText }}</span> tag and send your first
        message
      </p>
    </div>
    <div class="code-box">
      <norby-copy-button (click)="goToSecondStep($event)"></norby-copy-button>
      <norby-widget-code-box
        [ngStyle]="
          !(firstStep$ | async)?.isClickable && !isGuideCompleted
            ? { 'margin-top': '18px', position: 'relative' }
            : { 'margin-top': '0px', position: 'relative' }
        "
        [widgetKey]="apiKey"
        (emitCodeText)="getCode($event)"
        [hideCopyButton]="true"
      ></norby-widget-code-box>
    </div>
    <button (click)="goToSecondStep($event)" class="main-button step-one-btn">
      Copy Code
    </button>
  </div>
  <norby-mark
    [positionStyles]="{
      top: '10px',
      right: 'auto',
      position: 'absolute',
      transform: 'translate(100%, 50%)',
      zIndex: 99999
    }"
    *ngIf="!!(isHidden$ | async)"
    >The widget code was copied</norby-mark
  >

  <!--  Second step-->
  <!--  Disabled state-->
  <div
    class="step-container step-margin"
    *ngIf="(secondStep$ | async)?.isCompleted || isGuideCompleted"
    (click)="openStep('second')"
  >
    <norby-radio-button
      class="mr-fix-position"
      [color]="'var(--gray76)'"
    ></norby-radio-button>
    <p
      class="step-text completed-step"
      [ngStyle]="
        (secondStep$ | async)?.isClickable
          ? { cursor: 'pointer' }
          : { cursor: 'auto' }
      "
    >
      Create your first automation to try the Norby Bot
    </p>
  </div>
  <!--  Not open step-->
  <div
    class="step-container step-margin"
    *ngIf="(secondStep$ | async)?.isFeature && !isGuideCompleted"
  >
    <norby-number-two-icon></norby-number-two-icon>
    <p class="step-text feature-step">
      Create your first automation to try the Norby Bot
    </p>
  </div>
  <!--  Open step-->
  <div
    class="step-second"
    *ngIf="(currentPopupStep$ | async)?.step === 'second'"
    [ngStyle]="
      !(secondStep$ | async)?.isClickable && !isGuideCompleted
        ? { margin: '28px 0px' }
        : { margin: '0px 0px 28px 0px' }
    "
  >
    <div
      class="step-container"
      *ngIf="!(secondStep$ | async)?.isClickable && !isGuideCompleted"
    >
      <norby-number-two-icon></norby-number-two-icon>
      <p class="step-text">Create your first automation to try the Norby Bot</p>
    </div>
    <div class="step-notice">
      The bot responds to your client's questions based on your automation
      content.
      <p class="gray-text">LEARN MORE</p>
      <div class="info-link">
        <a
          class="info-url"
          [href]="
            'https://norby-io.notion.site/Automatizations-Overview-and-How-to-Set-Up-7f5726c63ba34f388bd3aa910a0310c5'
          "
          target="_blank"
          >Automations: Overview and How to Set Up</a
        >
        <norby-external-link-icon
          [url]="
            'https://norby-io.notion.site/Automatizations-Overview-and-How-to-Set-Up-7f5726c63ba34f388bd3aa910a0310c5'
          "
        ></norby-external-link-icon>
      </div>
    </div>
    <a
      (click)="goToThirdStep($event)"
      [routerLink]="'/automations'"
      class="main-button step-one-btn"
      >Go to the Automation Page</a
    >
  </div>

  <!--  Third step-->
  <!--  Disabled state-->
  <div
    class="step-container step-margin"
    *ngIf="(thirdStep$ | async)?.isCompleted || isGuideCompleted"
    (click)="openStep('third')"
  >
    <norby-radio-button
      class="mr-fix-position"
      [color]="'var(--gray76)'"
    ></norby-radio-button>
    <p
      class="step-text completed-step"
      [ngStyle]="
        (thirdStep$ | async)?.isClickable
          ? { cursor: 'pointer' }
          : { cursor: 'auto' }
      "
    >
      Try the New Automation in Action
    </p>
  </div>
  <!--  Not open step-->
  <div
    class="step-container step-margin"
    *ngIf="(thirdStep$ | async)?.isFeature && !isGuideCompleted"
  >
    <norby-number-three-icon></norby-number-three-icon>
    <p class="step-text feature-step">Try the New Automation in Action</p>
  </div>
  <!--  Open step-->
  <div
    class="step-third"
    *ngIf="(currentPopupStep$ | async)?.step === 'third'"
    [ngStyle]="
      !(thirdStep$ | async)?.isClickable && !isGuideCompleted
        ? { margin: '28px 0px' }
        : { margin: '0px 0px 28px 0px' }
    "
  >
    <div
      class="step-container"
      *ngIf="!(thirdStep$ | async)?.isClickable && !isGuideCompleted"
    >
      <norby-number-three-icon></norby-number-three-icon>
      <p class="step-text">Try the New Automation in Action</p>
    </div>
    <div class="step-notice">
      The bot responds to your client's questions based on your automation
      content.
      <p class="gray-text">ANSWERS EXAMPLE</p>
      <div class="example">
        <div class="client-message-container">
          <div class="client-message">
            <p>Hi! Tell me about OrangeHarvest foods</p>
          </div>
          <norby-client-icon></norby-client-icon>
        </div>
        <div class="bot-message-container">
          <norby-bot-icon></norby-bot-icon>
          <div class="bot-message">
            <p>
              OrangeHarvest Foods pioneers sustainable, organic products,
              fostering health and environmental stewardship with local
              partners.
            </p>
          </div>
        </div>
      </div>
    </div>
    <button (click)="completeSetUp()" class="main-button step-one-btn">
      Complete Set Up
    </button>
  </div>

  <!--  Footer-->
  <div class="main-popup-footer">
    <a
      target="_blank"
      href="https://norby-io.notion.site/Norby-Help-5f232b5d0bab47d98fada69631841c2c"
      class="footer-link"
      >Go to Knowledge Base</a
    >
  </div>
</div>
